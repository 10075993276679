
	<h3 class="title">Datos del cliente</h3>
    
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ customer?.name_customer + " " + customer?.lastName_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">DNI</span>
					<span>{{ customer?.identifier_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Telefono</span>
					<span>{{ customer?.phoneNumber_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ customer?.adress_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Codigo</span>
					<span>{{ customer?.code_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Email</span>
					<span>{{ customer?.email_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ customer?.status_customer  || '-'}}</span>
				</div>
      </div>

      <div *ngIf="customer?.sucursal_id != null" class="field-row">
        <div class="field-row-item">
          <span class="label">Sucursal</span>
          <span>{{ customer?.sucursal_id  || '-'}}</span>
        </div>
      </div>

  <br>
  <h2 class="title">Planes del cliente</h2>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			<ng-container matColumnDef="plan.name_plan">
				<th mat-header-cell *matHeaderCellDef  >Plan</th>
				<td mat-cell *matCellDef="let element">{{element?.plan.name_plan || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="startdate_customerPlan">
				<th mat-header-cell *matHeaderCellDef  >Fecha inicio</th>
				<td mat-cell *matCellDef="let element">{{element?.startdate_customerPlan || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="endDate_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Fecha fin</th>
        <td mat-cell *matCellDef="let element">{{element?.endDate_customerPlan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="next_bill_date">
        <th mat-header-cell *matHeaderCellDef  >Proxima fecha de cobro</th>
        <td mat-cell *matCellDef="let element">{{element?.next_bill_date || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="contractNumber_Plan">
        <th mat-header-cell *matHeaderCellDef  >Contrato</th>
        <td mat-cell *matCellDef="let element">{{element?.contractNumber_Plan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="status_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element?.status_customerPlan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="balance_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Balance</th>
        <td mat-cell *matCellDef="let element">{{element.balance_customerPlan }}</td>
      </ng-container>

      <ng-container matColumnDef="plan.feeCost_plan">
        <th mat-header-cell *matHeaderCellDef  >Costo</th>
        <td mat-cell *matCellDef="let element">{{element.plan.feeCost_plan }}</td>
      </ng-container>


      <ng-container matColumnDef="plan.enrollmentCost_plan">
        <th mat-header-cell *matHeaderCellDef  >inscripción</th>
        <td mat-cell *matCellDef="let element">{{element.plan.enrollmentCost_plan }}</td>
      </ng-container>

      <ng-container matColumnDef="plan.freezeCostPlan">
        <th mat-header-cell *matHeaderCellDef  >Congelamiento</th>
        <td mat-cell *matCellDef="let element">{{element.plan.freezeCostPlan }}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
