
	<h3 class="title">Respuesta obtenida para el pago</h3>


	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Autorization number</span>
					<span>{{ authorizationNumber || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Reference number</span>
					<span>{{ referenceNumber || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">System trace number</span>
					<span>{{ systemTraceNumber || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Transaction ID</span>
					<span>{{ transactionId || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Host date</span>
					<span>{{ hostDate || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Host time</span>
					<span>{{ hostTime || '-'}}</span>
				</div>
			</div>

    </div>

    <div class="options">
      <button class="btn-text" type="button" (click)="atras()">
        <span>Volver</span>
      </button>
    </div>

  </div>
  
