
	<h3  class="title">Listado de opciones</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.partner">
							<span class="label">{{label}}</span>
							<span>{{partner.name_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.service">
							<span class="label">Servicio</span>
							<span>{{service.name_service}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.plan">
							<span class="label">Servicio</span>
							<span>{{service.name_service}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name">Nombre</label>
							<input type="text" name="name" placeholder="None"  [(ngModel)]="filter.name">
						</div>

						<div class="field-row-item">
								<label class="label" for="company" >Compañia</label>
								<mat-select [formControl]="partnerS" [(value)]="filter.partner"  [disabled]="true">
									<mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
								</mat-select>
						</div>

						<div class="field-row-item">
								<label class="label" for="service" >Servicio</label>
								<mat-select [formControl]="serviceS"  [(value)]="filter.service"  [disabled]="true">
									<mat-option *ngFor="let opt2 of services" [value]="opt2.id">{{opt2.name_service}}</mat-option>
								</mat-select>
            </div>
            
            <div class="field-row-item">
                <label class="label" for="service" >Plan</label>
                <mat-select [formControl]="planS"  [(value)]="filter.plan" (selectionChange)="changePlan($event.value)" >
                  <mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
                </mat-select>
            </div>


					</div>

					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['options.create']" class="right row">
			<button  class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      
      <ng-container matColumnDef="position">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Posición</th>
				<td mat-cell *matCellDef="let element">{{element.position}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['options.create']"  contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
      </ng-template>
      
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
