import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {RebillingService} from '../rebilling.service';
import cronstrue from 'cronstrue';

@Component({
  template: `
	<h3 class="title">Detalle de la renovacion</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
    <div class="fieldset">
    
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ rebilling?.name_rebillingsetting || '-'}}</span>
				</div>
			</div>
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Billing</span>
					<span>{{ rebilling?.billingSetting?.name_billingsetting || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fase</span>
					<span>{{ fase || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Frecuencia</span>
					<span>{{  time || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Próxima fecha de ejecución</span>
					<span>{{ rebilling?.next_date_execution_rebillingsetting || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha  ultima ejecución</span>
					<span>{{ rebilling?.date_execution_rebillingsetting || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha ejecución de proceso almacenado</span>
					<span>{{ rebilling?.date_execution_storeprocedure || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha ejecución exitosa de proceso almacenado</span>
					<span>{{ rebilling?.date_successfullexecution_storeprocedure|| '-'}} </span>
				</div>
			</div>

			

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Cantidad de intentos</span>
					<span>{{ intentos  }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Cantidad de dias</span>
					<span>{{ dias }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Dias para intentar cobro</span>
					<span>{{ rebilling?.retry_frecuency_rebillingsetting }} </span>
				</div>
			</div>


			<div class="field-row">
				<div class="field-row-item">
				<span class="label">Dias de corte</span>
				<br>
				<span *ngFor="let day of rebilling?.rebillingDays" >
					- {{day.day}}
					<br>
          		</span>
          		<span *ngIf="rebilling?.rebillingDays.length == 0">
					- NO posee dias de corte asociados
				</span>
						</div>
			  </div>
			  

			<div class="field-row">
				<div class="field-row-item">
				<span class="label">Se aplica en</span>
				<br>
				<span *ngFor="let error of errorCodes" >
					- {{error.description_errorcodebac}}
					<br>
          		</span>
          		<span *ngIf="errorCodes.length == 0">
					- Para ningun error
				</span>
						</div>
      		</div>

		</div>
	</div>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
`
})
export class ReadRebillingComponent implements OnInit {

  confirmDelete = true;
  errorCodes = [];
  rebilling;
  name;
  status;
  fase;
  time;
  intentos;
  dias;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rebillingService: RebillingService,
    private notificationService: NotificationService
  ) { }


  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(param => {
        this.rebillingService.getById(param['rebillingId']).subscribe(rebilling => {
		  this.rebilling = rebilling;
          this.errorCodes = rebilling.errorCodeBacs;
          this.status = rebilling.status_rebillingsetting  === 1 ? 'Activo' : 'Inactivo';
          this.fase = rebilling.progress_rebillingsetting === 1 ? 'To Start' : 'Running';
          if (rebilling.typeoftry_rebillingsetting === 1) {
            this.intentos = rebilling.qtytry_rebillingsSetting;
            this.dias = '-';
          }else {
            this.dias = rebilling.qtytry_rebillingsSetting;
            this.intentos = '-';
          }
          this.time = cronstrue.toString(rebilling.time_rebillingsetting);
        }, err => {
          console.log(err);
          this.notificationService.error(err);
        });
      }, err => this.notificationService.error(err));

  }

  update() {
    this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
  }

  delete(this) {
    console.log("desarrollar")
  }

}
