
	<h3 class="title">Bitacora de Recobros</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					
						<div class="field-row-item" *ngIf="tableService.filter.del">
							<span class="label">Del</span>
              <span *ngIf="tableService.filter.del == 1"> Dia </span>
              <span *ngIf="tableService.filter.del == 2"> Dia Anterior </span>
              <span *ngIf="tableService.filter.del == 3"> Mes </span>
              <span *ngIf="tableService.filter.del == 4"> Mes Anterior </span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.id_rebilling">
							<span class="label">Recobro</span>
							<span>{{tableService.filter.id_rebilling}}</span>
						</div>

					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
        <fieldset class="fieldset">
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_plan">Del</label>
              <input type="radio" value="1" (change)="dropBusqueda()" name="type">
            </div>
            <div class="field-row-item">
              <label class="label" for="name_plan">Rango de fecha</label>
              <input type="radio" value="2" (change)="rangoBusqueda()" name="type">
            </div>
					</div>
			
					<div class="field-row" *ngIf="del">
            <div class="field-row-item">
              <mat-select placeholder="Selecciona" [(value)]="filter.del"  disableRipple>
                <mat-option *ngFor="let opt of types" [value]="opt.value">{{opt.desc}}</mat-option>
              </mat-select>
            </div>
					</div>
                   
          <div class="field-row" *ngIf="rango">

						<div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
            
            <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
          </div>
          
          <div class="field-row">
							<div class="field-row-item">
								<label class="label" for="service" >Recobro</label>
								<mat-select placeholder="None" [(value)]="filter.id_rebilling" disableRipple>
									<mat-option *ngFor="let rebilling of rebillings" [value]="rebilling.id">{{rebilling.name_rebillingsetting}}</mat-option>
								</mat-select>
							</div>
          </div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>
  
  <div class="tool-bar-wrap both-side">
    <div class="right row">
    <button class="btn-icon" type="button" (click)="export()">
      <i class="material-icons">cloud_download</i>
    </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
	
			<ng-container matColumnDef="idprocess">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >ID Proceso</th>
				<td mat-cell *matCellDef="let element">{{element?.idprocess || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element?.description || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha creacion</th>
        <td mat-cell *matCellDef="let element">{{element.date_created}}</td>
      </ng-container>

      <ng-container matColumnDef="qtyprocessed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Cantidad</th>
        <td mat-cell *matCellDef="let element">{{element.qtyprocessed}}</td>
      </ng-container>

     

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
