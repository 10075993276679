import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {UserService} from '../user.service';

@Component({
  template: `
	<h3 class="title">Detalle del usuario</h3>

  <div class="tool-bar-wrap both-side">
  
    <div class="right row">
      <button class="btn-icon" title="Actualizar clave" type="button" (click)="updatePassword()">
					<i class="material-icons">vpn_key</i>
			</button>
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ name }}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Apellido</span>
					<span>{{ lastname }}</span>
				</div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <span class="label">Email</span>
          <span>{{ email }}</span>
        </div>
      </div>


      <div class="field-row">
				<div class="field-row-item">
          <span class="label">Roles</span>
          <br>
          <span *ngFor="let r of roles" >
            - {{r.description}}
            <br>
          </span>
          <span *ngIf="roles.length == 0">
            - No posee roles asociados
          </span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
          <span class="label">Grupos</span>
          <br>
          <span *ngFor="let r of grupos" >
            - {{r.description}}
            <br>
          </span>
          <span *ngIf="grupos.length == 0">
            - No posee grupos asociados
          </span>
				</div>
      </div>
      
    </div>
   </div>
`
})
export class UserReadComponent implements OnInit {

  grupos: any[] = [];
  roles: any[] = [];
  partners: any[] = [];
  name;
  lastname;
  email;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  //  localStorage.setItem('data', JSON.stringify({user: item.id, token : item.token}));

  // JSON.parse(localStorage.getItem("actualUser")).user

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(param => {
        this.userService.getById(param['userId']).subscribe(user => {
          this.name = user['name_user'];
          this.lastname = user['lastName_user'];
          this.email =  user['email_user'];
          localStorage.setItem('authUser', JSON.stringify({user: user.authUser.id}))
          this.grupos = user['authUser']['groups'];
          this.roles = user['authUser']['roles'];
          this.partners = user['partners'];
        }, err => {
          console.log(err);
          this.notificationService.error(err);
        });
      }, err => this.notificationService.error(err));
  }

  update() {
    this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
  }
  updatePassword() {
		this.router.navigate(['./password'], {relativeTo: this.activatedRoute});
	}
}
