import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {BillingService} from '../billing.service';
import {Group} from '../../group/Group';
import cronstrue from 'cronstrue';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { BillingFilter } from '../billing.filter';

@Component({
  template: `

	<h3 class="title">Listado de Renovaciones</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name_billingsetting">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name_billingsetting}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name_billingsetting">Nombre</label>
							<input type="text" name="name_billingsetting" placeholder="None"  [(ngModel)]="filter.name_billingsetting">
						</div>
					</div>

					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="status_partner" >Estatus</label>
							<mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
								<mat-option >None</mat-option>
								<mat-option [value]="'1'">Activo</mat-option>
								<mat-option [value]="'2'">Inactivo</mat-option>
							</mat-select>
						</div>
					</div>
					-->
					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button *hasPermission="['billingsetting.create']" class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
			
			<button class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i  class="material-icons">delete</i>
			</button>

		

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
		<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_billingsetting">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_billingsetting}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status_billingsetting">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status_billingsetting}}</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="time_billingsetting">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.time_billingsetting}}</td>
      		</ng-container>
      
      <ng-container matColumnDef="progress_billingsetting">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Progreso</th>
				<td mat-cell *matCellDef="let element">{{element.progress_billingsetting}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['billingsetting.update']"  contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
      </ng-template>

      <ng-template contextMenuItem divider="true"></ng-template>
			<ng-template contextMenuItem (execute)="rebilling($event.item)">
				<div class="item">
					<i class="material-icons">loop</i>
					<span>Recobros</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['billingsetting.update']" contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span>Eliminar</span>
				</div>
			</ng-template>
		
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})
export class ListBillingComponent implements OnInit {

  
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['select', 'id', 'name_billingsetting', 'status_billingsetting','time_billingsetting', 'progress_billingsetting'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new BillingFilter(this.tableService.filter);
  items: any[] = [];
  billings: object = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) { 
	this.tableService = new TableService;
   }

  ngOnInit() {
    this.list(); // listamos la informacion que se mostrara en la tabla
  }
  list(event?: PageEvent) {

	if(this.tableService.pager.pageSize != 20){
		this.tableService.pager.pageSize = 20;
	}

	let httpParams = this.billingService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
	httpParams = this.filter.getHttpParams(httpParams);

    this.items = [];
    this.billingService.getAll(httpParams).subscribe(params => {
      if (params['result'] != null) {
		this.items = params['result'];
        for (const billing of this.items) {
          billing.status_billingsetting = billing.status_billingsetting === 1 ? 'Activo' : 'Inactivo';
		  billing.progress_billingsetting = billing.progress_billingsetting === 1 ? 'To Start' : 'Running';
		  billing.time_billingsetting = cronstrue.toString(billing.time_billingsetting);
        }
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = params['pager'];
        this.tableService.selection.clear();
				
      }
    }, err => {
      this.notificationService.error(err);
    });
  }


  update(item: any) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  rebilling(item: any) {
    this.router.navigate(['./' + item.id + '/rebillings'], {relativeTo: this.activatedRoute});
  }

  create() { /// crear un nuevo billing en el sistema
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: Group) { // leer un partner
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }
  //-- 

deletes() {
	const length = this.tableService.selection.selected.length;
	this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
		--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
      this.billingService.delete(this.tableService.selection.selected[0].id).subscribe(any => {
        this.notificationService.sucessDelete(any.name_billingsetting);
        this.list();
      }, err => this.notificationService.error(err));
		} else if (length > 1) {
      let ids : Array<number> = [];
				for(const r of this.tableService.selection.selected ){
					ids.push(r.id);
				}
        this.billingService.deletes({'ids': ids}).subscribe(any =>  {
          this.notificationService.sucessDelete();
          this.list();
        }, err => this.notificationService.error(err));
	} 
}

reset() {
	this.filter = new BillingFilter();
	this.list();
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new BillingFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new BillingFilter(this.tableService.filter);
}

compareFn(c1: any, c2: any): boolean { 
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}
applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
