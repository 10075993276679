import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';

@Injectable()
export class DashboardService extends BaseService {
    private readonly BASE_URL: string = this.HOST + '/dashboard';

    httpOptions : any;

    constructor(
      private http: HttpClient
      ) { 
        super();
      // Http Headers
      this.httpOptions = {
        headers: this.createAuthorizationHeader()
      } 
  }

    getByAuthId(id: number): Observable<any> {
        return this.http.get(this.BASE_URL + '/auth/' + id, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    create(dashboard: any): Observable<any> {
      
      return this.http.post(this.BASE_URL, dashboard, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

    }
  
    update(dashboard: any): Observable<any> {
      return this.http.put(this.BASE_URL + '/' + dashboard['id'], dashboard , this.httpOptions)
       .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }
}