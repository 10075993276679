import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';
import { Validators, FormControl } from '@angular/forms';

@Component({
  template: `
	<h3 class="title">Reporte de facturas por cliente</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.company">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.company}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.parameter">
							<span class="label">Cliente</span>
							<span>{{tableService.filter.parameter}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
            <div class="field-row-item">
              <label class="label" for="company" >Compañia</label>
              <mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.company" disableRipple>
                <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
              </mat-select>
            </div>

            <div class="field-row-item">
                  <label class="label" for="parameter">DNI o Código</label>
                  <input type="text" name="parameter" placeholder="None"  [(ngModel)]="filter.parameter">
            </div>
					</div>

			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>
  
  <div class="tool-bar-wrap both-side">
    <div class="right row">
    <button class="btn-icon" type="button" (click)="export()">
      <i class="material-icons">cloud_download</i>
    </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="invoice">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id Factura</th>
				<td mat-cell *matCellDef="let element">{{element.invoice}}</td>
			</ng-container>

			<ng-container matColumnDef="customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.customer}}</td>
      </ng-container>
      
      <ng-container matColumnDef="centro">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
				<td mat-cell *matCellDef="let element">{{element.sucursal}}</td>
			</ng-container>
			
      
      <ng-container matColumnDef="estatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.estatus}}</td>
      </ng-container>


      <ng-container matColumnDef="creada">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.creada}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row" (click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  <context-menu #basicMenu>

			<ng-template *hasPermission="['processfile.process']" contextMenuItem (execute)="cancelPayment($event.item)">
				<div class="item">
					<i class="material-icons">cancel</i>
					<span>Cancelar Cobro</span>
				</div>
			</ng-template>
	</context-menu>
`
})
export class CustomerInvoicesComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'invoice', 'estatus', 'creada', 'customer','centro'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

  partners: any[]= [];
  selectedPartner: any;
  partner: any;
  partnerS: FormControl = new FormControl('', Validators.required);

  items: any[] = [];


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}

  ngOnInit() {
    let httpParams = this.partnerService.buildRequestParams();

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result'];
      // cargamos sucursales
      httpParams = httpParams.set('parents',true.toString());
      httpParams = httpParams.set('parent',(-1).toString());
      this.partnerService.getPartnersByUserId(httpParams).subscribe(params =>{
        for( var p of params['result']){
          this.partners.push(p)
        }
        if(localStorage.getItem('searchInvoice')){
          this.partnerS.setValue(this.partners[this.partners.findIndex(p => JSON.parse(localStorage.getItem('searchInvoice')).company == p.id)].id)
          this.filter.company = this.partnerS.value;
          this.filter.parameter = JSON.parse(localStorage.getItem('searchInvoice')).customer;
          localStorage.removeItem("searchInvoice");
          this.search();
        }
      });
      
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  list(event?: PageEvent) {
    
      if(this.tableService.pager.pageSize != 20){
        this.tableService.pager.pageSize = 20;
      }
      this.items = [];


      if(this.filter.company == null || this.filter.company == undefined){
        this.notificationService.error("Debe seleccionar una Compania");
        return;
      }
      if(this.filter.parameter == null || this.filter.parameter == undefined){
          this.notificationService.error("Debe ingresar DNI o Nombre");
          return;
      }

      let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
      httpParams = this.filter.getHttpParams(httpParams);
    
      this.reportService.getReportCustomerInvoices(httpParams).subscribe(params => {
          this.items = params['result']; // items que mostrara la tabla
          for(var customer of this.items){
            if(customer.sucursal == null){
              customer.sucursal = "N/A";
            }
          }
          this.dataSource = new MatTableDataSource<any>(this.items);
          this.tableService.pager = params['pager'];
          this.tableService.selection.clear();
          if (this.items.length === 0) {
            this.notificationService.alert('No se encontraron resultados para la busqueda');
          }
        }, err => {
          this.notificationService.error(err);
          console.log(err);
      }); 
     
  }

  cancelPayment(item) {
    this.reportService.cancelInvoice(item.invoice).subscribe(response =>{
      this.notificationService.sucessUpdate(response.id);
      this.list();
    })
  }



reset() {
	this.filter = new ReportFilter();
  this.dataSource = new MatTableDataSource<any>([]);
  this.tableService.filter = new ReportFilter(this.filter);
  this.partnerS =  new FormControl('');
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new ReportFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new ReportFilter(this.tableService.filter);
}

export() {
  if(this.items.length > 0){
   
      this.tableService.filter = new ReportFilter(this.filter);
     

      let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
      {pageIndex: -1 , pageSize: -1})

      httpParams = this.filter.getHttpParams(httpParams);

      this.reportService.getReportCustomerInvoices(httpParams).subscribe(params => {
        let data = [];
         for(var item of params['result']){
          if(item.sucursal == null){
            item.sucursal = "N/A";
          }
          data.push({'ID':item.invoice,'ESTATUS':item.estatus,'FECHA':item.creada,'CLIENTE':item.customer,'CENTRO':item.sucursal})
         }
      
        const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          useTextFile: false,
          filename: "Report-Invoices-Customer-"+date,
          useBom:false,
          useKeysAsHeaders: true,
          showTitle: true,
          title: 'Facturas por cliente - Fecha:' + date,
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
      
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      }); 

    }else{
      this.notificationService.error("No existen registros para exportar");
    }
    
  }

  read(item) {
    localStorage.setItem("searchInvoice", JSON.stringify({company: this.filter.company, customer : this.filter.parameter}));
    this.router.navigate(['./' + item.invoice], {relativeTo: this.activatedRoute });
  }

}
