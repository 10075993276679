
	<h3 class="title">Reporte de notificaciones fallidas</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

				
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
          <div class="field-row-item">
            <label class="label" for="company" >Compañia</label>
            <mat-select placeholder="None" [(value)]="filter.id_partner" >
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>
          </div>
					</div>

          <div class="field-row">

						<div class="field-row-item">

							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>

            			</div>
            
            			<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker2></mat-datepicker>
            			</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

  <!--
	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>
-->


	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="customer.name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.customer.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="customer_has_plan.plan.name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.customer_has_plan.plan.name_plan}}</td>
      </ng-container>
      
      <ng-container matColumnDef="notification_response">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Respuesta</th>
        <td mat-cell *matCellDef="let element">{{element.notification_response}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Creada</th>
        <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
	  </ng-container>
	  

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
