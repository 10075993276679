import {Component,OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {BaseService} from '../../shared/services/base.service';
import {Location} from '@angular/common';
import {PartnerService} from '../../partner/partner.service';
import {FormBuilder, Validators} from '@angular/forms';
import {EnrollmentService} from '../enrollment.service';


@Component({
  template: `
	<h3 class="title">Importar Clientes</h3>

	<form *ngIf="uploadFileForm" [formGroup]="uploadFileForm" (ngSubmit)="upload()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" >Compañia</label>

          <mat-select [formControl]="uploadFileForm.controls['selectedPartner']"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>

				</div>
				<div class="field-row-item">
          <label class="label" >Sucursal</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedSucursal']"  (selectionChange)="onSucursalChange($event.value)" disableRipple>
							<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
          <label class="label" >Servicio</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedService']"  (selectionChange)="onServiceChange($event.value)" disableRipple>
              <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
          <label class="label" >Plan</label>

          <mat-select [formControl]="uploadFileForm.controls['plan']"  disableRipple>
              <mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>

        </div>
        
        <div class="field-row-item">
          <label class="label">Archivo</label>
          <input style="display:inline; width: 90%;" type="file" (change)="onFileChange($event)" required>
          <button style="display:inline;" mat-raised-button 
            matTooltip="El archivo de clientes NO debe tener header y la siguiente estructura:
            Columna 1: Identificador(DNI) -
            Columna 2: Primer Nombre - 
            Columna 3: Primer Apellido - 
            Columna 4: Teléfono - 
            Columna 5: Dirección - 
            Columna 6: Email - 
            Columna 7: Fecha de nacimiento (yyyy-MM-dd) - 
            Columna 8: Fecha de admisión (yyyy-MM-dd) - 
            Columna 9: Fecha de culminación (yyyy-MM-dd) - 
            Columna 10: Número de contrato - 
            Columna 11: Código de cliente - 
            Columna 12: Número TDC - 
            Columna 13: Nombre de TDC - 
            Columna 14: Mes expiración - 
            Columna 15: Año Expiración - 
            Columna 16: Fecha de próximo Cobro"
                  aria-label="Button that displays a tooltip when focused or hovered over" >
                  <i class="material-icons">info</i>
          </button>
        </div>
        
			</div>
			

	

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!uploadFileForm.valid">
				<span>Continuar</span>
			</button>
		</div>
  </form>
  

  <modal-ns [(closed)]="confirmation">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea procesar los cobros de este archivo?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmation = true">
						<span >NO</span>
					</button>
					<button class="btn-text green" type="button" (click)="onCharge(); confirmation = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
  </modal-ns>
  
  <modal-ns [(closed)]="back">
		<ng-template modalContentDirective>
      <div class="dialog-content">
      <div class="dialog-title" >Notificacion</div>
				<div class="dialog-message" >El proceso de cobro se esta ejecutando, usted recibira un email con el resultado, adicionalmente puede consultar el estado de la importacion aqui</div>
				<div class="options">
					<button class="btn-text green" type="button" (click)="backToList()">
						<span >Consultar</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})
export class EnrollmentCreateComponent implements OnInit {


  partners: any[]= [];


  sucursales: any[]= [];
  

  services: any[]= [];
  

  plans: any[]= [];
  
  billings: any[]= [];
 

  fileName;
  fileType;
  fileValue;
  fileToSend;
  nameFound = true;
  message: string;
  total: number;
  id_file: number;
  back = true;
  confirmation = true;

  uploadFileForm = this.fb.group({
    selectedPartner:['',[Validators.required]],
    selectedSucursal:[''],
    selectedService:['',[Validators.required]],
    plan: ['',[Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private location: Location,
    private partnerService: PartnerService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private enrollmentService: EnrollmentService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    let httpParams = this.partnerService.buildRequestParams();

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result']; // items que mostrara la tabla
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  onPartnerChange(partner: any) {
    this.services = [];
    this.sucursales = [];
    this.plans = [];
    if(partner != undefined){
       this.partnerService.getPartnersByParent(partner).subscribe(sucursales => {
        this.sucursales = sucursales['result'];
        if (this.sucursales.length === 0) {
          this.notificationService.info('Info', 'Esta Compañia no posee sucursales');
          this.servicesService.getByPartner(partner).subscribe( services => {
            this.services = services['result'];
            if (this.services.length === 0) {
              this.notificationService.info('Info', 'Esta Compañia no posee servicios');
            }
          }, err => {
            this.notificationService.error('Error al cargar los servicios de la sucursal');
            console.log(err);
          });
        }
      }, err => {
        this.notificationService.error('Error consultando las sucursales del partner seleccionado');
        console.log(err);
      });
    }
  }

  onSucursalChange(partner: any) {
    this.services = [];
    this.plans = [];
   
    this.billings = [];
    if(partner != undefined){
      this.servicesService.getByPartner(partner).subscribe( services => {
        this.services = services['result'];
        if (this.services.length === 0) {
          this.notificationService.info('Info', 'Esta sucursal no posee servicios');
        }
      }, err => {
        this.notificationService.error('Error al cargar los servicios de la sucursal');
        console.log(err);
      });
    }
  }

  onServiceChange(service : any) {
   
    this.plans = [];
    
   // this.selectedService = this.services[value];
  
    this.billings = [];
   
    if(service != undefined){
      this.planServices.getByService(service).subscribe( plans => {
        this.plans = plans['result'];
      }, error1 => {
        this.notificationService.error('Error cargando los planes del servicio');
        console.log(error1);
      });
    }
   
  }

  upload() {
    
    const obj = <any> BaseService.builderObject(this.uploadFileForm.value);
   
    obj.plan = {id: obj.plan};
    obj.user = {id: JSON.parse(localStorage.getItem('currentData')).user.id};
    obj.name_source = new Date();
    obj.status = 1;
    obj.value = this.fileValue;

    if (obj.value === null || obj.value === undefined) {
      this.notificationService.error('Debe seleccionar un archivo para cargar');
      return;
    }

    this.back = false;

    this.enrollmentService.create(obj).subscribe(response =>{
      
    });

  }

  onConfirmation(){
    this.confirmation = false;
  }

  backToList(): void {
    this.location.back();
  }


  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileName = file.name;
        this.fileType = file.type;
        this.fileValue = (<string>reader.result).split(',')[1];
      };
    }
  }


}
