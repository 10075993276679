<h3 class="title">Reporte de facturas por cobrar</h3>

<mat-accordion>
    <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
        <mat-expansion-panel-header>
            <div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
                <div class="field-row">
              

                    <div class="field-row-item" *ngIf="tableService.filter.id_billing">
                        <span class="label">Renovacion</span>
                        <span>{{tableService.filter.id_billing}}</span>
                    </div>

                    <div class="field-row-item" *ngIf="tableService.filter.date">
                        <span class="label">Fecha</span>
                        <span>{{tableService.filter.date}}</span>
                    </div>

                  
                    
                </div>
            </div>
            <button class="btn-icon"  title="Buscar" type="button">
                <i class="material-icons">search</i>
            </button>
        </mat-expansion-panel-header>

        <form>
            <fieldset class="fieldset">
               
                <div class="field-row">
                    <div class="field-row-item">
                        <label class="label" for="service" >Renovacion</label>
                        <mat-select placeholder="None" [(value)]="filter.id_billing" disableRipple>
                            <mat-option *ngFor="let billing of billings" [value]="billing.id">{{billing.name_billingsetting}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="field-row-item">
                        <label style="display: block;" class="label" for="service" >Fecha</label>
                        <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker"  [(ngModel)]="filter.date" [ngModelOptions]="{standalone: true}" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </div>
                </div>

        
            </fieldset>
            <div class="options">
                <button class="btn-text gray" type="button" (click)="reset()">
                    <span >Limpiar</span>
                </button>
                <button class="btn-text blue" type="button" (click)="search()">
                    <span>Buscar</span>
                </button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>

<div class="tool-bar-wrap both-side">
<div class="right row">
<button class="btn-icon" type="button" (click)="export()">
  <i class="material-icons">cloud_download</i>
</button>
</div>
</div>


<div class="mat-elevation-z8e" *ngIf="dataSource">

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
        
        <ng-container matColumnDef="identifier_customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
            <td mat-cell *matCellDef="let element">{{element.identifier_customer}}</td>
        </ng-container>

        <ng-container matColumnDef="name_customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
            <td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
        </ng-container>
  
         <ng-container matColumnDef="last_name_customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Apellido</th>
            <td mat-cell *matCellDef="let element">{{element.last_name_customer}}</td>
        </ng-container>
        
  
        <ng-container matColumnDef="Contribuyente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Contribuyente</th>
            <td mat-cell *matCellDef="let element">{{element.Contribuyente}}</td>
        </ng-container>


        <ng-container matColumnDef="code_customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Código</th>
            <td mat-cell *matCellDef="let element">{{element.code_customer}}</td>
        </ng-container>

        <ng-container matColumnDef="name_plan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
            <td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
        </ng-container>

        <ng-container matColumnDef="centro">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
            <td mat-cell *matCellDef="let element">{{element.sucursal}}</td>
        </ng-container>

        <ng-container matColumnDef="next_bill_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha de cobro</th>
            <td mat-cell *matCellDef="let element">{{element.next_bill_date}}</td>
        </ng-container>

        <ng-container matColumnDef="total_invoice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Total</th>
            <td mat-cell *matCellDef="let element">{{element.total_invoice}}</td>
        </ng-container>

        <ng-container matColumnDef="status_invoice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
            <td mat-cell *matCellDef="let element">{{element.status_invoice}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
        </tr>
    </table>

    <mat-paginator showFirstLastButtons
        [length]="tableService.pager.totalEntities"
        [pageIndex]="tableService.pager.pageIndex"
        [pageSize]="tableService.pager.pageSize"
        [pageSizeOptions]="[20, 50, 100]"
        (page)="list($event)">
    </mat-paginator>
</div>
