import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { User } from '../user/user';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

export interface Filter {
	firstName?: string;
	lastName?: string;
}

@Injectable()
export class UserService extends BaseService {
	private readonly BASE_URL: string = this.HOST + '/user';

	httpOptions : any;

	constructor(
		private http: HttpClient
	) {
		super();
         // Http Headers
         this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
	}

	getById(id: number): Observable<any> {
		 // Http Headers
         this.httpOptions = {
			headers: this.createAuthorizationHeader()
		  }
		return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
		.pipe(
			map(this.extractData),
			catchError(this.handleError)
		  );
	}

	getByEmail(email: string): Observable<User> {
		return this.http.get(this.BASE_URL + '/email/' + email, this.httpOptions)
		.pipe(
			map(this.extractData),
			catchError(this.handleError)
		  );
	}

	updatePassword(id:any,obj: any): Observable<any> {
		return this.http.put(this.BASE_URL + '/' + id + '/changePassword', obj, this.httpOptions)
		.pipe(
			map(this.extractData),
			catchError(this.handleError)
		  );
	}
}
