<h3 class="title">Reporte de facturas por fecha de cobro</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_service">
							<span class="label">Servicio</span>
							<span>{{tableService.filter.id_service}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_plan">
							<span class="label">Plan</span>
							<span>{{tableService.filter.id_plan}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="company" >Compañia</label>
							<mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.id_partner"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
							<mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
							</mat-select>
						</div>
					</div>

					<div class="field-row">

							<div class="field-row-item">
								<label class="label" for="service" >Servicio</label>
								<mat-select placeholder="None" [formControl]="serviceS" [(value)]="filter.id_service"  (selectionChange)="onServiceChange($event.value)" disableRipple>
									<mat-option *ngFor="let opt of services" [value]="opt.id">{{opt.name_service}}</mat-option>
								</mat-select>
							</div>

							<div class="field-row-item">
								<label class="label" for="plan" >Plan</label>
								<mat-select placeholder="None" [formControl]="planS" [(value)]="filter.id_plan"  disableRipple>
									<mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
								</mat-select>
							</div>

          			</div>
          
          			<div class="field-row">

						<div class="field-row-item">

							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>

            			</div>
            
            			<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker2></mat-datepicker>
            			</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()" >
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>



	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer + ' ' + element.last_name }}</td>
			</ng-container>

			

			<ng-container matColumnDef="identifier_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
				<td mat-cell *matCellDef="let element">{{ element.identifier_customer }}</td>
			</ng-container>

			<ng-container matColumnDef="code_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Código</th>
				<td mat-cell *matCellDef="let element">{{ element.code_customer }}</td>
			</ng-container>
			
			<ng-container matColumnDef="name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="centro">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
				<td mat-cell *matCellDef="let element">{{element.sucursal}}</td>
      		</ng-container>
      
      <ng-container matColumnDef="desc_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.desc_status}}</td>
	  </ng-container>
	  
	  <ng-container matColumnDef="mask">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >TDC</th>
        <td mat-cell *matCellDef="let element">{{element.mask}}</td>
      </ng-container>

      <ng-container matColumnDef="total_invoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Monto</th>
        <td mat-cell *matCellDef="let element">{{element.total_invoice}}</td>
	  </ng-container>
	  
	  

	  <ng-container matColumnDef="autoritation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Autorización</th>
        <td mat-cell *matCellDef="let element">{{element.autoritation}}</td>
	  </ng-container>

      <ng-container matColumnDef="Fecha_Factura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Creada</th>
        <td mat-cell *matCellDef="let element">{{element.Fecha_Factura}}</td>
      </ng-container>

	  <ng-container matColumnDef="Fecha_Procesado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Procesada</th>
        <td mat-cell *matCellDef="let element">{{element.Fecha_Procesado}}</td>
	  </ng-container>
	  
	  <ng-container matColumnDef="next_bill">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Próximo cobro</th>
        <td mat-cell *matCellDef="let element">{{element.next_bill}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
