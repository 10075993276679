import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../shared/notification/notification.service';
import {FrecuencyService} from '../../frecuency.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../../utils/models/pager';
import { FrecuencyFilter } from '../../frecuency.filter';

@Component({
  template: `
  <h3 class="title">Listado de Frecuencias</h3>

  <mat-accordion>
    <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
      <mat-expansion-panel-header>
        <div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
          <div class="field-row">
          <!--
            <div class="field-row-item" *ngIf="tableService.filter.id">
              <span class="label" i>id</span>
              <span>{{tableService.filter.id}}</span>
            </div>
            -->

            <div class="field-row-item" *ngIf="tableService.filter.name_frecuency">
              <span class="label">Nombre</span>
              <span>{{tableService.filter.name_frecuency}}</span>
            </div>
    
          </div>
        </div>
        <button class="btn-icon"  title="Buscar" type="button">
          <i class="material-icons">search</i>
        </button>
      </mat-expansion-panel-header>

      <form>
        <fieldset class="fieldset">
          <!--
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="id">id</label>
              <input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
            </div>
          </div>
        -->

          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_frecuency">Nombre</label>
              <input type="text" name="name_frecuency" placeholder="None"  [(ngModel)]="filter.name_frecuency">
            </div>
          </div>
          
        </fieldset>
        <div class="options">
          <button class="btn-text gray" type="button" (click)="reset()">
            <span >Limpiar</span>
          </button>
          <button class="btn-text blue" type="button" (click)="search()">
            <span>Buscar</span>
          </button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button *hasPermission="['frecuency.create']" class="btn-icon" type="button" (click)="create()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
		  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name_frecuency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
        <td mat-cell *matCellDef="let element">{{element.name_frecuency}}</td>
      </ng-container>
      

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
        (click)="read(row)">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template contextMenuItem (execute)="update($event.item)">
        <div *hasPermission="['frecuency.update']" class="item">
          <i class="material-icons">edit</i>
          <span>Actualizar</span>
        </div>
      </ng-template>
    
  </context-menu>

  <modal-ns [(closed)]="confirmDelete">
    <ng-template modalContentDirective>
      <div class="dialog-content">
        <div class="dialog-title" >Confirmacion</div>
        <div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
        <div class="options">
          <button class="btn-text red" type="button" (click)="confirmDelete = true">
            <span >No</span>
          </button>
          <button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
            <span>SI</span>
          </button>
        </div>
      </div>
    </ng-template>statusServiceOrder
  </modal-ns>
`
})

export class ListFrecuencyComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['id', 'name_frecuency'];
	confirmDelete = true;
	isOpenSearchPanel = false;
  filter = new FrecuencyFilter(this.tableService.filter);
  

  
  public frecuencias: object = [];
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private freceuncyService: FrecuencyService,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) {this.tableService = new TableService;}

  ngOnInit() {
    this.list(); // listamos la informacion que se mostrara en la tabla
  }
  list(event?: PageEvent) {
   
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
    
    let httpParams = this.freceuncyService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		
		httpParams = this.filter.getHttpParams(httpParams);


    this.freceuncyService.getAll(httpParams).subscribe(response => {
        this.dataSource = new MatTableDataSource<any>(response['result']);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  create() { /// crear una frecuencia
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }
  read(item: any) { // leer una frecuencia
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }
  update(item: any) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }
  	//-- 


reset() {
	this.filter = new FrecuencyFilter();
	this.list();
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new FrecuencyFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new FrecuencyFilter(this.tableService.filter);
}

compareFn(c1: any, c2: any): boolean { 
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}

applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
  }

//--


}
