import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class EnrollmentService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/enrollmentsource';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  create(file: any): Observable<any> {
    return this.http.post(this.BASE_URL, file, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(file: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + file['id'], file , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getBatch(params: HttpParams = new HttpParams(), id_source): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/' + id_source + '/batch', this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

}
