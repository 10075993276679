import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {GroupService} from '../../group/group.service';
import {RoleService} from '../../role/role.service';
import {Location} from '@angular/common';
import {NotificationService} from '../../shared/notification/notification.service';
import {UserService} from '../user.service';
import {BaseService} from '../../shared/services/base.service';
import {PartnerService} from '../../partner/partner.service';
import { CustomValidators } from '../../shared/form/custom.validators';

@Component({
  template: `
	<h3 class="title">Crear Usuario</h3>

	<form *ngIf="createUserForm" [formGroup]="createUserForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del usuario</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_user">Nombre</label>
					<input formControlName="name_user" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createUserForm.controls['name_user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="lastName_user">Apellido</label>
          <input formControlName="lastName_user" type="text" autocomplete="off" placeholder="Ingrese Apellido" >
          <validator [control]="createUserForm.controls['lastName_user']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="email_user">Email</label>
          <input formControlName="email_user" type="text" autocomplete="off" placeholder="Ingrese Email" >
          <validator [control]="createUserForm.controls['email_user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="password">Password</label>
          <input formControlName="password" type="text" autocomplete="off" placeholder="Ingrese Password" >
          <validator [control]="createUserForm.controls['password']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los grupos del usuario</label>
          <mat-select  [formControl]="groupMultiCtrl" [multiple]="true" #multiSelect2>
            <ngx-mat-select-search [formControl]="groupMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredGroupsMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los roles del usuario</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="partner" >Elija las Compañias a las que podra acceder el  usuario</label>
          <mat-select  [formControl]="comMultiCtrl" [multiple]="true" #multiSelect3>
            <ngx-mat-select-search [formControl]="comMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredComMulti | async" [value]="bank.id">
              {{bank.name_partner}}
            </mat-option>
          </mat-select>
        </div>
      </div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createUserForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class UserCreateComponent implements OnInit {

  roles: any[] = [];
  grupos: any[] = [];
  partners: any[]= [];
  rolesSelected: any[] = [];
  gruposSelected: any[] = [];
  partnerSelected: any[] = [];
  default: any[] = [];

  // Para roles
  public roleMultiCtrl: FormControl = new FormControl();
  public roleMultiFilterCtrl: FormControl = new FormControl();
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect1',{static: false}) multiSelect: MatSelect;

  // Para grupos
  public groupMultiCtrl: FormControl = new FormControl();
  public groupMultiFilterCtrl: FormControl = new FormControl();
  public filteredGroupsMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect2',{static: false}) multiSelect2: MatSelect;

  // Para Compañia
  public comMultiCtrl: FormControl = new FormControl();
  public comMultiFilterCtrl: FormControl = new FormControl();
  public filteredComMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect3',{static: false}) multiSelect3: MatSelect;



  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // validaciones para campos de formulario
  createUserForm = this.fb.group({
    name_user: ['', [Validators.required]],
    lastName_user: ['',[Validators.required]],
    email_user: ['',[Validators.required, CustomValidators.emailRegex]],
    password: ['',[Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private groupService: GroupService,
    private roleService: RoleService,
    private userService: UserService,
    private partnerService: PartnerService,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.roleService.getAll().subscribe(roles => {

      this.roles = roles['result'];
      // load the initial role list
      this.filteredRolesMulti.next(this.roles.slice());
      // listen for search field value changes
      this.roleMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterRoleMulti();
        });

      this.groupService.getAll().subscribe(groups =>{
        this.grupos = groups['result'];
        // load the initial role list
        this.filteredGroupsMulti.next(this.grupos.slice());
        // listen for search field value changes
        this.groupMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterGroupMulti();
          });

        this.partnerService.getAll2().subscribe(partners => {
          this.partners = partners['result'];
            // load the initial role list
          this.filteredComMulti.next(this.partners.slice());
          // listen for search field value changes
          this.comMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterComMulti();
            });

        }, err => {
          this.notificationService.error(err);
          console.log(err);
        });
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createUserForm.value);
    // seteamos los permisos seleccionados por el usuario para el rol que se va a crear
    obj.rolesUser = this.roleMultiCtrl.value;
    obj.groupsUser = this.groupMultiCtrl.value;
    var partners = [];

    for(var p of this.comMultiCtrl.value ){
      partners.push({id:p});
    }
    obj.partners = partners;
    this.userService.create(obj).subscribe(user => {
      this.notificationService.sucessInsert(user['name_user']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  ngAfterViewInit() {
    this.setInitialValueRole();
    this.setInitialValueCom();
    this.setInitialValueGroup();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValueRole() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }
  protected setInitialValueGroup() {
    this.filteredGroupsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect2.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected setInitialValueCom() {
    this.filteredComMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect3.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterRoleMulti() {

    if (!this.roles) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.roles.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );

  }

  protected filterGroupMulti() {

    if (!this.grupos) {
      return;
    }
    // get the search keyword
    let search = this.groupMultiFilterCtrl.value;
    if (!search) {
      this.filteredGroupsMulti.next(this.grupos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredGroupsMulti.next(
      this.grupos.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );

  }

  protected filterComMulti() {

    if (!this.partners) {
      return;
    }
    // get the search keyword
    let search = this.comMultiFilterCtrl.value;
    if (!search) {
      this.filteredComMulti.next(this.partners.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredComMulti.next(
      this.partners.filter(bank => bank.name_partner.toLowerCase().indexOf(search) > -1)
    );

  }

}
