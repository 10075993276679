import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../shared/notification/notification.service';
import {FrecuencyService} from '../../frecuency.service';

@Component({
  template: `
	<h3 class="title">Detalle de la frecuencia</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ frecuency?.name_frecuency ||  '-' }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Dia</span>
					<span>{{ frecuency?.day_frecuency || '-' }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Mes</span>
					<span>{{ frecuency?.month_frecuency || '-' }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Dia de la semana</span>
					<span>{{ frecuency?.dayOfWeek_frecuency || '-' }}</span>
				</div>
      </div>
      
    </div>
   </div>
`
})
export class ReadFrecuencyComponent implements OnInit {

public frecuency: any;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private frecuencyService: FrecuencyService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(param => {
        this.frecuencyService.getFrecuency(param['frecuencyId'])
          .subscribe(result => {
            this.frecuency = result;
          });
      }, err => this.notificationService.error(err));
  }
  update() {
    this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
  }

}
