import {Component,OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {BaseService} from '../../shared/services/base.service';
import {Location} from '@angular/common';
import {PartnerService} from '../../partner/partner.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FileService} from '../file.service';
import {BillingService} from '../../billing/billing.service';


@Component({
  template: `
	<h3 class="title">Cargar Archivo</h3>

	<form *ngIf="uploadFileForm" [formGroup]="uploadFileForm" (ngSubmit)="upload()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" >Compañia</label>

          <mat-select [formControl]="uploadFileForm.controls['selectedPartner']" [compareWith]="compareFn" (selectionChange)="onPartnerChange($event.value)" disableRipple>
							<mat-option >None</mat-option>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>

				</div>
				<div class="field-row-item">
          <label class="label" >Sucursal</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedSucursal']" [compareWith]="compareFn" (selectionChange)="onSucursalChange($event.value)" disableRipple>
							<mat-option >None</mat-option>
							<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
          <label class="label" >Servicio</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedService']" [compareWith]="compareFn" (selectionChange)="onServiceChange($event.value)" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
          <label class="label" >Plan</label>

          <mat-select [formControl]="uploadFileForm.controls['plan']" [compareWith]="compareFn" (selectionChange)="onPlanChange($event.value)" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>

				</div>
			</div>
			
			<div class="field-row">
				<div class="field-row-item">
          <label class="label">Tipo de cobro</label>

          <mat-select [formControl]="uploadFileForm.controls['billing']" [compareWith]="compareFn" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let billing of billings" [value]="billing.id">{{billing.name_billingsetting}}</mat-option>
          </mat-select>

        </div>
        <div class="field-row-item">
					<label class="label" for="name_processFile">Nombre del cobro</label>
					<input formControlName="name_processFile" type="text" autocomplete="off" placeholder="Ingrese nombre" >
					<validator [control]="uploadFileForm.controls['name_processFile']"></validator>
        </div>
        
        <div class="field-row-item">
          <label class="label">Archivo</label>
          <input style="display:block;" type="file" (change)="onFileChange($event)" required>
				</div>
      </div>

	

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!uploadFileForm.valid">
				<span>Continuar</span>
			</button>
		</div>
  </form>
  
  <modal-ns [(closed)]="confirmation">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea procesar los cobros de este archivo?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmation = true">
						<span >NO</span>
					</button>
					<button class="btn-text green" type="button" (click)="onCharge(); confirmation = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
  </modal-ns>
  
  <modal-ns [(closed)]="back">
		<ng-template modalContentDirective>
      <div class="dialog-content">
      <div class="dialog-title" >Notificacion</div>
				<div class="dialog-message" >El proceso de cobro se esta ejecutando, usted recibira un email con el resultado, adicionalmente puede consultar el estado aqui</div>
				<div class="options">
					<button class="btn-text green" type="button" (click)="backToList()">
						<span >Consultar</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})
export class FileCreateComponent implements OnInit {

  partners: any[]= [];


  sucursales: any[]= [];
  

  services: any[]= [];
  

  plans: any[]= [];
  
  billings: any[]= [];
 

  fileName;
  fileType;
  fileValue;
  fileToSend;
  nameFound = true;
  message: string;
  total: number;
  id_file: number;
  back = true;
  confirmation = true;

  uploadFileForm = this.fb.group({
    name_processFile: ['', [Validators.required]],
    selectedPartner:['',[Validators.required]],
    selectedSucursal:[''],
    selectedService:['',[Validators.required]],
    plan: ['',[Validators.required]],
    billing: ['',[Validators.required]]
  });


  constructor(
    public fb: FormBuilder,
    private location: Location,
    private partnerService: PartnerService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private fileServices: FileService,
    private notificationService: NotificationService,
    private billingService: BillingService,
  ) { }

  ngOnInit() {
    let httpParams = this.partnerService.buildRequestParams();

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result']; // items que mostrara la tabla
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  onPartnerChange(partner: any) {
    this.services = [];
    this.sucursales = [];
    this.plans = [];
    if(partner != undefined){
       this.partnerService.getPartnersByParent(partner).subscribe(sucursales => {
        this.sucursales = sucursales['result'];
        if (this.sucursales.length === 0) {
          this.notificationService.info('Info', 'Esta Compañia no posee sucursales');
          this.servicesService.getByPartner(partner).subscribe( services => {
            this.services = services['result'];
            if (this.services.length === 0) {
              this.notificationService.info('Info', 'Esta Compañia no posee servicios');
            }
          }, err => {
            this.notificationService.error('Error al cargar los servicios de la sucursal');
            console.log(err);
          });
        }
      }, err => {
        this.notificationService.error('Error consultando las sucursales del partner seleccionado');
        console.log(err);
      });
    }
  }

  onSucursalChange(partner: any) {
    this.services = [];
    this.plans = [];
   
    this.billings = [];
    if(partner != undefined){
      this.servicesService.getByPartner(partner).subscribe( services => {
        this.services = services['result'];
        if (this.services.length === 0) {
          this.notificationService.info('Info', 'Esta sucursal no posee servicios');
        }
      }, err => {
        this.notificationService.error('Error al cargar los servicios de la sucursal');
        console.log(err);
      });
    }
  }

  onServiceChange(service : any) {
   
    this.plans = [];
    
   // this.selectedService = this.services[value];
  
    this.billings = [];
   
    if(service != undefined){
      this.planServices.getByService(service).subscribe( plans => {
        this.plans = plans['result'];
      }, error1 => {
        this.notificationService.error('Error cargando los planes del servicio');
        console.log(error1);
      });
    }
   
  }

  upload() {
    
    const obj = <any> BaseService.builderObject(this.uploadFileForm.value);
   
    if (obj.name_processFile === null || undefined) {
      this.notificationService.error('Debe ingresar un nombre para el cobro');
      return;
    }
    obj.plan = {id: obj.plan};
    obj.user = {id: JSON.parse(localStorage.getItem('currentData')).user.id};
    obj.nameFile_processFile = this.fileName;
    obj.status_processFile = 1;
    obj.value = this.fileValue;

    if (obj.value === null || obj.value === undefined) {
      this.notificationService.error('Debe seleccionar un archivo para cargar');
      return;
    }
    this.fileToSend = obj; /** TODO - REVISAR ESTO */
    this.fileServices.getByName(obj.nameFile_processFile, obj.plan.id).subscribe( found =>{
      this.nameFound = false;
    }, err => {
      this.confirmation = false;
    }) 
  }

  onConfirmation(){
    this.confirmation = false;
  }

  sendFile(obj:any){
    this.back = false;
    // creamos esta variable para tomarla en el listado de los archivos y buscar directamente los resultados para este plan
    sessionStorage.setItem('searchFilesPlan',obj.plan.id)
    this.fileServices.create(obj).subscribe(file => {
    });
  }
  
  processPayments(): void {
    this.back = false;
      this.fileServices.processPayments(this.id_file).subscribe(response => {
    });
  }

  backToList(): void {
    this.location.back();
  }


  onCharge(){
    this.sendFile(this.fileToSend);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileName = file.name;
        this.fileType = file.type;
        this.fileValue = (<string>reader.result).split(',')[1];
      };
    }
  }

  onPlanChange(plan : any) {
   
    this.billings = [];
    if(plan != undefined){ 
      
      this.billingService.getbyplan(this.billingService.buildRequestParams(), plan).subscribe( billings => {
        this.billings = billings['result'];
        if (this.billings.length === 0) {
          this.notificationService.info('Info', 'Este plan no posee Renovaciones asociadas');
        }
       
      });
    }
  }

  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
