import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import { Observable } from 'rxjs';
import { Servicio } from './servicio';
import { PartnerService } from '../partner/partner.service';
import { Partner } from '../partner/partner';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

export interface Filter {
    name_service?: string;
}


@Injectable({
  providedIn : 'root'
})
export class ServicioService extends BaseService {

    private readonly BASE_URL: string = this.HOST + '/service';
    
    httpOptions : any;

    constructor(
        private http: HttpClient,
        private partnerService: PartnerService,
    ){
        super();
         // Http Headers
         this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
    }

    getAll(): Observable<any> {
      return this.http.get(this.BASE_URL+'/full/search', this.httpOptions)
        .pipe(catchError(this.handleError));
    }

   getByName(name: string): Observable<any> {
        return this.http.get(this.BASE_URL+ '/name/' + name, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }


   create(servicio: Servicio): Observable<Servicio> {
        return this.http.post(this.BASE_URL, {"service":servicio}, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

   getByPartner(id: number, params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
     return this.http.get(this.BASE_URL + '/partner/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
        );
    }

    getById(id: number): Observable<Servicio> {
        return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    findPartner(id:number):Observable<Partner>{
        return this.partnerService.getById(id);
    }

    deletes(array: any[]): Observable<any> {
        return this.http.post(this.BASE_URL + '/delete', {services: array}, this.httpOptions)
        .pipe(catchError(this.handleError));
    }

    edit(id: number): Observable<Servicio> {
        return this.http.get(this.BASE_URL + '/' + id + '/edit', this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    getByStatus(status: number, partner: number): Observable<any> {
        return this.http.get(this.BASE_URL + '/status/' + status + '/partner/' + partner, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }


    delete(id: number): Observable<any> {
        return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(catchError(this.handleError));
    }

    update(servicio: Servicio): Observable<Servicio> {
        return this.http.put(this.BASE_URL + '/' + servicio.id, {'service': servicio},this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

}
