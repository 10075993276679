import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location,DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Global } from '../utils/global';
import { CustomerService } from './customer.service';
import { NotificationService} from '../shared/notification/notification.service';
import { BaseService } from '../shared/services/base.service';
import { CustomValidators } from '../shared/form/custom.validators';


@Component({
  template: `
	<h3 class="title">Crear Cliente</h3>

	<form *ngIf="createCustomerForm" [formGroup]="createCustomerForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del cliente</legend>

      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="name_customer" >Nombre</label>
          <input formControlName="name_customer" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createCustomerForm.controls['name_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="lastName_customer" >Apellido</label>
          <input formControlName="lastName_customer" type="text" autocomplete="off" placeholder="Ingrese Appellido" >
					<validator [control]="createCustomerForm.controls['lastName_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="code_customer" >Código</label>
          <input formControlName="code_customer" type="text" autocomplete="off" placeholder="Ingrese Código" >
					<validator [control]="createCustomerForm.controls['code_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="identifier_customer" >DNI</label>
          <input formControlName="identifier_customer" type="text" autocomplete="off" placeholder="Ingrese DNI" >
					<validator [control]="createCustomerForm.controls['identifier_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="email_customer" >Email</label>
          <input formControlName="email_customer" type="text" autocomplete="off" placeholder="Ingrese Email" >
					<validator [control]="createCustomerForm.controls['email_customer']"></validator>
        </div>
        
				<div class="field-row-item" style="padding-top:15px;">
              <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha de nacimiento" formControlName="birthDate_customer"  readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <validator [control]="createCustomerForm.controls['birthDate_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="phoneNumber_customer" >Teléfono</label>
          <input formControlName="phoneNumber_customer" type="text" (keypress)="onlyNumberKey($event)" autocomplete="off" placeholder="Ingrese Teléfono" >
					<validator [control]="createCustomerForm.controls['phoneNumber_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="adress_customer" >Dirección</label>
          <input formControlName="adress_customer" type="text" autocomplete="off" placeholder="Ingrese Dirección" >
					<validator [control]="createCustomerForm.controls['adress_customer']"></validator>
        </div>

      </div>
      

      <div class="field-row">

        <div class="field-row-item">
          <label class="label" for="sucursal_id" >Sucursal</label>
          <input formControlName="sucursal_id" type="text" autocomplete="off" placeholder="Ingrese ID de sucursal" >
        </div>

				
        <div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
          <mat-select [formControl]="createCustomerForm.controls['status_customer']" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createCustomerForm.controls['status_customer']"></validator>
        </div>
        
        <div class="field-row-item">
					<label class="label" for="status" >Contribuyente</label>
          <mat-select [formControl]="createCustomerForm.controls['taxexempt']" disableRipple>
							<mat-option *ngFor="let tax of taxes" [value]="tax.value">{{tax.description}}</mat-option>
						</mat-select>
						<validator [control]="createCustomerForm.controls['taxexempt']"></validator>
				</div>

			</div>
					

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createCustomerForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})

export class CustomerCreateComponent {

  public customer: any;
  
  public estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];

  public taxes : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];

  //Formulario para crear un nuevo cliente
	createCustomerForm = this.fb.group({
		name_customer: ['', [Validators.required]],
    lastName_customer: ['', [Validators.required]],
    code_customer: ['',[Validators.required]],
    identifier_customer: ['', [Validators.required]],
    email_customer: ['', [CustomValidators.emailRegex, Validators.maxLength(100)]],
    phoneNumber_customer: ['', [Validators.maxLength(20)]],
    adress_customer: [''],
    birthDate_customer: [''],
    status_customer:['', [Validators.required]],
    sucursal_id:[''],
    taxexempt:['', [Validators.required]]
  });
  
	constructor(
		public fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private customerService: CustomerService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
	){}

	ngOnInit(){
    
	}

  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

	create() {
        let obj = <any> BaseService.builderObject(this.createCustomerForm.value)
        this.customer = obj;
        this.customer.birthDate_customer = this.datePipe.transform(this.customer.birthDate_customer, 'yyyy-MM-dd');
        if( this.customer.taxexempt == null){
          this.customer.taxexempt = true;
        }else{
          this.customer.taxexempt = false;
        }
              this.activatedRoute.parent.params.subscribe(param => {
                this.customer.partner = {id:param.partnerId};         
                this.customerService.create(this.customer).subscribe(customer => {
                    this.notificationService.sucessInsert(customer.name_customer);
                    this.location.back();
                }, (err) => {
                  console.log(err);
                  this.notificationService.error(err?.error?.message);
                }); 
              }, (err) => {
                console.log(err);
                this.notificationService.error(err?.error?.message);
              });	
          

    }
}
