import { Routes } from '@angular/router';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { EnrollmentListComponent } from './enrollment-list/enrollment-list.component';
import { EnrollmentCreateComponent } from './enrollment-create/enrollment-create.component';
import { EnrollmentBatchComponent } from './enrollment-batch/enrollment-batch.component';

export const enrollmentRoutes: Routes = [
  {
    path: 'sources',
    component: EnrollmentComponent,
    data: {
      breadcrumb: 'Archivos',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: EnrollmentListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: EnrollmentCreateComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':sourceId',
        component: EnrollmentComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EnrollmentBatchComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          }
        ]
      }
    ]
  }
];

export const routingEnrollmentComponents = [EnrollmentComponent,EnrollmentListComponent,EnrollmentCreateComponent,EnrollmentBatchComponent];
