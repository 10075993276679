import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { RouterModule } from '@angular/router';
import { routingComponnents } from './auth.routes';



import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgProgressModule,
		HttpClientModule,
		NgProgressModule
	],
	declarations: [
		routingComponnents
	],
	providers: [
		AuthService,
		AuthGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true }
	]
})

export class AuthModule { }
