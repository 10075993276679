
	<h3 class="title">Clientes por compañía</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.status">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.dni">
							<span class="label">DNI</span>
							<span>{{tableService.filter.dni}}</span>
						</div>
 
						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

            <div class="field-row">
              <div class="field-row-item">
                <label class="label" for="company" >Compañia</label>
                <mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.id_partner" >
                  <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
                </mat-select>
              </div>
              <div class="field-row-item">
                <label class="label" for="status" >Estatus</label>
                  <mat-select placeholder="None" [formControl]="statusS" [(value)]="filter.status" >
                  <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
                </mat-select>
              </div>

							<div class="field-row-item">
								<label class="label" for="dni">DNI</label>
								<input type="text" name="dni" placeholder="None"  [(ngModel)]="filter.dni">
							</div>
						
            

					</div>

				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>



	<div class="mat-elevation-z8e" *ngIf="dataSource">
    
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>

		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
      <ng-container matColumnDef="identifier_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
        <td mat-cell *matCellDef="let element">{{element.identifier_customer}}</td>
      </ng-container>

			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="lastName_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Apellido</th>
				<td mat-cell *matCellDef="let element">{{element.lastName_customer}}</td>
      </ng-container>

      <ng-container matColumnDef="code_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Codigo</th>
        <td mat-cell *matCellDef="let element">{{element.code_customer}}</td>
      </ng-container>

      <ng-container matColumnDef="sucursal_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
        <td mat-cell *matCellDef="let element">{{element.sucursal_id}}</td>
      </ng-container>

      <ng-container matColumnDef="email_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Email</th>
        <td mat-cell *matCellDef="let element">{{element.email_customer}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
