
import { Routes } from '@angular/router';
import { ServicioComponent } from './servicio.component';
import { ServicioListComponent } from './servicio-list.component';
import { ServicioCreateComponent } from './servicio-create.component';
import { ServicioReadComponent } from './servicio-read.component';
import { ServicioUpdateComponent } from './servicio-update.component';
import {planesRoutes} from '../plan/plan.routes';

export const servicioRoutes: Routes = [
	{
				path: ':IdPartner/services',
				component: ServicioComponent,
				data: {
					breadcrumb: 'Servicios'
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: ServicioListComponent
					},
					{
					    path:'create',
					    component: ServicioCreateComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':serviceID',
					    component: ServicioComponent,
					    data: {
					    	breadcrumb: "Detalle"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: ServicioReadComponent
							},
							{
							    path: 'update',
							    component: ServicioUpdateComponent,
							  	data: {
							    	breadcrumb: "Actualizar"
							    }
						  	}
					  	]
				  	},
          			...planesRoutes
				]
			}
];

export const routingServiceComponnents = [ServicioComponent, ServicioListComponent, ServicioCreateComponent, ServicioReadComponent, ServicioUpdateComponent];
