import { Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Servicio } from './servicio';
import { ServicioService } from './servicio.service';
import { NotificationService} from '../shared/notification/notification.service';
import { PartnerService } from '../partner/partner.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import { ServiceFilter } from './servicio.filter';
import { FormControl } from '@angular/forms';

@Component({
	template: `
	<h3 *ngIf="!show" class="title">Listado de servicios de : {{partner?.name_partner}}</h3>

	<h3 *ngIf="show" class="title">Listado de servicios</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name_service">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name_service}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.partner">
							<span class="label">Compañia</span>
							<span>{{partner.name_partner}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_service">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_service}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name_service">Nombre</label>
							<input type="text" name="name_service" placeholder="None"  [(ngModel)]="filter.name_service">
						</div>

						<div class="field-row-item">
								<label class="label" for="company">Compañia</label>

								<mat-select [formControl]="partnerS" placeholder="None" [(value)]="filter.partner"  disableRipple>
									<mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
								</mat-select>

						</div>
					</div>
					

					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="status_service" >Estatus</label>
							<mat-select placeholder="None" [(value)]="filter.status_service" disableRipple>
								<mat-option >None</mat-option>
								<mat-option [value]="'1'">Activo</mat-option>
								<mat-option [value]="'2'">Inactivo</mat-option>
							</mat-select>
						</div>
					</div>
					-->
					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['create.service']" class="right row">
			<button   class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
			

			<button   class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i class="material-icons">delete</i>
			</button>


		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_service">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_service}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status_service">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status_service}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['service.update']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>
			
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['plan.by.service']" contextMenuItem (execute)="planes($event.item)">
				<div class="item">
					<i class="material-icons">view_carousel</i>
					<span>Planes</span>
				</div>
			</ng-template> 

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['service.delete']" contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span>Eliminar</span>
				</div>
			</ng-template>
		
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})

export class ServicioListComponent {

	show = false;
	
	// --
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['select', 'id', 'name_service', 'status_service'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ServiceFilter(this.tableService.filter);
	partnerS: FormControl = new FormControl('');
	// -- 
	partners = [];
	partner: any;
	id: number;
  	parents = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private servicioService: ServicioService,
		public tableService: TableService<any>,
		private notificationService: NotificationService,
		private partnerService: PartnerService

	){this.tableService = new TableService;}

	ngOnInit() {
	  	if (this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
	    this.parents = true;
    	}
		this.cargarPartners();
		this.list();
	}

	list(event?: PageEvent) {
		
		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
		
		// si se esta haciendo alguna busqueda por Compañia
		if(this.filter.partner != undefined){
			this.selectDropPartner(this.filter.partner)
		}
		if(this.filter.name_service != undefined){
			this.show = true;
		}else{
			this.show = false;
		
		}
    	this.activatedRoute.params.subscribe(param => {
			  this.id = param['IdPartner'];

			  let httpParams = this.servicioService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
			  httpParams = this.filter.getHttpParams(httpParams);

			  this.servicioService.getByPartner(this.id, httpParams).subscribe(response => {

				for(const r of response['result']){
					if(r.status_service == 1){
						r.status_service = 'Activo';
					}
					if(r.status_service == 2){
						r.status_service = 'Inactivo';
					}
				}

				this.dataSource = new MatTableDataSource<any>(response['result']);
				this.tableService.pager = response['pager'];
				this.tableService.selection.clear(); 

			}, err => {
				this.notificationService.error(err);
				console.log(err);
			});
    	});
	}

	cargarPartners(){
	  let httpParams = this.partnerService.buildRequestParams();

      httpParams = httpParams.set('parents',this.parents.toString());
	  httpParams = httpParams.set('parent',(-1).toString());
	  
      // para listar todos los partners registrados
      	this.partnerService.getPartnersByUserId(httpParams).subscribe(response => {
        //  dropdown para filtrar
				this.partners = response['result'];
				this.selectDropPartner(this.id)
      		});
	}

	selectDropPartner(id: number){
		this.partner = this.partners.find(c => c.id == id);
		this.partnerS.setValue(this.partner.id);
	}

//-- 

deletes() {
	const length = this.tableService.selection.selected.length;
	this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
		--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.servicioService.delete(this.tableService.selection.selected[0].id).subscribe(response => {
				this.notificationService.sucessDelete(response.name_service);
				this.list();
			}, err => this.notificationService.error(err));
		} else if (length > 1) {
			this.servicioService.deletes(this.tableService.selection.selected).subscribe(any =>  {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
	} 
}

reset() {
	this.filter = new ServiceFilter();
	this.list();
	this.selectDropPartner(this.id);
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new ServiceFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new ServiceFilter(this.tableService.filter);
}


//--
	create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
	}

	read(item: Servicio){
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
	}

	update(item: Servicio){ // actualiza un servicio
		this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
	}

	planes(item:Servicio){ /// carga los planes asociados a un servicio
		var partner;
		if(item['partner']['id'] != null){
			partner = item['partner']['id'];
		}else{
			partner = item['partner'];
		}
		localStorage.setItem("planes", JSON.stringify({servicio: item.id, partner : partner}));
		this.router.navigate(['../' + item.id + '/plans'], {relativeTo: this.activatedRoute});
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
