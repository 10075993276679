import { Component, Input, Output, EventEmitter, ContentChild } from '@angular/core';
import { ModalContentDirective } from './modalContentDirective';

@Component({
	selector: 'modal-ns',
	templateUrl: './modal-ns.component.html',
	styleUrls: ['./modal-ns.component.css'],
})
export class ModalComponent {
	@Input() closed: boolean;
	@Output() closedChange = new EventEmitter();
	@ContentChild(ModalContentDirective,{static: false}) public content: ModalContentDirective;

	/*ngAfterViewInit() {
		console.log(this.content);
	}*/

	close() {
		this.closed = true;
		this.closedChange.emit(this.closed);
	}

	open() {
		this.closed = false;
		this.closedChange.emit(this.closed);
	}
}
