import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseService } from '../shared/services/base.service';
import { NotificationService} from '../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {PlanService} from "../plan/plan.service";
import {ServicioService} from "../servicio/servicio.service";
import {Customer_has_plansService} from "../customer_has_plans/customer_has_plans.service";
import {CreditcardService} from "../creditcard/creditcard.service";
import {Customer} from "../customer/customer";
import {CustomerService} from "../customer/customer.service";

@Component({
  template: `
	<h3 class="title">Crear plan a cliente</h3>

	<form *ngIf="createCustomerHasPlansForm" [formGroup]="createCustomerHasPlansForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_customer">Nombre</label>
					<input  type="text" autocomplete="off" value="{{ customer.name_customer || '-' }} {{customer.lastName_customer || '-' }}" readonly>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="service" >Servicio</label>
					
          <mat-select [formControl]="service"  (selectionChange)="onChangeService($event.value)" disableRipple>
            <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
          <validator [control]="service"></validator>

				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
				
          <mat-select [formControl]="plan" disableRipple>
            <mat-option *ngFor="let plan of planes" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>
          <validator [control]="plan"></validator>

				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="contractNumber_Plan">Número de contrato</label>
					<input formControlName="contractNumber_Plan" type="text"  autocomplete="off" placeholder="Ingrese el número de contrato" >
					<validator [control]="createCustomerHasPlansForm.controls['contractNumber_Plan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="idPlanEnrollmentrenewalPartner">Código de renovación de matricula del partner</label>
					<input formControlName="idPlanEnrollmentrenewalPartner" type="text" autocomplete="off" placeholder="Ingrese el código de renovación de matricula del partner" >
					<validator [control]="createCustomerHasPlansForm.controls['idPlanEnrollmentrenewalPartner']"></validator>
				</div>
			</div>

      <div class="field-row">
      
        <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha de inscripción del plan(DD/MM/YYYY)" formControlName="startDate_customerPlan"  readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <validator [control]="createCustomerHasPlansForm.controls['startDate_customerPlan']"></validator>
        </div>




				<div class="field-row-item">

          <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Ingrese fecha de culminación del plan(DD/MM/YYYY)" formControlName="endDate_customerPlan"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker2></mat-datepicker>
          <validator [control]="createCustomerHasPlansForm.controls['endDate_customerPlan']"></validator>
          
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" for="tdc">Tarjeta de crédito asociada</label>
          
          <mat-select [formControl]="tdc"  disableRipple>
            <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.id">{{tarjeta.maskCreditCard}} - {{tarjeta.nameHolderCreditCard}}</mat-option>
          </mat-select>
          <validator [control]="tdc"></validator>

        </div>
        
        <div class="field-row-item">
          <label class="label" for="status" >Estatus</label>
          <mat-select [formControl]="createCustomerHasPlansForm.controls['status_customerPlan']"  disableRipple>
            <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
          </mat-select>
          <validator [control]="createCustomerHasPlansForm.controls['status_customerPlan']"></validator>
        </div>

        <div class="field-row-item">
          <label class="label" for="next" >Calcular fecha de cobro</label>
          <mat-select [formControl]="createCustomerHasPlansForm.controls['nextBill']"  disableRipple>
            <mat-option *ngFor="let n of next" [value]="n.value">{{n.description}}</mat-option>
          </mat-select>
          <validator [control]="createCustomerHasPlansForm.controls['nextBill']"></validator>
        </div>


			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createCustomerHasPlansForm.valid || !tdc.valid || !plan.valid || !service.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CustomerHasPlansCreateComponent{

  private customer: Customer = new Customer();
  private tarjetas = [];
  private lastarjetasa = [];
  private planes = [];
  private services = [];
  public chp: any = {};

  private isDisabled : boolean = false;

  private estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}, {value:3,description:'Congelado'}];

  private next : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];

  tdc: FormControl = new FormControl('', Validators.required);
  plan: FormControl = new FormControl('', Validators.required);
  service: FormControl = new FormControl('', Validators.required);


  //Formulario de creacion del plan para el cliente
  createCustomerHasPlansForm = this.fb.group({
    contractNumber_Plan: [''],
    startDate_customerPlan: ['', [Validators.required]],
    endDate_customerPlan: ['', [Validators.required]],
    idPlanEnrollmentrenewalPartner: [''],
    status_customerPlan: ['', [Validators.required]],
    nextBill: ['', [Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private customerService: CustomerService,
    private planService: PlanService,
    private servicioService : ServicioService,
    private hasplanService: Customer_has_plansService,
    private creditCardService: CreditcardService,
    private notificationService: NotificationService
	){}

  //Metodo que permite validar que solo sean introducido numeros
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

	ngOnInit(){
    this.activatedRoute.parent.params.subscribe(params =>{
      //Cargar el cliente
      this.customerService.getById(params["customerID"]).subscribe(customer => {
      this.customer = customer; 
      //Se cargan las tarjetas de credito que posee el cliente
      this.creditCardService.findByCustomer(this.customer.id).subscribe(parametros => {

        if(parametros['result'] == null){
          //El cliente no posee tarjetas (imposible incluirse en un plan)
          this.tarjetas = [];
          this.notificationService.error("El cliente NO posee tarjetas (imposible incluirse en un plan)");
          return;
        }
       
        let lastarjetas = parametros['result'];
        //POR CADA TARJETA QUE TENGA EL CLIENTE CONSULTAMOS SU ESTATUS
        for (let i in lastarjetas) {
            let tdc = lastarjetas[i].creditCard;
            //Se busca el estado actual de la tarjeta
            this.creditCardService.findTdcById(this.customer.id,tdc.id).subscribe(parms => {
              let estatus = parms['result']['statusCreditCard'];
              //Si la tarjeta se encuentra activa, cargarla en el componente correspondiente
              if (estatus == 1) {
                //Se verifica la integridad referencial customer-core a traves del token
                this.creditCardService.findByToken(this.customer.id,lastarjetas[i].tokenCreditCard).subscribe(params => {
                  if(params['result'] != null) {
                    this.lastarjetasa.push(lastarjetas[i]);
                    this.tarjetas.push(tdc);
                  }
                });
              }
            });
        }
        this.cargarCombos(); // cargamos los combos de la vista
      }, (err) => {this.cargarCombos(); this.tarjetas = []});
    });
    });
	}

  cargarCombos(){
      // SE CARGAN LOS SERVICIOS ASOCIADOS A LA COMPANIA
      this.servicioService.getByPartner(this.customer['partner'].id).subscribe(params => {
          this.services = params['result'];
    }); 
  }

  onChangeService(service:any){
    //Cambio el servicio
    this.planes = [];
    if(service!=undefined){
      this.planService.getByService(service).subscribe(params => {
        this.planes = params['result'];
      }, (err) => {
        this.notificationService.error(err);
      });
    }  
  }

	create() {
      let obj = <any> BaseService.builderObject(this.createCustomerHasPlansForm.value);
        //Se verifica si el cliente ya tiene asociado el plan seleccionado anteriormente
        this.hasplanService.getByCustomerAndPlan(this.customer.id, this.plan.value).subscribe(params => {
          if(params['result'] != null){
            this.notificationService.alert("El cliente ya se encuentra asociado al plan seleccionado");
            return;
          }

          let start = new Date(obj.startDate_customerPlan);
          let end = new Date(obj.endDate_customerPlan);
          // VALIDAMOS LAS FECHAS
          if(end.getTime() <= start.getTime()){
            this.notificationService.error("Fecha de culminacion debe ser POSTERIOR a la fecha de inscripción");
            return;
          }

          //Creando plan para el customer
          this.chp.plan = {id: this.plan.value};
          this.chp.customer = {id: this.customer.id} ;
          this.chp.idPlanEnrollmentrenewalPartner = obj.idPlanEnrollmentrenewalPartner;
          this.chp.contractNumber_Plan = obj.contractNumber_Plan;
          this.chp.startdate_customerPlan = obj.startDate_customerPlan;
          this.chp.endDate_customerPlan = obj.endDate_customerPlan;
          this.chp.status_customerPlan = obj.status_customerPlan;
          this.chp.nextBill = obj.nextBill;
      
          this.creditCardService.findByToken(this.customer.id,this.lastarjetasa[this.lastarjetasa.findIndex(t => t.creditCard.id == this.tdc.value)].tokenCreditCard).subscribe(prm => {
        
              let card = prm['result'];
              this.chp.creditCardToken = {id: card.id};
              //Se asocia el plan al cliente
              this.hasplanService.create(this.chp).subscribe(hasplan => {
              this.notificationService.sucessUpdate(this.customer.name_customer);
                            localStorage.setItem("servicio", JSON.stringify({ser: this.service.value}));
                            this.location.back();
              }, err => this.notificationService.error(err));
          }, err => this.notificationService.error(err));
           
            
          
        }, err => this.notificationService.error(err));

  }
}
