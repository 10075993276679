import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OptionService } from '../option.service';
import { NotificationService} from '../../shared/notification/notification.service';
import { PartnerService } from '../../partner/partner.service';
import { ServicioService } from '../../servicio/servicio.service';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { OptionFilter } from '../option.filter';
import { PlanService } from '../../plan/plan.service';

@Component({
  template: `
	<h3  class="title">Listado de opciones</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.partner">
							<span class="label">{{label}}</span>
							<span>{{partner.name_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.service">
							<span class="label">Servicio</span>
							<span>{{service.name_service}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.plan">
							<span class="label">Servicio</span>
							<span>{{service.name_service}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name">Nombre</label>
							<input type="text" name="name" placeholder="None"  [(ngModel)]="filter.name">
						</div>

						<div class="field-row-item">
								<label class="label" for="company" >Compañia</label>
								<mat-select [formControl]="partnerS" [(value)]="filter.partner"  [disabled]="true">
									<mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
								</mat-select>
						</div>

						<div class="field-row-item">
								<label class="label" for="service" >Servicio</label>
								<mat-select [formControl]="serviceS"  [(value)]="filter.service"  [disabled]="true">
									<mat-option *ngFor="let opt2 of services" [value]="opt2.id">{{opt2.name_service}}</mat-option>
								</mat-select>
            </div>
            
            <div class="field-row-item">
                <label class="label" for="service" >Plan</label>
                <mat-select [formControl]="planS"  [(value)]="filter.plan" (selectionChange)="changePlan($event.value)" >
                  <mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
                </mat-select>
            </div>


					</div>

					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['options.create']" class="right row">
			<button  class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      
      <ng-container matColumnDef="position">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Posición</th>
				<td mat-cell *matCellDef="let element">{{element.position}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['options.create']"  contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
      </ng-template>
      
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})
export class OptionListComponent implements OnInit {

  show = false;
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['id', 'name', 'position', 'status'];
  confirmDelete = true;
  
  isOpenSearchPanel = false;
  filter = new OptionFilter(this.tableService.filter);
  parents = false;
  id_partner: number;
  id_service: number;
  id_plan: number;

  services = [];
  service: any;
  partners = [];
  partner: any;
  plans = [];
  plan: any;
  
  partnerS: FormControl = new FormControl('');
  serviceS: FormControl = new FormControl('');
  planS: FormControl = new FormControl('');
  

  constructor(
    private router: Router,
		private activatedRoute: ActivatedRoute,
		private optionService: OptionService,
		public  tableService: TableService <any>,
		private servicioService : ServicioService,
		private notificationService: NotificationService,
    private partnerService: PartnerService,
    private planService: PlanService
  ) {
    this.tableService = new TableService;
   }

  ngOnInit(): void {

    if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
      this.parents = true;
    }

    this.activatedRoute.params.subscribe(param =>{

      this.id_plan = param['IDplan'];

      this.activatedRoute.parent.parent.params.subscribe(param =>{

       this.id_service = param['IdService']

        this.activatedRoute.parent.parent.parent.params.subscribe( param =>{

          this.id_partner = param['IdPartner'];

          let httpParams = this.partnerService.buildRequestParams();

          httpParams = httpParams.set('parents',this.parents.toString());
          httpParams = httpParams.set('parent',(-1).toString());
	
          // se cargan los partners con estatus activo
          this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
            // se cargan los servicios que posee el partner y que esten activos
            this.servicioService.getByStatus(1, this.id_partner).subscribe(services => {
              
              let httpParams = this.planService.buildRequestParams();

              this.planService.getByService(this.id_service,httpParams).subscribe(plans =>{

                  // se llenan los dropdown
                  this.services = services['result'];
                  this.partners = params['result'];
                  this.plans = plans['result'];
                  
                  // Servicio
                  if(this.services.length != 0){
                    this.service = this.services.find(s => s.id == this.id_service);
                    this.serviceS.setValue(this.service.id);
                  }else{
                    this.notificationService.error("NO Existen servicios activos")
                  }
                  
                  // Plan
                  if(this.plans.length != 0){
                    this.plan = this.plans.find(s => s.id == this.id_plan);
                    this.planS.setValue(this.plan.id);
                  }else{
                    this.notificationService.error("NO Existen planes activos")
                  }

                  // Compania
                  this.partner = this.partners.find(c => c.id == this.id_partner);
                  this.partnerS.setValue(this.partner.id);

                  this.filter = new OptionFilter();

                  this.list();

              });
            });
          });
        });
      });
    });
  }


  list(event?: PageEvent){
		// si se esta haciendo alguna busqueda por Compañia

		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}


		let httpParams = this.optionService.buildRequestParams(this.tableService.sort,"m",
		{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

  
		httpParams = this.filter.getHttpParams(httpParams);
		
		this.optionService.getByPlan(this.id_plan,httpParams).subscribe(response => {

      for(const o of response['result']){
        switch(o.status) { 
          case 1: { 
             o.status = "Activo"; 
             break; 
          } 
          case 2: { 
            o.status = "Inactivo"; 
             break; 
          } 
          default: {  
             break; 
          } 

        }
      }
      
			this.dataSource = new MatTableDataSource<any>(response['result']);
			this.tableService.pager = response['pager'];
			this.tableService.selection.clear();
				
			}, err => {
        this.notificationService.error(err);
        console.log(err);
      });

	}

  create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
	}

	read(item: any){
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }
  
	update(item: any){ 
		this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
	}

	//-- 

	reset() {
		this.filter = new OptionFilter();
		this.list();
		this.ngOnInit();
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
		this.tableService.filter = new OptionFilter(this.filter);
		this.list();
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new OptionFilter(this.tableService.filter);
  }
  
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  changePlan(plan: any) {
    this.id_plan = plan;
    this.list()
	}
}
