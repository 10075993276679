
	<h3 class="title">Editar Cliente</h3>

	<form *ngIf="updateCustomerForm" [formGroup]="updateCustomerForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del cliente</legend>

      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="name_customer" >Nombre</label>
          <input formControlName="name_customer" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateCustomerForm.controls['name_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="lastName_customer" >Apellido</label>
          <input formControlName="lastName_customer" type="text" autocomplete="off" placeholder="Ingrese Appellido" >
					<validator [control]="updateCustomerForm.controls['lastName_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="code_customer" >Código</label>
          <input formControlName="code_customer" type="text" autocomplete="off" placeholder="Ingrese Código" >
					<validator [control]="updateCustomerForm.controls['code_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="identifier_customer" >DNI</label>
          <input formControlName="identifier_customer" autocomplete="off" placeholder="Ingrese DNI" >
					<validator [control]="updateCustomerForm.controls['identifier_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="email_customer" >Email</label>
          <input formControlName="email_customer" type="text" autocomplete="off" placeholder="Ingrese Email" >
					<validator [control]="updateCustomerForm.controls['email_customer']"></validator>
        </div>
        
				<div class="field-row-item" style="padding-top:15px;">
          <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Fecha de nacimiento" formControlName="birthDate_customer"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
					<validator [control]="updateCustomerForm.controls['birthDate_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="phoneNumber_customer" >Teléfono</label>
          <input formControlName="phoneNumber_customer" type="text" (keypress)="onlyNumberKey($event)" autocomplete="off" placeholder="Ingrese Teléfono" >
					<validator [control]="updateCustomerForm.controls['phoneNumber_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="adress_customer" >Dirección</label>
          <input formControlName="adress_customer" type="text" autocomplete="off" placeholder="Ingrese Dirección" >
					<validator [control]="updateCustomerForm.controls['adress_customer']"></validator>
        </div>


      </div>
      
      <div class="field-row">
      
      <div class="field-row-item">
        <label class="label" for="sucursal_id" >Sucursal</label>
        <input formControlName="sucursal_id" type="text" autocomplete="off" placeholder="Ingrese ID de sucursal" >
      </div>

      <div class="field-row-item">
        <label class="label" for="status" >Estatus</label>
        <mat-select [formControl]="status_customer" disableRipple>
          <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
        </mat-select>
        <validator [control]="status_customer"></validator>
      </div>


      <div class="field-row-item">
      <label class="label" for="status" >Contribuyente</label>
      <mat-select [formControl]="taxexempt" disableRipple>
          <mat-option *ngFor="let tax of taxes" [value]="tax.value">{{tax.description}}</mat-option>
        </mat-select>
        <validator [control]="taxexempt"></validator>
    </div>

      

    </div>
					

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCustomerForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
