import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { NotificationService} from '../../shared/notification/notification.service';
import { BaseService } from '../../shared/services/base.service';
import { CuponService } from '../cupon.service';


@Component({
  template: `
	<h3 class="title">Crear Cupon</h3>

	<form  [formGroup]="createCuponForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del Cupon</legend>
			
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_cupon">Nombre</label>
					<input formControlName="name_cupon" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createCuponForm.controls['name_cupon']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="type" >Tipo</label>
					<mat-select [formControl]="type" disableRipple>
						<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
					</mat-select>
					<validator [control]="type"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" *ngIf="type.value==1" for="value">Porcentaje</label>
					<label class="label" *ngIf="type.value==2" for="value">Monto</label>
					<input formControlName="value" type="number" autocomplete="off" placeholder="Ingrese valor" >
					<validator [control]="createCuponForm.controls['value']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
					<mat-select [formControl]="createCuponForm.controls['status']" disableRipple>
						<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
					</mat-select>
					<validator [control]="createCuponForm.controls['status']"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					
					<input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha desde" formControlName="dateFrom"  readonly>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker touchUi #picker></mat-datepicker>



					<validator [control]="createCuponForm.controls['dateFrom']"></validator>
				</div>
				<div class="field-row-item">
					
					<input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Fecha hasta" formControlName="dateUntil"  readonly>
					<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					<mat-datepicker touchUi #picker2></mat-datepicker>

					<validator [control]="createCuponForm.controls['dateUntil']"></validator>
				</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createCuponForm.valid || !type.valid ">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CreateCuponComponent implements OnInit {

	public estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
	public types : any[] = [{value:1,description:"Porcentaje - %"},{value:2, description:"Monto - $"}];
  
  private status: any;

  type: FormControl = new FormControl('', Validators.required);

  // validaciones para campos de formulario
	createCuponForm = this.fb.group({
		name_cupon: ['', [Validators.required]],
		value: ['', [Validators.required]],
		dateFrom : ['',[Validators.required]],
		dateUntil : ['',[]],
		status: ['', [Validators.required]]
	});

  constructor(
    	public fb: FormBuilder,
		private location: Location,
		private cuponService: CuponService,
		private notificationService: NotificationService
  ) { }

  ngOnInit() {
	  this.type.setValue(this.types[0].value);
  }

  create() {
    let obj = <any> BaseService.builderObject(this.createCuponForm.value)
    // esto es un tipo de porcentaje
    if(this.type.value == 1){
		if(obj.value < 0 || obj.value > 100){
			this.notificationService.error("Valor del porcentaje debe estar entre 0 y 100");
			return;
		}
	}
	if(obj.dateFrom != null && obj.dateUntil != null){
		var since = new  Date (obj.dateFrom);
		var until = new  Date (obj.dateUntil);
      	if(until.getTime() < since.getTime()){
			this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
			return;
		}
	}
	if(obj.dateUntil == null || obj.dateUntil == undefined){
		obj.dateUntil = '2050-12-31'
	}
    obj.type = this.type.value;
	obj.customer_has_plan = {id:JSON.parse(localStorage.getItem("customer_has_plan")).chp};
   	this.cuponService.create(obj).subscribe(response =>{
      this.notificationService.sucessInsert(response.name_cupon);
      this.location.back();
    }, err=>{
      this.notificationService.error("Error creando cupon");
      console.log(err);
    }); 
}

}
