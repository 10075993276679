import { HttpParams } from '@angular/common/http';

export class HomeFilter {

    partners: string;
	period: number;
	day_since_date: string;
    day_until_date: string;


	
	constructor(filter?: HomeFilter) {
		if (filter) {
            this.partners = filter.partners;
			this.period = filter.period;
			this.day_since_date = filter.day_since_date;
			this.day_until_date = filter.day_until_date;
		}
	}
	getHttpParams(httpParams = new HttpParams()): HttpParams {
    
        if (this.partners) {
			httpParams = httpParams.set('partners', this.partners);
		}
		
		if (this.day_since_date) {
			httpParams = httpParams.set('since_date', this.day_since_date);
		}
		if (this.day_until_date) {
			httpParams = httpParams.set('until_date', this.day_until_date);
        }
   
        if (this.period) {
			httpParams = httpParams.set('period', this.period.toString());
        }
		return httpParams;
	}
}