
	<h3 class="title">Importar Clientes</h3>

	<form *ngIf="uploadFileForm" [formGroup]="uploadFileForm" (ngSubmit)="upload()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" >Compañia</label>

          <mat-select [formControl]="uploadFileForm.controls['selectedPartner']"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>

				</div>
				<div class="field-row-item">
          <label class="label" >Sucursal</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedSucursal']"  (selectionChange)="onSucursalChange($event.value)" disableRipple>
							<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
          <label class="label" >Servicio</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedService']"  (selectionChange)="onServiceChange($event.value)" disableRipple>
              <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
          <label class="label" >Plan</label>

          <mat-select [formControl]="uploadFileForm.controls['plan']"  disableRipple>
              <mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>

        </div>
        
        <div class="field-row-item">
          <label class="label">Archivo</label>
          <input style="display:inline; width: 90%;" type="file" (change)="onFileChange($event)" required>
          <button style="display:inline;" mat-raised-button 
            matTooltip="El archivo de clientes NO debe tener header y la siguiente estructura:
            Columna 1: Identificador(DNI) -
            Columna 2: Primer Nombre - 
            Columna 3: Primer Apellido - 
            Columna 4: Teléfono - 
            Columna 5: Dirección - 
            Columna 6: Email - 
            Columna 7: Fecha de nacimiento (yyyy-MM-dd) - 
            Columna 8: Fecha de admisión (yyyy-MM-dd) - 
            Columna 9: Fecha de culminación (yyyy-MM-dd) - 
            Columna 10: Número de contrato - 
            Columna 11: Código de cliente - 
            Columna 12: Número TDC - 
            Columna 13: Nombre de TDC - 
            Columna 14: Mes expiración - 
            Columna 15: Año Expiración - 
            Columna 16: Fecha de próximo Cobro"
                  aria-label="Button that displays a tooltip when focused or hovered over" >
                  <i class="material-icons">info</i>
          </button>
        </div>
        
			</div>
			

	

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!uploadFileForm.valid">
				<span>Continuar</span>
			</button>
		</div>
  </form>
  

  <modal-ns [(closed)]="confirmation">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea procesar los cobros de este archivo?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmation = true">
						<span >NO</span>
					</button>
					<button class="btn-text green" type="button" (click)="onCharge(); confirmation = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
  </modal-ns>
  
  <modal-ns [(closed)]="back">
		<ng-template modalContentDirective>
      <div class="dialog-content">
      <div class="dialog-title" >Notificacion</div>
				<div class="dialog-message" >El proceso de cobro se esta ejecutando, usted recibira un email con el resultado, adicionalmente puede consultar el estado de la importacion aqui</div>
				<div class="options">
					<button class="btn-text green" type="button" (click)="backToList()">
						<span >Consultar</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
