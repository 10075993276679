import { HttpParams } from '@angular/common/http';

export class ServiceFilter {

	name_service: string;
    partner: number;
	id: number;
	status_service: number;
    
	constructor(filter?: ServiceFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_service = filter.name_service;
			this.partner = filter.partner;
			this.status_service = filter.status_service;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_service) {
			httpParams = httpParams.set('name_service', this.name_service);
        }
        if (this.partner) {
			httpParams = httpParams.set('partner', this.partner.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_service) {
			httpParams = httpParams.set('status_service', this.status_service.toString());
		}
		return httpParams;
	}
}