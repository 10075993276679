import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {ActivatedRoute } from '@angular/router';
import { Location , DatePipe} from '@angular/common';
import { NotificationService} from '../../shared/notification/notification.service';
import { BaseService } from '../../shared/services/base.service';
import { CuponService } from '../cupon.service';

@Component({
  template: `
  <h3 class="title">Editar Cupon</h3>

	<form  [formGroup]="updateCuponForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
    <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_cupon">Nombre</label>
					<input formControlName="name_cupon" type="text" autocomplete="off" placeholder="Ingrese Nombre" readonly >
					<validator [control]="updateCuponForm.controls['name_cupon']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="type" >Tipo</label>
			
          <mat-select [formControl]="typeS" disabled="true">
            <mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
          </mat-select>
          <validator [control]="typeS"></validator>

				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="value">Costo</label>
					<input formControlName="value" type="number" autocomplete="off" placeholder="Ingrese valor" >
					<validator [control]="updateCuponForm.controls['value']"></validator>
				</div>
				<div class="field-row-item">
          <label class="label" for="status" >Estatus</label>

          <mat-select [formControl]="estatusS" disableRipple>
						<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
					</mat-select>
          <validator [control]="estatusS"></validator>
          
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="dateFrom">Fecha desde</label>
					<input formControlName="dateFrom" type="date"  placeholder="Ingrese costo" readonly >
					<validator [control]="updateCuponForm.controls['dateFrom']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="dateUntil">Fecha Hasta</label>
					<input formControlName="dateUntil" type="date"  placeholder="Ingrese fecha" >
					<validator [control]="updateCuponForm.controls['dateUntil']"></validator>
				</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCuponForm.valid || !estatusS.valid">
				<span>Guardar</span>
			</button>
		</div>
`
})
export class UpdateCuponComponent implements OnInit {

  public estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  public types : any[] = [{value:1,description:"Porcentaje - %"},{value:2, description:"Monto - $"}];


  estatusS: FormControl = new FormControl('', Validators.required);
  typeS: FormControl = new FormControl('', Validators.required);

  // validaciones para campos de formulario
	updateCuponForm = this.fb.group({
		name_cupon: ['', [Validators.required]],
		value: ['', [Validators.required]],
    dateFrom : ['',[Validators.required]],
    id: [''],
    dateUntil : ['',[]]
	});

  constructor(
    public  fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private cuponService: CuponService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) { }


  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(param => {
      this.cuponService.getById(param.cuponID).subscribe(response=>{

        this.updateCuponForm.controls["name_cupon"].setValue(response.name_cupon);
        this.updateCuponForm.controls["value"].setValue(response.value);
        this.updateCuponForm.controls["dateFrom"].setValue(this.datePipe.transform(response.dateFrom, 'yyyy-MM-dd'));
        this.updateCuponForm.controls["dateUntil"].setValue(this.datePipe.transform(response.dateUntil, 'yyyy-MM-dd'));
        this.updateCuponForm.controls["id"].setValue(response.id);
        this.estatusS.setValue(this.estatus[this.estatus.findIndex(e => response.status === e.value)].value);
        this.typeS.setValue(this.types[this.types.findIndex( t => response.type === t.value)].value);
      })
    })
  }

  update() {
    let obj = <any> BaseService.builderObject(this.updateCuponForm.value)

    // esto es un tipo de porcentaje
    if(this.typeS.value == 1){
      if(obj.value < 0 || obj.value > 100){
        this.notificationService.error("Valor del porcentaje debe estar entre 0 y 100");
        return;
      }
    }
    if(obj.dateFrom != null && obj.dateUntil != null){
			var since = new  Date (obj.dateFrom);
			var until = new  Date (obj.dateUntil);
      if(until.getTime() < since.getTime()){
        this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
        return;
			}
    }
  
   obj.status = this.estatusS.value;
   obj.type = this.typeS.value;
   
   this.cuponService.update(obj).subscribe(response =>{
      this.notificationService.sucessUpdate(response.name_cupon);
      this.location.back();
    }, err=>{
      this.notificationService.error("Error actualizando cupon");
      console.log(err);
    });
}

}
