import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, FormControl } from '@angular/forms';
import {PartnerService} from '../../partner/partner.service';
import {CityService} from '../../city/city.service';
import {CountryService} from '../../country/country.service';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from '../../shared/services/base.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {CustomValidators} from '../../shared/form/custom.validators';

@Component({
  template: `
		<h3 class="title">Editar Sucursal</h3>

		<form *ngIf="updatePartnerForm" [formGroup]="updatePartnerForm" (ngSubmit)="update()">
			<fieldset class="fieldset">
        <legend class="legend" >Datos de la sucursal</legend>

        <div class="form-group">
          <input type="text" formControlName="id" class="form-control" hidden="true" >
        </div>
  
        <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_partner">Nombre</label>
              <input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
              <validator [control]="updatePartnerForm.controls['name_partner']"></validator>
            </div>
					<div class="field-row-item">
            <label for="sucursal">Compañía</label>
            <mat-select [formControl]="parentPartnerId">
                  <mat-option *ngFor="let compania of companias" [value]="compania.id">{{compania.name_partner}}</mat-option>
            </mat-select>
            <validator [control]="parentPartnerId"></validator>
					</div>
				</div>
        
       

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="adress_partner" >Direccion</label>
						<textarea formControlName="adress_partner"></textarea>
						<validator [control]="updatePartnerForm.controls['adress_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" >Pais</label>
					
            <mat-select [formControl]="country_obj" (selectionChange)="onItemChange($event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
            </mat-select>
            <validator [control]="country_obj"></validator>

					</div>
					<div class="field-row-item">
						<label class="label" for="city">Ciudad</label>  
            <mat-select [formControl]="city_obj" >
                  <mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
            </mat-select>
            <validator [control]="city_obj"></validator>

					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone_partner" >Telefono</label>
						<input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
						<validator [control]="updatePartnerForm.controls['phone_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="feeRate_partner">Porcentaje de cobro</label>
						<input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
						<validator [control]="updatePartnerForm.controls['feeRate_partner']"></validator>
          </div>
          <div class="field-row-item">
            <label class="label" for="returnRoute">Ruta de notificación</label>
            <input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" >
            <button style="display:inline;box-shadow: none;padding:0;min-width: 30px;" mat-raised-button matTooltip="Agregar nombre del cliente Ej: poliwin"
              aria-label="Button that displays a tooltip when focused or hovered over" >
              <i class="material-icons">info</i>
            </button>
            <validator [control]="updatePartnerForm.controls['returnRoute']"></validator>
          </div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email_partner">Email</label>
						<input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
						<validator [control]="updatePartnerForm.controls['email_partner']"></validator>
          </div>
          
          <div class="field-row-item">
            <label class="label" for="identifier_bank">Identificador del banco</label>
            <input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
            <validator [control]="updatePartnerForm.controls['identifier_bank']"></validator>
          </div>

          <div class="field-row-item">
              <label class="label" for="status" >Estatus</label>
              <mat-select [formControl]="status_partner">
                <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
              </mat-select>
              <validator [control]="status_partner"></validator>
          </div>
          
				</div>
				

			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!updatePartnerForm.valid || !status_partner.valid || !city_obj.valid || !country_obj.valid">
					<span>Actualizar</span>
				</button>
			</div>
		</form>
	`
})
export class EditBranchComponent implements OnInit {
  private partner: any;
  private countries = [];
  private cities = [];
  private companias : any[]= [];

  private estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];

  public status_partner: FormControl = new FormControl('', Validators.required);
  public parentPartnerId: FormControl = new FormControl('', Validators.required);
  public city_obj: FormControl = new FormControl('', Validators.required);
  public country_obj: FormControl = new FormControl('', Validators.required);


  // validaciones para campos de formulario
  updatePartnerForm = this.fb.group({
    id: ['', [Validators.required]],
    name_partner: ['', [Validators.required]],
    identifier_bank: ['', [Validators.required]],
		adress_partner: ['', [Validators.required]],
		email_partner: ['', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]],
		phone_partner: ['', [Validators.required]],
		feeRate_partner: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
		returnRoute:['', [Validators.required]]
	});

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private partnerService: PartnerService,
    private countryService: CountryService,
    private cityService: CityService,
    private notificationService: NotificationService
  ){}

  ngOnInit(){
    
    this.cargarCombos(); // cargamos los combos de la vista
    /// buscamos la informacion del partner
    this.activatedRoute.parent.params.subscribe(param => {
      let idpartner;
      if (param['partnerId']) {
        idpartner = param['partnerId'];
      }else{
        idpartner = param['branchId'];
      }
      this.partnerService.getById(idpartner).subscribe(partner => {
        this.partner = partner;
        // setamos la informacion del partner en el formulario
        this.updatePartnerForm.controls["name_partner"].setValue(this.partner.name_partner);
        this.updatePartnerForm.controls["identifier_bank"].setValue(this.partner.identifier_bank);
        this.updatePartnerForm.controls["adress_partner"].setValue(this.partner.adress_partner);
        this.updatePartnerForm.controls["phone_partner"].setValue(this.partner.phone_partner);
        this.updatePartnerForm.controls["email_partner"].setValue(this.partner.email_partner);
        this.updatePartnerForm.controls["feeRate_partner"].setValue(this.partner.feeRate_partner);
        this.updatePartnerForm.controls["returnRoute"].setValue(this.partner.returnRoute);
        this.updatePartnerForm.controls["id"].setValue(this.partner.id);
        /// buscamos las ciudades del pais del usuario
        this.cityService.getCity(this.partner.city.id).subscribe(city =>{
          // buscamos todas las ciudades que posee el pais del partner
          this.cityService.getByCountry(city['country'].id).subscribe(params => {
            // llenamos las ciudades
            this.cities = params['result'];
            // buscamos las posiciones de ciudad,pais y estatus del aprtner seleccionado
            this.status_partner.setValue(this.estatus[this.estatus.findIndex(e => this.partner.status_partner === e.value)].value)
            this.parentPartnerId.setValue(this.companias[this.companias.findIndex( c => c.id === this.partner.parentPartner.id )].id)
            this.country_obj.setValue(this.countries[this.countries.findIndex(e => this.partner.city.country.id === e.id)].id);
            this.city_obj.setValue(this.cities[this.countries.findIndex(e => this.partner.city.id === e.id)].id);
          }, (err) => {
            this.notificationService.error(err); 
          });
        },(err) => {
          this.notificationService.error(err);
        }); 
      }, (err) => {
        this.notificationService.error(err);
        
      });
    });


  }


  cargarCombos(){
    /// cargamos los paises registrados
    this.countryService.getAll().subscribe(params => {
      this.countries = params['result'];
    }, (err) => {
      this.notificationService.error(err);
    });

    let httpParams = this.partnerService.buildRequestParams('name_partner', 's');

    httpParams = httpParams.set('parents',false.toString());
	  httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.companias = params['result'];
    },(err) =>{
      this.notificationService.error(err);
    });
  }

  onItemChange(country:any){
    // cargamos las ciudades del pais que seleccione el usuario
   this.cities = [];
   if(country!=undefined){
     this.cityService.getByCountry(country).subscribe(params => {
         this.cities = params['result'];
         }, (err) => {
           this.notificationService.error(err);
     });
   }
 }

  update() {
    if(!this.updatePartnerForm.valid){
      this.notificationService.error("Debe llenar todos los campos del formulario");
      return;
    }
    let obj = <any> BaseService.builderObject(this.updatePartnerForm.value);
    this.partner = obj;
    this.partner.city = {id: this.city_obj.value};
    this.partner.parentPartnerId = this.parentPartnerId.value;
    this.partner.status_partner = this.status_partner.value;
    this.partner.parentPartner = {id: this.parentPartnerId.value};
    // validamos el nombre introducido por el usuario
    this.partnerService.getByName(this.partner.name_partner).subscribe(partner =>{
      // si consigue un partner registrado con el mismo nombre
      if(partner.id == this.partner.id){ // si el conseguido tiene el mismo id que el que esta actualizando es porque son los mismos
        this.partner.email_partner = this.updatePartnerForm.controls["email_partner"].value;
        this.sendPartnerToCore(this.partner)
      }else{
        this.notificationService.error("Ya existe un Partner registrado con este nombre");
      }
    }, (err) => { /// si la BD arroja error 404 not found - osea el nombre no existe por ningun lado
            this.partnerService.getByEmail(this.partner.email_partner).subscribe(partner => {
              if (partner.id !== this.partner.id){
                this.notificationService.errorDuplicated('email');
              }else{
                this.sendPartnerToCore(this.partner)
              }
            }, (err) => {
              this.sendPartnerToCore(this.partner)
            });
    });

  }
  sendPartnerToCore(partner: any){
    if(partner.feeRate_partner == null){
      partner.feeRate_partner = 0;
    }
    this.partnerService.update(partner).subscribe(partner => {
      this.notificationService.sucessUpdate(partner.name_partner);
      this.location.back();
    }, err => this.notificationService.error(err));
  }
  
  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
