
  <h3 class="title">Crear Sucursal</h3>

  <form *ngIf="createPartnerForm" [formGroup]="createPartnerForm" (ngSubmit)="create()">
    <fieldset class="fieldset">
      <legend class="legend" i18n="@@data-account">Datos de la sucursal</legend>


      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="name_partner">Nombre</label>
          <input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
          <validator [control]="createPartnerForm.controls['name_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label for="partner">Compañía</label>
        
          <mat-select [formControl]="parentPartnerId" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
          <validator [control]="parentPartnerId"></validator>
        
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="adress_partner" >Direccion</label>
          <textarea formControlName="adress_partner"></textarea>
          <validator [control]="createPartnerForm.controls['adress_partner']"></validator>
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="country" >Pais</label>

          <mat-select [formControl]="createPartnerForm.controls['selectedCountry']" [compareWith]="compareFn" (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
					</mat-select>
					<validator [control]="createPartnerForm.controls['selectedCountry']"></validator>


        </div>
        <div class="field-row-item">
          <label class="label" for="city">Ciudad</label>
          <mat-select [formControl]="createPartnerForm.controls['selectedCity']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
					</mat-select>
					<validator [control]="createPartnerForm.controls['selectedCity']"></validator>
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="phone_partner" >Telefono</label>
          <input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
          <validator [control]="createPartnerForm.controls['phone_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="feeRate_partner">Porcentaje de cobro</label>
          <input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
          <validator [control]="createPartnerForm.controls['feeRate_partner']"></validator>
        </div>
        <div class="field-row-item">
						<label class="label" for="returnRoute">Ruta de notificación</label>
            <input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" >
            <button style="display:inline;box-shadow: none;padding:0;min-width: 30px;" mat-raised-button matTooltip="Agregar nombre del cliente Ej: poliwin"
                  			aria-label="Button that displays a tooltip when focused or hovered over" >
                  			<i class="material-icons">info</i>
          	</button>
						<validator [control]="createPartnerForm.controls['returnRoute']"></validator>
					</div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="email_partner">Email</label>
          <input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
          <validator [control]="createPartnerForm.controls['email_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="identifier_bank">Identificador del banco</label>
          <input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
          <validator [control]="createPartnerForm.controls['identifier_bank']"></validator>
        </div>
        <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createPartnerForm.controls['status_partner']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['status_partner']"></validator>
				</div>
      </div>
      

    </fieldset>

    <div class="options">
      <button class="btn-text" type="submit" [disabled]="!createPartnerForm.valid || !parentPartnerId.valid">
        <span>Guardar</span>
      </button>
    </div>
  </form>
