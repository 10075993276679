import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification/notification.service';
import { UserToolService } from './usertool.service';
import { UserToolModel } from './usertoolModel';


@Component({
	selector: 'usertool',
	template: `
		<div class="top-bar">
			<span *ngIf="user" i18n="@@welcome">Bienvenido: <b class="clickable-item"  (click)="perfil()" >{{username}}</b></span>
			<span *ngIf="!user" i18n="@@welcome-unknow">Bienvenido: <b>unknow</b></span>
			<a class="clickable-item" style="margin-left: 10px;" (click)="logout()" i18n="@@logout">Salir</a>
		</div>
	`,
	styleUrls: ['./usertool.component.css'],
	providers:
	[
		UserToolService
	]
})
// TODO - Adaptar el cambio de contrase*a
/*
		<div *ngIf="user" class="down-right"> Usuario: <b>{{username}}</b> | <a (click)="password()">Cambiar clave</a> | <a (click)="logout()">Salir</a></div>
*/

export class UsertoolComponent implements OnInit {

	user = false;
	username: string;
	constructor(private router: Router, private notificationService: NotificationService, private userToolService: UserToolService) {
	}

	ngOnInit() {
		this.user = sessionStorage.getItem('token') != null ? true : false;
		if (this.user) {
      	this.username = JSON.parse(localStorage.getItem('currentData')).user.name_user.toUpperCase();
			// TODO - Esto va a cambiar
		} else {
			this.notificationService.genericerror('No ha ingresado al sistema', 'pruebe hacer login');
			this.router.navigateByUrl('/');
			localStorage.clear();
			sessionStorage.clear();
		}
	}

	public logout() {
		this.notificationService.genericsuccess('Ha salido del sistema', 'Tenga un buen dia!');
		this.router.navigate(['/']);
		localStorage.clear();
		sessionStorage.clear();
	}

	public perfil() {
		this.router.navigate(['/home/user']);
	}
}