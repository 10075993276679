import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class RebillingService extends BaseService  {

  private readonly BASE_URL: string = this.HOST + '/rebilling';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super();
     // Http Headers
     this.httpOptions = {
      headers: this.createAuthorizationHeader()
    } 
  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  getAllErrorsCodeBac(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/errorcodebac', this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getByBilling(params: HttpParams = new HttpParams(), id: number): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/billing/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  create(billing: any): Observable<any> {
    return this.http.post(this.BASE_URL, billing, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(billing: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + billing['id'], billing, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  
  delete(id: number): Observable<any> {
    return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  deletes(ids: {'ids': number[]}): Observable<any> {
    return this.http.post(this.BASE_URL + '/delete', ids , this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

}
