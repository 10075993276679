
	<h3 class="title">Actualizar plan a cliente</h3>

	<form *ngIf="updateCustomerHasPlansForm" [formGroup]="updateCustomerHasPlansForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
			
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_customer">Nombre</label>
					<input  type="text" autocomplete="off" value="{{ customer.name_customer }} {{customer.lastName_customer }}" readonly>
				</div>
      </div>
      
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="service" >Servicio</label>
					<input  type="text" autocomplete="off" value="{{ servicio.name_service }}" readonly>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
					<input  type="text" autocomplete="off" value=" {{ plan.name_plan }} " readonly>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="contractNumber_Plan">Número de contrato</label>
					<input formControlName="contractNumber_Plan" type="text"  autocomplete="off" placeholder="Ingrese el número de contrato" >
					<validator [control]="updateCustomerHasPlansForm.controls['contractNumber_Plan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="idPlanEnrollmentrenewalPartner">Código de renovación de matricula del partner</label>
					<input formControlName="idPlanEnrollmentrenewalPartner" type="text" autocomplete="off" placeholder="Ingrese el código de renovación de matricula del partner" >
					<validator [control]="updateCustomerHasPlansForm.controls['idPlanEnrollmentrenewalPartner']"></validator>
        </div>
        
			</div>

      <div class="field-row">
      
        <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha de inscripción del plan(DD/MM/YYYY)" formControlName="startdate_customerPlan"  readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <validator [control]="updateCustomerHasPlansForm.controls['startdate_customerPlan']"></validator>
        </div>

        <div class="field-row-item">
          <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Ingrese fecha de culminación del plan(DD/MM/YYYY)" formControlName="endDate_customerPlan"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker2></mat-datepicker>
          <validator [control]="updateCustomerHasPlansForm.controls['endDate_customerPlan']"></validator>
        </div>

			</div>

      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="tdc">Tarjeta de crédito asociada</label>
        
          <mat-select [formControl]="tdc"  disableRipple>
            <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.id">{{tarjeta.maskCreditCard}} - {{tarjeta.nameHolderCreditCard}}</mat-option>
          </mat-select>
          <validator [control]="tdc"></validator>

        </div>
        
        <div class="field-row-item">
					<label class="label" for="tdc">Estatus</label>

          <mat-select [formControl]="status"  disableRipple>
            <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
          </mat-select>
          <validator [control]="status"></validator>

        </div>

        <div class="field-row-item">
          <label class="label" for="next">Proxima fecha de cobro</label>
          <input  type="text" autocomplete="off" value="{{ chp?.next_bill_date }}" readonly>
        </div>

        <div class="field-row-item">
          <label class="label" for="next" >Calcular fecha de cobro</label>
          <mat-select [formControl]="updateCustomerHasPlansForm.controls['nextBill']"  disableRipple>
            <mat-option *ngFor="let n of next" [value]="n.value">{{n.description}}</mat-option>
          </mat-select>
          <validator [control]="updateCustomerHasPlansForm.controls['nextBill']"></validator>
        </div>
        
			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCustomerHasPlansForm.valid || !status.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
