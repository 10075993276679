	
		<div i18n="@@recoverPass-forgot">
			<div class="dialog-title">¿Olvidaste tu contraseña?</div>
			<div class="dialog-message">Ingrese su correo electrónico y le enviaremos un pin para crear un nueva contraseña. Si ya posee el pin, entra <u class="clickable-item" routerLink="./reset">aqui</u></div>
		</div>

		<form [formGroup]="form" (ngSubmit)="requestPin()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span>Enviar Pin para reestablecer contraseña</span>
				</button>
			</div>
		</form>

		<a class="clickable-item link" routerLink="../login" >Regresar a <u>Autenticarse</u></a>
	