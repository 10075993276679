
	<h3 class="title">Respuestas obtenidas para el archivo</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del archivo</span>
					<span>{{ name_source || '-'}}</span>
				</div>
			</div>


			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status || '-'}}</span>
				</div>
			</div>

		</div>
  </div>
<!--
  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button class="btn-icon" type="button" (click)="export()">
        <i class="material-icons">cloud_download</i>
      </button>
    </div>
  </div>
-->

  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
	
			<ng-container matColumnDef="json.customer.identifier_customer">
				<th mat-header-cell *matHeaderCellDef  >DNI</th>
				<td mat-cell *matCellDef="let element">{{element?.json?.customer?.identifier_customer || element?.json?.dni}}</td>
			</ng-container>
			
			<ng-container matColumnDef="json.customer.name_customer">
				<th mat-header-cell *matHeaderCellDef  >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element?.json?.customer?.name_customer || "-" }}  </td>
      </ng-container>
      
      <ng-container matColumnDef="json.creditcard.numberCreditCard">
        <th mat-header-cell *matHeaderCellDef  >TDC</th>
        <td mat-cell *matCellDef="let element">{{element?.json?.creditcard?.numberCreditCard || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="result.code">
        <th mat-header-cell *matHeaderCellDef  >Respuesta</th>
        <td mat-cell *matCellDef="let element">{{element?.result?.code  || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="result.message">
        <th mat-header-cell *matHeaderCellDef  >Mensaje</th>
        <td mat-cell *matCellDef="let element">{{element?.result?.message  || "-"}}</td>
      </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
