import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {BaseService} from '../../shared/services/base.service';
import { IntegrationService } from '../integration.service';
import {ActivatedRoute } from '@angular/router';
import { PartnerService } from '../../partner/partner.service';
import { FormControl } from '@angular/forms';

@Component({
  template: `
	<h3 class="title">Actualizar</h3>

	<form *ngIf="updateIntegrationForm" [formGroup]="updateIntegrationForm" (ngSubmit)="update()">
    <fieldset class="fieldset">
    
    <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

      <div class="field-row">

        <div class="field-row-item">
          <label class="label" for="country" >Integracion de compañía</label> 
          <mat-select [formControl]="partner"  disableRipple>
              <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
          </mat-select>
          <validator [control]="partner"></validator> 
        </div>
      
				<div class="field-row-item">
					<label class="label" for="user">Usuario</label>
					<input formControlName="user" type="text" autocomplete="off" placeholder="Ingrese Usuario" >
					<validator [control]="updateIntegrationForm.controls['user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="password">Password</label>
          <input formControlName="password" type="text" autocomplete="off" placeholder="Ingrese Password" >
          <validator [control]="updateIntegrationForm.controls['password']"></validator>
        </div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="url">Url del cliente</label>
					<input formControlName="url" type="text" autocomplete="off" placeholder="Ingrese url" >
					<validator [control]="updateIntegrationForm.controls['url']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="token">Token de seguridad</label>
          <input formControlName="token" type="text" autocomplete="off" placeholder="Ingrese token" >
          <validator [control]="updateIntegrationForm.controls['token']"></validator>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateIntegrationForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class UpdateIntegrationComponent implements OnInit {

  integration: any;
  private partners : any[];
  public partner: FormControl = new FormControl('', Validators.required);

  updateIntegrationForm = this.fb.group({
    id: [''],
    user: ['', [Validators.required]],
    password: ['',[Validators.required]],
    url: ['', [Validators.required]],
    token: ['',[Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private integrationService: IntegrationService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private partnerService : PartnerService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(params =>{
      this.integrationService.getById(params.integrationId).subscribe(integration =>{
        this.updateIntegrationForm.controls["user"].setValue(integration.user);
        this.updateIntegrationForm.controls["password"].setValue(integration.password);
        this.updateIntegrationForm.controls["url"].setValue(integration.url);
        this.updateIntegrationForm.controls["token"].setValue(integration.token);
        this.updateIntegrationForm.controls["id"].setValue(params.integrationId);
        this.partnerService.getAll2().subscribe(response =>{
          this.partners = response.result;
          this.partner.setValue(this.partners[this.partners.findIndex(e => integration.partner.id === e.id)].id);
        });
      });
    });
  }

  update() {
    this.integration = <any> BaseService.builderObject(this.updateIntegrationForm.value);
    this.integration.partner = {id: this.partner.value}
    this.integrationService.update(this.integration).subscribe(response => {
      this.notificationService.sucessUpdate(response.url);
			this.location.back();
    }, err => this.notificationService.error(err));
  }

}
