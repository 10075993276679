import { HttpParams } from '@angular/common/http';

export class EnrollmentFilter {
    id: number;
    id_companys: number;
    id_services: number;
    id_plans: number;
    since_dates: string;
    until_dates: string;
    id_sucursals: number;
	
    
	constructor(filter?: EnrollmentFilter) {
		if (filter) {
            this.id = filter.id;
            this.id_companys = filter.id_companys;
            this.id_services = filter.id_services;
            this.id_plans = filter.id_plans;
            this.since_dates = filter.since_dates;
            this.until_dates = filter.until_dates;
            this.id_sucursals = filter.id_sucursals;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.id_services) {
			httpParams = httpParams.set('id_service', this.id_services.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
        }
        if (this.id_sucursals) {
			httpParams = httpParams.set('id_sucursal', this.id_sucursals.toString());
        }
        if (this.id_companys) {
			httpParams = httpParams.set('id_company', this.id_companys.toString());
		}
		if (this.id_plans) {
			httpParams = httpParams.set('id_plan', this.id_plans.toString());
        }
        if (this.until_dates) {
			httpParams = httpParams.set('until_date', this.until_dates);
        }
        if (this.since_dates) {
			httpParams = httpParams.set('since_date', this.since_dates);
        }
		return httpParams;
	}

}