import {  Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationService} from '../../shared/notification/notification.service';
import { RoleService } from '../role.service';
import { BaseService } from '../../shared/services/base.service';
import {PermissionService} from '../../permission/permission.service';

@Component({
  template: `
	<h3 class="title">Crear Rol</h3>

	<form *ngIf="createRoleForm" [formGroup]="createRoleForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del rol</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createRoleForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="createRoleForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
        <label class="label" for="rol" >Elija los permisos que tendra el rol</label>
        <mat-select  [formControl]="permiMultiCtrl" [multiple]="true" #multiSelect>
          <ngx-mat-select-search [formControl]="permiMultiFilterCtrl"></ngx-mat-select-search>
          <mat-option *ngFor="let bank of filteredPermiMulti | async" [value]="bank">
            {{bank.description}}
          </mat-option>
        </mat-select>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createRoleForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class RoleCreateComponent implements OnInit {

  public loading = false;
  permissions: any[] = [];
  permissionSelected: any[] = [];
  default: any[] = [];

  /** control for the selected role for multi-selection */
  public permiMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public permiMultiFilterCtrl: FormControl = new FormControl();

    /** list of roles filtered by search keyword */
  public filteredPermiMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect',{static: false}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // validaciones para campos de formulario
  createRoleForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['',[Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private location: Location,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.permissionService.getAll().subscribe(permission => {
      this.permissions = permission['result'];
      // set initial selection
      //this.bankMultiCtrl.setValue([this.banks[10], this.banks[11], this.banks[12]]);

      // load the initial role list
      this.filteredPermiMulti.next(this.permissions.slice());

      // listen for search field value changes
      this.permiMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPermisMulti();
        });
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createRoleForm.value);
    // seteamos los permisos seleccionados por el usuario para el rol que se va a crear
    obj.permissions = this.permiMultiCtrl.value;
    this.roleService.create(obj).subscribe(role => {
      this.notificationService.sucessInsert(role['name']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    }) ;
  }

  selectPermissions(permisos: any[]) {
    this.permissionSelected = permisos;
  }

  searchPermissions(value: string) {
   
    let httpParams = this.permissionService.buildRequestParams(null, null, null);

    httpParams = httpParams.set("description", value)
    
    this.permissionService.getAllSearch(httpParams).subscribe( permisos => {
      this.permissions = permisos['result'];
      if(this.permissions.length === 0){
        this.notificationService.error('Su busqueda no ha arrojado resultados');
      }
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredPermiMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterPermisMulti() {

    if (!this.permissions) {
      return;
    }
    // get the search keyword
    let search = this.permiMultiFilterCtrl.value;
    if (!search) {
      this.filteredPermiMulti.next(this.permissions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredPermiMulti.next(
      this.permissions.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );

  }

}
