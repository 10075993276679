import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { partnerRoutes } from '../partner/partner.routes';
import {roleRoutes} from '../role/role.routes';
import {groupRoutes} from '../group/group.routes';
import {userRoutes} from '../users/user.routes';
import {frecuencyRoutes} from '../frecuency/frecuency.route';
import {billingRoutes} from '../billing/billing.routes';
import {rebillingRoutes} from '../rebilling/rebilling-routing';
import {branchRoutes} from '../branch/branch.routes';
import {jobRoutes} from '../job/job.routes';
import {reportRoutes} from '../report/report.routes';
import {fileRoutes} from '../file/file.routes';
import { usersRoutes } from '../user/user.routes';
import { ResumenComponent } from './resumen.component';
import { dashboardRoutes } from '../dashboard/dashboard.routes';
import { enrollmentRoutes } from '../enrollment/enrollment.routes';
import { integrationRoutes } from '../integration/integration.route';



export const homeRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {
			    path: '',
			    pathMatch: 'full',
			    component: ResumenComponent
			},
           ...partnerRoutes,
          ...roleRoutes,
          ...groupRoutes,
          ...userRoutes,
          ...usersRoutes,
          ...frecuencyRoutes,
          ...billingRoutes,
          ...branchRoutes,
          ...jobRoutes,
          ...reportRoutes,
          ...fileRoutes,
          ...dashboardRoutes,
          ...enrollmentRoutes,
          ...integrationRoutes
        ],
        data: {
            breadcrumb: 'Home',
            icon: 'home'
        }
    }
];

export const homeRouting : ModuleWithProviders =  RouterModule.forChild(homeRoutes);
