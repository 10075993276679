import { Routes } from '@angular/router';
import {FileComponent} from './file/file.component';
import {FileListComponent} from './file-list/file-list.component';
import {FileCreateComponent} from './file-create/file-create.component';
import {ResponseFileComponent} from './response-file/response-file.component';

export const fileRoutes: Routes = [
  {
    path: 'files',
    component: FileComponent,
    data: {
      breadcrumb: 'Archivos',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: FileListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: FileCreateComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':fileId',
        component: FileComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ResponseFileComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          }
        ]
      }
    ]
  }
];

export const routingFileComponnents = [FileComponent,FileListComponent,FileCreateComponent,ResponseFileComponent];
