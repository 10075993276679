import { Component, OnInit , ViewChild} from '@angular/core';
import { Chart } from 'chart.js';

// Progres Bar - <ng-progress></ng-progress>
// https://www.npmjs.com/package/ngx-progressbar
@Component({
  selector: 'app-home',
  template: `
  <ng-progress></ng-progress>
  <div class="grid-h">
    <div class="nav">
      <side-nav></side-nav>
    </div>
    <div class="content grid-v">
      <section class="header">
        <header>
          <usertool></usertool>
          <breadcrumbs id="breadcrumbs"></breadcrumbs>
        </header>
      </section>

      <section class="content">
        <router-outlet>
       <!-- <div> <canvas #lineChart>{{ chart }}</canvas> </div> -->
        </router-outlet>
      </section>

      <!--<footer class="footer"></footer>-->
    </div>
  </div>
	`,
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  //@ViewChild('lineChart') private chartRef;    
  //chart: any; 

  constructor() { }

  ngOnInit() {
    /*this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: [
          'Red',
          'Yellow',
          'Blue'
      ], // your labels array
        datasets: [
          {
            data: [10, 20, 30], // your data array
            borderColor: '#00AEFF',
            fill: false
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        }
      }
    }); 
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'polarArea',
      data: {
        labels: [
          'Red',
          'Yellow',
          'Blue'
      ], // your labels array
      datasets: [{
        data: [10, 20, 30]
       }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        }
      }
    });*/
  }

}
