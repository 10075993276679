
import { Routes } from '@angular/router';
import { PlanComponent } from './plan.component';
import { PlanListComponent } from './plan-list.component';
import { PlanCreateComponent } from './plan-create.component';
import { PlanReadComponent } from './plan-read.component';
import { PlanUpdateComponent } from './plan-update.component';
import { optionsRoutes } from '../option/option.routes';

export const planesRoutes: Routes = [
	{
				path: ':IdService/plans',
				component: PlanComponent,
				data: {
					breadcrumb: "Planes"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: PlanListComponent,
					
					},
					{
					    path:'create',
					    component: PlanCreateComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':planID',
					    component: PlanComponent,
					    data: {
					    	breadcrumb: "Detalle"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: PlanReadComponent,
							
							},
							{
							    path: 'update',
							    component: PlanUpdateComponent,
							  	data: {
							    	breadcrumb: "Actualizar"
							    }
						  	}

					  	]
					  },
					  ...optionsRoutes
				]
			}
];

export const routingPlanComponnents = [PlanComponent, PlanListComponent, PlanCreateComponent, PlanReadComponent, PlanUpdateComponent];
