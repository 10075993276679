import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseService } from '../shared/services/base.service';
import { NotificationService} from '../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {Servicio} from "../servicio/servicio";
import {Plan} from "../plan/plan";
import {Customer_has_plansService} from "../customer_has_plans/customer_has_plans.service";
import {Creditcard} from "../creditcard/creditcard";
import {CreditcardService} from "../creditcard/creditcard.service";
import {Customer} from "../customer/customer";

@Component({
  template: `
	<h3 class="title">Actualizar plan a cliente</h3>

	<form *ngIf="updateCustomerHasPlansForm" [formGroup]="updateCustomerHasPlansForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
			
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_customer">Nombre</label>
					<input  type="text" autocomplete="off" value="{{ customer.name_customer }} {{customer.lastName_customer }}" readonly>
				</div>
      </div>
      
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="service" >Servicio</label>
					<input  type="text" autocomplete="off" value="{{ servicio.name_service }}" readonly>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
					<input  type="text" autocomplete="off" value=" {{ plan.name_plan }} " readonly>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="contractNumber_Plan">Número de contrato</label>
					<input formControlName="contractNumber_Plan" type="text"  autocomplete="off" placeholder="Ingrese el número de contrato" >
					<validator [control]="updateCustomerHasPlansForm.controls['contractNumber_Plan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="idPlanEnrollmentrenewalPartner">Código de renovación de matricula del partner</label>
					<input formControlName="idPlanEnrollmentrenewalPartner" type="text" autocomplete="off" placeholder="Ingrese el código de renovación de matricula del partner" >
					<validator [control]="updateCustomerHasPlansForm.controls['idPlanEnrollmentrenewalPartner']"></validator>
        </div>
        
			</div>

      <div class="field-row">
      
        <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha de inscripción del plan(DD/MM/YYYY)" formControlName="startdate_customerPlan"  readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <validator [control]="updateCustomerHasPlansForm.controls['startdate_customerPlan']"></validator>
        </div>

        <div class="field-row-item">
          <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Ingrese fecha de culminación del plan(DD/MM/YYYY)" formControlName="endDate_customerPlan"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker2></mat-datepicker>
          <validator [control]="updateCustomerHasPlansForm.controls['endDate_customerPlan']"></validator>
        </div>

			</div>

      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="tdc">Tarjeta de crédito asociada</label>
        
          <mat-select [formControl]="tdc"  disableRipple>
            <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.id">{{tarjeta.maskCreditCard}} - {{tarjeta.nameHolderCreditCard}}</mat-option>
          </mat-select>
          <validator [control]="tdc"></validator>

        </div>
        
        <div class="field-row-item">
					<label class="label" for="tdc">Estatus</label>

          <mat-select [formControl]="status"  disableRipple>
            <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
          </mat-select>
          <validator [control]="status"></validator>

        </div>

        <div class="field-row-item">
          <label class="label" for="next">Proxima fecha de cobro</label>
          <input  type="text" autocomplete="off" value="{{ chp?.next_bill_date }}" readonly>
        </div>

        <div class="field-row-item">
          <label class="label" for="next" >Calcular fecha de cobro</label>
          <mat-select [formControl]="updateCustomerHasPlansForm.controls['nextBill']"  disableRipple>
            <mat-option *ngFor="let n of next" [value]="n.value">{{n.description}}</mat-option>
          </mat-select>
          <validator [control]="updateCustomerHasPlansForm.controls['nextBill']"></validator>
        </div>
        
			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCustomerHasPlansForm.valid || !status.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CustomerHasPlansUpdateComponent{

  private customer: Customer = new Customer();
  private tarjetas : Creditcard[] = [];
  private lastarjetasa = [];
  private servicio : Servicio = new Servicio();
  private plan : Plan = new Plan();
  private isDisabled : boolean = false;
  chp: any;

  private estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}, {value:3,description:'Congelado'}];

  private next : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];

  tdc: FormControl = new FormControl('', Validators.required);
  status: FormControl = new FormControl('', Validators.required);
 


  //Formulario de actualizacion del plan asociado al cliente
  updateCustomerHasPlansForm = this.fb.group({
    contractNumber_Plan: [''],
    startdate_customerPlan: ['', [Validators.required]],
    idPlanEnrollmentrenewalPartner: [''],
    endDate_customerPlan: ['', [Validators.required]],
    nextBill: ['', [Validators.required]]
    
  });

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private hasplanService: Customer_has_plansService,
    private creditCardService: CreditcardService,
    private notificationService: NotificationService
  ){}

  //Permite validar que solo sean numeros los introducidos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  ngOnInit(){
    
    this.activatedRoute.parent.params.subscribe(params =>{
    //Se carga el plan asociado al cliente que se desea actualizar
      this.hasplanService.getById(params["customerhasplansID"]).subscribe(chp => {

        this.chp = chp;
        this.customer = this.chp.customer;
        this.plan = this.chp.plan;
        this.servicio = this.chp.plan.service; 
        //Se carga la informacion del plan asociado al cliente a traves de los componentes
        if (this.chp.lastOkBillingDate != null) this.chp.lastOkBillingDate = this.chp.lastOkBillingDate;
        if (this.chp.lastNokBillingDate != null) this.chp.lastNokBillingDate = this.chp.lastNokBillingDate;
        this.updateCustomerHasPlansForm.controls["contractNumber_Plan"].setValue(this.chp.contractNumber_Plan);
        this.updateCustomerHasPlansForm.controls["startdate_customerPlan"].setValue(this.chp['startdate_customerPlan']);
        this.updateCustomerHasPlansForm.controls["endDate_customerPlan"].setValue(this.chp.endDate_customerPlan);
        this.updateCustomerHasPlansForm.controls["nextBill"].setValue(this.next[1].value);
        if (this.chp.idPlanEnrollmentrenewalPartner != null) this.updateCustomerHasPlansForm.controls["idPlanEnrollmentrenewalPartner"].setValue(this.chp.idPlanEnrollmentrenewalPartner);
        //Se selecciona el estado actual del plan asociado al cliente
        this.status.setValue(this.estatus[this.estatus.findIndex(e => this.chp.status_customerPlan === e.value)].value);

        //Se buscan las tarjetas del cliente
        this.creditCardService.findByCustomer(this.customer.id).subscribe(parametros => {

          if(parametros['result'] != null) {
            let lastarjetas = parametros['result'];
            for (let i in lastarjetas) {
              let tdc = lastarjetas[i].creditCard;
              this.creditCardService.findByToken(this.customer.id,lastarjetas[i].tokenCreditCard).subscribe(params => {
                if (params['result'] != null) {
                  this.lastarjetasa.push(lastarjetas[i]);
                  this.tarjetas.push(tdc);
                  if(this.chp.creditCardToken.id == params['result'].id){ // ESTA ES LA TDC ASOCIADA AL PLAN
                    this.tdc.setValue(this.tarjetas[this.tarjetas.length-1].id)
                  }
                }
              });
            }
          }        
        });
      });    
    });
  }

  update() {
    let obj = <any> BaseService.builderObject(this.updateCustomerHasPlansForm.value);           
    //Actualizando plan para el customer       
    this.chp.idPlanEnrollmentrenewalPartner = obj.idPlanEnrollmentrenewalPartner;
    this.chp.contractNumber_Plan = obj.contractNumber_Plan;

    let start = new Date(obj.startdate_customerPlan);
    let startPrev = new Date(this.chp.startdate_customerPlan);
    let end = new Date(obj.endDate_customerPlan);

    if(obj.nextBill == null){
      obj.nextBill = false;
    }
    // VALIDAMOS LAS FECHAS
    if(end.getTime() <= start.getTime()){ // ESTO EN CASO DE QUE LA FECHA DE CULMINACION SEA PREVIA A LA DE inscripción
      this.notificationService.error("Fecha de culminacion debe ser POSTERIOR a la fecha de inscripción");
      return;
    }
    if((start.getTime() != startPrev.getTime()) && obj.nextBill == false){ // ESTO EN CASO DE QUE SE CAMBIE LA FECHA DE INSCRIPCIOON - POR LO CUAL SE DEBE CALCULAR UNA NUEVA FECHA DE COBRO
      this.notificationService.error("Debe seleccionar la opcion : Calcular fecha de cobro");
      return;
    }

    this.chp.startdate_customerPlan = obj.startdate_customerPlan;
    this.chp.endDate_customerPlan = obj.endDate_customerPlan;
    this.chp.status_customerPlan = this.status.value;
    if(this.chp.lastOkBillingDate != null) this.chp.lastOkBillingDate = this.chp.lastOkBillingDate;
    if(this.chp.lastNokBillingDate != null) this.chp.lastNokBillingDate = this.chp.lastNokBillingDate;
    
    this.chp.nextBill = obj.nextBill;
    this.chp.plan = {id:this.chp.plan.id};
    this.chp.customer = {id:this.chp.customer.id};
    this.creditCardService.findByToken(this.customer.id,this.lastarjetasa[this.lastarjetasa.findIndex(t => t.creditCard.id == this.tdc.value)].tokenCreditCard).subscribe(prm => {
      let card = prm['result'];
      //Se guarda el id de la tarjeta seleccionada
      this.chp.creditCardToken = {id: card.id};
        // Se actualiza la informacion correspondiente
        this.hasplanService.update(this.chp).subscribe(hasplan => {
          this.notificationService.sucessUpdate(this.customer.name_customer);
          this.location.back();
        }, err => this.notificationService.error(err)); 
    });
  }
}
