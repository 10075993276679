import { Injectable } from '@angular/core';
import {BaseService} from '../shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';


export interface Filter {
  typeRole?: string;
}
@Injectable({
  providedIn : 'root'
})
export class UserService extends BaseService  {

  private readonly BASE_URL: string = this.HOST + '/user';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super();
     // Http Headers
     this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getAllSearch(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  create(user: any): Observable<any> {
    return this.http.post(this.BASE_URL, user, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(user: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + user['id'], user , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  updatePassword(id:any,obj: any): Observable<any> {
		return this.http.put(this.BASE_URL + '/' + id + '/changePassword', obj, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  
  delete(id: number): Observable<any> {
    return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  deletes(array: any[]): Observable<any> {
    return this.http.post(this.BASE_URL + '/delete', {ids: array} ,this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
}

}
