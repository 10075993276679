
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/services/base.service';
import { AuthorizationRequest } from './oauth2/authorizationRequest';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class AuthService extends BaseService {

	public token: string;
	private readonly BASE_URL: string = this.HOST + '/oauth';


	httpOptions : any;

	constructor( private http: HttpClient) {
		super();
	}

	public handleError (error: Response | any) {
		
			if (error.status === 400) {
			  //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
				return observableThrowError('Email o password es incorrecto, intente nuevamente');
			}
			if (error.status === 401) {
				return observableThrowError('No se pudo actualizar, su contraseña actual es incorrecta');
			}
			if (error.status === 403) {
				//   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
				return observableThrowError('No posee permisos suficientes para esta accion, contacte con el administrado');
			}
			if (error.status === 404) {
				return observableThrowError('No se pudo actualizar, registro inexistente');
			}
			if (error.status === 409) {
				return observableThrowError('No se pudo actualizar, corriga los parámetros requeridos o las dependencias inválidas e intente nuevamente');
			}
	
	}

	login(authorizationRequest: AuthorizationRequest): Observable<any> {
	
		let params = new HttpParams() ;
		params = params.set('grant_type', 'password');
		params = params.set('username', authorizationRequest.username);
		params = params.set('password', authorizationRequest.password);
		params = params.set('client_id', 'web_site');
		 // Http Headers
         this.httpOptions = {
			headers: this.createLoginHeader()
		  }
		return this.http.post(this.BASE_URL + '/token',params,this.httpOptions)
		.pipe(catchError(this.handleError));
	}

	logout() {
		localStorage.removeItem('currentData');
	}

	forgotPassword(email: string): Observable<boolean> {
		return this.http.get(this.BASE_URL + '/reset/' + email)
      .pipe(
        map(this.handleResponse)
        , catchError(this.handleError)
      );
	}

	recoverPassforgot(request: {email: string}): Observable<any> {
		return this.http.post(this.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
	}

	recoverPassReset(request: {pin: string, newPassword: string}): Observable<any> {
		return this.http.post(this.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
	}

/*	verifyToken(token: string): Observable<Headers> {
		let headers = new Headers({ 'Authorization': token });
		let options = new RequestOptions({ headers: headers });
		return this.http.get(AuthService.BASE_URL + '/verify', options)
      .pipe(
        map(BaseService.extractHeaders)
        , catchError(BaseService.handleError)
      );
	}

	resetPassword(email: string, password: string, token: string): Observable<Boolean> {
		let headers = new Headers({ 'Authorization': token });
		let options = new RequestOptions({ headers: headers });
		// return this.http.put(this.urlUser + '/reset', JSON.parse(JSON.stringify({ email: email, password: Global.toMD5(password)})), options)
		return this.http.put(AuthService.BASE_URL + '/reset', JSON.parse(JSON.stringify({ email: email, password: password})), options)
      .pipe(
        map(BaseService.handleResponse)
        , catchError(BaseService.handleError)
      );
	}

	changePassword(email: string, password: string, oldpassword:string): Observable<Boolean> {
		// console.log('NewPass '+ JSON.stringify({ email: email, password: Global.toMD5(password)}));
		return this.http.put(
			AuthService.BASE_URL + '/changepassword',
			JSON.parse(JSON.stringify({ email: email, password: password, oldpassword: oldpassword})))
      .pipe(
        map(BaseService.handleResponse)
        , catchError(BaseService.handleError)
      );
	}

	/* ToDo enhance this method 
	addOptionInHeader(key: string, value: any) {
		return new RequestOptions({ headers: new Headers({ key: value })});
	}

	getPayload(token: string) {
		let decodedToken = this.jwtHelper.decodeToken(token);
		return decodedToken;
	} */

}
