import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportService} from '../report.service';
import {NotificationService} from '../../shared/notification/notification.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { CuponService } from '../../cupon/cupon.service';


@Component({
  template: `
	<h3 class="title">Detalle de factura</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Cliente</span>
					<span>{{ invoice?.customer?.name_customer  + ' ' + invoice?.customer?.lastName_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Codigo de cliente</span>
					<span>{{ invoice?.customer?.code_customer || '-'}}</span>
				</div>
			</div>

			

			<div *ngIf="invoice?.customer?.sucursal_id != null" class="field-row">
				<div class="field-row-item">
					<span class="label">Sucursal</span>
					<span>{{ invoice?.customer?.sucursal_id || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ invoice?.customer_has_plan?.plan?.name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Contrato de cliente</span>
					<span>{{ invoice?.customer_has_plan?.contractNumber_Plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Total factura</span>
					<span>{{ invoice?.total_invoice || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Balance de factura</span>
					<span>{{ invoice?.balance_invoice }}</span>
				</div>
			</div>


			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus de factura</span>
					<span>{{ invoice?.status_invoice || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Creada</span>
					<span>{{ invoice?.createdAt || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Actualizada</span>
					<span>{{ invoice?.updatedAt || '-'}} </span>
				</div>
			</div>

		</div>
  </div>

  <br>

  <mat-tab-group>
	<mat-tab label="Pagos"> 

  	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef  >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="description_payment">
				<th mat-header-cell *matHeaderCellDef  >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element.description_payment}}</td>
			</ng-container>
			
			<ng-container matColumnDef="resultStatus_payment">
				<th mat-header-cell *matHeaderCellDef  >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.resultStatus_payment}}</td>
      </ng-container>
      
      <ng-container matColumnDef="type_payment">
        <th mat-header-cell *matHeaderCellDef  >Tipo</th>
        <td mat-cell *matCellDef="let element">{{element.type_payment}}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated_payment">
        <th mat-header-cell *matHeaderCellDef  >Creada</th>
        <td mat-cell *matCellDef="let element">{{element.dateCreated_payment}}</td>
      </ng-container>

      <ng-container matColumnDef="amount_payment">
        <th mat-header-cell *matHeaderCellDef  >Total</th>
        <td mat-cell *matCellDef="let element">{{element.amount_payment}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
	
	</mat-tab>

	<mat-tab label="Cupones">  

	<div class="mat-elevation-z8e" *ngIf="dataCuponSource">
		<table mat-table [dataSource]="dataCuponSource" >
		
			
			<ng-container matColumnDef="cupon.id">
				<th mat-header-cell *matHeaderCellDef  >Id</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.id}}</td>
			</ng-container>

			<ng-container matColumnDef="cupon.name_cupon">
				<th mat-header-cell *matHeaderCellDef  >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.name_cupon}}</td>
			</ng-container>
			
			<ng-container matColumnDef="cupon.value">
				<th mat-header-cell *matHeaderCellDef  >Valor</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.value}}</td>
      		</ng-container>
      
			<ng-container matColumnDef="cupon.type">
				<th mat-header-cell *matHeaderCellDef  >Tipo</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.type}}</td>
			</ng-container>

			<ng-container matColumnDef="cupon.status">
				<th mat-header-cell *matHeaderCellDef  >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.status}}</td>
			</ng-container>

			<ng-container matColumnDef="cupon.createdAt">
				<th mat-header-cell *matHeaderCellDef  >Creado</th>
				<td mat-cell *matCellDef="let element">{{element.cupon.createdAt}}</td>
			</ng-container>

			


			<tr mat-header-row *matHeaderRowDef="displayedCuponColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedCuponColumns;">
			
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableCuponService.pager.totalEntities"
			[pageIndex]="tableCuponService.pager.pageIndex"
			[pageSize]="tableCuponService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="listCupon($event)">
		</mat-paginator>
	</div>
	
	</mat-tab>
	
  </mat-tab-group>

`
})
export class CustomerInvoiceDetailComponent implements OnInit {

dataSource: MatTableDataSource<any>;
dataCuponSource: MatTableDataSource<any>;
displayedColumns: string[] = [ 'id', 'description_payment', 'resultStatus_payment', 'type_payment', 'dateCreated_payment','amount_payment'];
displayedCuponColumns: string[] = [ 'cupon.id', 'cupon.name_cupon', 'cupon.value', 'cupon.type', 'cupon.status','cupon.createdAt'];
invoice;

  items: any[] = [];
  cupons: any[] = [];

  constructor(
	private router: Router,
    private activatedRoute: ActivatedRoute,
	public tableService: TableService<any>,
	public tableCuponService: TableService<any>,
    private reportService: ReportService,
	private notificationService: NotificationService,
	private cuponService: CuponService
  ) {this.tableService = new TableService;}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params =>{
      this.reportService.getInvoiceById(params['IDinvoice']).subscribe(invoice => {  // BUSCAMOS LA FACTURA
		this.invoice = invoice;
        this.reportService.getStatusInvoice(this.invoice.status_invoice).subscribe(status =>{ // BUSCAMOS SU ESTATUS
          this.invoice.status_invoice = status.description;
		  this.list();
		  this.cuponService.getByInvoice(this.cuponService.buildRequestParams(),invoice.id).subscribe(cupons=>{
				this.cupons = cupons['result'];
				for(const c of this.cupons){
					c.cupon.status = c.cupon.status === 1 ? 'Activo' : 'Inactivo';
					c.cupon.type = c.cupon.type === 1 ? '%' : '$';
				}
				this.dataCuponSource = new MatTableDataSource<any>(this.cupons);
				this.tableCuponService.pager = cupons['pager'];
				this.tableCuponService.selection.clear();
		
		  })
        });
      });
    });
  }
  listCupon(event?: PageEvent) {
	  console.log("Hi!!!")
  }

  list(event?: PageEvent) {
	let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
	{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

      this.reportService.getPaymentsByInvoice(httpParams, this.invoice.id).subscribe(params => {
		this.items = params['result'];
       
		for(let p of this.items){ // BUSCAMOS LA DESCRIPCION DEL CODIGO DE LOS PAYMENTS
			if(p.resultStatus_payment != null){
				this.reportService.getErrocCodeBacDescription(p.resultStatus_payment).subscribe(error =>{
					p.resultStatus_payment = error.description_errorcodebac
				  })
			}else{
				p.resultStatus_payment = "N/A"
			}
         
        }
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = params['pager'];
        this.tableService.selection.clear();
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });
  }

  read(item) {
	localStorage.setItem('payment', JSON.stringify(item));
    this.router.navigate(['./payment/' + item.id], {relativeTo: this.activatedRoute});
  }

}
