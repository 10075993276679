import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn : 'root'
})
export class NotificationService {
	constructor(private service : ToastrService) { }


	success(msg?:string, title?:string){
		this.service.success(msg,title);
	}
	
	errorToast(msg?:string, title?:string){
		this.service.error(msg,title);
	}

	info(title?:string,msg?:string){
		this.service.warning(msg,title);
	}

	show(msg?:string, title?:string){
		this.service.show(msg,title);
	}

	sucessInsert(name?: string) {
		if (name) {
			this.service.success(`¡El registro '${name}' ha sido creado`, 'Creado Correctamente');
		} else {
			this.service.success( `El(los) registro(s) han sido creado(s)`,'Creado(s) Correctamente');
		}
	}

	sucessUpdate(name?: string) {
		if (name) {
			this.service.success(`¡El registro '${name}' ha sido actualizado!`,'Actualizado Correctamente');
		} else {
			this.service.success(`El(los) registro(s) han sido creado(s)`,'Creado(s) Correctamente');
		}
	}

	sucessDelete(name?: string) {
		if (name) {
			this.service.success(`¡El registro '${name}' ha sido borrado!`,'Borrado Correctamente', );
		} else {
			this.service.success(`¡El(los) registro(s) han sido borrado(s)!`,'Borrado Correctamente');
		}
	}

	sucessLogin() {
		this.service.success('Ha ingresado correctamente','Login Exitoso');
	}

	errorDuplicated(name: string) {
		this.service.error(`El registro '${name}' ya existe en el sistema`,'Registro duplicado');
	}

	error(msg = 'Hubo un problema. Intentelo mas tarde') {
		this.service.error( msg,'Error');
	}

	alert(msg?: string) {
		this.service.warning( msg , 'Notificación');
	}

	genericsuccess(title: string, body: string) {
		this.service.success(body,title);
	}

	genericerror(title: string, body: string) {
		this.service.error(body,title);
	}
}
