import {Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NotificationService} from '../../shared/notification/notification.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {RoleService} from '../../role/role.service';
import {GroupService} from '../group.service';
import {BaseService} from '../../shared/services/base.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  template: `
	<h3 class="title">Editar Grupo</h3>

	<form *ngIf="updateGroupForm" [formGroup]="updateGroupForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos del grupo</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateGroupForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="updateGroupForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="rol" >Elija el rol al que pertenecera el grupo</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.description}}
            </mat-option>
          </mat-select>
				</div>
      </div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateGroupForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class GroupUpdateComponent implements OnInit {

  public loading = false;
  rolesSelected: any[] = [];
  seleccionados: any[] = [];
  roles: any[] = [];
  // validaciones para campos de formulario
  updateGroupForm = this.fb.group({
    name: ['', [Validators.required]],
    description: [''],
    id: ['', [Validators.required]]
  });

  /** control for the selected role for multi-selection */
  public roleMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public roleMultiFilterCtrl: FormControl = new FormControl();

    /** list of roles filtered by search keyword */
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect',{static: false}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(public fb: FormBuilder,
              private roleService: RoleService,
              private activatedRoute: ActivatedRoute,
              private groupService: GroupService,
              private location: Location,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.parent.params.subscribe(param => {
      this.groupService.getById(param['groupId']).subscribe(grupo => {
        this.updateGroupForm.controls['name'].setValue(grupo['name']);
        this.updateGroupForm.controls['description'].setValue(grupo['description']);
        this.updateGroupForm.controls['id'].setValue(grupo['id']);
          this.seleccionados = grupo['roles'];
          this.roleService.getAll().subscribe(roles => {
            this.roles = roles['result'];
            // set initial selection
            this.roleMultiCtrl.setValue(this.seleccionados);
            // load the initial role list
            this.filteredRolesMulti.next(this.roles.slice());
            // listen for search field value changes
            this.roleMultiFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterBanksMulti();
              });
        }, err => {
          this.notificationService.error(err);
          console.log(err);
        });
      } , (err) => {
        this.notificationService.error(err);
        this.loading = false;
      });
    });
  }

  update() {
    const obj = <any> BaseService.builderObject(this.updateGroupForm.value);
    obj.rolesGroup = this.roleMultiCtrl.value;
    this.groupService.update(obj).subscribe(grupo => {
      this.notificationService.sucessUpdate(grupo['name']);
      this.location.back();
    }, (err) => {
      this.notificationService.error(err);
      this.loading = false;
    });
  }

  selectRoles(roles: any[]) {
    this.rolesSelected = roles;
  }

  searchRoles(value: string) {
    let httpParams = this.roleService.buildRequestParams(null, null, null);

    httpParams = httpParams.set('description',value);

    this.roleService.getAllSearch(httpParams).subscribe( roles => {
      this.roles = roles['result'];
      if(this.roles.length === 0){
        this.notificationService.error('Su busqueda no ha arrojado resultados');
      }
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterBanksMulti() {

    if (!this.roles) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.roles.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );

  }

}
