import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BaseService } from '../shared/services/base.service';
import { PartnerService } from './partner.service';
import { NotificationService} from '../shared/notification/notification.service';
import { CountryService } from '../country/country.service';
import { CityService } from '../city/city.service';
import { CustomValidators } from '../shared/form/custom.validators';



@Component({
    template: `
		<h3 class="title">Editar Compañía</h3>

		<form *ngIf="updatePartnerForm" [formGroup]="updatePartnerForm" (ngSubmit)="update()">
			<fieldset class="fieldset">
                <legend class="legend" >Datos de la compañía</legend>
                
                <div class="form-group">
                    <input type="text" formControlName="id" class="form-control" hidden="true" >
                </div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name_partner">Nombre</label>
						<input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
						<validator [control]="updatePartnerForm.controls['name_partner']"></validator>
                    </div>
                    <div class="field-row-item">
						<label class="label" for="identifier_bank">Identificador del banco</label>
						<input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
						<validator [control]="updatePartnerForm.controls['identifier_bank']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="adress_partner" >Direccion</label>
						<textarea formControlName="adress_partner"></textarea>
						<validator [control]="updatePartnerForm.controls['adress_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="country" >Pais</label>
                        <mat-select [formControl]="country" [compareWith]="compareFn" (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
						</mat-select>
						<validator [control]="country"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="city">Ciudad</label>
                        <mat-select [formControl]="city" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
						</mat-select>
						<validator [control]="city"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone_partner" >Telefono</label>
						<input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
						<validator [control]="updatePartnerForm.controls['phone_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="feeRate_partner">Porcentaje de cobro</label>
						<input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
						<validator [control]="updatePartnerForm.controls['feeRate_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email_partner">Email</label>
						<input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
						<validator [control]="updatePartnerForm.controls['email_partner']"></validator>
                    </div>

                    <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_partner">
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="status_partner"></validator>
                    </div>


                    <div class="field-row-item">
						<label class="label" for="notify" >Notificar cobros</label>
						<mat-select [formControl]="notification" disableRipple>
							<mat-option *ngFor="let option of notify" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="notification"></validator>
					</div>
                   
                    
                </div>
     

                <div class="form-group">
				    <input style="width:90%;" type="text" formControlName="returnRoute" hidden="true" placeholder="Ingrese ruta" >
                </div>
                
                
				

			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!updatePartnerForm.valid">
					<span>Actualizar</span>
				</button>
			</div>
		</form>
	`
})
export class PartnerUpdateComponent {
	partner: any;
    countries = [];
    cities = [];
    estatus = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
    notify = [{value:true,description:"SI"},{value:false,description:'NO'}];

   // validaciones para campos de formulario
	updatePartnerForm = this.fb.group({
        id: ['', [Validators.required]],
        name_partner: ['', [Validators.required]],
        identifier_bank: ['', [Validators.required]],
		adress_partner: ['', [Validators.required]],
		email_partner: ['', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]],
		phone_partner: ['', [Validators.required]],
        feeRate_partner: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
        returnRoute:['', [Validators.required]]
    });
    
    public status_partner: FormControl = new FormControl('', Validators.required);
    public notification: FormControl = new FormControl('', Validators.required);
    public city: FormControl = new FormControl('', Validators.required);
    public country: FormControl = new FormControl('', Validators.required);

	constructor(
        public fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private partnerService: PartnerService,
        private countryService: CountryService,
        private cityService: CityService,
		private notificationService: NotificationService
	){}

	ngOnInit(){
        this.cargarCombos(); // cargamos los combos de la vista
        /// buscamos la informacion del partner
		this.activatedRoute.parent.params.subscribe(param => {
			this.partnerService.getById(param['partnerId']).subscribe(partner => {
                this.partner = partner;
                // setamos la informacion del partner en el formulario
                this.updatePartnerForm.controls["name_partner"].setValue(this.partner.name_partner);
                this.updatePartnerForm.controls["identifier_bank"].setValue(this.partner.identifier_bank);
                this.updatePartnerForm.controls["adress_partner"].setValue(this.partner.adress_partner);
                this.updatePartnerForm.controls["phone_partner"].setValue(this.partner.phone_partner);
                this.updatePartnerForm.controls["email_partner"].setValue(this.partner.email_partner);
                this.updatePartnerForm.controls["feeRate_partner"].setValue(this.partner.feeRate_partner);
                this.updatePartnerForm.controls["returnRoute"].setValue(this.partner.returnRoute);
                this.updatePartnerForm.controls["id"].setValue(this.partner.id);
                // seteamos el estatus
                this.status_partner.setValue(this.estatus[this.estatus.findIndex(e => this.partner.status_partner === e.value)].value);
                this.notification.setValue(this.notify[this.notify.findIndex(e => this.partner.notify === e.value)].value);
                /// buscamos las ciudades del pais del usuario
                    // buscamos todas las ciudades que posee el pais del partner
                    this.cityService.getByCountry(this.partner.city.country.id).subscribe(params => {
                        // llenamos las ciudades
                        this.cities = params['result'];
                        this.country.setValue(this.countries[this.countries.findIndex(e => this.partner.city.country.id === e.id)].id);
                        this.city.setValue(this.cities[this.countries.findIndex(e => this.partner.city.id === e.id)].id);
                    }, (err) => {
                            this.notificationService.error(err);
                           
                    });
			}, (err) => {
                        this.notificationService.error(err);
                        
                    });
		});
	}

    cargarCombos(){
        /// cargamos los paises registrados
        this.countryService.getAll().subscribe(params => {
            this.countries = params['result'];
        }, (err) => {
            this.notificationService.error(err);
        });
    }

    onItemChange(country:any){
        // cargamos las ciudades del pais que seleccione el usuario
       this.cities = [];
       if(country!=undefined){
           this.cityService.getByCountry(country).subscribe(params => {
                   this.cities = params['result'];
                   }, (err) => {
                       this.notificationService.error(err);
           });
       }
   }

	update() {
        if(!this.updatePartnerForm.valid){
            this.notificationService.error("Campos incompletos o invalidos, por favor verifique");
            return;
        }
        let obj = <any> BaseService.builderObject(this.updatePartnerForm.value);
        this.partner = obj;
        if(this.partner.feeRate_partner == null){
            this.partner.feeRate_partner = 0;
          }
        this.partner.city = {id: this.city.value};
        this.partner.status_partner = this.status_partner.value;
        this.partner.notify = this.notification.value;
        this.partnerService.update(this.partner).subscribe(partner => {
            this.notificationService.sucessUpdate(partner.name_partner);
            this.location.back();
        }, (err) => {
            console.log(err);
				this.notificationService.error(err?.error?.message);
        }); 
    }
    
    compareFn(c1: any, c2: any): boolean { 
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
      }
      
}
