import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import { Observable } from 'rxjs';
import {Customer_has_plans} from "./customer_has_plans";
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class Customer_has_plansService extends BaseService {

    BASE_URL: string = this.HOST + '/hasplan';
    
    httpOptions : any;

    constructor(
      private http: HttpClient,
    ){
        super();
        // Http Headers
        this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
    }

   create(customer_has_plans: Customer_has_plans): Observable<Customer_has_plans> {

     return this.http.post(this.BASE_URL, {"hasplan": customer_has_plans}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
        );
    }

    getByCustomer(id: number,params: HttpParams = new HttpParams() ): Observable<any> {
      this.httpOptions.params = params;
      return this.http.get(this.BASE_URL + '/customer/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
        );
    }

    getByCustomerAndPlan(idcus : number, idplan : number): Observable<any>{
      return this.http.get(this.BASE_URL + '/customer/' + idcus + '/plan/' + idplan, this.httpOptions)
      .pipe( catchError(this.handleError)
      );
    }

    getById(id: number): Observable<Customer_has_plans> {
      
        return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
          );
    }

    delete(id: number): Observable<any> {
      
      return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
        );
    }

    update(customer_has_plans: Customer_has_plans): Observable<Customer_has_plans> {
      return this.http.put(this.BASE_URL + '/' + customer_has_plans.id, {'hasplan': customer_has_plans}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
        );
    }
}
