
	<h3 class="title">Detalle del grupo</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ name }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ description }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Roles</span>
          <span *ngFor="let r of roles" >
            - {{r.description}}
          </span>
          <span *ngIf="roles.length == 0">
            - No posee roles asociados
          </span>
				</div>
      </div>
      
    </div>
   </div>
