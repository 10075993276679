
	<h3 class="title">Editar Servicio</h3>

	<form *ngIf="updateServiceForm" [formGroup]="updateServiceForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" > 
            </div>  

			<div class="field-row">
				
                 <div class="field-row-item">
                    <label class="label" for="name_service">Nombre</label>
                    <input formControlName="name_service" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
                    <validator [control]="updateServiceForm.controls['name_service']"></validator>
                </div>
                
                <div class="field-row-item">
                    <label class="label" for="country" >Compañía</label> 
                    <mat-select [formControl]="partnerS"  disableRipple>
                            <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
                    </mat-select>
                    <validator [control]="partnerS"></validator> 
                </div>

            </div>
            
            
            <div class="field-row">

				<div class="field-row-item">
						<label class="label" for="type" >Tipo</label>
						<mat-select [formControl]="type" (selectionChange)="onTypeChange($event.value)"  disableRipple>
							<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
						</mat-select>
						<validator [control]="type"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_service" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="status_service"></validator>
                </div>

            </div>
            
            <div *ngIf="show">

                <div class="field-row">

                    <div class="field-row-item">
                        <label class="label" for="title">Titulo</label>
                        <input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Titulo" >
                        <validator [control]="updateServiceForm.controls['title']"></validator>
                    </div>

                    <div class="field-row-item">
                        <label class="label" for="url_ecommerce">URL Ecommerce</label>
                        <input formControlName="url_ecommerce" type="text" autocomplete="off" placeholder="Ingrese URL Ecommerce" >
                        <validator [control]="updateServiceForm.controls['url_ecommerce']"></validator>
                    </div>

                </div>


                <div class="field-row">

            
                    <div class="field-row-item">
                        <label class="label" for="url_logo">Banner del servicio</label>
                        <input id="file-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChange($event)" >
                    </div>

                    <div class="field-row-item">
							<label class="label" for="url_logo">Imagen del último artículo enviado </label>
							<input id="file-upload-2" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChangeLastSended($event)" required>
			
						</div>

                   <!--  <div class="field-row-item">
						<label style="width:100%;" class="label" for="url_ecommerce">Color del tema</label>
						<color-circle [colors]="colors" [color]="colorSelected" (onChange)="handleChange($event)" ></color-circle>
					</div> -->

            

                </div>


                <div class="field-row">

						<div class="field-row-item">
							<label class="label" for="email_service">Email del comercio</label>
							<input formControlName="email_service" type="text" autocomplete="off" placeholder="Ingrese email" >
							<validator [control]="updateServiceForm.controls['email_service']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="password_email">Password email</label>
							<button *ngIf="change" (click) ="onClick();changeButton.disabled = true"  #changeButton class="btn-text">
                                <span>Cambiar</span>
                            </button>

                            <input *ngIf="change==false" formControlName="new_password" type="text" autocomplete="off" placeholder="Ingrese nuevo password" >

                            
						</div>

                </div>	
                
                <div class="field-row">
					<div class="field-row-item">
                        <label class="label" for="terms" >Descripción</label>
                        
                        <textarea formControlName="description"></textarea>

						<validator [control]="updateServiceForm.controls['description']"></validator>
					</div>
                </div>
                
                <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="desc_of_last_sended" >Descripción de último artículo enviado</label>

						<textarea formControlName="desc_of_last_sended"></textarea>
						
					</div>
				</div>


                <div class="field-row">
					<div class="field-row-item">
                        <label class="label" for="terms" >Términos</label>
                        <textarea formControlName="terms"></textarea>
                       
						<validator [control]="updateServiceForm.controls['terms']"></validator>
					</div>
				</div>
                
                
                
		    </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateServiceForm.valid || !type.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
