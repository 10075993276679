
	<h3 class="title">Detalle del usuario</h3>

  <div class="tool-bar-wrap both-side">
  
    <div class="right row">
      <button class="btn-icon" title="Actualizar clave" type="button" (click)="updatePassword()">
					<i class="material-icons">vpn_key</i>
			</button>
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ name }}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Apellido</span>
					<span>{{ lastname }}</span>
				</div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <span class="label">Email</span>
          <span>{{ email }}</span>
        </div>
      </div>


      <div class="field-row">
				<div class="field-row-item">
          <span class="label">Roles</span>
          <br>
          <span *ngFor="let r of roles" >
            - {{r.description}}
            <br>
          </span>
          <span *ngIf="roles.length == 0">
            - No posee roles asociados
          </span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
          <span class="label">Grupos</span>
          <br>
          <span *ngFor="let r of grupos" >
            - {{r.description}}
            <br>
          </span>
          <span *ngIf="grupos.length == 0">
            - No posee grupos asociados
          </span>
				</div>
      </div>
      
    </div>
   </div>
