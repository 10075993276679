import { Component } from '@angular/core';

@Component({
	template: `
	<ng-progress></ng-progress>
	<header>
		<img id="logo" src="./assets/images/logo-blanco.png" routerLink="/login"/>
	</header>
	<router-outlet></router-outlet>`,
	styleUrls: ['../account.css']
})
export class RecoverPassComponent {
}
