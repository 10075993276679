import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import {MatChipInputEvent} from '@angular/material/chips';

import { ExportToCsv } from 'export-to-csv';



@Component({
  template: `
	<h3 class="title">Auditoria INFO</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

            <div class="field-row-item" *ngIf="tableService.filter.del">
              <span class="label">Del</span>
              <span *ngIf="tableService.filter.del == 1"> Ultima hora </span>
              <span *ngIf="tableService.filter.del == 2"> Ultimas 6 horas </span>
              <span *ngIf="tableService.filter.del == 3"> Ultimas 24 horas </span>
              <span *ngIf="tableService.filter.del == 4"> Ultimos 7 dias </span>
              <span *ngIf="tableService.filter.del == 5"> Sin limite </span>
              <span *ngIf="tableService.filter.del == 6"> Personalizado </span>
            </div>

						<div class="field-row-item" *ngIf="tableService.filter.parameter">
							<span class="label">Texto</span>
							<span>{{tableService.filter.parameter}}</span>
            </div>

            <div class="field-row-item" *ngIf="tableService.filter.label">
							<span class="label">Etiquetas</span>
							<span>{{tableService.filter.label}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

          <div class="field-row">

                <mat-form-field class="field-row-item">
                <label class="label" for="company" >Texto</label>
                <mat-chip-list [(value)]="filter.parameter" #chipList aria-label="parameter selection">
                  <mat-chip  *ngFor="let parameter of parameters" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(parameter)">
                    {{parameter}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>

                  <input  style="width:90%;background:ghostwhite"
                        [matChipInputFor]="chipList"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
              </mat-form-field>
           

            

            <div class="field-row-item">
              <label class="label" for="company" >Periodo</label>
              <mat-select  [(value)]="filter.del"  disableRipple>
                <mat-option *ngFor="let opt of types" [value]="opt.value">{{opt.desc}}</mat-option>
              </mat-select>
            </div>

          </div>

          <div class="field-row">

              <mat-form-field class="field-row-item">
              <label class="label" for="label" >Etiquetas</label>
              <mat-chip-list [(value)]="filter.label" #chipList2 aria-label="label selection">
                <mat-chip  *ngFor="let label of labels" [selectable]="selectable"
                        [removable]="removable" (removed)="removeLabel(label)">
                  {{label}}
                  <mat-icon matChipRemove *ngIf="removable">add</mat-icon>
                </mat-chip>

                <input readonly  style="width:90%;background-color:white;border:none;"
                      [matChipInputFor]="chipList2"
                      [matChipInputAddOnBlur]="addOnBlur">
              </mat-chip-list>

              </mat-form-field>

          </div>

          

          <div class="field-row" *ngFor="let label of searchLabels; index as i">

            <mat-chip [selectable]="selectable"
                    [removable]="removable" (removed)="removeSearchLabel(label)">
              {{label}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>

            <input  (blur)="test_event($event,i)"  style="width:90%;background:ghostwhite">
            
      
          </div>

          
          <div class="field-row" *ngIf="filter.del == 6">

						<div class="field-row-item">  
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>
            </div>
            
            <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
					</div>

			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>


  <div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>
  
  <div class="mat-elevation-z8e" *ngIf="dataSource">
    
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="message">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Mensaje</th>
				<td mat-cell *matCellDef="let element">{{element.message}}</td>
      </ng-container>
      
      <ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
				<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
			</ng-container>
			
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
`
})
export class AuditioInfoComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['message', 'createdAt'];
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);
  items: any[] = [];
  types: any[]= [];

  static_data: any[] = [];
  toExport : any[] = [];

  /*
  Login
  Request
  Response
  Invoice
  */

  labels: any[] = ['accountNumber','authorizationNumber','username','Invoice'];
  searchLabels: any[] = [];

  searchParameters: any[] = [];

  /*  Chips */
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  parameters: any[] = [
];
  /* Termina Chips */

  constructor(
    public tableService: TableService<any>,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {
    this.tableService = new TableService;
    this.types = [{desc: 'Ultima hora', value: 1}, {desc: 'Ultimas 6 horas', value: 2}, {desc: 'Ultimas 24 horas', value: 3}, {desc: 'Ultimos 7 dias', value: 4}, {desc: 'Personalizado', value: 6}];
  }

  ngOnInit(): void {
    
  }

  list(event?: PageEvent) {

    if(this.filter.del == undefined){
      this.notificationService.error("Debe seleccionar un periodo para la busqueda");
      return;
    }

    if(this.filter.del == 6 && this.filter.since_date == undefined && this.filter.until_date == undefined){
      this.notificationService.error("Debe seleccionar un rango de fecha");
      return;
    }

    if(this.tableService.pager.pageSize != 20){
      this.tableService.pager.pageSize = 20;
    }

    if(this.filter.since_date != null && this.filter.until_date == null){
      this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
      return;
    }
    if(this.filter.since_date == null && this.filter.until_date != null){
      this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
      return;
    }
    this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
    this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
    var since = new  Date (this.filter.since_date);
    var until = new  Date (this.filter.until_date);
    if(until.getTime() < since.getTime()){
      this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
      return;
    }

    if(this.searchLabels.length > 0){
      let array = [];
      for(var i = 0 ; i < this.searchLabels.length ; i ++){
        if(this.searchLabels[i] == "Invoice"){
          array.push( '%'+this.searchLabels[i]+':'+this.searchParameters[i] + '%');
        }else{
          array.push('"' +this.searchLabels[i]+'":"'+this.searchParameters[i] + '%"');
        }
        
      }
      this.filter.label = array.toString();
    }
    this.tableService.filter = new ReportFilter(this.filter);
    
    
    let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

    httpParams = this.filter.getHttpParams(httpParams);

    this.reportService.getAuditionInfoReport(httpParams).subscribe(response =>{
        this.items = response['result']; // items que mostrara la tabla
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
        if (this.items.length === 0) {
          this.notificationService.alert('No se encontraron resultados para la busqueda');
        }
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    } ) 
  
  }


  export() {
    if(this.items.length > 0){
        
        this.tableService.filter = new ReportFilter(this.filter);
        let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
        {pageIndex: -1 , pageSize: -1})
    
        httpParams = this.filter.getHttpParams(httpParams);
        this.reportService.getAuditionInfoReport(httpParams).subscribe(params => {
          
          let data = [];
           for(var item of params['result']){
            data.push({'MENSAJE':item.message,'FECHA':item.createdAt})
           }
        
          const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
          const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            useTextFile: false,
            filename: "Report-Audition-INFO-"+date,
            useBom:false,
            useKeysAsHeaders: true,
            showTitle: true,
            title: 'Auditoria INFO - Fecha:' + date,
          };
          const csvExporter = new ExportToCsv(options);
  
          this.toExport = data;

          csvExporter.generateCsv(this.toExport); 
        
        }, err => {
          this.notificationService.error(err);
          console.log(err);
        }); 
  
      }else{
        this.notificationService.error("No existen registros para exportar");
      }
      
    }

	reset() {
		this.filter = new ReportFilter();
		this.dataSource = new MatTableDataSource<any>([]);
    this.tableService.filter = new ReportFilter(this.filter);
    this.parameters = [];
    for(var label of this.searchLabels){
      this.labels.push(label)
    }
    this.searchLabels = [];
    this.searchParameters = [];
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new ReportFilter(this.filter);
    this.filter.parameter = this.parameters.toString();
    this.list(); 
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new ReportFilter(this.tableService.filter);
	}

	compareFn(c1: any, c2: any): boolean { 
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /* Chips */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our parameter
    if ((value || '').trim()) {
      this.parameters.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(parameter: any): void {
    const index = this.parameters.indexOf(parameter);

    if (index >= 0) {
      this.parameters.splice(index, 1);
    }
    
  }

  removeLabel(parameter: any): void {
    const index = this.labels.indexOf(parameter);

    if (index >= 0) {
      this.labels.splice(index, 1);
    }
    // Add our parameter
    if ((parameter || '').trim()) {
      this.searchLabels.push(parameter.trim());
    }
  }

  removeSearchLabel(parameter: any): void {
    const index = this.searchLabels.indexOf(parameter);

    if (index >= 0) {
      this.searchLabels.splice(index, 1);
      this.searchParameters.splice(index,1);
    }
    // Add our parameter
    if ((parameter || '').trim()) {
      this.labels.push(parameter.trim());
    }
  }
  /* Termina chips */
  test_event(parameter:any,index){
    if((parameter.target.value || '').trim()){
      if(this.searchParameters[index] == undefined){
        this.searchParameters.push(parameter.target.value.trim())
      }else{
        this.searchParameters[index] = parameter.target.value.trim();
      }
    }
  }
}
