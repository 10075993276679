import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  template: `
	<h3 class="title">Respuesta obtenida para el pago</h3>


	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Autorization number</span>
					<span>{{ authorizationNumber || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Reference number</span>
					<span>{{ referenceNumber || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">System trace number</span>
					<span>{{ systemTraceNumber || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Transaction ID</span>
					<span>{{ transactionId || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Host date</span>
					<span>{{ hostDate || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Host time</span>
					<span>{{ hostTime || '-'}}</span>
				</div>
			</div>

    </div>

    <div class="options">
      <button class="btn-text" type="button" (click)="atras()">
        <span>Volver</span>
      </button>
    </div>

  </div>
  
`
})
export class PaymentDetailComponent implements OnInit {

  payment;
  
  authorizationNumber;
  referenceNumber;
  systemTraceNumber;
  hostTime;
  hostDate;
  transactionId;

  constructor(
    private location: Location,
  ) { }
  // TODO - Utilizar el payment directamente y eliminar las otras variables
  ngOnInit() {
    this.payment = JSON.parse(localStorage.getItem('payment'));
    this.authorizationNumber = this.payment.authorizationNumber;
    this.referenceNumber = this.payment.referenceNumber;
    this.systemTraceNumber = this.payment.systemTraceNumber;
    this.hostDate = this.payment.hostDate;
    this.hostTime = this.payment.hostTime;
    this.transactionId = this.payment.transactionId;
  }

  atras() {
    this.location.back();
  }

}
