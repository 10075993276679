import { HttpParams } from '@angular/common/http';

export class RebillingFilter {

	name_rebillingsetting: string;
	id: number;
	status_rebillingsetting: number;
    
	constructor(filter?: RebillingFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_rebillingsetting = filter.name_rebillingsetting;
            this.status_rebillingsetting = filter.status_rebillingsetting;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_rebillingsetting) {
			httpParams = httpParams.set('name_rebillingsetting', this.name_rebillingsetting);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_rebillingsetting) {
			httpParams = httpParams.set('status_rebillingsetting', this.status_rebillingsetting.toString());
        }
		return httpParams;
	}
}