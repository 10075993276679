import { Component, OnInit } from '@angular/core';
import {FilterService} from '../../shared/filter-ns/filter-ns.service';

@Component({
  selector: 'app-branch',
  template: '<router-outlet></router-outlet>',
  providers: [
    FilterService
  ]
})
export class JobComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
