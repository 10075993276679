
	<h3 class="title">Crear Rol</h3>

	<form *ngIf="createRoleForm" [formGroup]="createRoleForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del rol</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createRoleForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="createRoleForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
        <label class="label" for="rol" >Elija los permisos que tendra el rol</label>
        <mat-select  [formControl]="permiMultiCtrl" [multiple]="true" #multiSelect>
          <ngx-mat-select-search [formControl]="permiMultiFilterCtrl"></ngx-mat-select-search>
          <mat-option *ngFor="let bank of filteredPermiMulti | async" [value]="bank">
            {{bank.description}}
          </mat-option>
        </mat-select>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createRoleForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
