import { HttpParams } from '@angular/common/http';

export class CuponFilter {

	name_cupon: string;
	id: number;
    
	constructor(filter?: CuponFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_cupon = filter.name_cupon;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_cupon) {
			httpParams = httpParams.set('name_cupon', this.name_cupon);
        }

        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		
		return httpParams;
	}
}