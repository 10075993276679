import {Routes} from '@angular/router';
import {ReportComponent} from './report/report.component';
import {ReportInvoicesComponent} from './report-invoices/report-invoices.component';
import {InvoicesByPaymentComponent} from './invoices-by-payment/invoices-by-payment.component';
import {InvoiceDetailComponent} from './invoice-detail/invoice-detail.component';
import {PaymentDetailComponent} from './payment-detail/payment-detail.component';
import {InvoicesChargedComponent} from './invoices-charged/invoices-charged.component';
import { CustomerInvoicesComponent } from './customer-invoices/customer-invoices.component';
import { UpdateStatusComponent } from './update-status/update-status.component';
import { BillingsettingtrackComponent } from './billingsettingtrack/billingsettingtrack.component';
import { RebillingsettingtrackComponent } from './rebillingsettingtrack/rebillingsettingtrack.component';
import { NotificationFailedComponent } from './notification-failed/notification-failed.component';
import { CustomersPlanComponent } from './customers-plan/customers-plan.component';
import { CustomerPlanDetailComponent } from './customer-plan-detail/customer-plan-detail.component';
import { CustomerPartnerComponent } from './customer-partner/customer-partner.component';
import { CustomerPlansComponent } from './customer-plans/customer-plans.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import { AuditioApiComponent } from './auditio-api/auditio-api.component';
import { AuditioInfoComponent } from './auditio-info/auditio-info.component';
import { AuditionApiDetailComponent } from './audition-api-detail/audition-api-detail.component';
import { UpdateStatusFixComponent } from './update-status-fix/update-status-fix.component';
import { InvoiceToChargeComponent } from './invoice-to-charge/invoice-to-charge.component';

export const reportRoutes: Routes = [
  {
    path: 'reports/invoices',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ReportInvoicesComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: ':invoiceId',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: InvoiceDetailComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'payment/:paymentId',
            component: PaymentDetailComponent,
            data: {
              breadcrumb: 'Pago',
              icon: 'update'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/charged',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InvoicesChargedComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/customer/invoices',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch :'full',
        component: CustomerInvoicesComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: ':IDinvoice',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CustomerInvoiceDetailComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'payment/:paymentId',
            component: PaymentDetailComponent,
            data: {
              breadcrumb: 'Pago',
              icon: 'update'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/update/invoices',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UpdateStatusComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/fix/invoices',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UpdateStatusFixComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/billingsetting',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: BillingsettingtrackComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/rebillingsetting',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RebillingsettingtrackComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/notification-failed',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: NotificationFailedComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/customers',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CustomersPlanComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'detail/:idPlan',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CustomerPlanDetailComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/customers/partner',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CustomerPartnerComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'plans/:idCustomer',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CustomerPlansComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/audition/api',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AuditioApiComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: ':IDaudition',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: AuditionApiDetailComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/audition/info',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AuditioInfoComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
  {
    path: 'reports/invoices/by/payment',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InvoicesByPaymentComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'detail/:invoiceId',
        component: ReportComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: InvoiceDetailComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'payment/:paymentId',
            component: PaymentDetailComponent,
            data: {
              breadcrumb: 'Pago',
              icon: 'update'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reports/invoices/to/charge',
    component: ReportComponent,
    data: {
      breadcrumb: 'Reporte',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InvoiceToChargeComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  },
];

export const routingReportComponents = [ReportComponent,ReportInvoicesComponent,InvoiceDetailComponent,PaymentDetailComponent,
  InvoicesChargedComponent,CustomerInvoicesComponent,UpdateStatusComponent,BillingsettingtrackComponent,RebillingsettingtrackComponent,
  NotificationFailedComponent,CustomersPlanComponent, CustomerPlanDetailComponent,CustomerPartnerComponent, CustomerPlansComponent, CustomerInvoiceDetailComponent,
AuditioApiComponent,AuditioInfoComponent,AuditionApiDetailComponent,InvoicesByPaymentComponent, UpdateStatusFixComponent];
