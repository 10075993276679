import { Component, ViewEncapsulation } from '@angular/core';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Servicio } from './servicio';
import { ServicioService } from './servicio.service';
import { NotificationService} from '../shared/notification/notification.service';
import { BaseService } from '../shared/services/base.service';
import { PartnerService } from '../partner/partner.service';
import { ColorEvent } from 'ngx-color';

@Component({
	styleUrls: ['./servicio.component.scss'],
	encapsulation: ViewEncapsulation.None,
	template: `
	<h3 class="title">Crear Servicio</h3>

	<form *ngIf="createServiceForm" [formGroup]="createServiceForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name_service">Nombre</label>
					<input formControlName="name_service" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createServiceForm.controls['name_service']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="country" >Compañía</label> 
					<mat-select [formControl]="partner"  disableRipple>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
					<validator [control]="partner"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

				<div class="field-row-item">
						<label class="label" for="type" >Tipo</label>
						<mat-select [formControl]="createServiceForm.controls['type']" (selectionChange)="onTypeChange($event.value)"  disableRipple>
							<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
						</mat-select>
						<validator [control]="createServiceForm.controls['type']"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createServiceForm.controls['status_service']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createServiceForm.controls['status_service']"></validator>
				</div>

			</div>	


			<div *ngIf="show">
				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="title">Titulo</label>
							<input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Titulo" >
							<validator [control]="createServiceForm.controls['title']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="url_ecommerce">URL Ecommerce</label>
							<input formControlName="url_ecommerce" type="text" autocomplete="off" placeholder="Ingrese URL Ecommerce" >
							<validator [control]="createServiceForm.controls['url_ecommerce']"></validator>
						</div>

				</div>	

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="url_logo">Banner del servicio</label>
							<input id="file-upload" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChange($event)" required>
			
						</div>

						<div class="field-row-item">
							<label class="label" for="url_logo">Imagen del último artículo enviado </label>
							<input id="file-upload-2" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChangeLastSended($event)" required>
			
						</div>

					<!--	<div class="field-row-item">
							<label style="width:100%;" class="label" for="url_ecommerce">Color del tema</label>
							<color-circle [colors]="colors" color="#f44336" (onChange)="handleChange($event)" ></color-circle>
						</div> -->

				</div>
				
				

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="email_service">Email del comercio</label>
							<input formControlName="email_service" type="text" autocomplete="off" placeholder="Ingrese email" >
							<validator [control]="createServiceForm.controls['email_service']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="password_email">Password email</label>
							<input formControlName="password_email" type="text" autocomplete="off" placeholder="Ingrese password" >
							<validator [control]="createServiceForm.controls['password_email']"></validator>
						</div>

				</div>	


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>

						<textarea formControlName="description"></textarea>
						<!-- <editor  [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="desc_of_last_sended" >Descripción de último artículo enviado</label>

						<textarea formControlName="desc_of_last_sended"></textarea>
						
					</div>
				</div>


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="terms" >Términos</label>
						<textarea formControlName="terms"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print"  formControlName="terms" >
						  </editor> -->
					</div>
				</div>
				


			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createServiceForm.valid || !partner.valid  ">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})

export class ServicioCreateComponent {


	servicio: any;
	partners : any[];
	parents = false; 
	show = false;  
	estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
	types : any[] = [{value:1,description:"Tradicional"},{value:2,description:'Ecommerce'}];
	
	colors = [ "#689f38", "#d32f2f","#1976d2","#c2185b","#455a64","#7b1fa2"];
	public partner: FormControl = new FormControl('', Validators.required);

	imageName: any;
	image64: any;
	imageType: any;
	color = null;

	imageNameOfLastSended: any;
	image64OfLastSended: any;
	imageTypeOfLastSended: any;

	createServiceForm = this.fb.group({
		name_service: ['', [Validators.required]],
		status_service:['', [Validators.required]],
		type:['', [Validators.required]],
		title:[''],
		url_ecommerce:[''],
		terms:[''],
		description:[''],
		desc_of_last_sended:[''],
		color:[''],
		email_service:[''],
		password_email:['']
	});

	constructor(
		public fb: FormBuilder,
		private router: Router,
		private location: Location,
		private servicioService: ServicioService,
		private partnerService: PartnerService,
		private notificationService: NotificationService
	){
		
	}

	handleChange($event: ColorEvent) {
		
		if($event.color.hex == "#689f38"){
			this.color = "green";
		}else if($event.color.hex == "#d32f2f"){
			this.color = "red";
		}else if($event.color.hex == "#1976d2"){
			this.color = "blue";
		}else if($event.color.hex == "#c2185b"){
			this.color = "pink";
		}else if($event.color.hex == "#455a64"){
			this.color = "gray";
		}else{
			this.color = "purple";
		}

	  }

	ngOnInit() {
		if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
		this.parents = true;
		}
		this.cargarCombos(); // cargamos los combos de la vista
	}
	cargarCombos() {
		let httpParams = this.partnerService.buildRequestParams();

		httpParams = httpParams.set('parents',this.parents.toString());
		httpParams = httpParams.set('parent',(-1).toString());
		/// cargamos los partner activos
		this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
				this.partners = params['result'];
				this.partner.setValue(this.partners[this.partners.findIndex(e => JSON.parse(localStorage.getItem("data")).user === e.id)].id);
			}, (err) => {
				this.notificationService.error(err);
		});
	}

	create() {
        let obj = <Servicio> BaseService.builderObject(this.createServiceForm.value)
		this.servicio = obj;
		this.servicio.partner = {id:this.partner.value};
		// En caso de que sea ECOMMERCE
		if(this.servicio.type == 2){
			if(this.servicio.title == null){
				return this.notificationService.error("Debe ingresar un Titulo");
			}

			if(this.servicio.description == null){
				return this.notificationService.error("Debe ingresar una Descripción");
			}

			if(this.servicio.desc_of_last_sended == null){
				return this.notificationService.error("Debe ingresar una Descripción para el artículo");
			}

			if(this.imageName == null || this.imageName == undefined){
				return this.notificationService.error("Debe cargar un logo para el servicio")
			}

			if(this.servicio.url_ecommerce == null){
				return this.notificationService.error("Debe ingresar una URL para Ecommerce")
			}
			if(this.servicio.terms == null){
				return this.notificationService.error("Debe ingresar Terminos")
			}

			if(this.servicio.email_service == null){
				return this.notificationService.error("Debe ingresar Email")
			}

			if(this.servicio.password_email == null){
				return this.notificationService.error("Debe ingresar Password")
			}

			/*if(this.color == null){
				return this.notificationService.error("Debe seleccionar un color para el tema del servicio")
			}*/

			this.servicio.imageName = this.imageName;
			this.servicio.imageType = this.imageType;
			this.servicio.image64 = this.image64;
			this.servicio.color = this.color;

			this.servicio.imageNameOfLastSended = this.imageNameOfLastSended;
			this.servicio.imageTypeOfLastSended = this.imageTypeOfLastSended
			this.servicio.image64OfLastSended = this.image64OfLastSended;
			
		}

		this.servicioService.getByName(this.servicio.name_service).subscribe(servicio => {
			    let servicios = servicio["result"];
			    let encontrado = false;
			    // comprobamos si uno de los servicios con el mismo nombre registrado se encuentra adjudicado al partner seleccionado
			    for(var i = 0; i< Object.keys(servicios).length; i++){
			        if(servicios[i]["id_partner"]==this.partner.value){
			        	encontrado = true;
			        }
			    }
			        	/// si se encuentra un servicio con el mismo nombre para el partner seleccionado
			    if(encontrado){
			        this.notificationService.error("Esta Compañia ya posee un servicio asociado con este nombre");
			    }else{ /// no se encuentra
			            this.servicioService.create(this.servicio).subscribe(servicio =>  {
			        	this.notificationService.sucessInsert(servicio.name_service);
			        	this.location.back();
			        }, err => this.notificationService.error(err));
			    }
			}, (err) => {
						this.notificationService.error(err);

		}); 

	}

	onTypeChange(value:any) {
		if(value == 2){
			this.show = true;
		}else{
			this.show = false;
		}
	}

	onFileChange(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageName = file.name;
			this.imageType = file.type;
			this.image64 = reader.result.toString();
		  };
		}
	}

	onFileChangeLastSended(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageNameOfLastSended = file.name;
			this.imageTypeOfLastSended = file.type;
			this.image64OfLastSended = reader.result.toString();
		  };
		}
	}

}
