import { Injectable } from '@angular/core';
import {BaseService} from '../shared/services/base.service';
import {Observable} from 'rxjs';
import {Group} from './Group';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})

export class GroupService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/group';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super(); 

    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }

  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  getAllSearch(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  create(group: Group): Observable<any> {
    return this.http.post(this.BASE_URL, group, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(group: Group): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + group['id'], group , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

}
