import { HttpParams } from '@angular/common/http';

export class PartnerFilter {

	name_partner: string;
    status_partner: number;
    id: number;
    
	constructor(filter?: PartnerFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_partner = filter.name_partner;
            this.status_partner = filter.status_partner;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_partner) {
			httpParams = httpParams.set('name_partner', this.name_partner);
        }
        if (this.status_partner) {
			httpParams = httpParams.set('status_partner', this.status_partner.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		return httpParams;
	}
}