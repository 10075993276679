import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {RebillingService} from '../rebilling.service';
import {BaseService} from '../../shared/services/base.service';
import {NotificationService} from '../../shared/notification/notification.service';
import cronstrue from 'cronstrue';
import { CustomValidators } from '../../shared/form/custom.validators';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  template: `
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Editar Recobro</h3>

	<form *ngIf="updateRebillingForm" [formGroup]="updateRebillingForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
		
      <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
          <label  for="name_billingsetting">Renovacion:</label>
          <input type="text" formControlName="name_billingsetting"  class="form-control" readonly>
				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Nombre del recobro</label>
          <input formControlName="name_rebillingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateRebillingForm.controls['name_rebillingsetting']"></validator>
        </div>

        <div class="field-row-item">
          <label class="label" for="estatus" >Estatus</label>
          <mat-select [formControl]="status_rebilling" disableRipple>
              <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
            </mat-select>
          <validator [control]="status_rebilling"></validator>
        </div>
        
      </div>

      <div class="field-row">
          <div class="field-row-item">
            <label  for="retry_frecuency_rebillingsetting">Dias para intentar cobro</label>
            <input type="text" (keypress)="onlyNumberKey($event)" formControlName="retry_frecuency_rebillingsetting"  class="form-control" >
            <validator [control]="updateRebillingForm.controls['retry_frecuency_rebillingsetting']"></validator>
          </div>
				<div class="field-row-item">
					<label class="label" for="daysForRebilling" >Dias de corte</label>
          <mat-select  [formControl]="day" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let day of filteredRolesMulti | async" [value]="day">
              {{day}}
            </mat-option>
          </mat-select>


          <validator [control]="day"></validator>
				</div>
      </div>
      
  
      <div class="field-row">

            <div class="field-row-item">
              <label class="label" >Frecuencia actual</label>
              <input formControlName="frequency_billingsetting" type="text" autocomplete="off" (click) ="onClick()" readonly>
            </div>


           <div class="field-row-item">
            <div *ngIf = "show" >
              <label for="time_rebillingsetting" >Elija una frecuencia nueva</label>
              <cron-jobs [formControl]="updateRebillingForm.controls['time_rebillingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
            </div>
            
          </div>
      </div>
      
      
      <div class="field-row">
        <div class="field-row-item">
          <label>Intentos de recobros:</label>
          <div style="display: flex">
          <div style="width: 50%">
            <div style="display: flex">
              <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" style="width: 10%; height: 20px" type="radio" value="1">  Por cantidad de Intentos
            </div>
            <div style="display: flex">
              <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" style="width: 10%; height: 20px"type="radio"  value="2"> Por cantidad de dias
            </div>
          </div>
          <div style="width: 50%">
              
              <mat-select [formControl]="recobro" disableRipple>
                <mat-option *ngFor="let recobro of recobros" [value]="recobro">{{recobro}}</mat-option>
              </mat-select>
              <validator [control]="recobro"></validator>

          </div>
        </div>
        </div>
      </div>
      
      <div class="field-row" style="background: ghostwhite">
				<div class="field-row-item">
          <label>Aplicar Rebilling en: </label>
          <div >
            <div style="width: 100%; margin: 0 0 0 10px">
              <div *ngFor="let code of rebillingCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{code.id}}" name="SomeName" (change)="changeEvent($event,code)" /> {{code.code_errorcodebac + " - " + code.description_errorcodebac}}</div>
            </div>
          </div>
        </div>

        <div class="field-row-item">
        <div style="display: block">
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onAdd()" > > </button>
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onRemov()" > < </button>
        </div>
        </div>
        
				<div class="field-row-item">
          <div class="form-subgroup" >
            <label>Codigo Errores del Api: </label>
            <div>
              <div style="width: 100%">
                <div *ngFor="let error of errorCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{error.id}}" name="SomeName" (change)="checkErrorCodes($event,error)" /> {{error.code_errorcodebac + " - " + error.description_errorcodebac}}</div>
              </div>
            </div>
          </div>
				</div>
      </div>
		

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateRebillingForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class UpdateRebillingComponent implements OnInit {


  days = ['01','02','03','04','05','06','07','08','09','10'
  ,'11','12','13','14','15','16','17','18','19','20'
  ,'21','22','23','24','25','26','27','28','29','30','31'];
  // Para dias
  public roleMultiCtrl: FormControl = new FormControl();
  public roleMultiFilterCtrl: FormControl = new FormControl();
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect1',{static: false}) multiSelect: MatSelect;

  day: FormControl = new FormControl('');

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();



  public cronConfig = {
    multiple:true,
    bootstrap:true,
    quartz:true
  };

  public cronValidate = {
    validate:true
  };

  recobros: any[]= [] ;
  rebillingCodes: any[]= [] ;
  seleccionados: any[]= [] ;
  errorCodeSeleccionados: any[]= [] ;
  errorCodes: any[]= [];
  rebilling;
  billing;
  radio;
  name_billingsetting;
  show = false;
  time_rebillingsetting;

  // validaciones para campos de formulario
  updateRebillingForm = this.fb.group({
    name_rebillingsetting: [''],
    status_rebillingsetting: [''],
    time_rebillingsetting: [''],
    name_billingsetting : [''],
    retry_frecuency_rebillingsetting:['', [Validators.required,CustomValidators.min(1)]],
    frequency_billingsetting: [''],
    id: ['']
  });

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  status_rebilling: FormControl = new FormControl('', Validators.required);
  recobro: FormControl = new FormControl('', Validators.required);

  constructor(
    public fb: FormBuilder,
    private rebillingService: RebillingService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {

    this.activatedRoute.parent.params.subscribe(param => {
      this.rebillingService.getById(param['rebillingId']).subscribe(rebilling => {   
        let diasAsociados = [];
        // tomamos los planes asociados al billing
        for (let r of rebilling.rebillingDays){
          diasAsociados.push(r.day);
        }
        // set initial selection
        this.day.setValue(diasAsociados);
        // load the initial role list
        this.filteredRolesMulti.next(this.days.slice());
        // listen for search field value changes
        this.roleMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterRoleMulti();
        });

        this.billing = rebilling.billingSetting;
        this.name_billingsetting = rebilling.billingSetting.name_billingsetting;
        this.rebilling = rebilling;
        this.updateRebillingForm.controls['id'].setValue(rebilling.id);
        this.updateRebillingForm.controls['name_billingsetting'].setValue(rebilling.billingSetting.name_billingsetting);
        this.updateRebillingForm.controls['name_rebillingsetting'].setValue(rebilling.name_rebillingsetting);
        this.updateRebillingForm.controls['retry_frecuency_rebillingsetting'].setValue(rebilling.retry_frecuency_rebillingsetting);
        // this.updateRebillingForm.controls['time_rebillingsetting'].setValue(rebilling.time_rebillingsetting);
        this.time_rebillingsetting = rebilling.time_rebillingsetting;
        this.updateRebillingForm.controls['frequency_billingsetting'].setValue(cronstrue.toString(rebilling.time_rebillingsetting));
        this.rebillingCodes = rebilling.errorCodeBacs;
        for ( let i = 1; i <= 120; i ++ ) {
          this.recobros.push(i);
        }
       
        this.rebillingService.getAllErrorsCodeBac().subscribe( errors => { // listamos los codigo de errores de bac registrados
          this.errorCodes = errors['result'];
          this.listarCodigosFaltantes();
          this.llenarCombos();
          this.llenarRecobros();
        }, error1 => {
          this.notificationService.error('Error cargando los ErrorCodeBac');
          console.log(error1);
        });
      });
    });
  }

  update() {
    const obj = <any> BaseService.builderObject(this.updateRebillingForm.value);
    if (this.radio === null) {
      this.notificationService.error('Debe seleccionar un intento de recobro para el rebilling');
      return;
    }
    if (this.radio == "1") {
      obj.typeoftry_rebillingsetting = 1;
    }else{
      obj.typeoftry_rebillingsetting = 2;
    }
     // Esto es en caso de que NO se elija una frecuencia nueva. 
    if(obj.time_rebillingsetting == null){
      obj.time_rebillingsetting = this.time_rebillingsetting;
    }
    obj.qtytry_rebillingsetting = this.recobro.value;
    delete obj.name_billingsetting; // borramos este parametro que es extra;
    delete obj.frequency_billingsetting;
    obj.billingSetting = {id: this.billing.id}; // seteamos el billing al que pertenece el rebilling
    obj.status_rebillingsetting = this.status_rebilling.value;
    const errorCodeBacs = [];
    for (let i = 0 ; i < this.rebillingCodes.length; i++) {
      errorCodeBacs.push({id: this.rebillingCodes[i].id});
    }
    obj.errorCodeBacs = errorCodeBacs;
    obj.daysForRebilling = this.day.value;
    this.rebillingService.update(obj).subscribe(rebilling => {
      this.notificationService.sucessInsert(rebilling['name_rebillingsetting']);
      this.location.back();
    }, error1 => {
      this.notificationService.error('Error creando el Rebilling');
      console.log(error1);
    });
  }
  llenarCombos() {
    this.status_rebilling.setValue(this.estatus[this.estatus.findIndex(s => s.value === this.rebilling.status_rebillingsetting)].value);
  }
  changeEvent(event, prueba) {
    if (event.target.checked) {
      this.seleccionados.push(prueba);
    } else {
      this.seleccionados.splice(this.seleccionados.indexOf(prueba), 1);
    }
  }
  llenarRecobros() {
    if (this.rebilling.typeoftry_rebillingsetting === 1) {
      this.radio = '1';
    }else {
      this.radio = '2';
    }
    this.recobro.setValue(this.recobros[this.recobros.findIndex(r => r === this.rebilling.qtytry_rebillingsSetting)]);
  }
  checkErrorCodes(event, prueba) {
    if (event.target.checked) {
      this.errorCodeSeleccionados.push(prueba);
    } else {
      this.errorCodeSeleccionados.splice(this.seleccionados.indexOf(prueba), 1);
    }
  }
  onAdd() {
    let index;
    for (let i = 0 ; i < this.seleccionados.length; i++) {
      index = this.rebillingCodes.findIndex(e => e.id === this.seleccionados[i].id);
      this.rebillingCodes.splice(index, 1); // removemos de aplicar rebilling en
      this.errorCodes.push(this.seleccionados[i]); // agregamos en codigo de errores del ap
    }
    this.seleccionados = [];
  }
  onRemov() {
    let index;
    for (let i = 0 ; i < this.errorCodeSeleccionados.length; i++) {
      index = this.errorCodes.findIndex(e => e.id === this.errorCodeSeleccionados[i].id);
      this.errorCodes.splice(index, 1); // removemos de aplicar rebilling en
      this.rebillingCodes.push(this.errorCodeSeleccionados[i]); // agregamos en codigo de errores del ap
    }
    this.errorCodeSeleccionados = [];
  }

  listarCodigosFaltantes() {
    if (this.rebillingCodes.length !== 0) {
      let index;
      for (let i = 0 ; i < this.rebillingCodes.length ; i++) {
        index = this.errorCodes.findIndex(e => e.id === this.rebillingCodes[i].id);
        if (index !== -1) {
          this.errorCodes.splice(index, 1);
        }
      }
    }
  }

  onClick(){
    this.show = true;
  }


  ngAfterViewInit() {
    this.setInitialValueRole();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValueRole() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a === b;
      });
  }

  protected filterRoleMulti() {

    if (!this.days) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.days.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.days.filter(bank => bank.toLowerCase().indexOf(search) > -1)
    );

  }

  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
