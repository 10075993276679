import { Component, OnInit } from '@angular/core';
import { FilterService } from '../../shared/filter-ns/filter-ns.service';

@Component({
  selector: 'app-cupon',
  template: '<router-outlet></router-outlet>',
  providers: [
  		FilterService
  	]
})
export class CuponComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
