
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Editar Recobro</h3>

	<form *ngIf="updateRebillingForm" [formGroup]="updateRebillingForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
		
      <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
          <label  for="name_billingsetting">Renovacion:</label>
          <input type="text" formControlName="name_billingsetting"  class="form-control" readonly>
				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Nombre del recobro</label>
          <input formControlName="name_rebillingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateRebillingForm.controls['name_rebillingsetting']"></validator>
        </div>

        <div class="field-row-item">
          <label class="label" for="estatus" >Estatus</label>
          <mat-select [formControl]="status_rebilling" disableRipple>
              <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
            </mat-select>
          <validator [control]="status_rebilling"></validator>
        </div>
        
      </div>

      <div class="field-row">
          <div class="field-row-item">
            <label  for="retry_frecuency_rebillingsetting">Dias para intentar cobro</label>
            <input type="text" (keypress)="onlyNumberKey($event)" formControlName="retry_frecuency_rebillingsetting"  class="form-control" >
            <validator [control]="updateRebillingForm.controls['retry_frecuency_rebillingsetting']"></validator>
          </div>
				<div class="field-row-item">
					<label class="label" for="daysForRebilling" >Dias de corte</label>
          <mat-select  [formControl]="day" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let day of filteredRolesMulti | async" [value]="day">
              {{day}}
            </mat-option>
          </mat-select>


          <validator [control]="day"></validator>
				</div>
      </div>
      
  
      <div class="field-row">

            <div class="field-row-item">
              <label class="label" >Frecuencia actual</label>
              <input formControlName="frequency_billingsetting" type="text" autocomplete="off" (click) ="onClick()" readonly>
            </div>


           <div class="field-row-item">
            <div *ngIf = "show" >
              <label for="time_rebillingsetting" >Elija una frecuencia nueva</label>
              <cron-jobs [formControl]="updateRebillingForm.controls['time_rebillingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
            </div>
            
          </div>
      </div>
      
      
      <div class="field-row">
        <div class="field-row-item">
          <label>Intentos de recobros:</label>
          <div style="display: flex">
          <div style="width: 50%">
            <div style="display: flex">
              <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" style="width: 10%; height: 20px" type="radio" value="1">  Por cantidad de Intentos
            </div>
            <div style="display: flex">
              <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" style="width: 10%; height: 20px"type="radio"  value="2"> Por cantidad de dias
            </div>
          </div>
          <div style="width: 50%">
              
              <mat-select [formControl]="recobro" disableRipple>
                <mat-option *ngFor="let recobro of recobros" [value]="recobro">{{recobro}}</mat-option>
              </mat-select>
              <validator [control]="recobro"></validator>

          </div>
        </div>
        </div>
      </div>
      
      <div class="field-row" style="background: ghostwhite">
				<div class="field-row-item">
          <label>Aplicar Rebilling en: </label>
          <div >
            <div style="width: 100%; margin: 0 0 0 10px">
              <div *ngFor="let code of rebillingCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{code.id}}" name="SomeName" (change)="changeEvent($event,code)" /> {{code.code_errorcodebac + " - " + code.description_errorcodebac}}</div>
            </div>
          </div>
        </div>

        <div class="field-row-item">
        <div style="display: block">
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onAdd()" > > </button>
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onRemov()" > < </button>
        </div>
        </div>
        
				<div class="field-row-item">
          <div class="form-subgroup" >
            <label>Codigo Errores del Api: </label>
            <div>
              <div style="width: 100%">
                <div *ngFor="let error of errorCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{error.id}}" name="SomeName" (change)="checkErrorCodes($event,error)" /> {{error.code_errorcodebac + " - " + error.description_errorcodebac}}</div>
              </div>
            </div>
          </div>
				</div>
      </div>
		

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateRebillingForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
