import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';

@Component({
  template: `
	<h3 class="title">Facturas para actualizar estatus</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_invoice">
							<span class="label">Invoice</span>
							<span>{{tableService.filter.id_invoice}}</span>
						</div>

					
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
					-->

					<div class="field-row">
          <div class="field-row-item">
            <label class="label" for="company" >Compañia</label>
            <mat-select placeholder="None" [(value)]="filter.id_partner" disableRipple>
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>
					</div>
					<div class="field-row-item">
								<label class="label" for="id_invoice">Invoice</label>
								<input type="number" name="id_invoice" placeholder="None"  [(ngModel)]="filter.id_invoice">
							</div>
					</div>
          
          <div class="field-row">

						<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>
            		</div>
            
            		<div class="field-row-item">
						<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker touchUi #picker2></mat-datepicker>
            		</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>


	<div class="tool-bar-wrap both-side">
		<div class="right row">

			<button  class="btn-icon"  title="Actualizar" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i  class="material-icons">check_box</i>
			</button>


		</div>
	</div>


	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">


		<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)">
						</mat-checkbox>
					</td>
    </ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
      </ng-container>
      
      <ng-container matColumnDef="desc_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.desc_status}}</td>
      </ng-container>

      <ng-container matColumnDef="total_invoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Monto</th>
        <td mat-cell *matCellDef="let element">{{element.total_invoice}}</td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.created_at}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" >Confirmacion</div>
					<div class="dialog-message" >Esta seguro que desea cambiar el estatus de los registros seleccionados?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span >No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span>SI</span>
						</button>
					</div>
				</div>
			</ng-template>statusServiceOrder
		</modal-ns>
`
})
export class UpdateStatusComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'select','id', 'name_customer', 'name_plan', 'desc_status', 'total_invoice','created_at'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

  partners: any[]= [];
  selectedPartner: any;
  partner: any;

	items: any[] = [];
	invoices : any[] = [];

  filterDateDesde = null;
  filterDateHasta = null;


  constructor(
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}

  ngOnInit() {
	let httpParams = this.partnerService.buildRequestParams();

	httpParams = httpParams.set('parents',false.toString());
	httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
	  this.partners = params['result'];
	   // cargamos sucursales
	   	let httpParams2 = this.partnerService.buildRequestParams();

		httpParams2 = httpParams2.set('parents', true.toString());
		httpParams2 = httpParams2.set('parent',(-1).toString());
	   this.partnerService.getPartnersByUserId(httpParams2).subscribe(params =>{
		for( var p of params['result']){
			this.partners.push(p)
		}
		if(this.filter.getHttpParams()['updates'] != null ){
			this.list();
		}
	  });
	  
      
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  list(event?: PageEvent) {

		if(this.filter.id_partner == undefined){
			this.notificationService.error("Debe seleccionar al menos una compania");
			return;
		}
	
		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
		let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
		{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

			if(this.filter.id_partner == null || this.filter.id_partner == undefined){
				this.notificationService.error("Debe seleccionar una Compañia");
				this.dataSource = new MatTableDataSource<any>([]);
				return;
			}
			// si se manda alguna fecha entonces se aplican las validaciones correspondientes
			if((this.filter.since_date != undefined || this.filter.since_date != null) || (this.filter.until_date != undefined || this.filter.until_date != null)){
				if((this.filter.since_date == null && this.filter.until_date != null) || this.filter.since_date == undefined){
					this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
					this.dataSource = new MatTableDataSource<any>([]);
					return;
				}
	
				if((this.filter.since_date != null && this.filter.until_date == null) || this.filter.until_date == undefined){
					this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
					this.dataSource = new MatTableDataSource<any>([]);
					return;
				}
				this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
				this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
				var since = new  Date (this.filter.since_date);
				var until = new  Date (this.filter.until_date);
				if(until.getTime() < since.getTime()){
					this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
					this.dataSource = new MatTableDataSource<any>([]);
					return;
				}
				this.tableService.filter = new ReportFilter(this.filter);
				
			}

			httpParams = this.filter.getHttpParams(httpParams);


			this.reportService.getInvoicesForUpdate(httpParams).subscribe(params => {
				this.items = params['result']; // items que mostrara la tabla
				this.desc_invoices();
				this.dataSource = new MatTableDataSource<any>(this.items);
				this.tableService.pager = params['pager'];
				this.tableService.selection.clear();
				if (this.items.length === 0) {
					this.notificationService.alert('No se encontraron resultados para la busqueda');
				}
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 
		
  }

  	desc_invoices() {
		for (const invoice of this.items) {
				if(invoice.status_invoice == 1){
					invoice.desc_status = invoice.desc_status + " - " + invoice.description_errorcodebac;
				}
		}
	}

	invoices_to_update(array){
		this.invoices = [];
		for(var inv of array){
			this.invoices.push(inv.id);
		}
	}
	
	deletes() {
		this.invoices_to_update(this.tableService.selection.selected);
		this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
			--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;
	
		this.reportService.sendInvoicesToUpdate(this.invoices).subscribe(response => {
			this.notificationService.sucessUpdate("Invoices");
			this.list();
		}, err => this.notificationService.error(err));
	}


  	//-- 

reset() {
	this.filter = new ReportFilter();
	this.dataSource = new MatTableDataSource<any>([]);
	this.tableService.filter = new ReportFilter(this.filter);
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new ReportFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new ReportFilter(this.tableService.filter);
}


applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
