
	<h3 class="title">Actualizar opcion</h3>

	<form *ngIf="updateOptionsForm" [formGroup]="updateOptionsForm" (ngSubmit)="update()">
    <fieldset class="fieldset">
    
      <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" >
      </div>
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateOptionsForm.controls['name']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label> 
					<mat-select [formControl]="planS"  disableRipple>
							<mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
					</mat-select>
					<validator [control]="planS"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

          <div class="field-row-item">
            <label class="label" for="status" >Estatus</label>
            <mat-select [formControl]="statuS" disableRipple>
              <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
            </mat-select>
            <validator [control]="statuS"></validator>
          </div>
        
        <div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input [formControl]="updateOptionsForm.controls['position']" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="updateOptionsForm.controls['position']"></validator>
					</div>

      </div>
      
      <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
            <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" ></editor>
					</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateOptionsForm || !planS.valid || !statuS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
