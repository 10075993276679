import { Routes } from '@angular/router';
import {RoleComponent} from './role.component';
import {RoleListComponent} from './role-list/role-list.component';
import {RoleCreateComponent} from './role-create/role-create.component';
import {RoleUpdateComponent} from './role-update/role-update.component';
import {RoleReadComponent} from './role-read/role-read.component';

export const roleRoutes: Routes = [
  {
    path: 'roles',
    component: RoleComponent,
    data: {
      breadcrumb: 'Roles',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RoleListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: RoleCreateComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':roleId',
        component: RoleComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: RoleReadComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: RoleUpdateComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }

        ]
      }
    ]
  }
];

export const routingRoleComponents = [RoleComponent,RoleListComponent,RoleCreateComponent,RoleReadComponent,RoleUpdateComponent];
