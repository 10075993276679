import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { BaseService } from '../../shared/services/base.service';
import { UserToolModel } from './usertoolModel';

@Injectable()
export class UserToolService extends BaseService {
    private urlUser: string = "http://localhost:9000/partner"//BaseService.HOST + '/route';
    private jwtHelper = new JwtHelperService();;

    constructor(private http: HttpClient){
        super();
    }  
    
    checkroute(route: string): Observable<UserToolModel> {
        // console.log("Json ", JSON.stringify({ email: email, password: Global.toMD5(password) }));        
        return null //this.http.post(this.urlUser + '/checkngroute', JSON.parse(JSON.stringify({ route: route })), {headers: this.createAuthorizationHeader()})
           // .map(response => response.json())
            //.catch(this.handleError);
    }
}
