import { Routes } from '@angular/router';
import { CustomerComponent } from './customer.component';
import { CustomerCreateComponent } from './customer-create.component';
import { CustomerReadComponent } from './customer-read.component';
import { CustomerUpdateComponent } from './customer-update.component';
import {creditcardRoutes} from "../creditcard/creditcard.routes";
import {CustomerListOutComponent} from "./customer-list-out.component";
import {customerHasPlansRoutes} from "../customer_has_plans/customer_has_plans.routes";

export const customerRoutes: Routes = [
	{
				path: ':partnerId/customers',
				component: CustomerComponent,
				data: {
					breadcrumb: "Clientes"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: CustomerListOutComponent,
						data: {
						   	breadcrumb: undefined
						    }
					},
					{
					    path:'create',
					    component: CustomerCreateComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':customerID',
					    component: CustomerComponent,
					    data: {
					    	breadcrumb: "Detalle",
			    			icon: "detail"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: CustomerReadComponent,
							    data: {
							    	breadcrumb: undefined,
							    }
							},
							{
							    path: 'update',
							    component: CustomerUpdateComponent,
							  	data: {
							    	breadcrumb: "Actualizar",
			    					icon: "update"
							    }
						  	}
					  	]
				  	},
          ...customerHasPlansRoutes,
          ...creditcardRoutes
				]
			}
];

export const routingCustomerComponnents = [CustomerComponent, CustomerListOutComponent, CustomerReadComponent, CustomerCreateComponent, CustomerUpdateComponent];
