import { BaseModel } from '../shared/models/base.model';

export class Creditcard extends BaseModel{
  maskCreditCard : string;
  numberCreditCard : string;
  expMonthCreditCard: string;
  expYearCreditCard : string;
  nameHolderCreditCard: string;
  estatus : string;

  constructor(options: {} = {}){
    super();
    this.numberCreditCard = options['number_creditcard'];
    this.expMonthCreditCard = options['expirationmonth_creditcard'];
    this.expYearCreditCard = options['expirationyear_creditcard'];
    this.nameHolderCreditCard = options['nameholder_creditcard'];
    this.estatus = options['estatus'] || "Activo";
  }
}
