import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { homeRouting } from './home.routes';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbsComponent } from '../utils/breadcrumbs/breadcrumbs.component';
import { PartnerModule } from '../partner/partner.module';
import { PlanModule } from '../plan/plan.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { UsertoolComponent } from '../utils/usertool/usertool.component';
import { MenuComponent } from '../utils/menu/menu.component';
import { MenuItemComponent } from '../utils/menu/menu-item/menu-item.component';
import { BrowserModule } from '@angular/platform-browser';
import { ServicioModule } from '../servicio/servicio.module';
import {CustomerModule} from '../customer/customer.module';
import {CreditcardModule} from '../creditcard/creditcard.module';
import {CustomerHasPlansModule} from '../customer_has_plans/customer_has_plans.module';
import {RoleModule} from '../role/role.module';
import {PermissionModule} from '../permission/permission.module';
import {GroupModule} from '../group/group.module';
import {UserModule} from '../users/user.module';
import {FrecuencyModule} from '../frecuency/frecuency.module';
import {BillingModule} from '../billing/billing.module';
import {RebillingModule} from '../rebilling/rebilling.module';
import {BranchModule} from '../branch/branch.module';
import {JobModule} from '../job/job.module';
import {ReportModule} from '../report/report.module';
import {FileModule} from '../file/file.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button'

import { CuponModule } from '../cupon/cupon.module';
import { UsersModule } from '../user/user.module';
import { ResumenComponent } from './resumen.component';
import { HomeService } from './home.service';
import { DashboardModule } from '../dashboard/dashboard.module';
import { EnrollmentModule } from '../enrollment/enrollment.module';



import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { IntegrationModule } from '../integration/integration.module';
import { OptionModule } from '../option/option.module';



@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    PartnerModule,
    RoleModule,
    PermissionModule,
    GroupModule,
    CuponModule,
    UserModule,
    UsersModule,
    CustomerModule,
    CustomerHasPlansModule,
    CreditcardModule,
    PlanModule,
    FrecuencyModule,
    BillingModule,
    DashboardModule,
    JobModule,
    MatButtonModule,
    ReportModule,
    BranchModule,
    RebillingModule,
    FileModule,
    EnrollmentModule,
    homeRouting,
    NgProgressModule,
    MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    ServicioModule,
    HttpClientModule,
    NgProgressModule,
    IntegrationModule,
    OptionModule
  ],
  declarations: [
    BreadcrumbsComponent,
    UsertoolComponent,
    MenuComponent,
    MenuItemComponent,
    HomeComponent,
    ResumenComponent
  ],
  providers:[ { provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true }, HomeService]
})
export class HomeModule { }
