import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {NotificationService} from '../../../shared/notification/notification.service';
import {FrecuencyService} from '../../frecuency.service';
import {BaseService} from '../../../shared/services/base.service';

@Component({
  template: `
	<h3 class="title">Editar Frecuencia</h3>

	<form *ngIf="updateFrecuencyForm" [formGroup]="updateFrecuencyForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos de la frecuencia</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_frecuency">Nombre</label>
					<input formControlName="name_frecuency" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateFrecuencyForm.controls['name_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="day_frecuency">Dia de frecuencia</label>
          <input formControlName="day_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de frecuencia" >
          <validator [control]="updateFrecuencyForm.controls['day_frecuency']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="month_frecuency">Mes de frecuencia</label>
          <input formControlName="month_frecuency" type="number" autocomplete="off" placeholder="Ingrese mes de frecuencia" >
          <validator [control]="updateFrecuencyForm.controls['month_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="dayOfWeek_frecuency">Dia de la semana</label>
          <input formControlName="dayOfWeek_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de la semana" >
          <validator [control]="updateFrecuencyForm.controls['dayOfWeek_frecuency']"></validator>
        </div>
      </div>
      
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateFrecuencyForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class UpdateFrecuencyComponent implements OnInit {
  public loading = false;
  private frecuency: any;
  // validaciones para campos de formulario
  updateFrecuencyForm = this.fb.group({
    name_frecuency: ['', [Validators.required]],
    day_frecuency: ['', [Validators.required]],
    month_frecuency: ['', [Validators.required]],
    dayOfWeek_frecuency: ['', [Validators.required]],
    id: ['', [Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private freceuncyService: FrecuencyService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.parent.params.subscribe(param => {
      this.freceuncyService.getFrecuency(param['frecuencyId']).subscribe(result => {
        this.frecuency = result;
        // setamos la informacion del partner en el formulario
        this.updateFrecuencyForm.controls['name_frecuency'].setValue(this.frecuency.name_frecuency);
        this.updateFrecuencyForm.controls['day_frecuency'].setValue(this.frecuency.day_frecuency);
        this.updateFrecuencyForm.controls['month_frecuency'].setValue(this.frecuency.month_frecuency);
        this.updateFrecuencyForm.controls['dayOfWeek_frecuency'].setValue(this.frecuency.dayOfWeek_frecuency);
        this.updateFrecuencyForm.controls['id'].setValue(this.frecuency.id);
        this.loading = false;
        }, (err) => {
        this.notificationService.error(err);
        this.loading = false;
      });
    });
  }

  update() {
    const obj = <any> BaseService.builderObject(this.updateFrecuencyForm.value);
    obj.day_frecuency = this.updateFrecuencyForm.get('day_frecuency').value;
    obj.month_frecuency = this.updateFrecuencyForm.get('month_frecuency').value;
    obj.dayOfWeek_frecuency = this.updateFrecuencyForm.get('dayOfWeek_frecuency').value;
    this.freceuncyService.update(obj).subscribe(result => {
      this.notificationService.sucessUpdate(result['name_frecuency']);
      this.location.back();
    }, (err) => {
      this.notificationService.error(err);
      this.loading = false;
    });
  }

}
