import {Routes} from '@angular/router';
import {BranchComponent} from './branch/branch.component';
import {ListBranchComponent} from './list-branch/list-branch.component';
import {CreateBranchComponent} from './create-branch/create-branch.component';
import {ReadBranchComponent} from './read-branch/read-branch.component';
import {EditBranchComponent} from './edit-branch/edit-branch.component';
import {customerRoutes} from '../customer/customer.routes';
import {servicioRoutes} from '../servicio/servicio.routes';

export const branchRoutes: Routes = [
  {
    path: 'sucursals',
    component: BranchComponent,
    data: {
      breadcrumb: 'Sucursales'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ListBranchComponent
      },
      {
        path: 'create',
        component: CreateBranchComponent,
        data: {
          breadcrumb: 'Crear'
        }
      },
      {
        path: ':partnerId',
        component: BranchComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ReadBranchComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: EditBranchComponent,
            data: {
              breadcrumb: 'Actualizar'
            }
          }

        ]
      },
      ...servicioRoutes,
      ...customerRoutes
    ]
  },
  {
    path: ':partnerId/sucursals',
    component: BranchComponent,
    data: {
      breadcrumb: 'Sucursales',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ListBranchComponent
      },
      {
        path: 'create',
        component: CreateBranchComponent,
        data: {
          breadcrumb: 'Crear'
        }
      },
      {
        path: ':branchId',
        component: BranchComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ReadBranchComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: EditBranchComponent,
            data: {
              breadcrumb: 'Actualizar'
            }
          }

        ]
      },
      ...servicioRoutes,
      ...customerRoutes
    ]
  }
];


export const routingbranchComponnents = [BranchComponent, ListBranchComponent, CreateBranchComponent, EditBranchComponent, ReadBranchComponent];