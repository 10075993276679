import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class FileService extends BaseService {

  httpOptions : any;

  private readonly BASE_URL: string = this.HOST + '/processfile';


  constructor(private http: HttpClient) { 
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getByName(name: string, plan: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/name/' + name + '/plan/' + plan, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  create(file: any): Observable<any> {
    return this.http.post(this.BASE_URL, file, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(file: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + file['id'], file , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  processPayments(file: any): Observable<any> {
    return this.http.get(this.HOST + '/transaction/processpayment/file?file=' + file, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getResponses(params: HttpParams = new HttpParams(), id_file): Observable<any> {
    if (id_file !== undefined) {
      params.set('id_file', id_file.toString());
    }
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/payment/responses', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
