import { HttpParams } from '@angular/common/http';

export class CustomerFilter {

	name_customer: string;
    adress_customer: string;
	id: number;
	status_customer: number;
	identifier_customer: string;
    
	constructor(filter?: CustomerFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_customer = filter.name_customer;
			this.adress_customer = filter.adress_customer;
			this.status_customer = filter.status_customer;
			this.identifier_customer = filter.identifier_customer;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_customer) {
			httpParams = httpParams.set('name_customer', this.name_customer);
        }
        if (this.adress_customer) {
			httpParams = httpParams.set('adress_customer', this.adress_customer);
		}
		if (this.identifier_customer) {
			httpParams = httpParams.set('identifier_customer', this.identifier_customer);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_customer) {
			httpParams = httpParams.set('status_customer', this.status_customer.toString());
        }
		return httpParams;
	}
}