import { Component, OnInit } from '@angular/core';

@Component({
  template: `
  <p>read-integration works!</p>
  `
})
export class ReadIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
