import { HttpParams } from '@angular/common/http';

export class FileFilter {

    id: number;
    id_companyf: number;
    id_servicef: number;
    id_planf: number;
    since_datef: string;
    until_datef: string;
    id_sucursalf: number;
	
    
	constructor(filter?: FileFilter) {
		if (filter) {
            this.id = filter.id;
            this.id_companyf = filter.id_companyf;
            this.id_servicef = filter.id_servicef;
            this.id_planf = filter.id_planf;
            this.since_datef = filter.since_datef;
            this.until_datef = filter.until_datef;
            this.id_sucursalf = filter.id_sucursalf;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.id_servicef) {
			httpParams = httpParams.set('id_service', this.id_servicef.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
        }
        if (this.id_sucursalf) {
			httpParams = httpParams.set('id_sucursal', this.id_sucursalf.toString());
        }
        if (this.id_companyf) {
			httpParams = httpParams.set('id_company', this.id_companyf.toString());
		}
		if (this.id_planf) {
			httpParams = httpParams.set('id_plan', this.id_planf.toString());
        }
        if (this.until_datef) {
			httpParams = httpParams.set('until_date', this.until_datef);
        }
        if (this.since_datef) {
			httpParams = httpParams.set('since_date', this.since_datef);
        }
		return httpParams;
	}
}