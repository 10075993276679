import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

export interface Filter {
  typeRole?: string;
}

@Injectable({
  providedIn : 'root'
})
export class RoleService extends BaseService {

  private  readonly BASE_URL: string = this.HOST + '/roles';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
   }
  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  getAllSearch(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  create(role: any): Observable<any> {
    return this.http.post(this.BASE_URL, role, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(role: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + role['id'], role , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
}
