import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFrecuencyComponent } from './component/create-frecuency/create-frecuency.component';
import { UpdateFrecuencyComponent } from './component/update-frecuency/update-frecuency.component';
import { ListFrecuencyComponent } from './component/list-frecuency/list-frecuency.component';
import { FrecuencyComponent } from './component/frecuency/frecuency.component';
import { ReadFrecuencyComponent } from './component/read-frecuency/read-frecuency.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {homeRouting} from '../home/home.routes';
import {FrecuencyService} from './frecuency.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import {MatButtonModule} from '@angular/material/button'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
    MatSelectModule, MatInputModule,
    MatButtonModule
  ],
  declarations: [CreateFrecuencyComponent, UpdateFrecuencyComponent, ListFrecuencyComponent, FrecuencyComponent, ReadFrecuencyComponent],
  providers: [
    FrecuencyService
  ]
})
export class FrecuencyModule { }
