
	<h3 class="title">Respuestas obtenidas para el archivo</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del proceso</span>
					<span>{{ name_processFile || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del archivo</span>
					<span>{{ name_file_processFile || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status_processFile || '-'}}</span>
				</div>
			</div>

		</div>
  </div>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button class="btn-icon" type="button" (click)="export()">
        <i class="material-icons">cloud_download</i>
      </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
	
			<ng-container matColumnDef="DescriptionPayment">
				<th mat-header-cell *matHeaderCellDef  >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element.DescriptionPayment}}</td>
			</ng-container>
			
			<ng-container matColumnDef="NameCustomer">
				<th mat-header-cell *matHeaderCellDef  >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.NameCustomer}}</td>
      </ng-container>
      
      <ng-container matColumnDef="CodeCustomer">
        <th mat-header-cell *matHeaderCellDef  >Codigo</th>
        <td mat-cell *matCellDef="let element">{{element.CodeCustomer}}</td>
      </ng-container>

      <ng-container matColumnDef="DateInvoice">
        <th mat-header-cell *matHeaderCellDef  >Fecha factura</th>
        <td mat-cell *matCellDef="let element">{{element.DateInvoice}}</td>
      </ng-container>

      <ng-container matColumnDef="DatePayment">
        <th mat-header-cell *matHeaderCellDef  >Fecha pago</th>
        <td mat-cell *matCellDef="let element">{{element.DatePayment}}</td>
      </ng-container>

      <ng-container matColumnDef="TotalInvoice">
        <th mat-header-cell *matHeaderCellDef  >Total</th>
        <td mat-cell *matCellDef="let element">{{element.TotalInvoice}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
