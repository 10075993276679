import { HttpParams } from '@angular/common/http';

export class RoleFilter {

	description: string;
    id: number;
    
	constructor(filter?: RoleFilter) {
		if (filter) {
            this.id = filter.id;
            this.description = filter.description;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.description) {
			httpParams = httpParams.set('description', this.description);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		return httpParams;
	}
}