<div id="side-nav">
	<header>
		<img id="logo" src="./assets/images/logo-blanco.png" routerLink="/home"/>
	</header>
	<nav class="nav-item" *hasPermission="['partner.id.user']">
		<nav class="nav-item">
			<div class="item header" (click)="selection.toggle(NAV_COMPANIES_SUCURSALS)" [ngClass]="{'expanded': selection.isSelected(NAV_COMPANIES_SUCURSALS), 'selected': dataMenuItem == 'companies' || dataMenuItem == 'sucursals'}">
				<span>Compañías y Sucursales</span>
				<i class="material-icons">keyboard_arrow_right</i>
			</div>
			<div class="nav-tab">
				<div class="item" data-link="./partners" data-menu-item="companies" [ngClass]="{'selected': dataMenuItem == 'companies'}" (click)="select($event)" routerLink="./partners">Compañías</div>

				<div class="item" data-link="./sucursals" data-menu-item="sucursals" [ngClass]="{'selected': dataMenuItem == 'sucursals'}" (click)="select($event)" routerLink="./sucursals">Sucursales</div>
			</div>
		</nav>
	</nav>
	<nav class="nav-item" *hasPermission="['processfile.all']">
		<nav class="nav-item">
			<div class="item" data-link="./files" data-menu-item="payments" [ngClass]="{'selected': dataMenuItem == 'payments'}" (click)="select($event)" routerLink="./files">Generar Cobros</div>
		</nav>
	</nav>
	<nav class="nav-item" *hasPermission="['list.enrollment']">
		<nav class="nav-item">
			<div class="item" data-link="./sources" data-menu-item="sources" [ngClass]="{'selected': dataMenuItem == 'sources'}" (click)="select($event)" routerLink="./sources">Importar Clientes</div>
		</nav>
	</nav>
	<nav class="nav-item" *hasPermission="['security']">
		<nav class="nav-item">
			<div class="item header" (click)="selection.toggle(NAV_SECURITY)" [ngClass]="{'expanded': selection.isSelected(NAV_SECURITY), 'selected': dataMenuItem == 'group' || dataMenuItem == 'rols' || dataMenuItem == 'users'}">
				<span>Seguridad</span>
				<i class="material-icons">keyboard_arrow_right</i>
			</div>
			<div class="nav-tab">
				<div class="item" data-link="./group" data-menu-item="group" [ngClass]="{'selected': dataMenuItem == 'group'}" (click)="select($event)" routerLink="./group">Grupos</div>

				<div class="item" data-link="./roles" data-menu-item="rols" [ngClass]="{'selected': dataMenuItem == 'rols'}" (click)="select($event)" routerLink="./roles">Roles</div>

				<div class="item" data-link="./users" data-menu-item="users" [ngClass]="{'selected': dataMenuItem == 'users'}" (click)="select($event)" routerLink="./users">Usuarios</div>
			</div>
		</nav>
	</nav>
	<nav class="nav-item" *hasPermission="['configurations']">
		<nav class="nav-item">
			<div class="item header" (click)="selection.toggle(NAV_CONFIG)" [ngClass]="{'expanded': selection.isSelected(NAV_CONFIG), 'selected': dataMenuItem == 'frecuencies' || dataMenuItem == 'renewal' || dataMenuItem == 'daemons'  || dataMenuItem == 'dashboard' }">
				<span>Configuraciones</span>
				<i class="material-icons">keyboard_arrow_right</i>
			</div>
			<div class="nav-tab">
				<div class="item" data-link="./frecuencies" data-menu-item="frecuencies" [ngClass]="{'selected': dataMenuItem == 'frecuencies'}" (click)="select($event)" routerLink="./frecuencies">Frecuencias</div>

				<div class="item" data-link="./billings" data-menu-item="renewal" [ngClass]="{'selected': dataMenuItem == 'renewal'}" (click)="select($event)" routerLink="./billings">Renovaciones</div>

				<div class="item" data-link="./jobs" data-menu-item="daemons" [ngClass]="{'selected': dataMenuItem == 'daemons'}" (click)="select($event)" routerLink="./jobs">Demonios</div>

				<div class="item" data-link="./dashboard" data-menu-item="dashboard" [ngClass]="{'selected': dataMenuItem == 'dashboard'}" (click)="select($event)" routerLink="./dashboard">Tablero</div>

				<div class="item" data-link="./integrations" data-menu-item="integrations" [ngClass]="{'selected': dataMenuItem == 'integrations'}" (click)="select($event)" routerLink="./integrations">Integraciones</div>
			</div>
		</nav>
	</nav>
	<nav class="nav-item" *hasPermission="['reports']">
		<nav class="nav-item">
			<div class="item header" (click)="selection.toggle(NAV_REPORT)" [ngClass]="{'expanded': selection.isSelected(NAV_REPORT), 'selected': dataMenuItem == 'invoices' || dataMenuItem == 'charged' || dataMenuItem == 'customer'  || dataMenuItem == 'updateinvoice' 
			|| dataMenuItem == 'billingsettingtrack' || dataMenuItem == 'rebillingsettingtrack' || dataMenuItem == 'notifications' 
			|| dataMenuItem == 'customers' || dataMenuItem == 'customersByPartner' || dataMenuItem == 'auditionInfo' || dataMenuItem == 'auditionApi' || dataMenuItem == 'fixInvoice' }">
				<span>Reportes</span>
				<i class="material-icons">keyboard_arrow_right</i>
			</div>

			<div class="nav-tab">
				<div  class="item" data-link="reports/invoices/to/charge" data-menu-item="toCharge" [ngClass]="{'selected': dataMenuItem == 'toCharge'}" (click)="select($event)" routerLink="reports/invoices/to/charge">Facturas por cobrar</div>

				<div *hasPermission="['invoices.report']" class="item" data-link="./reports/invoices" data-menu-item="invoices" [ngClass]="{'selected': dataMenuItem == 'invoices'}" (click)="select($event)" routerLink="./reports/invoices">Facturas por fecha de creación</div>

				<div *hasPermission="['invoices.report']" class="item" data-link="./reports/invoices/by/payment" data-menu-item="invoices-by-payment" [ngClass]="{'selected': dataMenuItem == 'invoices-by-payment'}" (click)="select($event)" routerLink="./reports/invoices/by/payment">Facturas por fecha de cobro</div>

				<div *hasPermission="['invoices.charged']" class="item" data-link="./reports/charged" data-menu-item="charged" [ngClass]="{'selected': dataMenuItem == 'charged'}" (click)="select($event)" routerLink="./reports/charged">Facturas cobradas</div>  

				<div *hasPermission="['report.invoice.user.status']" class="item" data-link="./reports/customer/invoices" data-menu-item="customer" [ngClass]="{'selected': dataMenuItem == 'customer'}" (click)="select($event)" routerLink="./reports/customer/invoices">Facturas por cliente</div>

				<div *hasPermission="['invoice.cancel']" class="item" data-link="reports/update/invoices" data-menu-item="updateinvoice" [ngClass]="{'selected': dataMenuItem == 'updateinvoice'}" (click)="select($event)" routerLink="reports/update/invoices">Actualizar estado de facturas</div>

				<div *hasPermission="['billingSetting.report']"  class="item" data-link="reports/billingsetting" data-menu-item="billingsettingtrack" [ngClass]="{'selected': dataMenuItem == 'billingsettingtrack'}" (click)="select($event)" routerLink="reports/billingsetting">Bitacora de renovaciones</div>

				<div *hasPermission="['billingSetting.report']"  class="item" data-link="reports/rebillingsetting" data-menu-item="rebillingsettingtrack" [ngClass]="{'selected': dataMenuItem == 'rebillingsettingtrack'}" (click)="select($event)" routerLink="reports/rebillingsetting">Bitacora de recobros</div>

				<div *hasPermission="['invoices.tonotificate']" class="item" data-link="reports/notification-failed" data-menu-item="notifications" [ngClass]="{'selected': dataMenuItem == 'notifications'}" (click)="select($event)" routerLink="reports/notification-failed">Notificaciones fallidas</div>
				
				<div *hasPermission="['customer.find.all']" class="item" data-link="reports/customers" data-menu-item="customers" [ngClass]="{'selected': dataMenuItem == 'customers'}" (click)="select($event)" routerLink="reports/customers">Clientes por plan</div>

				<div *hasPermission="['all.customer']" class="item" data-link="reports/customers/partner" data-menu-item="customersByPartner" [ngClass]="{'selected': dataMenuItem == 'customersByPartner'}" (click)="select($event)" routerLink="reports/customers/partner">Clientes por compañía</div>

				<div *hasPermission="['all.customer']" class="item" data-link="reports/audition/api" data-menu-item="auditionApi" [ngClass]="{'selected': dataMenuItem == 'auditionApi'}" (click)="select($event)" routerLink="reports/audition/api">Auditoria API</div>

				<div *hasPermission="['all.customer']" class="item" data-link="reports/audition/info" data-menu-item="auditionInfo" [ngClass]="{'selected': dataMenuItem == 'auditionInfo'}" (click)="select($event)" routerLink="reports/audition/info">Auditoria INFO</div>
				

			</div>
		</nav>
	</nav>