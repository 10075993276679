import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseService } from '../shared/services/base.service';
import { PlanService } from './plan.service';
import { NotificationService} from '../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { PartnerService } from '../partner/partner.service';
import { ServicioService } from '../servicio/servicio.service';
import { FrecuencyService } from '../frecuency/frecuency.service';
import { CustomValidators } from '../shared/form/custom.validators';

@Component({
    template: `
	<h3 class="title">Editar Plan</h3>

	<form *ngIf="updatePlanForm" [formGroup]="updatePlanForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
            <legend class="legend" >Datos del plan</legend>
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" >
            </div>

			<div class="field-row">
                
                <div class="field-row-item">
						<label class="label" for="partner" >{{label}}</label>
						<mat-select [formControl]="partnerS"  (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
						</mat-select>
						<validator [control]="partnerS"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="service" >Servicio</label>
						<mat-select [formControl]="service"  disableRipple>
								<mat-option *ngFor="let servicio of servicios" [value]="servicio.id">{{servicio.name_service}}</mat-option>
						</mat-select>
						<validator [control]="service"></validator>
                </div>
                
                <div class="field-row-item">
					<label class="label" for="name_plan">Nombre</label>
					<input formControlName="name_plan" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updatePlanForm.controls['name_plan']"></validator>
				</div>
			</div>
			
			

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="enrollmentCost_plan">Costo de inscripción</label>
					<input formControlName="enrollmentCost_plan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese Costo" >
					<validator [control]="updatePlanForm.controls['enrollmentCost_plan']"></validator>
				</div>

				<div *ngIf="show" class="field-row-item">
						<label class="label" for="charge" >Cobrar inscripción</label>
						<mat-select [formControl]="charge"  disableRipple>
							<mat-option *ngFor="let option of options" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="charge"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="feeCost_plan">Costo </label>
					<input formControlName="feeCost_plan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese costo" >
					<validator [control]="updatePlanForm.controls['feeCost_plan']"></validator>
				</div>
				
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="freezeCostPlan">Costo por congelamiento</label>
					<input formControlName="freezeCostPlan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese costo" >
					<validator [control]="updatePlanForm.controls['freezeCostPlan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="taxRate_plan">Impuesto(%)</label>
					<input formControlName="taxRate_plan" maxlength="3" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese impuesto" >
					<validator [control]="updatePlanForm.controls['taxRate_plan']"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="idPlan_partner">ID plan partner</label>
					<input formControlName="idPlan_partner" type="text" autocomplete="off" placeholder="Ingrese ID" >
					<validator [control]="updatePlanForm.controls['idPlan_partner']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="frecuency" >Frecuencia</label>
                    <mat-select [formControl]="frecuencyS" disableRipple>
							<mat-option *ngFor="let frecuency of frecuencies" [value]="frecuency.id">{{frecuency.name_frecuency}}</mat-option>
					</mat-select>
					<validator [control]="frecuencyS"></validator>
                </div>
                <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_plan" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="status_plan"></validator>
				</div>
            </div>
            



			<div *ngIf="show">
			

				<div class="field-row">

					<div class="field-row-item">
						<label class="label" for="title">Título</label>
						<input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Título" >
						<validator [control]="updatePlanForm.controls['title']"></validator>
					</div>

					
					<div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input formControlName="position" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="updatePlanForm.controls['position']"></validator>
					</div>
				</div>

				<div class="field-row">

					

					<div class="field-row-item">
						<label class="label" for="url_image">Imagen del plan</label>
						<input id="file-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChange($event)" >
					</div>

					<!-- <div class="field-row-item">
						<label class="label" for="url_image_detail">Imagen para detalle de plan</label>
						<input id="file-detail-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChangeDetail($event)" required>
					</div> -->
					
				</div>


				<!-- <div class="field-row">

					<div class="field-row-item">
						<label class="label" for="label_frecuency">Etiqueta de frecuencia</label>
						<input formControlName="label_frecuency" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_frecuency']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_inscription">Etiqueta de inscripción</label>
						<input formControlName="label_inscription" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_inscription']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_payment">Etiqueta de pago</label>
						<input formControlName="label_payment" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_payment']"></validator>
					</div>

					
					
				</div> -->

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="title">Titulo del email</label>
							<textarea formControlName="title_email"></textarea>
							<validator [control]="updatePlanForm.controls['title_email']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="url_ecommerce">Mensaje del email</label>
							<textarea formControlName="message_email"></textarea>
							<validator [control]="updatePlanForm.controls['message_email']"></validator>
						</div>

				</div>	



				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="validity_period" >Periodo</label>
						<mat-select [formControl]="period"  disableRipple>
							<mat-option *ngFor="let period of periods" [value]="period.value">{{period.description}}</mat-option>
						</mat-select>
						<validator [control]="period"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="validity_value">Valor</label>
						<input formControlName="validity_value" type="text" maxlength="5" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Valor" >
						<validator [control]="updatePlanForm.controls['validity_value']"></validator>
					</div>
					
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
						<textarea formControlName="description"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="detail_plan" >Descripción</label>
						<textarea formControlName="detail_plan"></textarea>
						
					</div>
				</div>

				<!-- <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="extended_description" >Descripción extendida</label>
						<editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="extended_description" ></editor>
					</div>
				</div> -->

			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updatePlanForm.valid || !status_plan.valid || !partnerS.valid || !service.valid || !frecuencyS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class PlanUpdateComponent{

	imageName: any;
	image64: any;
	imageType: any;

	imageDetailName: any;
	imageDetail64: any;
	imageDetailType: any;

	plan:any;
    partners = [];
    servicios = [];
    frecuencies = [];
    parents = false;
    show = false;
    label = 'Compañía';

    estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
	periods : any[] = [{value:1,description:"Dias"},{value:2,description:'Meses'}];
	options : any[] = [{value: false, description: "NO"}, {value: true, description: "SI"}];

	partnerS: FormControl = new FormControl('', Validators.required);
    service: FormControl = new FormControl('', Validators.required);
    frecuencyS: FormControl = new FormControl('', Validators.required);
    status_plan: FormControl = new FormControl('', Validators.required);
	period: FormControl = new FormControl('');
	charge: FormControl = new FormControl('', Validators.required);


    // validaciones para campos de formulario
    updatePlanForm = this.fb.group({
        name_plan: ['', [Validators.required]],
        feeCost_plan: ['', [Validators.required]],
        idPlan_partner : ['',[Validators.required]],
        enrollmentCost_plan: ['', [Validators.required]],
        freezeCostPlan : ['', [Validators.required]],
        taxRate_plan: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
        id: ['', [Validators.required]],
		description:[''],
		position:[''],
		validity_value:[''],
		detail_plan:[''],
		title:[''],
		charge_inmediately:[''],
		label_inscription: [''],
		label_payment:[''],
		label_frecuency:[''],
		title_email:[''],
		message_email:[''],
		extended_description:['']

    });

	  constructor(
            public fb: FormBuilder,
		    private router: Router,
		    private activatedRoute: ActivatedRoute,
		    private location: Location,
		    private planService: PlanService,
            private partnerService: PartnerService,
            private servicioService: ServicioService,
            private frecuencyService: FrecuencyService,
		    private notificationService: NotificationService
	  ){}

	ngOnInit() {
        if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
        this.parents = true;
        this.label = 'Sucursal';
        }
        this.cargarPantalla(); // cargamos los combos de la vista
	}

    cargarPantalla(){

        this.activatedRoute.parent.params.subscribe(param => {

            let httpParams = this.partnerService.buildRequestParams();

            httpParams = httpParams.set('parents',this.parents.toString());
            httpParams = httpParams.set('parent',(-1).toString());


            /// cargamos los partner registrados
            this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
                this.partners = params['result'];
                    // cargamos la informacion del plan del partner
                    this.planService.getById(param['planID']).subscribe(plan => {
                    this.plan = plan;
                    // cargamos los servicios
                    this.servicioService.getAll().subscribe(params=>{
                        this.servicios = params['result'];
                        // cargamos las frecuencias
                        this.frecuencyService.getAll().subscribe(params=>{
                            this.frecuencies = params["result"];
                            // seteamos la informacion de la vista
                            this.updatePlanForm.controls["name_plan"].setValue(this.plan.name_plan);
                            this.updatePlanForm.controls["freezeCostPlan"].setValue(this.plan.freezeCostPlan);
                            this.updatePlanForm.controls["feeCost_plan"].setValue(this.plan.feeCost_plan);
                            this.updatePlanForm.controls["enrollmentCost_plan"].setValue(this.plan.enrollmentCost_plan);
							this.updatePlanForm.controls["taxRate_plan"].setValue(this.plan.taxRate_plan);
							this.updatePlanForm.controls["detail_plan"].setValue(this.plan.detail_plan);
                            this.updatePlanForm.controls["id"].setValue(this.plan.id);
                            this.updatePlanForm.controls["idPlan_partner"].setValue(this.plan.idPlan_partner);
                            this.status_plan.setValue(this.estatus[this.estatus.findIndex(e => this.plan.status_plan === e.value)].value);
                            this.frecuencyS.setValue(this.frecuencies[this.frecuencies.findIndex(e => this.plan.frecuency.id === e.id)].id);
                            this.service.setValue(this.servicios[this.servicios.findIndex(e => this.plan.service.id === e.id)].id);
                            this.partnerS.setValue(this.partners[this.partners.findIndex(e => this.plan.service.partner.id === e.id)].id);
                            
                            if(this.plan.service.type != null && this.plan.service.type == 2){
								this.show = true;
								this.updatePlanForm.controls["title"].setValue(this.plan.title);
                                this.updatePlanForm.controls["position"].setValue(this.plan.position);
								this.updatePlanForm.controls["description"].setValue(this.plan.description);
								//this.updatePlanForm.controls["extended_description"].setValue(this.plan.extended_description);
								this.updatePlanForm.controls["validity_value"].setValue(this.plan.validity_value);
								//this.updatePlanForm.controls["label_inscription"].setValue(this.plan.label_inscription);
								//this.updatePlanForm.controls["label_frecuency"].setValue(this.plan.label_frecuency);
								//this.updatePlanForm.controls["label_payment"].setValue(this.plan.label_payment);
								this.updatePlanForm.controls["title_email"].setValue(this.plan.title_email);
								this.updatePlanForm.controls["message_email"].setValue(this.plan.message_email);
								this.period.setValue(this.periods[this.periods.findIndex(e => this.plan.validity_period === e.value)].value);
								this.charge.setValue(this.options[this.options.findIndex(e => this.plan.charge_inmediately === e.value )].value);
                            }
                        });
                    });
                });
            
            }, (err) => {
                
                this.notificationService.error(err);
            });
			
		}, err => this.notificationService.error(err));	
        
    }

	update() {
        let obj = <any> BaseService.builderObject(this.updatePlanForm.value)
        this.plan = obj;         
        this.plan.service = {id:this.service.value};
        this.plan.status_plan= this.status_plan.value;
        this.plan.frecuency = {id:this.frecuencyS.value};

        this.plan.feeCost_plan = this.updatePlanForm.value.feeCost_plan;
        this.plan.enrollmentCost_plan = this.updatePlanForm.value.enrollmentCost_plan;
        this.plan.freezeCostPlan = this.updatePlanForm.value.freezeCostPlan;
        this.plan.taxRate_plan = this.updatePlanForm.value.taxRate_plan;

        if(this.show == true){

			this.plan.validity_period = this.period.value;

			if(this.plan.title == null){
				return this.notificationService.error("Debe ingresar Título");
			}

			if(this.plan.description == null){
				return this.notificationService.error("Debe ingresar Descripción del plan");
			}
			/*if(this.plan.extended_description == null){
				return this.notificationService.error("Debe ingresar Descripción extendida del plan");
			}*/

			if(this.plan.position == null){
				return this.notificationService.error("Debe ingresar Posición");
			}
			if(this.plan.validity_period == null){
				return this.notificationService.error("Debe seleccionar un Periodo");
			}
			if(this.plan.validity_value == null){
				return this.notificationService.error("Debe ingresar un Valor para el periodo");
			}

			/*if(this.plan.label_inscription == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de inscripción");
			}

			if(this.plan.label_payment == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de pago");
			}

			if(this.plan.label_frecuency == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de frecuencia");
			}*/

			if(this.plan.title_email == null){
				return this.notificationService.error("Debe ingresar un titulo para el email");
			}

			if(this.plan.message_email == null){
				return this.notificationService.error("Debe ingresar un mensaje para el email");
			}
			
			this.plan.imageName = this.imageName;
			this.plan.imageType = this.imageType;
			this.plan.image64 = this.image64;

			//this.plan.imageDetailName = this.imageDetailName;
			//this.plan.imageDetailType = this.imageDetailType;
			//this.plan.imageDetail64 = this.imageDetail64;

			this.plan.charge_inmediately = this.charge.value;
            
        }

        this.planService.update(this.plan).subscribe(plan =>  {
                            this.notificationService.sucessUpdate(plan.name_plan);
                            this.location.back();
        }, err => this.notificationService.error(err)); 
    }
    
    // funcion que se ejecuta cuando se selecciona un partner
	onItemChange(partner:any){
        // cargamos las ciudades del pais que seleccione el usuario
       this.servicios = [];
       // cargamos los servicios que esten registrados
       if(partner!= undefined){
           this.servicioService.getByPartner(partner).subscribe(params => {
               this.servicios = params['result'];
           }, (err) => {
               this.notificationService.error(err);
           });
       }
   }
      
      checkInputValue(input) {
        input.value = input.value == '0' ? '0' : input.value;
      }

      //Metodo que permite que solo se introduzcan valores numericos
	onlyNumberKey(event){
		return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
	  }
	
	  onFileChange(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageName = file.name;
			this.imageType = file.type;
			this.image64 = reader.result.toString();
		  };
		}
	  }

	  onFileChangeDetail(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageDetailName = file.name;
			this.imageDetailType = file.type;
			this.imageDetail64 = reader.result.toString();
		  };
		}
	  }
}
