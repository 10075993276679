
	<h3 class="title">Editar usuario </h3>

	<form *ngIf="updateUserForm" [formGroup]="updateUserForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos del usuario</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_user">Nombre</label>
					<input formControlName="name_user" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateUserForm.controls['name_user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="lastName_user">Apellido</label>
          <input formControlName="lastName_user" type="text" autocomplete="off" placeholder="Ingrese Apellido" >
          <validator [control]="updateUserForm.controls['lastName_user']"></validator>
        </div>
       
      </div>


      <div class="field-row">
				
        <div class="field-row-item">
          <label class="label" for="email_user">Email</label>
          <input formControlName="email_user" type="text" autocomplete="off" placeholder="Ingrese Email" >
          <validator [control]="updateUserForm.controls['email_user']"></validator>
        </div>

        <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_user" disableRipple>
							<mat-option *ngFor="let s of status" [value]="s.value">{{s.description}}</mat-option>
						</mat-select>
						<validator [control]="status_user"></validator>
        </div>


      </div>
      
    

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los grupos del usuario</label>
          <mat-select  [formControl]="groupMultiCtrl" [multiple]="true" #multiSelect2>
            <ngx-mat-select-search [formControl]="groupMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredGroupsMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los roles del usuario</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="partner" >Elija las Compañias a las que podra acceder el  usuario</label>
          <mat-select  [formControl]="comMultiCtrl" [multiple]="true" #multiSelect3>
            <ngx-mat-select-search [formControl]="comMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredComMulti | async" [value]="bank">
              {{bank.name_partner}}
            </mat-option>
          </mat-select>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateUserForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
