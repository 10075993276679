import { Component, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';
import {FileService} from '../file.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import { ExportToCsv } from 'export-to-csv';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';

@Component({
  template: `
	<h3 class="title">Respuestas obtenidas para el archivo</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del proceso</span>
					<span>{{ name_processFile || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del archivo</span>
					<span>{{ name_file_processFile || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status_processFile || '-'}}</span>
				</div>
			</div>

		</div>
  </div>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button class="btn-icon" type="button" (click)="export()">
        <i class="material-icons">cloud_download</i>
      </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
	
			<ng-container matColumnDef="DescriptionPayment">
				<th mat-header-cell *matHeaderCellDef  >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element.DescriptionPayment}}</td>
			</ng-container>
			
			<ng-container matColumnDef="NameCustomer">
				<th mat-header-cell *matHeaderCellDef  >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.NameCustomer}}</td>
      </ng-container>
      
      <ng-container matColumnDef="CodeCustomer">
        <th mat-header-cell *matHeaderCellDef  >Codigo</th>
        <td mat-cell *matCellDef="let element">{{element.CodeCustomer}}</td>
      </ng-container>

      <ng-container matColumnDef="DateInvoice">
        <th mat-header-cell *matHeaderCellDef  >Fecha factura</th>
        <td mat-cell *matCellDef="let element">{{element.DateInvoice}}</td>
      </ng-container>

      <ng-container matColumnDef="DatePayment">
        <th mat-header-cell *matHeaderCellDef  >Fecha pago</th>
        <td mat-cell *matCellDef="let element">{{element.DatePayment}}</td>
      </ng-container>

      <ng-container matColumnDef="TotalInvoice">
        <th mat-header-cell *matHeaderCellDef  >Total</th>
        <td mat-cell *matCellDef="let element">{{element.TotalInvoice}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class ResponseFileComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'DescriptionPayment', 'NameCustomer', 'CodeCustomer', 'DateInvoice','DatePayment', 'TotalInvoice'];

  name_plan;
  name_processFile;
  name_file_processFile;
  status_processFile;
  process;
  items: any[] = [];
  id_file;
  staticSuccess_data: any[] = [];
  staticFail_data: any[] = [];
  success_data: any[] = [];
  fail_data: any[] = [];
  staticLine_data: any[] = [];
  toExport : any[] = [];



  constructor(
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) { 
    this.staticSuccess_data = [{'DatePayment':'Transacciones Exitosas', 'Referencia':'','CodeCustomer':'','Transaccion':'','Tarjeta':'','TotalInvoice':'','DescriptionPayment':''},
    {'DatePayment':'======================','Referencia':'','CodeCustomer':'','Transaccion':'','Tarjeta':'','TotalInvoice':'','DescriptionPayment':''},
    {'DatePayment':'Fecha y Hora','Referencia':'Referencia','CodeCustomer':'Documento','Transaccion':'Transacción','Tarjeta':'Tarjeta','TotalInvoice':'Monto','DescriptionPayment':'Detalle del rechazo'}];
    this.staticFail_data = [{'DatePayment':'Transacciones Fallidas', 'Referencia':'','CodeCustomer':'','Transaccion':'','Tarjeta':'','TotalInvoice':'','DescriptionPayment':''},
    {'DatePayment':'======================','Referencia':'','CodeCustomer':'','Transaccion':'','Tarjeta':'','TotalInvoice':'','DescriptionPayment':''},
    {'DatePayment':'Fecha y Hora','Referencia':'Referencia','CodeCustomer':'Documento','Transaccion':'Transacción','Tarjeta':'Tarjeta','TotalInvoice':'Monto','DescriptionPayment':'Detalle del rechazo'}];
    this.staticLine_data = [{'DatePayment':'', 'Referencia':'','CodeCustomer':'','Transaccion':'','Tarjeta':'','TotalInvoice':'','DescriptionPayment':''}];
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.id_file = param['fileId'];
      this.fileService.getById(param['fileId']).subscribe(file => {
        this.process = file.name_processFile;
        this.name_plan = file.plan.name_plan;
        this.name_processFile = file.name_processFile;
        this.name_file_processFile = file.nameFile_processFile;
        this.estatus_change(file);
        this.list();
      });
    });
  }

  estatus_change( file: any) {
      if (file.status_processFile === 0) {
        this.status_processFile = 'Procesando';
      }
      if (file.status_processFile === 1) {
        this.status_processFile = 'Por procesar';
      }
      if (file.status_processFile === 2) {
        this.status_processFile = 'Procesado Exitosamente';
      }
      if (file.status_processFile === 3) {
        this.status_processFile = 'Erroneo, no cargado el archivo';
      }
      if (file.status_processFile === 4) {
        this.status_processFile = 'Erroneo, cobro no procesado';
      }
  }

  list(event?: PageEvent) {

    let httpParams = this.fileService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		
    this.fileService.getResponses(httpParams, this.id_file).subscribe(params => {
      this.items = params['result']; // items que mostrara la tabla
      //this.dates();
      this.dataSource = new MatTableDataSource<any>(this.items);
      this.tableService.pager = params['pager'];
      this.tableService.selection.clear();
      if (this.items.length === 0) {
        this.notificationService.alert('No se encontraron resultados para la busqueda');
      }
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  dates() {
    for (const pay of this.items) {
      pay.DateInvoice = this.datePipe.transform(pay.DateInvoice, 'MM/dd/yy - HH:mm:ss');
      pay.DatePayment = this.datePipe.transform(pay.DatePayment, 'MM/dd/yy - HH:mm:ss');
    }
  }

  export() {
    let data = [];
    if(this.items.length > 0){
      const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        useTextFile: false,
        filename: this.process+date,
        useBom:false
      };
      const csvExporter = new ExportToCsv(options);

      let httpParams = this.fileService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	

      this.fileService.getResponses(httpParams, this.id_file).subscribe(params => {
        data = params['result'];
        // eliminamos lo que no nos interesa que se muestre en el documento
        for(const pay of data){
          delete pay.DateInvoice;
          delete pay.NameCustomer;
          if(pay.Resultado == 1){
            delete pay.Resultado;
            this.success_data.push(pay);
          }else{
            delete pay.Resultado;
            this.fail_data.push(pay)
          }
        }
        this.toExport = this.staticSuccess_data.concat(this.success_data.concat(this.staticLine_data.concat(this.staticFail_data.concat(this.fail_data))));
        csvExporter.generateCsv(this.toExport);
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });

      
    }else{
      this.notificationService.error("No existen registros para exportar");
    }
    
  }

}
