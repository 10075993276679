import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';
import { Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-invoice-to-charge',
  templateUrl: './invoice-to-charge.component.html',
  styleUrls: ['./invoice-to-charge.component.css']
})
export class InvoiceToChargeComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'identifier_customer', 'name_customer', 'last_name_customer', 'Contribuyente', 'code_customer', 'name_plan','centro', 'next_bill_date', 'total_invoice', 'status_invoice'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
  filter = new ReportFilter(this.tableService.filter);
  
  items: any[] = [];
  billings: any[]= [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}

  ngOnInit(): void {
    this.reportService.getBillingSettins().subscribe(response =>{
      this.billings = response['result'];
    });
  }

  list(event?: PageEvent) {

   
   
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}

    if(this.filter.id_billing == null){
        this.notificationService.error("Debe seleccionar una renovacion");
        return;
    }
    if(this.filter.date == null){
        this.notificationService.error("Debe ingresar una fecha de busqueda");
        return;
    }
  
    this.filter.date = this.datePipe.transform(this.filter.date, 'yyyy-MM-dd');
	
  

    this.tableService.filter = new ReportFilter(this.filter);
     
      
    let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		httpParams = this.filter.getHttpParams(httpParams);


      this.reportService.getInvoicesTOcharge(httpParams).subscribe(params => {
        this.items = params['result']; // items que mostrara la tabla
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = params['pager'];
        this.tableService.selection.clear();
        if (this.items.length === 0) {
          this.notificationService.alert('No se encontraron resultados para la busqueda');
        } 
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });

  }

  reset() {
    this.filter = new ReportFilter();
    this.dataSource = new MatTableDataSource<any>([]);
    this.tableService.filter = new ReportFilter(this.filter);
  }

  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new ReportFilter(this.filter);
    this.list();
  }

  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new ReportFilter(this.tableService.filter);
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

export() {
  if(this.items.length > 0){
      
      this.tableService.filter = new ReportFilter(this.filter);


      let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	
      httpParams = this.filter.getHttpParams(httpParams);
      
      this.reportService.getInvoicesTOcharge(httpParams).subscribe(params => {
        let data = [];
         for(var item of params['result']){
          data.push({'CENTRO':item.sucursal,'NOMBRE':item.name_customer,'APELLIDO':item.last_name_customer,'DNI':item.identifier_customer,'CODIDO': item.code_customer,'PLAN':item.name_plan,'ESTATUS':item.status_invoice,'CONTRIBUYENTE':item.Contribuyente,'TOTAL':item.total_invoice,'FECHA-DE-COBRO':item.next_bill_date})
         }
      
        const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          useTextFile: false,
          filename: "Report-Invoices-to-charge-"+date,
          useBom:false,
          useKeysAsHeaders: true,
          showTitle: true,
          title: 'Facturas por cobrar - Fecha:' + date,
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
      
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      }); 

    }else{
      this.notificationService.error("No existen registros para exportar");
    }
    
  }


}
