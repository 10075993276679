import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from '../job.service';
import { NotificationService} from '../../shared/notification/notification.service';

@Component({
  template: `
	<h3 class="title">Detalle</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ job?.fullName || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Descripción</span>
					<span>{{ job?.description || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Ultima ejecucion</span>
					<span>{{ job?.last_executed || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Proxima ejecucion</span>
					<span>{{ job?.next_execution || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Milisegundos</span>
					<span>{{ job?.millisSeparation || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ job?.status || '-'}}</span>
				</div>
      </div>
      
		</div>
	</div>
`
})
export class ReadJobComponent implements OnInit {

  job:any;

  constructor(
    private router: Router,
		private activatedRoute: ActivatedRoute,
		private jobService: JobService,
		private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params =>{
      this.jobService.getById(params['jobId']).subscribe(job =>{
        if (job.status === 0) {
          job.status = 'Inactivo';
        }
        if (job.status === 1) {
           job.status = 'Arrancando';
        }
        if (job.status === 2) {
          job.status = 'Corriendo';
        }
        this.job = job;
      },(err =>{
        this.notificationService.error(err);
      }));
    });
  }

  update(){
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

}
