
	<h3 class="title">Editar Grupo</h3>

	<form *ngIf="updateGroupForm" [formGroup]="updateGroupForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos del grupo</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateGroupForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="updateGroupForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="rol" >Elija el rol al que pertenecera el grupo</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.description}}
            </mat-option>
          </mat-select>
				</div>
      </div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateGroupForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
