
	<h3 class="title">Crear Servicio</h3>

	<form *ngIf="createServiceForm" [formGroup]="createServiceForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name_service">Nombre</label>
					<input formControlName="name_service" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createServiceForm.controls['name_service']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="country" >Compañía</label> 
					<mat-select [formControl]="partner"  disableRipple>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
					<validator [control]="partner"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

				<div class="field-row-item">
						<label class="label" for="type" >Tipo</label>
						<mat-select [formControl]="createServiceForm.controls['type']" (selectionChange)="onTypeChange($event.value)"  disableRipple>
							<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
						</mat-select>
						<validator [control]="createServiceForm.controls['type']"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createServiceForm.controls['status_service']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createServiceForm.controls['status_service']"></validator>
				</div>

			</div>	


			<div *ngIf="show">
				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="title">Titulo</label>
							<input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Titulo" >
							<validator [control]="createServiceForm.controls['title']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="url_ecommerce">URL Ecommerce</label>
							<input formControlName="url_ecommerce" type="text" autocomplete="off" placeholder="Ingrese URL Ecommerce" >
							<validator [control]="createServiceForm.controls['url_ecommerce']"></validator>
						</div>

				</div>	

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="url_logo">Banner del servicio</label>
							<input id="file-upload" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChange($event)" required>
			
						</div>

						<div class="field-row-item">
							<label class="label" for="url_logo">Imagen del último artículo enviado </label>
							<input id="file-upload-2" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChangeLastSended($event)" required>
			
						</div>

					<!--	<div class="field-row-item">
							<label style="width:100%;" class="label" for="url_ecommerce">Color del tema</label>
							<color-circle [colors]="colors" color="#f44336" (onChange)="handleChange($event)" ></color-circle>
						</div> -->

				</div>
				
				

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="email_service">Email del comercio</label>
							<input formControlName="email_service" type="text" autocomplete="off" placeholder="Ingrese email" >
							<validator [control]="createServiceForm.controls['email_service']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="password_email">Password email</label>
							<input formControlName="password_email" type="text" autocomplete="off" placeholder="Ingrese password" >
							<validator [control]="createServiceForm.controls['password_email']"></validator>
						</div>

				</div>	


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>

						<textarea formControlName="description"></textarea>
						<!-- <editor  [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="desc_of_last_sended" >Descripción de último artículo enviado</label>

						<textarea formControlName="desc_of_last_sended"></textarea>
						
					</div>
				</div>


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="terms" >Términos</label>
						<textarea formControlName="terms"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print"  formControlName="terms" >
						  </editor> -->
					</div>
				</div>
				


			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createServiceForm.valid || !partner.valid  ">
				<span>Guardar</span>
			</button>
		</div>
	</form>
