
    <div *ngIf="show">
    
        <h3 class="title">Procesamiento de Facturas</h3>

        <mat-accordion>
            <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                <mat-expansion-panel-header>
                    <button class="btn-icon"  title="Buscar" type="button">
                        <i class="material-icons">timer</i>
                    </button>
                </mat-expansion-panel-header>

                <form>
                    <fieldset class="fieldset">
                        <mat-tab-group>
                            <mat-tab label="Actualizar cada">  
                                <div class="field-row">
                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(1)">1 Minuto</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(2)">5 Minutos</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(3)">10 Minutos</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(4)">30 Minutos</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(5)">1 Hora</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(6)">2 Horas</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                </div>
                            </mat-tab>
                            <mat-tab label="Periodo"> 
                                <div class="field-row">
                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(1)">Hoy</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(2)">Ayer</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(3)">Esta semana</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(4)">Semana pasada</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(5)">Este mes</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(6)">Mes pasado</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(7)">Este año</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(8)">Año pasado</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </fieldset>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
        
        <div ><canvas #lineChart>{{ chart }}</canvas></div>
    
        </div>

        

      