import { HttpParams } from '@angular/common/http';

export class ReportFilter {

    id: number;
    id_partner: number;
    id_service: number;
    id_plan: number;
    since_date: string;
    date: string;
    until_date: string;
    del: number;
    company: number;
    parameter: string;
    id_invoice: number;
    id_billing: number;
    id_rebilling: number;
    status: number;
    dni : string;
    label:string;
    
	constructor(filter?: ReportFilter) {
		if (filter) {
            this.id = filter.id;
            this.id_partner = filter.id_partner;
            this.id_service = filter.id_service;
            this.id_plan = filter.id_plan;
            this.since_date = filter.since_date;
            this.until_date = filter.until_date;
            this.del = filter.del;
            this.company = filter.company;
            this.parameter = filter.parameter;
            this.id_invoice = filter.id_invoice;
            this.id_billing = filter.id_billing;
            this.id_rebilling = filter.id_rebilling;
            this.status = filter.status;
            this.dni = filter.dni;
            this.label = filter.label;
            this.date = filter.date;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
        if (this.dni) {
			httpParams = httpParams.set('identifier_customer', this.dni);
        }
        if (this.status) {
			httpParams = httpParams.set('status', this.status.toString());
        }
        if (this.id_partner) {
			httpParams = httpParams.set('id_partner', this.id_partner.toString());
        }
        if (this.id_billing) {
			httpParams = httpParams.set('id_billing', this.id_billing.toString());
        }
        if (this.id_rebilling) {
			httpParams = httpParams.set('id_rebilling', this.id_rebilling.toString());
        }
        if (this.id_invoice) {
			httpParams = httpParams.set('id_invoice', this.id_invoice.toString());
        }
		if (this.id_service) {
			httpParams = httpParams.set('id_service', this.id_service.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
        }
        if (this.del) {
			httpParams = httpParams.set('del', this.del.toString());
		}
		if (this.id_plan) {
			httpParams = httpParams.set('id_plan', this.id_plan.toString());
        }
        if (this.until_date) {
			httpParams = httpParams.set('until_date', this.until_date);
        }
        if (this.since_date) {
			httpParams = httpParams.set('since_date', this.since_date);
        }
        if (this.date) {
			httpParams = httpParams.set('date', this.date);
        }
        if (this.company) {
			httpParams = httpParams.set('company', this.company.toString());
        }
        if (this.parameter) {
			httpParams = httpParams.set('parameter', this.parameter);
        }
        if (this.label) {
			httpParams = httpParams.set('label', this.label);
        }
		return httpParams;
	}
}