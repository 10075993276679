import { Component, OnInit } from '@angular/core';
import { FilterService } from '../shared/filter-ns/filter-ns.service';

@Component({
  selector: 'app-customer-has-plans',
  template: '<router-outlet></router-outlet>',
  providers: [
  		FilterService
  	]
})
export class CustomerHasPlansComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
