import { Component , ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseService } from '../shared/services/base.service';
import { Servicio } from './servicio';
import { ServicioService } from './servicio.service';
import { NotificationService} from '../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { PartnerService } from '../partner/partner.service';
import { ColorEvent } from 'ngx-color';

@Component({
    styleUrls: ['./servicio.component.scss'],
	encapsulation: ViewEncapsulation.None,
    template: `
	<h3 class="title">Editar Servicio</h3>

	<form *ngIf="updateServiceForm" [formGroup]="updateServiceForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" > 
            </div>  

			<div class="field-row">
				
                 <div class="field-row-item">
                    <label class="label" for="name_service">Nombre</label>
                    <input formControlName="name_service" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
                    <validator [control]="updateServiceForm.controls['name_service']"></validator>
                </div>
                
                <div class="field-row-item">
                    <label class="label" for="country" >Compañía</label> 
                    <mat-select [formControl]="partnerS"  disableRipple>
                            <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
                    </mat-select>
                    <validator [control]="partnerS"></validator> 
                </div>

            </div>
            
            
            <div class="field-row">

				<div class="field-row-item">
						<label class="label" for="type" >Tipo</label>
						<mat-select [formControl]="type" (selectionChange)="onTypeChange($event.value)"  disableRipple>
							<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
						</mat-select>
						<validator [control]="type"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_service" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="status_service"></validator>
                </div>

            </div>
            
            <div *ngIf="show">

                <div class="field-row">

                    <div class="field-row-item">
                        <label class="label" for="title">Titulo</label>
                        <input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Titulo" >
                        <validator [control]="updateServiceForm.controls['title']"></validator>
                    </div>

                    <div class="field-row-item">
                        <label class="label" for="url_ecommerce">URL Ecommerce</label>
                        <input formControlName="url_ecommerce" type="text" autocomplete="off" placeholder="Ingrese URL Ecommerce" >
                        <validator [control]="updateServiceForm.controls['url_ecommerce']"></validator>
                    </div>

                </div>


                <div class="field-row">

            
                    <div class="field-row-item">
                        <label class="label" for="url_logo">Banner del servicio</label>
                        <input id="file-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChange($event)" >
                    </div>

                    <div class="field-row-item">
							<label class="label" for="url_logo">Imagen del último artículo enviado </label>
							<input id="file-upload-2" type="file" style="display:block; "accept="image/png,image/*, image/jpeg, application/svg+xml" (change)="onFileChangeLastSended($event)" required>
			
						</div>

                   <!--  <div class="field-row-item">
						<label style="width:100%;" class="label" for="url_ecommerce">Color del tema</label>
						<color-circle [colors]="colors" [color]="colorSelected" (onChange)="handleChange($event)" ></color-circle>
					</div> -->

            

                </div>


                <div class="field-row">

						<div class="field-row-item">
							<label class="label" for="email_service">Email del comercio</label>
							<input formControlName="email_service" type="text" autocomplete="off" placeholder="Ingrese email" >
							<validator [control]="updateServiceForm.controls['email_service']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="password_email">Password email</label>
							<button *ngIf="change" (click) ="onClick();changeButton.disabled = true"  #changeButton class="btn-text">
                                <span>Cambiar</span>
                            </button>

                            <input *ngIf="change==false" formControlName="new_password" type="text" autocomplete="off" placeholder="Ingrese nuevo password" >

                            
						</div>

                </div>	
                
                <div class="field-row">
					<div class="field-row-item">
                        <label class="label" for="terms" >Descripción</label>
                        
                        <textarea formControlName="description"></textarea>

						<validator [control]="updateServiceForm.controls['description']"></validator>
					</div>
                </div>
                
                <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="desc_of_last_sended" >Descripción de último artículo enviado</label>

						<textarea formControlName="desc_of_last_sended"></textarea>
						
					</div>
				</div>


                <div class="field-row">
					<div class="field-row-item">
                        <label class="label" for="terms" >Términos</label>
                        <textarea formControlName="terms"></textarea>
                       
						<validator [control]="updateServiceForm.controls['terms']"></validator>
					</div>
				</div>
                
                
                
		    </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateServiceForm.valid || !type.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class ServicioUpdateComponent{
    
    imageName: any;
	image64: any;
    imageType: any;
    imageNameOfLastSended: any;
	image64OfLastSended: any;
	imageTypeOfLastSended: any;
	servicio: any;
    partners = [];
    parents = false;
    change = true;
    show = false;
    estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
    types : any[] = [{value:1,description:"Tradicional"},{value:2,description:'Ecommerce'}];
    status_service: FormControl = new FormControl('', Validators.required);
    partnerS: FormControl = new FormControl('', Validators.required);
    type: FormControl = new FormControl('', Validators.required);
    color = null;
    colorSelected = null;
    colors = [ "#689f38", "#d32f2f","#1976d2","#c2185b","#455a64","#7b1fa2"];

    updateServiceForm = this.fb.group({
        name_service: ['', [Validators.required]],
        id: ['', [Validators.required]],
        title:[''],
		url_ecommerce:[''],
		terms:[''],
        description:[''],
        desc_of_last_sended:[''],
        email_service:[''],
        new_password:['']
    });

	constructor(
        public fb: FormBuilder,
		    private router: Router,
		    private activatedRoute: ActivatedRoute,
		    private location: Location,
		    private servicioService: ServicioService,
            private partnerService: PartnerService,
		    private notificationService: NotificationService
	){}

	ngOnInit(){
        if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
        this.parents = true;
        }
        this.cargarPantalla(); // cargamos los combos de la vista
    }


    handleChange($event: ColorEvent) {
		
		if($event.color.hex == "#689f38"){
			this.color = "green";
		}else if($event.color.hex == "#d32f2f"){
			this.color = "red";
		}else if($event.color.hex == "#1976d2"){
			this.color = "blue";
		}else if($event.color.hex == "#c2185b"){
			this.color = "pink";
		}else if($event.color.hex == "#455a64"){
			this.color = "gray";
		}else{
			this.color = "purple";
		}

	  }

    cargarPantalla(){
        this.activatedRoute.parent
			.params
			.subscribe(param => {
                let httpParams = this.partnerService.buildRequestParams();
                httpParams = httpParams.set('parents',this.parents.toString());
                httpParams = httpParams.set('parent',(-1).toString());
                /// cargamos los partner activos
                this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
                    this.partners = params['result'];
                    // cargamos los estatus para partner que esten registrados
                    //this.statuses = params;
                    // cargamos la informacion del servicio del partner
                    this.servicioService.getById(param['serviceID']).subscribe(servicio => {
                    this.servicio = servicio;
                    this.status_service.setValue(this.estatus[this.estatus.findIndex(e => this.servicio.status_service === e.value)].value);
                    this.partnerS.setValue(this.partners[this.partners.findIndex(e => this.servicio.partner.id === e.id)].id);
                    if(this.servicio.type == null){
                        this.type.setValue(this.types[0].value)
                    }else{
                        if(this.servicio.type == 2){
                            this.show = true;
                            this.updateServiceForm.controls["title"].setValue(this.servicio.title);
                            this.updateServiceForm.controls["url_ecommerce"].setValue(this.servicio.url_ecommerce);
                            this.updateServiceForm.controls["terms"].setValue(this.servicio.terms);
                            this.updateServiceForm.controls["description"].setValue(this.servicio.description);
                            this.updateServiceForm.controls["desc_of_last_sended"].setValue(this.servicio.desc_of_last_sended);
                            this.updateServiceForm.controls["email_service"].setValue(this.servicio.email_service);
                            
                            /*if(this.servicio.color == "green"){
                                this.colorSelected = "#689f38";
                            }else if(this.servicio.color == "red"){
                                this.colorSelected = "#d32f2f";
                            }else if(this.servicio.color == "blue"){
                                this.colorSelected = "#1976d2";
                            }else if(this.servicio.color == "pink"){
                                this.colorSelected = "#c2185b";
                            }else if(this.servicio.color == "gray"){
                                this.colorSelected = "#455a64";
                            }else{
                                this.colorSelected = "#7b1fa2";
                            }*/

                        }
                        this.type.setValue(this.types[this.types.findIndex(e => this.servicio.type === e.value)].value);
                    }
                    this.updateServiceForm.controls["name_service"].setValue(this.servicio.name_service);
                    this.updateServiceForm.controls["id"].setValue(this.servicio.id);
                    });
                }, (err) => { this.notificationService.error(err); });
		}, err => this.notificationService.error(err));	
    }

	update() {

        let obj = <Servicio> BaseService.builderObject(this.updateServiceForm.value);
        this.servicio = obj;
        this.servicio.status_service = this.status_service.value;
        this.servicio.partner = {id:this.partnerS.value};
        this.servicio.type = this.type.value;
        // En caso de que sea ECOMMERCE
		if(this.servicio.type == 2){
			if(this.servicio.title == null){
				return this.notificationService.error("Debe ingresar un Titulo");
            }
            
            if(this.servicio.description == null){
				return this.notificationService.error("Debe ingresar una Descripción");
			}
			
			if(this.servicio.url_ecommerce == null){
				return this.notificationService.error("Debe ingresar una URL para Ecommerce")
			}
			if(this.servicio.terms == null){
				return this.notificationService.error("Debe ingresar Terminos")
            }

            if(this.servicio.desc_of_last_sended == null){
				return this.notificationService.error("Debe ingresar una Descripción para el artículo");
			}

            /*if(this.color == null){
				return this.notificationService.error("Debe seleccionar un color para el tema del servicio")
			}*/

            if(this.servicio.email_service == null){
				return this.notificationService.error("Debe ingresar Email")
			}
            
			this.servicio.imageName = this.imageName;
			this.servicio.imageType = this.imageType;
            this.servicio.image64 = this.image64;
            this.servicio.color = this.color;

            this.servicio.imageNameOfLastSended = this.imageNameOfLastSended;
			this.servicio.imageTypeOfLastSended = this.imageTypeOfLastSended
			this.servicio.image64OfLastSended = this.image64OfLastSended;
        }

       this.servicioService.getByName(this.servicio.name_service).subscribe(servicio => {

        let servicios = servicio["result"];
        let igual = false;
        // comprobamos si uno de los servicios con el mismo nombre registrado se encuentra adjudicado al partner seleccionado
        for(var i = 0; i< Object.keys(servicios).length; i++){
            if(servicios[i]["partner"]==this.servicio.partner  && servicios[i]["status_service"] == this.servicio.status_service ){
                igual = true;
            }
        }
        /// si se encuentra un servicio con el mismo nombre para el partner seleccionado
        if(igual){
            this.notificationService.error("Sin cambios, o posee un Servicio registrado con el mismo nombre");
        }else{ /// no se encuentra
            this.servicioService.update(this.servicio).subscribe(servicio =>  {
                    this.notificationService.sucessUpdate(servicio.name_service);
                    this.location.back();
                }, err => this.notificationService.error(err.error.message));
            }
        }, (err) => { // name service no existe
                this.notificationService.error(err);
        }); 
    }

    compareFn(c1: any, c2: any): boolean { 
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
      }

      onTypeChange(value:any) {
		if(value == 2){
			this.show = true;
		}else{
			this.show = false;
		}
    }
    
    onFileChange(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageName = file.name;
			this.imageType = file.type;
			this.image64 = reader.result.toString();
		  };
		}
      }
      
      onClick(){
          this.change = false;
      }

      onFileChangeLastSended(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageNameOfLastSended = file.name;
			this.imageTypeOfLastSended = file.type;
			this.image64OfLastSended = reader.result.toString();
		  };
		}
	}

}
