
	<h3 class="title">Crear Usuario</h3>

	<form *ngIf="createUserForm" [formGroup]="createUserForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del usuario</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_user">Nombre</label>
					<input formControlName="name_user" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createUserForm.controls['name_user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="lastName_user">Apellido</label>
          <input formControlName="lastName_user" type="text" autocomplete="off" placeholder="Ingrese Apellido" >
          <validator [control]="createUserForm.controls['lastName_user']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="email_user">Email</label>
          <input formControlName="email_user" type="text" autocomplete="off" placeholder="Ingrese Email" >
          <validator [control]="createUserForm.controls['email_user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="password">Password</label>
          <input formControlName="password" type="text" autocomplete="off" placeholder="Ingrese Password" >
          <validator [control]="createUserForm.controls['password']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los grupos del usuario</label>
          <mat-select  [formControl]="groupMultiCtrl" [multiple]="true" #multiSelect2>
            <ngx-mat-select-search [formControl]="groupMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredGroupsMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los roles del usuario</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-row-item">
          <label class="label" for="partner" >Elija las Compañias a las que podra acceder el  usuario</label>
          <mat-select  [formControl]="comMultiCtrl" [multiple]="true" #multiSelect3>
            <ngx-mat-select-search [formControl]="comMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredComMulti | async" [value]="bank.id">
              {{bank.name_partner}}
            </mat-option>
          </mat-select>
        </div>
      </div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createUserForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
