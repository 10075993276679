import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../../shared/form/custom.validators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserService } from '../../../user/user.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { AuthorizationRequest } from '../oauth2/authorizationRequest';


@Component({
	selector: 'login',
	template: `
		<ng-progress></ng-progress>
		<header>
			<img id="logo" src="./assets/images/logo-blanco.png" routerLink="/login"/>
		</header>
		<div class="wrap">
		<div i18n="@@signin">
			<div class="dialog-title">Inicia sesión</div>
		</div>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="login()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input type="text" formControlName="email" class="form-control">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Contraseña</label>
						<input type="password" formControlName="password" class="form-control">
						<validator [control]="form.controls['password']"></validator>
						<div class="clickable-item" style="text-align: right" routerLink="/recoverPass" >Olvidaste tu contraseña?</div>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@login">Acceder</span>
				</button>
			</div>
		</form>
		</div>
	`,
	styleUrls: ['./login.component.css'],
	providers: [UserService]
})
/**
 *  TODO - Este es el HTML anterior, se guarda para despues de actualizar todo, retomar el olvido la contrase*a
 
<div class="container">	
			<img src="/assets/images/logo.png" alt="logo"/>	
			<form [formGroup]="loginForm" (ngSubmit)="login($event)" #f="ngForm" novalidate>		
				<div class="form-group">
					<label for="email">Email</label>
					<input type="text" formControlName="email" class="form-control">
					<div *ngIf="f.submitted && !loginForm.controls['email'].valid" class="help-block">Email is required</div>
				</div>
				<div class="form-group">
					<label for="password">Password</label>
					<input type="password" formControlName="password" class="form-control">
					<div *ngIf="f.submitted && !loginForm.controls['password'].valid" class="help-block">Password is required</div>
				</div>
				<div class="form-group">
				
					<a (click)="showDialog()">Forgot password</a>						
				</div>
			</form>
		</div>
		<forgot-password *ngIf="opened" (opened)="onOpen($event)"></forgot-password>*/
export class LoginComponent implements OnInit {

	opened: boolean;
	form: FormGroup;

	constructor(
		private authService: AuthService,
		private userService: UserService,
		private router: Router,
		private fb: FormBuilder,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.opened = false;
		this.form = this.toFormGroup();
	}

	login(): void {
		if (this.isValid('email') && this.isValid('password')) {

			const authorizationRequest: AuthorizationRequest = new AuthorizationRequest();
			authorizationRequest.grant_type = 'password';
			authorizationRequest.username = this.getValue('email');
			authorizationRequest.password = this.getValue('password');
			authorizationRequest.client_id = 'web_site';

			this.authService.login(authorizationRequest).subscribe(authorizationResponse => {
				
				sessionStorage.setItem('token', authorizationResponse.access_token);
				this.userService.getById(authorizationResponse.user_id).subscribe(user => {
					if(user.status_user == 1){ // SOLO LO DEJAMOS ENTRAR SI ESTA ACTIVO
					/* ToDO manage user data and Auth header */
					// Store data and jwt in local store to keep user logged
					localStorage.setItem('currentData', JSON.stringify({user: user, token: authorizationResponse.access_token}));
					sessionStorage.setItem('user', user.id)
					this.notificationService.sucessLogin();
					window.location.href = window.location.href + '../home' ;	
					}else{
						this.notificationService.error("Lo sentimos, su usuario se encuentra inactivo");
					}
					
				}, (err) =>{
					this.notificationService.error(err)
					console.log(err);
				}); 
			}, (err) => {
				console.log(err);
				this.notificationService.error(err);
			});
		}
	}

	showDialog() {
		this.opened = true;
	}

	onOpen(isOpened: boolean) {
		this.opened = isOpened;
	}

	isValid(parameter: string) {
		return !this.form.get(parameter).invalid;
	}

	getValue(parameter: string) {
		return this.form.get(parameter).value;
	}

	private toFormGroup(): FormGroup {
		return this.fb.group({
			email: new FormControl('', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]),
			password: new FormControl('', Validators.required)
		});
	}

}
