import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import { Observable } from 'rxjs';
import { CountryService } from '../country/country.service';
import { CityService } from '../city/city.service';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

export interface Filter {
    name_partner?: string;
}


@Injectable({
  providedIn : 'root'
})
export class PartnerService extends BaseService {

    private readonly BASE_URL: string = this.HOST + '/partner';
    
    httpOptions : any;

    constructor(
        private http: HttpClient,
        private countryService: CountryService,
        private cityService: CityService
    ){
        super();
        // Http Headers
        this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
    }

    getConfigUrl(key: string): Observable<any> {
      return this.http.get(this.HOST + '/config/key/' + key,this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
    }

    getAll(): Observable<any> {
        return this.http.get(this.BASE_URL + "/full/search", this.httpOptions)
        .pipe(catchError(this.handleError));
    }

    getAll2(): Observable<any> {
        return this.http.get(this.BASE_URL, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getAllPartners(): Observable<any> {
        return this.http.get(this.BASE_URL + "/full/search", this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getPartnersByUserId(params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
      return this.http.get(this.BASE_URL + '/user', this.httpOptions)
          .pipe(catchError(this.handleError));
      
    }


    getCities(id:number): Observable<any> {
        return this.cityService.getByCountry(id);
    }

   getByEmail(email: string): Observable<any> {
        return this.http.get(this.BASE_URL + '/email/' + email,this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    getByName(name:string): Observable<any>{
        return this.http.get(this.BASE_URL+ '/name/' + name,this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

   create(partner: any): Observable<any> {
        return this.http.post(this.BASE_URL, {'partner': partner}, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

   getById(id: number): Observable<any> {
        return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    delete(id: number): Observable<any> {
        return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    edit(id: number): Observable<any> {
        return this.http.get(this.BASE_URL + '/' + id + '/edit',this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    update(partner: any): Observable<any> {
        return this.http.put(this.BASE_URL + '/' + partner.id, {"partner":partner} , this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    deletes(array: any[]): Observable<any> {
        return this.http.post(this.BASE_URL + '/delete', {partners: array} ,this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    findCity(id:number): Observable<any>{
        return this.cityService.getCity(id);
    }

    findCountry(id:number): Observable<any>{
        return this.countryService.getByID(id);
    }

    getPartnersByParent(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/parent/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    }

}
