
	<h3 class="title">Crear plan a cliente</h3>

	<form *ngIf="createCustomerHasPlansForm" [formGroup]="createCustomerHasPlansForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_customer">Nombre</label>
					<input  type="text" autocomplete="off" value="{{ customer.name_customer || '-' }} {{customer.lastName_customer || '-' }}" readonly>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="service" >Servicio</label>
					
          <mat-select [formControl]="service"  (selectionChange)="onChangeService($event.value)" disableRipple>
            <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
          <validator [control]="service"></validator>

				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
				
          <mat-select [formControl]="plan" disableRipple>
            <mat-option *ngFor="let plan of planes" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>
          <validator [control]="plan"></validator>

				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="contractNumber_Plan">Número de contrato</label>
					<input formControlName="contractNumber_Plan" type="text"  autocomplete="off" placeholder="Ingrese el número de contrato" >
					<validator [control]="createCustomerHasPlansForm.controls['contractNumber_Plan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="idPlanEnrollmentrenewalPartner">Código de renovación de matricula del partner</label>
					<input formControlName="idPlanEnrollmentrenewalPartner" type="text" autocomplete="off" placeholder="Ingrese el código de renovación de matricula del partner" >
					<validator [control]="createCustomerHasPlansForm.controls['idPlanEnrollmentrenewalPartner']"></validator>
				</div>
			</div>

      <div class="field-row">
      
        <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha de inscripción del plan(DD/MM/YYYY)" formControlName="startDate_customerPlan"  readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <validator [control]="createCustomerHasPlansForm.controls['startDate_customerPlan']"></validator>
        </div>




				<div class="field-row-item">

          <input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Ingrese fecha de culminación del plan(DD/MM/YYYY)" formControlName="endDate_customerPlan"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker2></mat-datepicker>
          <validator [control]="createCustomerHasPlansForm.controls['endDate_customerPlan']"></validator>
          
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" for="tdc">Tarjeta de crédito asociada</label>
          
          <mat-select [formControl]="tdc"  disableRipple>
            <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.id">{{tarjeta.maskCreditCard}} - {{tarjeta.nameHolderCreditCard}}</mat-option>
          </mat-select>
          <validator [control]="tdc"></validator>

        </div>
        
        <div class="field-row-item">
          <label class="label" for="status" >Estatus</label>
          <mat-select [formControl]="createCustomerHasPlansForm.controls['status_customerPlan']"  disableRipple>
            <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
          </mat-select>
          <validator [control]="createCustomerHasPlansForm.controls['status_customerPlan']"></validator>
        </div>

        <div class="field-row-item">
          <label class="label" for="next" >Calcular fecha de cobro</label>
          <mat-select [formControl]="createCustomerHasPlansForm.controls['nextBill']"  disableRipple>
            <mat-option *ngFor="let n of next" [value]="n.value">{{n.description}}</mat-option>
          </mat-select>
          <validator [control]="createCustomerHasPlansForm.controls['nextBill']"></validator>
        </div>


			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createCustomerHasPlansForm.valid || !tdc.valid || !plan.valid || !service.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
