
	<h3 class="title">Crear Cupon</h3>

	<form  [formGroup]="createCuponForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del Cupon</legend>
			
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_cupon">Nombre</label>
					<input formControlName="name_cupon" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createCuponForm.controls['name_cupon']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="type" >Tipo</label>
					<mat-select [formControl]="type" disableRipple>
						<mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
					</mat-select>
					<validator [control]="type"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" *ngIf="type.value==1" for="value">Porcentaje</label>
					<label class="label" *ngIf="type.value==2" for="value">Monto</label>
					<input formControlName="value" type="number" autocomplete="off" placeholder="Ingrese valor" >
					<validator [control]="createCuponForm.controls['value']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
					<mat-select [formControl]="createCuponForm.controls['status']" disableRipple>
						<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
					</mat-select>
					<validator [control]="createCuponForm.controls['status']"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					
					<input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker" placeholder="Fecha desde" formControlName="dateFrom"  readonly>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker touchUi #picker></mat-datepicker>



					<validator [control]="createCuponForm.controls['dateFrom']"></validator>
				</div>
				<div class="field-row-item">
					
					<input matInput  style="width:90%;background:ghostwhite;" [matDatepicker]="picker2" placeholder="Fecha hasta" formControlName="dateUntil"  readonly>
					<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					<mat-datepicker touchUi #picker2></mat-datepicker>

					<validator [control]="createCuponForm.controls['dateUntil']"></validator>
				</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createCuponForm.valid || !type.valid ">
				<span>Guardar</span>
			</button>
		</div>
	</form>
