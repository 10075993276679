import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseService } from '../../shared/services/base.service';
import { JobService } from '../job.service';
import { NotificationService} from '../../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  template: `
	<h3 class="title">Editar demonio</h3>

	  <form *ngIf="updateJobForm" [formGroup]="updateJobForm" (ngSubmit)="update()">
		    <fieldset class="fieldset">
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" > 
            </div>  

			      <div class="field-row">
				
                 <div class="field-row-item">
                    <label class="label" for="full_name">Nombre</label>
                    <input formControlName="full_name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
                    <validator [control]="updateJobForm.controls['full_name']"></validator>
                  </div>

                  <div class="field-row-item">
                    <label class="label" for="description">Descripción</label>
                    <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
                    <validator [control]="updateJobForm.controls['description']"></validator>
                  </div>

            </div>	
            
            <div class="field-row">

              <div class="field-row-item">
                <label class="label" for="status" >Estatus</label>
                <mat-select [formControl]="status_job" disableRipple>
                  <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
                </mat-select>
                <validator [control]="status_job"></validator>
              </div>

              <div class="field-row-item">
                  <label class="label" for="millisSeparation">Milisegundos</label>
                  <input formControlName="millisSeparation" type="text" autocomplete="off" (keypress)="onlyNumberKey($event)" placeholder="Ingrese Milisegundos" >
                  <validator [control]="updateJobForm.controls['millisSeparation']"></validator>
              </div>

		    	  </div>

		    </fieldset>

      <div class="options">
        <button class="btn-text" type="submit" [disabled]="!updateJobForm.valid">
          <span>Guardar</span>
        </button>
      </div>
	  </form>
`
})
export class UpdateJobComponent implements OnInit {

  estatus : any[] = [{value:0,description:"Inactivo"},{value:1,description:'Arrancando'},{value:2,description:'Corriendo'}];
  status_job: FormControl = new FormControl('', Validators.required);
  updateJobForm = this.fb.group({
    full_name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    millisSeparation: ['', [Validators.required]],
    id: ['', [Validators.required]]
  });
  constructor( 
    public fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private jobService: JobService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(params =>{
      this.jobService.getById(params['jobId']).subscribe(job =>{
        this.updateJobForm.controls['id'].setValue(job.id);
        this.updateJobForm.controls['full_name'].setValue(job.fullName);
        this.updateJobForm.controls['description'].setValue(job.description);
        this.status_job.setValue(this.estatus[this.estatus.findIndex(e => job.status === e.value)].value);
        this.updateJobForm.controls['millisSeparation'].setValue(job.millisSeparation);
      },(err =>{
        this.notificationService.error(err);
      }));
    });
  }


  update() {
    let obj = <any> BaseService.builderObject(this.updateJobForm.value);
        
    obj.status = this.status_job.value;

    this.jobService.edit(obj).subscribe(job =>  {
                this.notificationService.sucessUpdate(job.fullName);
                this.location.back();
    }, err => this.notificationService.error(err.error.message));
        
}

  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
