import { Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from './customer';
import { CustomerService } from './customer.service';
import { NotificationService} from '../shared/notification/notification.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import { CustomerFilter } from './customer.filter';

@Component({
	template: `
	<h3  class="title">Listado de clientes</h3>

	<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
						
						<!--
              <div class="field-row-item" *ngIf="tableService.filter.id">
								<span class="label" i>id</span>
								<span>{{tableService.filter.id}}</span>
              </div>
							-->
							
							<div class="field-row-item" *ngIf="tableService.filter.name_customer">
								<span class="label">Nombre</span>
								<span>{{tableService.filter.name_customer}}</span>
							</div>

							<div class="field-row-item" *ngIf="tableService.filter.identifier_customer">
								<span class="label">DNI</span>
								<span>{{tableService.filter.identifier_customer}}</span>
							</div>
							
              <!--
							<div class="field-row-item" *ngIf="tableService.filter.status_partner">
								<span class="label">Estatus</span>
								<span>{{tableService.filter.status_partner}}</span>
							</div>
							-->
              
						</div>
					</div>
					<button class="btn-icon"  title="Buscar" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
					
						<!--
            <div class="field-row">
							<div class="field-row-item">
								<label class="label" for="id">id</label>
								<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
							</div>
						</div>
					-->
					
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="name_customer">Nombre</label>
								<input type="text" name="name_customer" placeholder="None"  [(ngModel)]="filter.name_customer">
							</div>

							<div class="field-row-item">
								<label class="label" for="identifier_customer">DNI</label>
								<input type="text" name="identifier_customer" placeholder="None"  [(ngModel)]="filter.identifier_customer">
							</div>
							
						</div>
						
            <!--
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="status_partner" >Estatus</label>
								<mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
									<mat-option >None</mat-option>
									<mat-option [value]="'1'">Activo</mat-option>
									<mat-option [value]="'2'">Inactivo</mat-option>
								</mat-select>
							</div>
						</div>
						-->
            
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span >Limpiar</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span>Buscar</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

	<div *hasPermission="['customer.create']" class="tool-bar-wrap both-side">
		<div class="right row">
			<button   class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
			

				<button class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
				</button>

			

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
			</ng-container>

			<ng-container matColumnDef="identifier_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
				<td mat-cell *matCellDef="let element">{{element.identifier_customer}}</td>
			</ng-container>

			<ng-container matColumnDef="adress_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Dirección</th>
				<td mat-cell *matCellDef="let element">{{element.adress_customer}}</td>
			</ng-container>

			<ng-container matColumnDef="sucursal_id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Sucursal</th>
				<td mat-cell *matCellDef="let element">{{element.sucursal_id}}</td>
			</ng-container>

			<ng-container matColumnDef="phoneNumber_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Teléfono</th>
				<td mat-cell *matCellDef="let element">{{element.phoneNumber_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status_customer}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['customer.update']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template contextMenuItem (execute)="updatePlan($event.item)">
				<div class="item">
					<i class="material-icons">card_membership</i>
					<span>Planes del cliente</span>
				</div>
			</ng-template>

			
			<ng-template contextMenuItem (execute)="creditcard($event.item)">
				<div class="item">
					<i class="material-icons">credit_card</i>
					<span>Tarjetas de credito</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['customer.delete']"contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span>Eliminar</span>
				</div>
			</ng-template>
		
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})

export class CustomerListOutComponent {

	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['select', 'id', 'name_customer','identifier_customer', 'adress_customer','sucursal_id', 'phoneNumber_customer', 'status_customer'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new CustomerFilter(this.tableService.filter);
	partners : object = [];
	services : object = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private customerService: CustomerService,
		public tableService: TableService<any>,
		private notificationService: NotificationService
	){
		this.tableService = new TableService;
	}

	ngOnInit(){
		this.list();
	}

	list(event?: PageEvent){
	
		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
		
		let httpParams = this.customerService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		
		httpParams = this.filter.getHttpParams(httpParams);



		this.activatedRoute.params.subscribe(param => {
			this.customerService.getByPartner(param['partnerId'],httpParams).subscribe(response => {
							for(const r of response['result']){
								if(r.status_customer == 1){
									r.status_customer = 'Activo';
								}
								if(r.status_customer == 2){
									r.status_customer = 'Inactivo';
								}
								if(r.sucursal_id ==  null){
								 	r.sucursal_id = "N/A"
								}
							}
							this.dataSource = new MatTableDataSource<any>(response['result']);
							this.tableService.pager = response['pager'];
							this.tableService.selection.clear();
			}, (err) => {this.notificationService.error(err);});
		}, err => this.notificationService.error(err));	
	}

	create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
	}

	read(item: Customer){
		localStorage.setItem("customer", JSON.stringify({cust: item.id}));
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
	}

  updatePlan(item : Customer){
		localStorage.setItem("customer", JSON.stringify({cust: item.id}));
   		this.router.navigate(["../" + item.id + "/hasplans"], {relativeTo: this.activatedRoute});
  }


	update(item: Customer){
		localStorage.setItem("customer", JSON.stringify({cust: item.id}));
		this.router.navigate(["./" + item.id + "/update"], {relativeTo: this.activatedRoute});
	}

  creditcard(item: Customer){
    localStorage.setItem("customer", JSON.stringify({cust: item.id}));
    this.router.navigate(['../' + item.id + '/creditcards'], {relativeTo: this.activatedRoute});
  }

  formatearFecha(fecha : string) : string{
    let splitted = fecha.split("-");
    return splitted[2] + "-" + splitted[1] + "-" + splitted[0];
  }

	//-- 

deletes() {
	const length = this.tableService.selection.selected.length;
	this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
		--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.customerService.delete(this.tableService.selection.selected[0].id).subscribe(response => {
        this.notificationService.sucessDelete(response.name_service);
				this.list();
			}, (err) => { this.notificationService.error(err); });
		} else if (length > 1) {
			this.customerService.deletes(this.tableService.selection.selected).subscribe(any => {
				this.notificationService.sucessDelete();
				this.list();
				}, (err) => { this.notificationService.error(err); });
	} 
}

reset() {
	this.filter = new CustomerFilter();
	this.list();
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new CustomerFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new CustomerFilter(this.tableService.filter);
}

compareFn(c1: any, c2: any): boolean { 
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}

applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
}

//--
}
