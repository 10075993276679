import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BuildDashboardComponent } from './build-dashboard/build-dashboard.component';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { DashboardService } from './dashboard.service';

@NgModule({
  declarations: [DashboardComponent, BuildDashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
		ReactiveFormsModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule
  ],
  providers: [
	DashboardService
  ]
})
export class DashboardModule { }
