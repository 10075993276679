import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {BaseService} from '../../shared/services/base.service';
import {Location} from '@angular/common';
import {NotificationService} from '../../shared/notification/notification.service';
import {BillingService} from '../billing.service';
import {PartnerService} from '../../partner/partner.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import cronstrue from 'cronstrue';



@Component({
  template: `
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Crear Renovacion</h3>

	<form *ngIf="createBillingForm" [formGroup]="createBillingForm" (ngSubmit)="create()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" for="partner" >Compañia</label>
          
          <mat-select  [compareWith]="compareFn" (selectionChange)="onPartnerChange($event.value)" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
			
				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Sucursal</label>
			
          <mat-select [formControl]="sucursal" [compareWith]="compareFn" (selectionChange)="onSucursalChange($event.value)" disableRipple>
								<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>

				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="servicio" >Servicio</label>
          <mat-select [formControl]="service"  [compareWith]="compareFn" (selectionChange)="onServiceChange($event.value)" disableRipple>
                <mat-option *ngFor="let serv of services" [value]="serv.id">{{serv.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
        

          <mat-select  [formControl]="createBillingForm.controls['plans']" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank.id">
              {{bank.name_plan}}
            </mat-option>
          </mat-select>




          <validator [control]="createBillingForm.controls['plans']"></validator>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_billingsetting" >Nombre</label>
					<input formControlName="name_billingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createBillingForm.controls['name_billingsetting']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
         
          <mat-select [formControl]="createBillingForm.controls['status_billingsetting']" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
					<validator [control]="createBillingForm.controls['status_billingsetting']"></validator>

				</div>
			</div>
			

      
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="cycle" >Inicio ciclo</label>
          
          <mat-select [formControl]="createBillingForm.controls['cycle_start_billingsetting']" disableRipple>
              <mat-option *ngFor="let cycle of cycles" [value]="cycle">{{cycle.desc}}</mat-option>
            </mat-select>
          <validator [control]="createBillingForm.controls['cycle_start_billingsetting']"></validator>

        </div>
        <div class="field-row-item">
          <label class="label" for="plan" >Cantidad de deuda acumulada</label>
         
          <mat-select [formControl]="createBillingForm.controls['qtyaccumulated_debt_billingsetting']" disableRipple>
          
              <mat-option *ngFor="let debt of debts" [value]="debt">{{debt}}</mat-option>
            </mat-select>
          <validator [control]="createBillingForm.controls['qtyaccumulated_debt_billingsetting']"></validator>

        </div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="frecuency">Elija una Frecuencia</label>
          <cron-jobs [formControl]="createBillingForm.controls['time_billingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
          {{frecuency}}
         </div>
      </div>

		</fieldset>

		<div class="options">
      <button class="btn-text" type="submit" [disabled]="!createBillingForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CreateBillingComponent implements OnInit {



  // Para planes
  public roleMultiCtrl: FormControl = new FormControl();
  public roleMultiFilterCtrl: FormControl = new FormControl();
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect1',{static: false}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  public cronConfig = {
    multiple:true,
    bootstrap:true,
    quartz:true
  };

  public cronValidate = {
    validate:true
  };

  partners = [];
  sucursales = [];
  services = [];
  plans = [];
  statuses= [] ;
  cycles = [] ;
  debts = [] ;

  frecuency;

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  service: FormControl = new FormControl('', Validators.required);
  sucursal: FormControl = new FormControl('', Validators.required);

  // validaciones para campos de formulario
  createBillingForm = this.fb.group({
    name_billingsetting: ['', [Validators.required]],
    status_billingsetting:['', [Validators.required]],
    plans:['', [Validators.required]],
    cycle_start_billingsetting: ['', [Validators.required]],
    qtyaccumulated_debt_billingsetting:['', [Validators.required]],
    time_billingsetting:['', [Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private billingService: BillingService,
    private partnerService: PartnerService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    for ( let i = 0; i <= 30; i ++ ) {
      this.cycles.push({value:i,desc:i});
    }
    for ( let i = 1; i <= 30; i ++ ) {
      this.debts.push(i);
    }
    this.partnerService.getAll().subscribe(partners => {
      this.partners = partners['result'];
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });

    this.onItemChange();
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createBillingForm.value);
    obj.plan = {'id': obj.plan};
    obj.progress_billingsetting = 1;
    obj.cycle_start_billingsetting = obj.cycle_start_billingsetting.value;
    this.billingService.create(obj).subscribe(billing => {
      this.notificationService.sucessInsert(billing['name_billingsetting']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    }); 
  }
  onPartnerChange(partner:any) {
    this.services = [];
    this.service.reset()
    this.sucursales = [];
    this.sucursal.reset()
    this.plans = [];
    if(partner != undefined){
      this.partnerService.getPartnersByParent(partner).subscribe(sucursales => {
        this.sucursales = sucursales['result'];
        if (this.sucursales.length === 0) {
          this.notificationService.info('Info', 'Esta Compañia no posee sucursales');
          this.servicesService.getByPartner(partner).subscribe( services => {
            this.services = services['result'];
            if (this.services.length === 0) {
              this.notificationService.info('Info', 'Esta Compañia no posee servicios');
            }
          }, err => {
            this.notificationService.error('Error al cargar los servicios de la sucursal');
            console.log(err);
          });
        }
      }, err => {
        this.notificationService.error('Error consultando las sucursales del partner seleccionado');
        console.log(err);
      });
    }
  }
  onSucursalChange(sucursal:any) {
    this.services = [];
    this.service.reset();
    this.plans = [];
    if(sucursal!=undefined){
      this.servicesService.getByPartner(sucursal).subscribe( services => {
        this.services = services['result'];
        if (this.services.length === 0) {
          this.notificationService.info('Info', 'Esta sucursal no posee servicios');
        }
      }, err => {
        this.notificationService.error('Error al cargar los servicios de la sucursal');
        console.log(err);
      });
    }
  }
  onServiceChange(service:any) {
    this.plans = [];
    if(service!=undefined){
      this.planServices.getByService(service).subscribe( plans => {
        this.plans = plans['result'];
        if (this.plans.length === 0) {
          this.notificationService.info('Info', 'Este servicio no posee planes registrados');
        }
        // load the initial role list
        this.filteredRolesMulti.next(this.plans.slice());
        // listen for search field value changes
        this.roleMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterRoleMulti();
          });
      }, error1 => {
        this.notificationService.error('Error cargando los planes del servicio');
        console.log(error1);
      });
    }
  }

  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  onItemChange(){
    this.createBillingForm.get('time_billingsetting').valueChanges.subscribe(val =>{
      if(val != ''){
        this.frecuency = "La frecuencia se ejecutara: " + cronstrue.toString(val);
      }
    });
  }

  ngAfterViewInit() {
    this.setInitialValueRole();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValueRole() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterRoleMulti() {

    if (!this.plans) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.plans.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.plans.filter(bank => bank.name_plan.toLowerCase().indexOf(search) > -1)
    );

  }

}
