import {NotificationService} from '../../shared/notification/notification.service';
import {FormBuilder, Validators} from '@angular/forms';
import {GroupService} from '../group.service';
import {Location} from '@angular/common';
import {RoleService} from '../../role/role.service';
import {BaseService} from '../../shared/services/base.service';
import { Component,OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  template: `
	<h3 class="title">Crear Grupo</h3>

	<form *ngIf="createGroupForm" [formGroup]="createGroupForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del grupo</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createGroupForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="createGroupForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="rol" >Elija el rol al que pertenecera el grupo</label>
          <mat-select  [formControl]="roleMultiCtrl" [multiple]="true" #multiSelect>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
				</div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createGroupForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})

// Multiselect Based on : https://www.npmjs.com/package/ngx-mat-select-search

export class GroupCreateComponent implements OnInit {

  roles: any[] = [];
  rolesSelected: any[] = [];
  default: any[] = [];

  // validaciones para campos de formulario
  createGroupForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['',[Validators.required]]
  });

  /** control for the selected role for multi-selection */
  public roleMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public roleMultiFilterCtrl: FormControl = new FormControl();

    /** list of roles filtered by search keyword */
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect',{static: false}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    public fb: FormBuilder,
    private groupService: GroupService,
    private roleService: RoleService,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
     this.roleService.getAll().subscribe(permission => {
      this.roles = permission['result'];

      // set initial selection
      //this.bankMultiCtrl.setValue([this.banks[10], this.banks[11], this.banks[12]]);

      // load the initial role list
      this.filteredRolesMulti.next(this.roles.slice());

      // listen for search field value changes
      this.roleMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanksMulti();
        });

    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createGroupForm.value);
    // seteamos los permisos seleccionados por el usuario para el rol que se va a crear
    obj.rolesGroup = this.roleMultiCtrl.value;
    this.groupService.create(obj).subscribe(role => {
      this.notificationService.sucessInsert(role['name']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    }) ;
  }

  selectRoles(roles: any[]) {
    this.rolesSelected = roles;
  }

  searchRoles(value: string) {


    let httpParams = this.roleService.buildRequestParams(null, null, null);

    httpParams = httpParams.set('description',value);

    this.roleService.getAllSearch(httpParams).subscribe( roles => {
      this.roles = roles['result'];
      if(this.roles.length === 0){
        this.notificationService.error('Su busqueda no ha arrojado resultados');
      }
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterBanksMulti() {

    if (!this.roles) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.roles.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );

  }

}
