
		<div i18n="@@recoverPass-reset">
			<div class="dialog-title">Reestablezca su contraseña</div>
			<div class="dialog-message">El correo electrónico ha sido enviado con éxito, use el pin que le enviamos por correo para restablecer su contraseña</div>
		</div>

		<form [formGroup]="form" (ngSubmit)="responsePin()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="pin">Pin</label>
						<input formControlName="pin" type="text" autocomplete="off">
						<validator [control]="form.controls['pin']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPassword" >Password</label>
						<input formControlName="newPassword" type="password" autocomplete="off">
						<validator [control]="form.controls['newPassword']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPasswordConfirm">Password confirm</label>
						<input formControlName="newPasswordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['newPasswordConfirm']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-reset-pass">Resetear su contraseña</span>
				</button>
			</div>
		</form>

		<a class="clickable-item link" routerLink="../" >Regresar a <u>Olvido de contraseña</u></a>
	