import { Component, OnInit } from '@angular/core';
import {EnrollmentService} from '../enrollment.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';

@Component({
  template: `
	<h3 class="title">Respuestas obtenidas para el archivo</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del archivo</span>
					<span>{{ name_source || '-'}}</span>
				</div>
			</div>


			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status || '-'}}</span>
				</div>
			</div>

		</div>
  </div>
<!--
  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button class="btn-icon" type="button" (click)="export()">
        <i class="material-icons">cloud_download</i>
      </button>
    </div>
  </div>
-->

  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
	
			<ng-container matColumnDef="json.customer.identifier_customer">
				<th mat-header-cell *matHeaderCellDef  >DNI</th>
				<td mat-cell *matCellDef="let element">{{element?.json?.customer?.identifier_customer || element?.json?.dni}}</td>
			</ng-container>
			
			<ng-container matColumnDef="json.customer.name_customer">
				<th mat-header-cell *matHeaderCellDef  >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element?.json?.customer?.name_customer || "-" }}  </td>
      </ng-container>
      
      <ng-container matColumnDef="json.creditcard.numberCreditCard">
        <th mat-header-cell *matHeaderCellDef  >TDC</th>
        <td mat-cell *matCellDef="let element">{{element?.json?.creditcard?.numberCreditCard || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="result.code">
        <th mat-header-cell *matHeaderCellDef  >Respuesta</th>
        <td mat-cell *matCellDef="let element">{{element?.result?.code  || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="result.message">
        <th mat-header-cell *matHeaderCellDef  >Mensaje</th>
        <td mat-cell *matCellDef="let element">{{element?.result?.message  || "-"}}</td>
      </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class EnrollmentBatchComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['json.customer.identifier_customer', 'json.customer.name_customer', 'json.creditcard.numberCreditCard', 'result.code', 'result.message'];

  name_plan;
  name_source;
  status;
  process;
  items: any[] = [];
  id_source;
  staticSuccess_data: any[] = [];
  staticFail_data: any[] = [];
  success_data: any[] = [];
  fail_data: any[] = [];
  staticLine_data: any[] = [];
  toExport : any[] = [];

  constructor(
    private enrollmentService: EnrollmentService,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.id_source = param['sourceId'];
      this.enrollmentService.getById(param['sourceId']).subscribe(file => {
        this.process = file.name_processFile;
        this.name_plan = file.plan.name_plan;
        this.name_source = file.name_source;
        this.estatus_change(file);
        this.list();
      });
    });
  }

  estatus_change( file: any) {
    if (file.status === 0) {
      this.status = 'Procesando';
    }
    if (file.status === 1) {
      this.status = 'Por procesar';
    }
    if (file.status === 2) {
      this.status = 'Procesado Exitosamente';
    }
    if (file.status === 3) {
      this.status = 'Erroneo, no cargado el archivo';
    }
    if (file.status === 4) {
      this.status = 'Erroneo, cobro no procesado';
    }
}

list(event?: PageEvent) {

  let httpParams = this.enrollmentService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
	      
  this.enrollmentService.getBatch( httpParams , this.id_source).subscribe(params => {
    this.items = params['result']; // items que mostrara la tabla
    
    this.dataSource = new MatTableDataSource<any>(this.items);
    this.tableService.pager = params['pager'];
    this.tableService.selection.clear();
    if (this.items.length === 0) {
      this.notificationService.alert('No se encontraron resultados para la busqueda');
    }
  }, err => {
    this.notificationService.error(err);
    console.log(err);
  });
}

export() {
  /*let data = [];
  if(this.items.length > 0){
    const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      useTextFile: false,
      filename: this.process+date,
      useBom:false
    };
    const csvExporter = new ExportToCsv(options);

    this.fileService.getResponses(this.fileService.buildRequestOptionsFinder(
      this.tableService.sort,
      'm',
      null, {pageIndex: -1 , pageSize: -1}), this.id_file).subscribe(params => {
      data = params['result'];
      // eliminamos lo que no nos interesa que se muestre en el documento
      for(const pay of data){
        delete pay.DateInvoice;
        delete pay.NameCustomer;
        if(pay.Resultado == 1){
          delete pay.Resultado;
          this.success_data.push(pay);
        }else{
          delete pay.Resultado;
          this.fail_data.push(pay)
        }
      }
      this.toExport = this.staticSuccess_data.concat(this.success_data.concat(this.staticLine_data.concat(this.staticFail_data.concat(this.fail_data))));
      csvExporter.generateCsv(this.toExport);
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });

    
  }else{
    this.notificationService.error("No existen registros para exportar");
  } */
  
}

}
