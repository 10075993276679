
	<h3 class="title">Detalle</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ job?.fullName || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Descripción</span>
					<span>{{ job?.description || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Ultima ejecucion</span>
					<span>{{ job?.last_executed || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Proxima ejecucion</span>
					<span>{{ job?.next_execution || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Milisegundos</span>
					<span>{{ job?.millisSeparation || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ job?.status || '-'}}</span>
				</div>
      </div>
      
		</div>
	</div>
