import { Component, OnInit } from '@angular/core';
import { FilterService } from '../shared/filter-ns/filter-ns.service';

@Component({
  selector: 'app-servicio',
  template: '<router-outlet></router-outlet>',
  providers: [
  		FilterService
  	]
})
export class ServicioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
