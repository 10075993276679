import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BaseService } from '../../shared/services/base.service';
import { PartnerService } from '../../partner/partner.service';
import {NotificationService} from '../../shared/notification/notification.service';
import { DashboardService } from '../dashboard.service';
import { DatePipe } from '@angular/common';

@Component({
  template: `
	<h3 class="title">Tablero</h3>

	<form [formGroup]="dashboardForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
    <div class="field-row">

        <div class="field-row-item">
          <label class="label" for="updateEvery" >Actualizar Cada</label>
          <mat-select [formControl]="interval" disableRipple>
              <mat-option *ngFor="let opt of updateEvery" [value]="opt.value">{{opt.desc}}</mat-option>
          </mat-select>
          
        </div>

        <div class="field-row-item">
            <label class="label" for="company" >Compañias</label>
            <mat-select [formControl]="partner" multiple>
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>    
        </div>

        <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_dashboard" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
				</div>

    </div>


    <div class="field-row">

          <div class="field-row-item">
                  <label class="label" for="name_plan">Periodo</label>
                  <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" type="radio" value="1" (change)="dropBusqueda()" name="type">
          </div>

          <div class="field-row-item">
                  <label class="label" for="name_plan">Rango de fecha</label>
                  <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" type="radio" value="2" (change)="rangoBusqueda()" name="type">
          </div>

    </div>
                        
    <div class="field-row" *ngIf="del">

          <div class="field-row-item">
                <mat-select [formControl]="periodS"  disableRipple>
                    <mat-option *ngFor="let opt of period" [value]="opt.value">{{opt.desc}}</mat-option>
                </mat-select>
                
          </div>

          <div class="field-row-item">
                                 
          </div>

    </div>
                            
      <div class="field-row" *ngIf="rango">
            <div class="field-row-item">
                  <input matInput formControlName="since_date" style="width:90%;" [matDatepicker]="picker" placeholder="Desde" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
                  
            <div class="field-row-item">
                <input matInput formControlName="until_date" style="width:90%;" [matDatepicker]="picker2" placeholder="Hasta" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
		  </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!partner.valid || !status_dashboard.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>`
})
export class BuildDashboardComponent implements OnInit {


   /**
     *  Implementacion para el material date
      <input matInput [matDatepicker]="picker" placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    <!--
                            <mat-tab label="Rango de fecha">  
                                <div class="field-row">
                                    <div class="field-row-item">
                                        <input matInput style="width:90%;" [matDatepicker]="picker" placeholder="Choose a date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>

                                    <div class="field-row-item">
                                        <input matInput style="width:90%;" [matDatepicker]="picker2" placeholder="Choose a date">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </div>                                 
                                </div>
                                <div class="options">
                                    <button class="btn-text blue" type="button" (click)="search()">
                                        <span>Actualizar</span>
                                    </button>
                                </div>
                            </mat-tab>
    -->
     * 
     * 
     */

  updateEvery = [{desc: '1 Min', value: 60000}, {desc: '5 Min', value: 300000}, {desc: '10 Min', value: 600000}, {desc: '30 Min', value: 1800000},{desc: '1 Hora', value: 3600000},{desc: '2 Horas', value: 7200000}];

  period = [{desc: 'Hoy', value: 1}, {desc: 'Ayer', value: 2}, {desc: 'Esta semana', value: 3}, {desc: 'Semana Pasada', value: 4},{desc: 'Este Mes', value: 5},{desc: 'Mes Pasado', value: 6},{desc: 'Este año', value: 7},{desc: 'Año pasado', value: 8}];

  partners = [];

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];

  rango = false;
  del = false;

  exist = false;
  radio;

  dashboardSetting : any;
  dashboardForm = this.fb.group({
    since_date: ['', [Validators.required]],
    until_date: ['', [Validators.required]],
  });

  public partner: FormControl = new FormControl('', Validators.required);
  public status_dashboard: FormControl = new FormControl('', Validators.required);
  public periodS: FormControl = new FormControl('', Validators.required);
  public interval: FormControl = new FormControl('', Validators.required);

  constructor(
    public fb: FormBuilder,
    private partnerService: PartnerService,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    // this.partnerS.setValue(this.partners[this.partners.findIndex(e => this.servicio.partner.id === e.id)].id);
    let httpParams = this.partnerService.buildRequestParams()

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result'];
      let httpParams = this.partnerService.buildRequestParams()

      httpParams = httpParams.set('parents',true.toString());
      httpParams = httpParams.set('parent',(-1).toString());
      // cargamos sucursales
      this.partnerService.getPartnersByUserId(httpParams).subscribe(params =>{
          for( var p of params['result']){
              this.partners.push(p)
          }
          this.dashboardService.getByAuthId(JSON.parse(localStorage.getItem('currentData')).user.authUser.id).subscribe(response =>{
            if(response['id']){ // si existe el DashboardSetting
              
              this.dashboardSetting = response;
              var partnersSeleccionados = [];
              for(var p of this.dashboardSetting.partner.split(",")){
                  partnersSeleccionados.push(+p)
              }
              this.partner.setValue(partnersSeleccionados);
              if(this.dashboardSetting.period !== -1){
                this.del = true;
                this.periodS.setValue(this.period[this.period.findIndex(e => this.dashboardSetting.period === e.value)].value);
                this.radio = '1';
              }else {
                this.periodS.setValue(-1);
                this.rango = true;
                this.radio = '2';
                this.dashboardForm.controls["since_date"].setValue(this.dashboardSetting.since_date);
                this.dashboardForm.controls["until_date"].setValue(this.dashboardSetting.until_date);
              }
              this.status_dashboard.setValue(this.estatus[this.estatus.findIndex(e => this.dashboardSetting.status_dashboard === e.value)].value)
              this.interval.setValue(this.updateEvery[this.updateEvery.findIndex(e => this.dashboardSetting.interval_time === e.value)].value);
            }

          });
      });
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }


  rangoBusqueda() {
    this.rango = true;
    this.del = false;
    this.periodS.setValue(-1);
  }

  dropBusqueda() {
      this.del = true;
      this.rango = false;
      this.dashboardForm.controls["since_date"].setValue(null);
      this.dashboardForm.controls["until_date"].setValue(null);
  }

  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  create() {
    let obj = <any> BaseService.builderObject(this.dashboardForm.value);
    if((this.periodS.value == -1 || this.periodS.value == "" ) && (obj.since_date == "" || obj.since_date == undefined || obj.since_date == null || obj.until_date == "" || obj.until_date == undefined || obj.until_date == null)) {
      this.notificationService.error("Debes seleccionar un Periodo o un Rango de fecha");
      return;
    }

    var since = new  Date (this.datePipe.transform(obj.since_date, 'yyyy-MM-dd'));
    var until = new  Date (this.datePipe.transform(obj.until_date, 'yyyy-MM-dd'));

   
    if(until.getTime() < since.getTime()){
      this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
      return;
    }

    if(obj.since_date === undefined){
      obj.since_date = null;
      obj.until_date = null;
    }else{
      obj.since_date = this.datePipe.transform(obj.since_date, 'yyyy-MM-dd');
      obj.until_date = this.datePipe.transform(obj.until_date, 'yyyy-MM-dd');
    } 
    
    obj.period = this.periodS.value;
    obj.partner = this.partner.value.toString();
    obj.interval_time =  this.interval.value.toString();
    obj.status_dashboard = this.status_dashboard.value.toString();
    obj.authUser = {id:JSON.parse(localStorage.getItem('currentData')).user.authUser.id};
    if(this.dashboardSetting == undefined){
      this.dashboardService.create(obj).subscribe(response =>{
        this.notificationService.sucessInsert("DashboardSetting");
      });
    }else{
      obj.id = this.dashboardSetting.id;
      this.dashboardService.update(obj).subscribe(response =>{
        this.notificationService.sucessUpdate("DashboardSetting")
      });
    } 
  }

}
