
	<h3 class="title">Editar Rol</h3>

	<form *ngIf="updateRoleForm" [formGroup]="updateRoleForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos del rol</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateRoleForm.controls['name']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="description">Descripción</label>
          <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
          <validator [control]="updateRoleForm.controls['description']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="rol" >Elija los permisos que tendra el rol</label>
          <mat-select  [formControl]="permiMultiCtrl" [multiple]="true" #multiSelect>
            <ngx-mat-select-search [formControl]="permiMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredPermiMulti | async" [value]="bank">
              {{bank.description}}
            </mat-option>
          </mat-select>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateRoleForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
