import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {homeRouting} from '../home/home.routes';
import {SharedModule} from '../shared/shared.module';
import { DatePipe } from '@angular/common';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { routingReportComponents } from './report.routes';
import { CustomersPlanComponent } from './customers-plan/customers-plan.component';
import { CustomerPlanDetailComponent } from './customer-plan-detail/customer-plan-detail.component';
import { CustomerPartnerComponent } from './customer-partner/customer-partner.component';
import { CustomerPlansComponent } from './customer-plans/customer-plans.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import {MatButtonModule} from '@angular/material/button';
import { AuditioApiComponent } from './auditio-api/auditio-api.component';
import { AuditioInfoComponent } from './auditio-info/auditio-info.component';
import {MatChipsModule,MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { AuditionApiDetailComponent } from './audition-api-detail/audition-api-detail.component';
import { InvoicesByPaymentComponent } from './invoices-by-payment/invoices-by-payment.component';
import { UpdateStatusFixComponent } from './update-status-fix/update-status-fix.component';
import {MatTabsModule} from '@angular/material/tabs';
import { InvoiceToChargeComponent } from './invoice-to-charge/invoice-to-charge.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
    MatSelectModule, 
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTabsModule
  ],
  declarations: [routingReportComponents, CustomersPlanComponent, CustomerPlanDetailComponent, CustomerPartnerComponent, CustomerPlansComponent, CustomerInvoiceDetailComponent, AuditioApiComponent, AuditioInfoComponent, AuditionApiDetailComponent, InvoicesByPaymentComponent, UpdateStatusFixComponent, InvoiceToChargeComponent],
  providers: [DatePipe,{
    provide: MAT_CHIPS_DEFAULT_OPTIONS,
    useValue: {
      separatorKeyCodes: [ENTER, COMMA,SPACE]
    }
  }]
})
export class ReportModule { }
