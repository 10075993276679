import {Routes} from '@angular/router';
import {JobComponent} from './job/job.component';
import {ListJobComponent} from './list-job/list-job.component';
import { UpdateJobComponent } from './update-job/update-job.component';
import { ReadJobComponent } from './read-job/read-job.component';

export const jobRoutes: Routes = [
  {
    path: 'jobs',
    component: JobComponent,
    data: {
      breadcrumb: 'Jobs',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ListJobComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: ':jobId',
        component: JobComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children:[
          {
            path: '',
            pathMatch: 'full',
            component: ReadJobComponent,
            data: {
              breadcrumb: undefined,
            }
        },
        {
            path: 'update',
            component: UpdateJobComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }

        ]
      }
    ]
  }
];

export const routingJobComponents = [JobComponent,ListJobComponent,ReadJobComponent,UpdateJobComponent];
