import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class CityService extends BaseService {

 private  readonly BASE_URL: string = this.HOST + '/city' ;

 httpOptions : any;

  constructor(  private http: HttpClient ) { 
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    } 
   }

    getByCountry(id: number): Observable <any> {
            return this.http.get(this.BASE_URL + '/country/' + id, this.httpOptions)
            .pipe(
              catchError(this.handleError)
            );
        }

    getCity(id: number): Observable <any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
    }
}
