import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Role} from '../../role/role';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { IntegrationService } from '../integration.service';
import { IntegrationFilter } from '../integration.filter';

@Component({
  template: `
  <h3 class="title">Listado de Integraciones</h3>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button *hasPermission="['group.create']" class="btn-icon" type="button" (click)="create()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Usuario</th>
        <td mat-cell *matCellDef="let element">{{element.user}}</td>
      </ng-container>

      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Password</th>
        <td mat-cell *matCellDef="let element">{{element.password}}</td>
      </ng-container>
      
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >URL</th>
        <td mat-cell *matCellDef="let element">{{element.url}}</td>
      </ng-container>

      <ng-container matColumnDef="token">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Token</th>
        <td mat-cell *matCellDef="let element">{{element.token}}</td>
      </ng-container>

      <ng-container matColumnDef="partner?.name_partner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >compañía</th>
        <td mat-cell *matCellDef="let element">{{element?.partner?.name_partner}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template contextMenuItem (execute)="update($event.item)">
        <div class="item">
          <i class="material-icons">edit</i>
          <span>Actualizar</span>
        </div>
      </ng-template>
    
  </context-menu>
`
})
export class IntegrationListComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['user', 'password', 'url','token','partner?.name_partner'];
	confirmDelete = true;
	isOpenSearchPanel = false;
  filter = new IntegrationFilter(this.tableService.filter);
  integrations: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private integrationService: IntegrationService,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) { this.tableService = new TableService; }

  ngOnInit(): void {
    this.list();
  }

  list(event?: PageEvent) {
  
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
    }
    
    let httpParams = this.integrationService.buildRequestParams(this.tableService.sort,'m', 
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});

    //this.integrationService.getAll()
    httpParams = this.filter.getHttpParams(httpParams); 

    this.integrationService.getAll(httpParams).subscribe(response => {
        this.integrations = response.result.length;
        this.dataSource = new MatTableDataSource<any>(response['result']);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });

  }

  update(item: Role) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  create() { 
    // ESTE ES EL TOTAL DE INTEGRACIONES REGISTRADAS
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: any) {
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  //-- 


  reset() {
    this.filter = new IntegrationFilter();
    this.dataSource = new MatTableDataSource<any>([]);
		this.tableService.filter = new IntegrationFilter(this.filter);
  }

  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new IntegrationFilter(this.filter);
    this.list();
  }

  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new IntegrationFilter(this.tableService.filter);
  }

  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

//--

}
