import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { Plan } from "./plan";
import { Partner } from '../partner/partner';
import { Frecuency } from '../frecuency/frecuency';
import { Servicio } from '../servicio/servicio';
import { PartnerService } from '../partner/partner.service';
import { ServicioService } from '../servicio/servicio.service';
import { FrecuencyService } from '../frecuency/frecuency.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class PlanService extends BaseService {

  httpOptions : any;

  private  readonly BASE_URL: string = this.HOST + '/plan';

  constructor(private http: HttpClient,private partnerService: PartnerService,
        private servicioService : ServicioService,private frecuencyService:FrecuencyService) { 
          super();
          // Http Headers
          this.httpOptions = {
            headers: this.createAuthorizationHeader()
          }
        }

  getPlan(id:number):Observable<Plan>{
    	return this.http.get(this.BASE_URL + '/' + id)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
   }

  getByService(id: number,params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
        return this.http.get(this.BASE_URL + '/service/' + id, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }


   deletes(array: any[]): Observable<any> {
          return this.http.post(this.BASE_URL + '/delete',{plans:array}, this.httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      }

  delete(id: number): Observable<any> {
    let BASE_URL2 = this.HOST + '/partner/'+JSON.parse(localStorage.getItem("data")).user +'/plan';
   
    return this.http.delete(BASE_URL2 + '/' + id,this.httpOptions)
          .pipe(
            catchError(this.handleError)
          );
  }

  create(plan: Plan): Observable<Plan> {
        return this.http.post(this.BASE_URL, {"plan":plan}, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    getById(id: number): Observable<Plan> {
        return this.http.get(this.BASE_URL + '/' + id,this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }



    findService(id: number): Observable<Servicio>{
        return this.servicioService.getById(id);
    }

    findFrecuency(id: number): Observable<Frecuency>{
        return this.frecuencyService.getFrecuency(id);
    }

    findPartner(id: number): Observable<Partner>{
        return this.partnerService.getById(id);
    }

    update(plan: Plan): Observable<Plan> {
        let BASE_URL2 = this.HOST + '/partner/'+JSON.parse(localStorage.getItem("data")).user +'/plan';
        return this.http.put(BASE_URL2 + '/' + plan.id, {"plan":plan} ,this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }


}
