
import { Routes } from '@angular/router';
import { CuponComponent } from './cupon/cupon.component';
import { ListCuponComponent } from './list-cupon/list-cupon.component';
import { CreateCuponComponent } from './create-cupon/create-cupon.component';
import { ReadCuponComponent } from './read-cupon/read-cupon.component';
import { UpdateCuponComponent } from './update-cupon/update-cupon.component';


export const cuponsRoutes: Routes = [
	{
				path: 'cupons',
				component: CuponComponent,
				data: {
					breadcrumb: "Cupones"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: ListCuponComponent,
						data: {
						   	breadcrumb: undefined
						    }
					},
					{
					    path:'create',
					    component: CreateCuponComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':cuponID',
					    component: CuponComponent,
					    data: {
					    	breadcrumb: "Detalle",
			    			icon: "detail"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: ReadCuponComponent,
							    data: {
							    	breadcrumb: undefined,
							    }
							},
							{
							    path: 'update',
							    component: UpdateCuponComponent,
							  	data: {
							    	breadcrumb: "Actualizar",
			    					icon: "update"
							    }
						  	}

					  	]
				  	}
				]
			}
];

export const routingCuponsComponents = [CuponComponent, ListCuponComponent, CreateCuponComponent, ReadCuponComponent, UpdateCuponComponent];
