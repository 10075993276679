import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {homeRouting} from '../home/home.routes';
import {SharedModule} from '../shared/shared.module';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button'
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { routingEnrollmentComponents } from './enrollment.routes';

@NgModule({
  declarations: [routingEnrollmentComponents],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
    MatExpansionModule,
    MatButtonModule,
    MatSelectModule, 
    MatInputModule,
    MatTooltipModule,MatDatepickerModule
  ],
  providers: [DatePipe]
})
export class EnrollmentModule { }
