import { HttpParams } from '@angular/common/http';

export class BillingFilter {

	name_billingsetting: string;
	id: number;
	status_billingsetting: number;
    
	constructor(filter?: BillingFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_billingsetting = filter.name_billingsetting;
            this.status_billingsetting = filter.status_billingsetting;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_billingsetting) {
			httpParams = httpParams.set('name_billingsetting', this.name_billingsetting);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_billingsetting) {
			httpParams = httpParams.set('status_billingsetting', this.status_billingsetting.toString());
        }
		return httpParams;
	}
}