import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { authRoutes } from './utils/auth/auth.routes';
import { homeRoutes } from './home/home.routes';


const appRoutes: Routes = [
	{
        path: '',
        component: AppComponent,
        children: [
        	...authRoutes,
            ...homeRoutes
        ]
    }/*,{
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }*/
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

