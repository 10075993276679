
		<h3 class="title">Editar Sucursal</h3>

		<form *ngIf="updatePartnerForm" [formGroup]="updatePartnerForm" (ngSubmit)="update()">
			<fieldset class="fieldset">
        <legend class="legend" >Datos de la sucursal</legend>

        <div class="form-group">
          <input type="text" formControlName="id" class="form-control" hidden="true" >
        </div>
  
        <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_partner">Nombre</label>
              <input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
              <validator [control]="updatePartnerForm.controls['name_partner']"></validator>
            </div>
					<div class="field-row-item">
            <label for="sucursal">Compañía</label>
            <mat-select [formControl]="parentPartnerId">
                  <mat-option *ngFor="let compania of companias" [value]="compania.id">{{compania.name_partner}}</mat-option>
            </mat-select>
            <validator [control]="parentPartnerId"></validator>
					</div>
				</div>
        
       

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="adress_partner" >Direccion</label>
						<textarea formControlName="adress_partner"></textarea>
						<validator [control]="updatePartnerForm.controls['adress_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" >Pais</label>
					
            <mat-select [formControl]="country_obj" (selectionChange)="onItemChange($event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
            </mat-select>
            <validator [control]="country_obj"></validator>

					</div>
					<div class="field-row-item">
						<label class="label" for="city">Ciudad</label>  
            <mat-select [formControl]="city_obj" >
                  <mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
            </mat-select>
            <validator [control]="city_obj"></validator>

					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone_partner" >Telefono</label>
						<input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
						<validator [control]="updatePartnerForm.controls['phone_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="feeRate_partner">Porcentaje de cobro</label>
						<input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
						<validator [control]="updatePartnerForm.controls['feeRate_partner']"></validator>
          </div>
          <div class="field-row-item">
            <label class="label" for="returnRoute">Ruta de notificación</label>
            <input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" >
            <button style="display:inline;box-shadow: none;padding:0;min-width: 30px;" mat-raised-button matTooltip="Agregar nombre del cliente Ej: poliwin"
              aria-label="Button that displays a tooltip when focused or hovered over" >
              <i class="material-icons">info</i>
            </button>
            <validator [control]="updatePartnerForm.controls['returnRoute']"></validator>
          </div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email_partner">Email</label>
						<input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
						<validator [control]="updatePartnerForm.controls['email_partner']"></validator>
          </div>
          
          <div class="field-row-item">
            <label class="label" for="identifier_bank">Identificador del banco</label>
            <input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
            <validator [control]="updatePartnerForm.controls['identifier_bank']"></validator>
          </div>

          <div class="field-row-item">
              <label class="label" for="status" >Estatus</label>
              <mat-select [formControl]="status_partner">
                <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
              </mat-select>
              <validator [control]="status_partner"></validator>
          </div>
          
				</div>
				

			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!updatePartnerForm.valid || !status_partner.valid || !city_obj.valid || !country_obj.valid">
					<span>Actualizar</span>
				</button>
			</div>
		</form>
	