import { Component,Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService} from '../shared/notification/notification.service';
import {ServicioService} from "../servicio/servicio.service";
import {Servicio} from "../servicio/servicio";
import {Customer_has_plans} from "./customer_has_plans";
import {CustomerService} from "../customer/customer.service";
import {Customer_has_plansService} from "./customer_has_plans.service";
import {Customer} from "../customer/customer";
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import { HasplanFilter } from './hasplan.filter';

@Component({
  template: `
	
	<h3 *ngIf="!show" class="title">Listado de planes de  : {{customer?.name_customer + " " + customer?.lastName_customer}}</h3>

  <h3 *ngIf="show" class="title">Listado de planes</h3> 

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name_plan">
							<span class="label">Plan</span>
							<span>{{tableService.filter.name_plan}}</span>
            			</div>
            
						<div class="field-row-item" *ngIf="tableService.filter.name_service">
							<span class="label">Servicio</span>
							<span>{{tableService.filter.name_service}}</span>
						</div>

					
						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name_plan">Plan</label>
							<input type="text" name="name_plan" placeholder="None"  [(ngModel)]="filter.name_plan">
						</div>
						<div class="field-row-item">
								<label class="label" for="company" >Servicio</label>
								<mat-select placeholder="None" [(value)]="filter.name_service" [compareWith]="compareFn"  disableRipple>
									<mat-option >None</mat-option>
									<mat-option *ngFor="let opt of services" [value]="opt.id">{{opt.name_service}}</mat-option>
								</mat-select>
						</div>
					</div>


					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="status_partner" >Estatus</label>
							<mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
								<mat-option >None</mat-option>
								<mat-option [value]="'1'">Activo</mat-option>
								<mat-option [value]="'2'">Inactivo</mat-option>
							</mat-select>
						</div>
					</div>
					-->
					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div *hasPermission="['hasplan.create']" class="tool-bar-wrap both-side">
		<div class="right row">

			<button class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
			
			<button class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i class="material-icons">delete</i>
			</button>

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="endDate_customerPlan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Servicio</th>
				<td mat-cell *matCellDef="let element">{{element.endDate_customerPlan}}</td>
      </ng-container>
      
      <ng-container matColumnDef="contractNumber_Plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
        <td mat-cell *matCellDef="let element">{{element.contractNumber_Plan}}</td>
      </ng-container>
			
			<ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['chp.update']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template  contextMenuItem (execute)="cupons($event.item)">
				<div class="item">
					<i class="material-icons">card_membership</i>
					<span>Cupones</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['chp.update']" contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span>Eliminar</span>
				</div>
			</ng-template>
		
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})

export class CustomerHasPlansListComponent {


  	show = false;
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['select', 'id', 'endDate_customerPlan', 'contractNumber_Plan', 'createdAt'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new HasplanFilter(this.tableService.filter);
	@Input('id_customer') id_customer : number;
	title: string = "Información de planes: ";
	items: any[] = [];
  	customer : Customer;
  	selectedService : Servicio;
	services : object = [];
	
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private customerService: CustomerService,
    	private customerHasPlansService : Customer_has_plansService,
		public tableService: TableService<any>,
		private notificationService: NotificationService,
    	private servicioService : ServicioService
	){
		this.tableService = new TableService;
	}

	ngOnInit(){
	//Se carga la informacion del cliente
    this.customerService.getById(JSON.parse(localStorage.getItem("customer")).cust).subscribe(customer => {
			this.customer = customer;
			if(JSON.parse(localStorage.getItem("servicio")) == undefined) {
				this.servicioService.getByPartner(JSON.parse(localStorage.getItem("data")).user).subscribe(param => {
					localStorage.setItem("servicio", JSON.stringify({ser: param['result'][0].id}));
					this.list();
				});
			}else{
				this.list();
			}
		});
		
	}

	list(event?: PageEvent){
	
	if(this.tableService.pager.pageSize != 20){
		this.tableService.pager.pageSize = 20;
	}
	let httpParams = this.servicioService.buildRequestParams(this.tableService.sort,"m",
	{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})


	httpParams = this.filter.getHttpParams(httpParams);
		this.customerHasPlansService.getByCustomer(this.customer.id,httpParams).subscribe(response =>{
					this.items = response['result']
					
					for(let item of this.items){

							

							item.contractNumber_Plan = item.plan.name_plan;
							item.endDate_customerPlan = item.plan.service.name_service;
							
							if(item.status_customerPlan == 1)
								item.createdAt = 'Activo';
							if(item.status_customerPlan == 2)
								item.createdAt = 'Inactivo';
							if(item.status_customerPlan == 3)
								item.createdAt = 'Congelado';
	
							
						
						//Se cargan los planes que el cliente tiene asociados en el componente lista
					}
					this.dataSource = new MatTableDataSource<any>(this.items);
					this.tableService.pager = response['pager'];
					this.tableService.selection.clear();
			}, (err) => {
        this.notificationService.error(err)
      })
	}

	create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
	}

	read(item: Customer_has_plans){
		localStorage.setItem("customer_has_plan", JSON.stringify({chp: item.id}));
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
	}

  formatearFecha(fecha : string) : string{
    let splitted = fecha.split("-");
    return splitted[2] + "-" + splitted[1] + "-" + splitted[0];
  }

	update(item: Customer_has_plans){
		localStorage.setItem("customer_has_plan", JSON.stringify({chp: item.id}));
		this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
	}

	cupons(item: Customer_has_plans){
    localStorage.setItem("customer_has_plan", JSON.stringify({chp: item.id}));
		this.router.navigate(["./" + item.id + '/cupons'], {relativeTo: this.activatedRoute});
	}

deletes() {
	const length = this.tableService.selection.selected.length;
	this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
		--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
				let chp = this.tableService.selection.selected[0];
				chp.status_customerPlan = 2;
				this.customerHasPlansService.update(chp).subscribe(param => {
				this.notificationService.sucessDelete(param['plan']['name_plan']);
					this.list();
				}, (err) => { this.notificationService.error(err); });

		} else if (length > 1) {

			for(let plan of this.tableService.selection.selected){

					
					plan.status_customerPlan = 2;
					
					this.customerHasPlansService.update(plan).subscribe(param => {
						this.list();
					}, (err) => { this.notificationService.error(err); });

		
			}
			
	} 
}

reset() {
	this.filter = new HasplanFilter();
	this.list();
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new HasplanFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new HasplanFilter(this.tableService.filter);
}

compareFn(c1: any, c2: any): boolean { 
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}	
applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
