import { Routes } from '@angular/router';
import { PartnerComponent } from './partner.component';
import { PartnerCreateComponent } from './partner-create.component';
import { PartnerListComponent } from './partner-list.component';
import { PartnerReadComponent } from './partner-read.component';
import { PartnerUpdateComponent } from './partner-update.component';
import { servicioRoutes } from '../servicio/servicio.routes';
import {customerRoutes} from '../customer/customer.routes';
import {branchRoutes} from '../branch/branch.routes';


export const partnerRoutes: Routes = [
	{
	    path: 'partners',
	    component: PartnerComponent,
	    data: {
	    	breadcrumb: "Compañías",
	    	icon: "list"
	    },
	    children:[
		    {
			    path: '',
			    pathMatch: 'full',
			    component: PartnerListComponent,
			    data: {
			    	breadcrumb: undefined
			    }
			},
			{
			    path: 'create',
			    component: PartnerCreateComponent,
			  	data: {
			    	breadcrumb: "Crear",
	    			icon: "create"
			    }
		  	},
		  	{
			    path: ':partnerId',
			    component: PartnerComponent,
			    data: {
			    	breadcrumb: "Detalle",
	    			icon: "detail"
			    },
			    children:[
				    {
					    path: '',
					    pathMatch: 'full',
					    component: PartnerReadComponent,
					    data: {
					    	breadcrumb: undefined,
					    }
					},
					{
					    path: 'update',
					    component: PartnerUpdateComponent,
					  	data: {
					    	breadcrumb: "Actualizar",
	    					icon: "update"
					    }
				  	}

			  	]
		  	},
		  	...servicioRoutes,
        	...customerRoutes,
        	...branchRoutes
	  	]
  	}
];

export const routingPartnerComponnents = [PartnerComponent,PartnerListComponent, PartnerReadComponent, PartnerUpdateComponent, PartnerCreateComponent ];
