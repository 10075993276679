import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { FilterComponent } from './filter-ns/filter-ns.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }  from '@angular/forms';
import { ValidatorComponent } from './form/validator.component';
import { ModalComponent } from './modal-ns/modal-ns.component';
import { ModalContentDirective } from './modal-ns/modalContentDirective';
import { HasPermissionDirective } from '../utils/auth/hasPermissionDirective'
import { ToastrModule } from 'ngx-toastr';
import { GalleryImagePreviewComponent } from './gallery/gallery-image-preview.component';
import { GalleryImagePreviewUniqueComponent } from './gallery/gallery-image-preview-unique.component';
import { GalleryImageComponent } from './gallery/gallery-image.component';
import { GalleryPdfComponent } from './gallery/gallery-pdf.component';
import { GalleryPdfPreviewUniqueComponent } from './gallery/gallery-pdf-preview-unique.component';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    MatCheckboxModule
  ],
  declarations: [
    ModalComponent,
		ModalContentDirective,
    ValidatorComponent,
    FilterComponent,
    NotificationComponent,
    HasPermissionDirective,
    GalleryImagePreviewComponent,
		GalleryImagePreviewUniqueComponent,
		GalleryImageComponent,
		GalleryPdfComponent,
		GalleryPdfPreviewUniqueComponent
  ],
  exports: [
    ModalComponent,
		ModalContentDirective,
    ValidatorComponent,
    FilterComponent,
    NotificationComponent,
    HasPermissionDirective,
    GalleryImagePreviewComponent,
		GalleryImagePreviewUniqueComponent,
		GalleryImageComponent,
		GalleryPdfComponent,
		GalleryPdfPreviewUniqueComponent
	]
})
export class SharedModule { }
