import { Routes } from '@angular/router';
import {UserComponent} from './user/user.component';
import {UserListComponent} from './user-list/user-list.component';
import {UserCreateComponent} from './user-create/user-create.component';
import {UserReadComponent} from './user-read/user-read.component';
import {UserUpdateComponent} from './user-update/user-update.component';
import { UserUpdatePasswordComponent } from './user-update-password/user-update-password.component';

export const userRoutes: Routes = [
  {
    path: 'users',
    component: UserComponent,
    data: {
      breadcrumb: 'Usuarios',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UserListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: UserCreateComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':userId',
        component: UserComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: UserReadComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: UserUpdateComponent,
            data: {
              breadcrumb: 'Actualizar',
              icon: 'update'
            }
          },
          {
            path: 'password',
            component: UserUpdatePasswordComponent,
            data: {
              breadcrumb: 'Actualizar',
              icon: 'update'
            }
          }
        ]
      }
    ]
  }
];

export const routingUserComponents = [UserComponent,UserListComponent,UserCreateComponent,UserReadComponent,UserUpdateComponent,UserUpdatePasswordComponent];
