import { HttpParams } from '@angular/common/http';

export class UserFilter {

    name_user: string;
    lastname_user: string;
    email_user: string;
    id: number;
    
	constructor(filter?: UserFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_user = filter.name_user;
            this.lastname_user = filter.lastname_user;
            this.email_user = filter.email_user;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_user) {
			httpParams = httpParams.set('name_user', this.name_user);
        }
        if (this.lastname_user) {
			httpParams = httpParams.set('lastname_user', this.lastname_user);
        }
        if (this.email_user) {
			httpParams = httpParams.set('email_user', this.email_user);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		return httpParams;
	}
}