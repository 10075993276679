import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {JobService} from '../job.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  template: `
  <h3 class="title">Listado de Demonios</h3>
  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
        <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
      </ng-container>
      
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Descripción</th>
        <td mat-cell *matCellDef="let element">{{element.description}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container>

      <ng-container matColumnDef="last_executed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Ultima ejecucion</th>
        <td mat-cell *matCellDef="let element">{{element.last_executed}}</td>
      </ng-container>

      <ng-container matColumnDef="next_execution">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Proxima ejecucion</th>
        <td mat-cell *matCellDef="let element">{{element.next_execution}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row" (click)="read(row)">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template *hasPermission="['group.update']" contextMenuItem (execute)="activate($event.item)">
        <div class="item">
          <i class="material-icons">done</i>
          <span>Activar</span>
        </div>
      </ng-template>
      <ng-template contextMenuItem divider="true"></ng-template>

        <ng-template  *hasPermission="['group.update']" contextMenuItem (execute)="update($event.item)">
					<div class="item">
						<i class="material-icons">edit</i>
						<span>Actualizar</span>
					</div>
				</ng-template>
				
				<ng-template contextMenuItem divider="true"></ng-template>

      <ng-template *hasPermission="['group.update']" contextMenuItem (execute)="desactivate($event.item)">
        <div class="item">
          <i class="material-icons">block</i>
          <span>Desactivar</span>
        </div>
      </ng-template>
    
  </context-menu>

`
})
export class ListJobComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['fullName', 'description', 'status', 'last_executed', 'next_execution'];
	confirmDelete = true;
	isOpenSearchPanel = false;
  


  items: any[] = [];
  jobs: object = [];

  

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService,
    public tableService: TableService<any>,
    private notificationService: NotificationService
  ) {this.tableService = new TableService;}

  ngOnInit() {
    this.list(); // listamos la informacion que se mostrara en la tabla
  }

  list(event?: PageEvent) {
    
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
    
    let httpParams = this.jobService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
    this.items = [];
    this.jobService.getAll(httpParams).subscribe(response => {
      if (response['result'] != null) {
        this.items = response['result'];
        for (const job of this.items) {
         if (job.status === 0) {
           job.status = 'Inactivo';
         }
         if (job.status === 1) {
            job.status = 'Arrancando';
         }
          if (job.status === 2) {
            job.status = 'Corriendo';
          }
        }
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
      }
    }, err => {
      this.notificationService.error(err);
    });
  }
  activate(item) {
   this.jobService.update(item.id, 1).subscribe( j => {
     this.notificationService.sucessInsert(j['fullName']);
     this.list(); // listamos la informacion que se mostrara en la tabla
   }, error1 => {
     console.log(error1);
     this.notificationService.error('Error Actualizando el Job');
   });
  }
  desactivate(item) {
    this.jobService.update(item.id, 0).subscribe( j => {
      this.notificationService.sucessInsert(j['fullName']);
      this.list(); // listamos la informacion que se mostrara en la tabla
    }, error1 => {
      console.log(error1);
      this.notificationService.error('Error Actualizando el Job');
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  update(item: any) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  read(item: any) {
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

}
