import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OptionService } from '../option.service';

@Component({
  template: `
	<h3 class="title">Opcion</h3>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['options.create']" class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ option?.name || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ option?.plan?.name_plan || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Servicio</span>
					<span>{{ option?.plan?.service?.name_service || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Posición</span>
					<span>{{ option?.position || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ option?.status || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Descripción</span>
					<editor [inline]="true" [disabled]="true" apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh"
						[init]="{
							height: 500,
							menubar: false,
							plugins: [
							],
							toolbar:
							  ''
						  }"
						[(ngModel)]="option.description" >
						</editor>
				</div>
      </div>


		</div>
	</div>

`
})
export class OptionReadComponent implements OnInit {

  option: any;

  constructor(
    private router: Router,
		private activatedRoute: ActivatedRoute,
		private optionService: OptionService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param =>{

      this.optionService.getById(param['optionID']).subscribe(option=>{
        
        switch(option.status) { 
          case 1: { 
            option.status = "Activo"; 
             break; 
          } 
          case 2: { 
            option.status = "Inactivo"; 
             break; 
          } 
          default: {  
             break; 
          } 

        }

        this.option = option;
      });

    });
  }

  update(){
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

}
