import {Component, OnInit, ViewChild} from '@angular/core';
import {PartnerService} from '../../partner/partner.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Partner} from '../../partner/partner';
import {NotificationService} from '../../shared/notification/notification.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { PartnerFilter } from '../../partner/partner.filter';

@Component({
  template: `
  <h3 class="title">Listado de Sucursales</h3>

  <mat-accordion>
    <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
      <mat-expansion-panel-header>
        <div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
          <div class="field-row">
          <!--
            <div class="field-row-item" *ngIf="tableService.filter.id">
              <span class="label" i>id</span>
              <span>{{tableService.filter.id}}</span>
            </div>
            -->
            <div class="field-row-item" *ngIf="tableService.filter.name_partner">
              <span class="label">Nombre</span>
              <span>{{tableService.filter.name_partner}}</span>
            </div>
            <!--
            <div class="field-row-item" *ngIf="tableService.filter.status_partner">
              <span class="label">Estatus</span>
              <span>{{tableService.filter.status_partner}}</span>
            </div>
            -->
            
          </div>
        </div>
        <button class="btn-icon"  title="Buscar" type="button">
          <i class="material-icons">search</i>
        </button>
      </mat-expansion-panel-header>

      <form>
        <fieldset class="fieldset">
          <!--
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="id">id</label>
              <input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
            </div>
          </div>
        -->
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_partner">Nombre</label>
              <input type="text" name="name_partner" placeholder="None"  [(ngModel)]="filter.name_partner">
            </div>
          </div>
          <!--
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="status_partner" >Estatus</label>
              <mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
                <mat-option >None</mat-option>
                <mat-option [value]="'1'">Activo</mat-option>
                <mat-option [value]="'2'">Inactivo</mat-option>
              </mat-select>
            </div>
          </div>
          -->
          
        </fieldset>
        <div class="options">
          <button class="btn-text gray" type="button" (click)="reset()">
            <span >Limpiar</span>
          </button>
          <button class="btn-text blue" type="button" (click)="search()">
            <span>Buscar</span>
          </button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button *hasPermission="['create.partner']" class="btn-icon" type="button" (click)="create()">
        <i class="material-icons">add</i>
      </button>
      

      <button  class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
          <i class="material-icons">delete</i>
      </button>
   

    </div>
  </div>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
  
    <mat-form-field style="display:inline;" appearance = "standard">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name_partner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
        <td mat-cell *matCellDef="let element">{{element.name_partner}}</td>
      </ng-container>
      idparent
      <ng-container  matColumnDef="parentPartner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Compañía</th>
        <td mat-cell *matCellDef="let element">{{element?.parentPartner?.name_partner || element.parentPartner }}</td>
      </ng-container>
      
      <ng-container matColumnDef="status_partner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.status_partner}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
        (click)="read(row)">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template *hasPermission="['partner.update']" contextMenuItem (execute)="update($event.item)">
        <div class="item">
          <i class="material-icons">edit</i>
          <span>Actualizar</span>
        </div>
      </ng-template>
      
      <ng-template contextMenuItem divider="true"></ng-template>
      <ng-template *hasPermission="['service.by.partner']" contextMenuItem (execute)="services($event.item)">
        <div class="item">
          <i class="material-icons">view_carousel</i>
          <span>Servicios</span>
        </div>
      </ng-template>

      <ng-template *hasPermission="['all.customer']" contextMenuItem (execute)="customers($event.item)">
        <div class="item">
          <i class="material-icons">people</i>
          <span>Clientes</span>
        </div>
      </ng-template>

      <ng-template contextMenuItem divider="true"></ng-template>
      <ng-template *hasPermission="['partner.delete']" contextMenuItem (execute)="confirmDelete = false">
        <div class="item">
          <i class="material-icons">delete</i>
          <span>Eliminar</span>
        </div>
      </ng-template>
    
  </context-menu>

  <modal-ns [(closed)]="confirmDelete">
    <ng-template modalContentDirective>
      <div class="dialog-content">
        <div class="dialog-title" >Confirmation</div>
        <div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
        <div class="options">
          <button class="btn-text red" type="button" (click)="confirmDelete = true">
            <span >No</span>
          </button>
          <button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
            <span>SI</span>
          </button>
        </div>
      </div>
    </ng-template>statusServiceOrder
  </modal-ns>
`
})
export class ListBranchComponent implements OnInit {

  // --
  dataSource: MatTableDataSource<any>;
	displayedColumns: string[];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
  filter = new PartnerFilter(this.tableService.filter);
  //----
  idparent = -1;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) {
    this.tableService = new TableService;
  }
  ngOnInit() {
    // comprobamos de donde venimos, si existe un partnerId entonces venimos del listado de Compañia
    this.activatedRoute.params.subscribe(param => {
      if (param['partnerId']) { // tomamos el id para asi listar los branch de esa Compañia
        this.idparent = param['partnerId'];
        this.displayedColumns = ['select', 'id', 'name_partner' , 'status_partner'];
      }else{
        this.displayedColumns = ['select', 'id', 'name_partner', 'parentPartner' , 'status_partner'];
      }
    });
    this.list(); // listamos la informacion que se mostrara en la tabla
  }
  list(event?: PageEvent) {
    
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
    let httpParams = this.partnerService.buildRequestParams(this.tableService.sort,
      'm',{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

      httpParams = httpParams.set('parents',true.toString());
      httpParams = httpParams.set('parent',this.idparent.toString());

      httpParams = this.filter.getHttpParams(httpParams);
      
      this.partnerService.getPartnersByUserId(httpParams)
        .subscribe(response => {
          for(const r of response['result']){
            if(r.status_partner == 1){
              r.status_partner = 'Activo';
            }
            if(r.status_partner == 2){
              r.status_partner = 'Inactivo';
            }
          }
          // Esto es para manejar la respuesta del servidor cuando no viene el objeto del parentPArtner
          for(const p of response['result'] ){
            if(p.parentPartner.id === undefined){
              for(const pa of response['result'] ){
                if( pa.parentPartner.id !== undefined && p.parentPartner === pa.parentPartner.id ){
                  p.parentPartner = pa.parentPartner;
                }
              }
            }
          }
          this.dataSource = new MatTableDataSource<any>(response['result']);
          this.tableService.pager = response['pager'];
          this.tableService.selection.clear(); 
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });
  }

  //-- 

  deletes() {
    const length = this.tableService.selection.selected.length;
    this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
      --this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;
  
    if (length === 1) {
      this.partnerService.delete(this.tableService.selection.selected[0].id).subscribe(response => {
        this.notificationService.sucessDelete(response.email_partner);
        this.list();
      }, err => this.notificationService.error(err));
  
    } else if (length > 1) {
      this.partnerService.deletes(this.tableService.selection.selected).subscribe(any =>  {
        this.notificationService.sucessDelete();
        this.list();
        }, err => this.notificationService.error(err));
    } 
  }

  reset() {
    this.filter = new PartnerFilter();
    this.list();
  }
  
  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new PartnerFilter(this.filter);
    this.list();
  }
  
  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new PartnerFilter(this.tableService.filter);
  }
  
  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
  //--
  create() { /// crear un partner
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }
  read(item: Partner) { // leer un partner
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }
  update(item: Partner) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }
  services(item: Partner) {
    localStorage.setItem('data', JSON.stringify({user: item.id, token : item.token}));
    this.router.navigate(['../' + item.id + '/services'], {relativeTo: this.activatedRoute});
  }

  customers(item: Partner) {
    localStorage.setItem('data', JSON.stringify({user: item.id, token : item.token}));
    this.router.navigate(['../' + item.id + '/customers'], {relativeTo: this.activatedRoute});
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
