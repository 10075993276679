import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import { Observable } from 'rxjs';
import {Creditcard} from "./creditcard";
import { map, catchError } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

export interface Filter {
    name_customer?: string;
}


@Injectable({
  providedIn : 'root'
})
export class CreditcardService extends BaseService {


    httpOptions : any;
    id_customer : number = JSON.parse(localStorage.getItem('customer')).cust;
  	BASE_URL: string = this.HOST + '/customer/' + this.id_customer + '/creditcard';

    constructor(
        private http: HttpClient,
    ){
        super();
        // Http Headers
        this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
    }

  create(IDcustomer:number,cc : Creditcard): Observable<Object[]> {
    /*
     {	"numberCreditCard" : "1287128444",
     "expMonthCreditCard" : "02",
     "expYearCreditCard" : "17",
     "nameHolderCreditCard" : "Juan Perez"
     }
     */
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard';
    return this.http.post(this.BASE_URL, {"numberCreditCard" : cc.numberCreditCard,
    "expMonthCreditCard" : cc.expMonthCreditCard.toLocaleString(), "expYearCreditCard" : cc.expYearCreditCard.toLocaleString(),
    "nameHolderCreditCard" : cc.nameHolderCreditCard}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  findByCustomer(IDcustomer:number) : Observable<any>{
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard';
    return this.http.get(this.BASE_URL,this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(IDcustomer:number,id: number) : Observable<any>{
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard/' + id;
    return this.http.get(this.BASE_URL,this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  findByToken(IDcustomer:number,token: string) : Observable<Object>{
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard/token/' + token;
    return this.http.get(this.BASE_URL,this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  findTdcById(IDcustomer:number,tdc: number) : Observable<Object>{
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/customerHasCreditCard/' + tdc;
    return this.http.get(this.BASE_URL,this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  delete(IDcustomer:number,id: number): Observable<any> {
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard/' + id;
    return this.http.delete(this.BASE_URL, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  update(IDcustomer:number,cc: Creditcard) : Observable<any>{
    this.BASE_URL = this.HOST + '/customer/' + IDcustomer + '/creditcard';
    return this.http.put(this.BASE_URL, {"id": cc.id, "nameHolderCreditCard" : cc.nameHolderCreditCard,
    "expMonthCreditCard" : cc.expMonthCreditCard, "expYearCreditCard" : cc.expYearCreditCard}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }
}
