
	<h3 class="title">Detalle de la frecuencia</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ frecuency?.name_frecuency ||  '-' }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Dia</span>
					<span>{{ frecuency?.day_frecuency || '-' }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Mes</span>
					<span>{{ frecuency?.month_frecuency || '-' }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Dia de la semana</span>
					<span>{{ frecuency?.dayOfWeek_frecuency || '-' }}</span>
				</div>
      </div>
      
    </div>
   </div>
