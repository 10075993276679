import { Routes } from '@angular/router';
import {CustomerHasPlansComponent} from "./customer_has_plans.component";
import {CustomerHasPlansListComponent} from "./customer_has_plans-list.component";
import {CustomerHasPlansCreateComponent} from "./customer_has_plans-create.component";
import {CustomerHasPlansReadComponent} from "./customer_has_plans-read.component";
import {CustomerHasPlansUpdateComponent} from "./customer_has_plans-update.component";
import { cuponsRoutes } from '../cupon/cupon.routes';

export const customerHasPlansRoutes: Routes = [
	{
				path: ':customerID/hasplans',
				component: CustomerHasPlansComponent,
				data: {
					breadcrumb: "Planes del cliente"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: CustomerHasPlansListComponent,
						data: {
						   	breadcrumb: undefined
						    }
					},
					{
					    path:'create',
					    component: CustomerHasPlansCreateComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':customerhasplansID',
					    component: CustomerHasPlansComponent,
					    data: {
					    	breadcrumb: "Detalle",
			    			icon: "detail"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: CustomerHasPlansReadComponent,
							    data: {
							    	breadcrumb: undefined,
							    }
							},
							{
							    path: 'update',
							    component: CustomerHasPlansUpdateComponent,
							  	data: {
							    	breadcrumb: "Actualizar",
			    					icon: "update"
							    }
							  },
							  ...cuponsRoutes
					  	]
					  }
					  
				]
			}
];

export const routingHasPlanComponnents = [CustomerHasPlansComponent, CustomerHasPlansListComponent, CustomerHasPlansReadComponent, CustomerHasPlansCreateComponent, CustomerHasPlansUpdateComponent];
