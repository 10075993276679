import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/notification/notification.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/form/custom.validators';
import { UserService } from '../user.service';
import { Location } from '@angular/common';
import {BaseService} from '../../shared/services/base.service';

@Component({
  template: `
		<h3 class="title" >Actualizar contraseña</h3>

		<form *ngIf="updatePasswordForm" [formGroup]="updatePasswordForm" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend">User data</legend>

        <div class="field-row">
        
					<div class="field-row-item">
              <label class="label" for="currentPassword" >Contraseña actual</label>
              <input formControlName="currentPassword" type="password" autocomplete="off">
              <validator [control]="updatePasswordForm.controls['currentPassword']"></validator>
          </div>
          
          <div class="field-row-item">
            <label class="label" for="newPassword" >Nueva contraseña</label>
            <input formControlName="newPassword" type="password" autocomplete="off">
            <validator [control]="updatePasswordForm.controls['newPassword']"></validator>
          </div>

          <div class="field-row-item">
						<label class="label" for="newPasswordConfirm" >Confirma la nueva contraseña</label>
						<input formControlName="newPasswordConfirm" type="password" autocomplete="off">
						<validator [control]="updatePasswordForm.controls['newPasswordConfirm']"></validator>
          </div>

        </div>
       
			
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!updatePasswordForm.valid">
					<span>Guardar</span>
				</button>
			</div>
		</form>
	`
})
export class UserUpdatePasswordComponent implements OnInit {

  user;

  constructor(
		private location: Location,
    private notificationService: NotificationService,
		private fb: FormBuilder,
    private userService: UserService
  ) { }

  // validaciones para campos de formulario
  updatePasswordForm = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['',[Validators.required]],
    newPasswordConfirm: ['',[Validators.required, CustomValidators.match('newPassword')]]
  });

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentData')).user;
  }

  update() {
    const obj = <any> BaseService.builderObject(this.updatePasswordForm.value);
  /*  console.log(this.user.authUser.password);
    console.log(obj.currentPassword);
    console.log(obj);
    if(this.user.authUser.password !==  obj.currentPassword){
        this.notificationService.error("Contraseña actual NO coincide");
        return;
    }*/
    
		this.userService.updatePassword(this.user.authUser.id,obj).subscribe(response=>{
      this.notificationService.sucessUpdate("Contraseña")
      this.location.back()
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    }); 
	}

}
