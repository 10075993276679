import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';

@Component({
  template: `
	<h3 class="title">Reporte de notificaciones fallidas</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

				
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
          <div class="field-row-item">
            <label class="label" for="company" >Compañia</label>
            <mat-select placeholder="None" [(value)]="filter.id_partner" >
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>
          </div>
					</div>

          <div class="field-row">

						<div class="field-row-item">

							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>

            			</div>
            
            			<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker2></mat-datepicker>
            			</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

  <!--
	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>
-->


	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="customer.name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.customer.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="customer_has_plan.plan.name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.customer_has_plan.plan.name_plan}}</td>
      </ng-container>
      
      <ng-container matColumnDef="notification_response">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Respuesta</th>
        <td mat-cell *matCellDef="let element">{{element.notification_response}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Creada</th>
        <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
	  </ng-container>
	  

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class NotificationFailedComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'id', 'customer.name_customer', 'customer_has_plan.plan.name_plan', 'notification_response',  'createdAt'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

  partners: any[]= [];
  selectedPartner: any;
  partner: any;

  items: any[] = [];

  toExport : any[] = [];

  filterDateDesde = null;
  filterDateHasta = null;

  static_data: any[] = [];

  constructor(
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}

  ngOnInit() {
		let httpParams = this.partnerService.buildRequestParams();

		httpParams = httpParams.set('parents',false.toString());
		httpParams = httpParams.set('parent',(-1).toString());
    	  // cargamos companias
        this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
          this.partners = params['result'];
		  // cargamos sucursales
		let httpParams2 = this.partnerService.buildRequestParams();

		httpParams2 = httpParams2.set('parents',true.toString());
		httpParams2 = httpParams2.set('parent',(-1).toString());
          this.partnerService.getPartnersByUserId(httpParams2).subscribe(params =>{
          for( var p of params['result']){
            this.partners.push(p)
          }
          if(this.filter.getHttpParams()['updates'] != null ){
            this.list();
            }
          });
          }, err => {
            this.notificationService.error(err);
            console.log(err);
          });
  }

  list(event?: PageEvent) {

		if(this.filter.id_partner == undefined){
			this.notificationService.error("Debe seleccionar al menos una compania");
			return;
		}
		
		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}
		this.items = [];
	
			if(this.filter.since_date != null && this.filter.until_date == null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
				return;
			}
			if(this.filter.since_date == null && this.filter.until_date != null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
				return;
			}
			this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
			this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
			var since = new  Date (this.filter.since_date);
			var until = new  Date (this.filter.until_date);
			if(until.getTime() < since.getTime()){
				this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
				return;
			}
			this.tableService.filter = new ReportFilter(this.filter);
			
			let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
  
	  		httpParams = this.filter.getHttpParams(httpParams);
			
			  this.reportService.getInvoicesToNotificate(httpParams).subscribe(params => {
				this.items = params['result']; // items que mostrara la tabla
				this.dataSource = new MatTableDataSource<any>(this.items);
				this.tableService.pager = params['pager'];
				this.tableService.selection.clear(); 
				if (this.items.length === 0) {
					this.notificationService.alert('No se encontraron resultados para la busqueda');
				}
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 
		
  }

  reset() {
    this.filter = new ReportFilter();
	this.dataSource = new MatTableDataSource<any>([]);
	this.tableService.filter = new ReportFilter(this.filter);
  }
  
  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new ReportFilter(this.filter);
    this.list();
  }
  
  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new ReportFilter(this.tableService.filter);
  }
  
  
  applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  export() {
    if(this.items.length > 0){
       
        this.tableService.filter = new ReportFilter(this.filter);
        let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	
     	httpParams = this.filter.getHttpParams(httpParams);
        this.reportService.getReportInvoices(httpParams).subscribe(params => {
          let data = [];
        
           for(var item of params['result']){
            data.push({'ID':item.id,'CLIENTE':item.name_customer,'PLAN':item.name_plan,'ESTATUS':item.desc_status,'MONTO':item.total_invoice,'FECHA':item.date_created_invoice})
           }
        
          const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
          const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            useTextFile: false,
            filename: "Report-Invoices-"+date,
            useBom:false,
            useKeysAsHeaders: true,
          };
          const csvExporter = new ExportToCsv(options);
  
          this.toExport = data;
          csvExporter.generateCsv(this.toExport);
        
        }, err => {
          this.notificationService.error(err);
          console.log(err);
        }); 
  
      }else{
        this.notificationService.error("No existen registros para exportar");
      }
      
    }

}
