

  <h3  class="title">Listado de Tarjetas de credito</h3>

  <form *ngIf="estado" [formGroup]="addNewCreditcardForm" (ngSubmit)="add()" novalidate>

  <fieldset class="fieldset">

  <div class="field-row">
      
				<div class="field-row-item">
          <label for="numberCreditCard">Número de tarjeta</label>
          <input type="text" formControlName="numberCreditCard" (keypress)="onlyNumberKey($event)"   class="form-control" placeholder="Número de tarjeta" required>
          <validator [control]="addNewCreditcardForm.controls['numberCreditCard']"></validator>
        </div>
        
				<div class="field-row-item">
          <label for="nameHolderCreditCard">Nombre en tarjeta</label>
          <input type="text" formControlName="nameHolderCreditCard"   class="form-control" placeholder="Nombre" required>
          <validator [control]="addNewCreditcardForm.controls['nameHolderCreditCard']"></validator>
        </div>

        <div class="field-row-item">
          <label style="display: block; " for="expiration">Vencimiento</label>
          <label for="expMonthCreditCard"></label><input type="text" (keypress)="onlyNumberKey($event)" formControlName="expMonthCreditCard" style="width: 15%; " min="1" max="12" maxlength="2" class="form-control" placeholder="Mes" required>
          <label for="expYearCreditCard"></label><input type="text" (keypress)="onlyNumberKey($event)" min="1" max="99" formControlName="expYearCreditCard" style="width: 15%; " maxlength="2" class="form-control" placeholder="Año" required>
          <validator [control]="addNewCreditcardForm.controls['expYearCreditCard']"></validator>
        </div>

      </div>

    <div class="options">
      <button class="btn-text" type="button" (click)="cancel()">
        <span> Cancelar </span>
      </button>
			<button class="btn-text" type="submit" [disabled]="!addNewCreditcardForm.valid">
				<span> Añadir </span>
			</button>
		</div>
    
  </fieldset>
  </form>

  <form *ngIf="editar" [formGroup]="editCreditcardForm" (ngSubmit)="edit(idedit)" novalidate>

    <fieldset class="fieldset">

      <div class="field-row">

          <input type="text" value="{{ idedit }}" hidden/>
        
          <div class="field-row-item">
            <label for="nameHolderCreditCard">Nombre en tarjeta</label>
            <input type="text" formControlName="nameHolderCreditCard" class="form-control" placeholder="Nombre" required>
            <validator [control]="editCreditcardForm.controls['nameHolderCreditCard']"></validator>
          </div>
        
      </div>

      <div class="options">
        <button class="btn-text" type="button" (click)="cancel()">
          <span> Cancelar </span>
        </button>
        <button class="btn-text" type="submit" [disabled]="!editCreditcardForm.valid">
          <span> Editar </span>
        </button>
		  </div>
    
    </fieldset>
  </form>


	<div class="tool-bar-wrap both-side">
    <div class="right row">
    
			<button *hasPermission="['tdc.create']" class="btn-icon" title="Crear" type="button" (click)="create()">
				<i class="material-icons">add</i>
      </button>
      
      <!--
      <button class="btn-icon"  title="Cambiar estado de la tarjeta" type="button" (click)="change($event.item)">
          <i class="material-icons">loop</i>
      </button>
			
			<button class="btn-icon"  title="Editar nombre de la tarjeta" type="button" (click)="update($event.item)">
					<i class="material-icons">edit</i>
      </button> 
      -->

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="maskCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Número de tarjeta</th>
				<td mat-cell *matCellDef="let element">{{element.maskCreditCard}}</td>
			</ng-container>

			<ng-container matColumnDef="expMonthCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Proveedor</th>
				<td mat-cell *matCellDef="let element">{{element.expMonthCreditCard}}</td>
      </ng-container>
      
      <ng-container matColumnDef="expMonth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Mes de vencimiento</th>
        <td mat-cell *matCellDef="let element">{{element.expMonth}}</td>
      </ng-container>

			<ng-container matColumnDef="expYearCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Año de vencimiento</th>
				<td mat-cell *matCellDef="let element">{{element.expYearCreditCard}}</td>
			</ng-container>
			
			<ng-container matColumnDef="nameHolderCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.nameHolderCreditCard}}</td>
      </ng-container>
      
      <ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha de creación</th>
				<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
      </ng-container>
      
      <ng-container matColumnDef="estatus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.estatus}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
			</tr>
		</table>

  </div>
  
  <context-menu #basicMenu>

			<ng-template *hasPermission="['tdc.create']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Editar Nombre</span>
				</div>
      </ng-template>
      
      
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['tdc.delete']" contextMenuItem (execute)="change($event.item)">
				<div class="item">
					<i class="material-icons">loop</i>
					<span>Cambiar estado</span>
				</div>
      </ng-template>
      
	</context-menu>


	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmación de cambio de estado</div>
				<div class="dialog-message" >¿Está seguro que desea cambiar el estado de las tarjetas seleccionadas?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
