import { OnInit } from '@angular/core';
import { Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {UserService} from '../user.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { UserFilter } from '../user.filter';

@Component({
  template: `
	<h3 class="title">Listado de usuarios</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name_user">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name_user}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.lastname_user">
							<span class="label">Apellido</span>
							<span>{{tableService.filter.lastname_user}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.email_user">
							<span class="label">Email</span>
							<span>{{tableService.filter.email_user}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name_user">Nombre</label>
							<input type="text" name="name_user" placeholder="None"  [(ngModel)]="filter.name_user">
						</div>
						<div class="field-row-item">
							<label class="label" for="lastname_user">Apellido</label>
							<input type="text" name="lastame_user" placeholder="None"  [(ngModel)]="filter.lastname_user">
						</div>
						<div class="field-row-item">
							<label class="label" for="email_user">Email</label>
							<input type="text" name="email_user" placeholder="None"  [(ngModel)]="filter.email_user">
						</div>
          			</div>
          
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="status_partner" >Estatus</label>
							<mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
								<mat-option >None</mat-option>
								<mat-option [value]="'1'">Activo</mat-option>
								<mat-option [value]="'2'">Inactivo</mat-option>
							</mat-select>
						</div>
					</div>
					-->
					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button *hasPermission="['user.create']" class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
			
			<button  class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i  class="material-icons">delete</i>
			</button>

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
		<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_user">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_user}}</td>
			</ng-container>
			
			<ng-container matColumnDef="lastName_user">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Apellido</th>
				<td mat-cell *matCellDef="let element">{{element.lastName_user}}</td>
      </ng-container>
      
      <ng-container matColumnDef="email_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Email</th>
        <td mat-cell *matCellDef="let element">{{element.email_user}}</td>
      </ng-container>

      <ng-container matColumnDef="status_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.status_user}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['user.updateuser']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template  contextMenuItem (execute)="confirmDelete = false">
			<div class="item">
				<i class="material-icons">delete</i>
				<span>Eliminar</span>
			</div>
			</ng-template>

	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})
export class UserListComponent implements OnInit {

  // TODO - Hacer funcionar filtros, y la eliminacion logica

  show = false;
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'select','id', 'name_user', 'lastName_user', 'email_user', 'status_user'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
  filter = new UserFilter(this.tableService.filter);

  usuarios: object = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public tableService: TableService<any>,
    private notificationService: NotificationService,
  ) {this.tableService = new TableService;}

  ngOnInit() {
	if(localStorage.getItem('searchUser') != null ){
		this.filter.name_user =  JSON.parse(localStorage.getItem('searchUser')).name_user;
		this.filter.lastname_user = JSON.parse(localStorage.getItem('searchUser')).lastname_user;
		this.filter.email_user = JSON.parse(localStorage.getItem('searchUser')).email_user;
		localStorage.removeItem("searchUser");
		this.search();
	  }else{
		this.list(); // listamos la informacion que se mostrara en la tabla
	  }
  }

  list(event?: PageEvent) {

	if(this.tableService.pager.pageSize != 20){
		this.tableService.pager.pageSize = 20;
	}
	
	let httpParams = this.userService.buildRequestParams(this.tableService.sort,'m', 
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});

    httpParams = this.filter.getHttpParams(httpParams); 

    this.userService.getAllSearch(httpParams).subscribe(response => {
      for(const r of response['result']){
          if(r.status_user == 1){
            r.status_user = 'Activo';
          }
          if(r.status_user == 2){
            r.status_user = 'Inactivo';
          }
      }
      this.dataSource = new MatTableDataSource<any>(response['result']);
			this.tableService.pager = response['pager'];
			this.tableService.selection.clear();
    }, err => {
        this.notificationService.error(err);
        console.log(err);
    });
  }

  create() { /// crear un nuevo rol en el sistema
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }


  update(item: any) {
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  read(item: any) { // leer un user
	localStorage.setItem("searchUser", JSON.stringify({name_user: this.filter.name_user, email_user : this.filter.email_user, lastname_user : this.filter.lastname_user}));
	this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  	//-- 

deletes() {
	const length = this.tableService.selection.selected.length;
	this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
		--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.userService.delete(this.tableService.selection.selected[0].id).subscribe(response => {
				this.notificationService.sucessDelete(response.name_service);
				this.list();
			}, err => this.notificationService.error(err)); 
		} else if (length > 1) {
			let ids : Array<number> = [];
				for(const r of this.tableService.selection.selected ){
					ids.push(r.id);
				}
			this.userService.deletes(ids).subscribe(response =>  {
				this.notificationService.sucessDelete();
				this.list();
			}, err => console.log(err));
	} 
}

reset() {
	this.filter = new UserFilter();
	this.list();
}

search() {
	this.isOpenSearchPanel = false;
	this.tableService.pager.pageIndex = 0;
	this.tableService.filter = new UserFilter(this.filter);
	this.list();
}

openSearchPanel(value: boolean) {
	this.isOpenSearchPanel = value;
	this.filter = new UserFilter(this.tableService.filter);
}

compareFn(c1: any, c2: any): boolean { 
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}

applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
