import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PartnerService} from '../../partner/partner.service';
import {NotificationService} from '../../shared/notification/notification.service';

@Component({
  template: `
	<h3 class="title">Detalle la sucursal</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ partner?.name_partner || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Compañía</span>
					<span>{{ partner?.parentPartner?.name_partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Identificador del banco</span>
					<span>{{ partner?.identifier_bank || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Ruta de notificación</span>
					<span>{{ partner?.returnRoute || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ partner?.adress_partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Telefono</span>
					<span>{{ partner?.phone_partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Ciudad</span>
					<span>{{ partner?.city?.name_city || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Pais</span>
					<span>{{ partner?.city?.country?.name_country || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Porcentaje de cobro</span>
					<span>{{ partner?.feeRate_partner || '-'}}%</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Pais</span>
					<span>{{ partner?.status_partner || '-'}}</span>
				</div>
			</div>
		</div>
	</div>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
`
})
export class ReadBranchComponent implements OnInit {

  confirmDelete = true;
  public partner: any;

  constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private partnerService: PartnerService,
		private notificationService: NotificationService
  ){}

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(param => {
        let idpartner;
        if (param['partnerId']) {
          idpartner = param['partnerId'];
        }else{
          idpartner = param['branchId'];
        }
        this.partnerService.getById(idpartner)
          .subscribe(partner => {
            this.partner = partner;
            if(this.partner.status_partner == 1)
							this.partner.status_partner = 'Activo';
						if(this.partner.status_partner == 2)
							this.partner.status_partner = 'Inactivo';
          });
      }, err => this.notificationService.error(err));
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
  }

  delete(this){
		this.partnerService.delete(this.partner.id)
			.subscribe(any =>  {
				this.notificationService.sucessDelete();
				this.location.back();
			}, err => this.notificationService.error(err));

    }

}
