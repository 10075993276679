
  <h3 class="title">Listado de Demonios</h3>
  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
        <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
      </ng-container>
      
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Descripción</th>
        <td mat-cell *matCellDef="let element">{{element.description}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container>

      <ng-container matColumnDef="last_executed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Ultima ejecucion</th>
        <td mat-cell *matCellDef="let element">{{element.last_executed}}</td>
      </ng-container>

      <ng-container matColumnDef="next_execution">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Proxima ejecucion</th>
        <td mat-cell *matCellDef="let element">{{element.next_execution}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row" (click)="read(row)">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template *hasPermission="['group.update']" contextMenuItem (execute)="activate($event.item)">
        <div class="item">
          <i class="material-icons">done</i>
          <span>Activar</span>
        </div>
      </ng-template>
      <ng-template contextMenuItem divider="true"></ng-template>

        <ng-template  *hasPermission="['group.update']" contextMenuItem (execute)="update($event.item)">
					<div class="item">
						<i class="material-icons">edit</i>
						<span>Actualizar</span>
					</div>
				</ng-template>
				
				<ng-template contextMenuItem divider="true"></ng-template>

      <ng-template *hasPermission="['group.update']" contextMenuItem (execute)="desactivate($event.item)">
        <div class="item">
          <i class="material-icons">block</i>
          <span>Desactivar</span>
        </div>
      </ng-template>
    
  </context-menu>

