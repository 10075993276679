
	<h3 class="title">Auditoria API</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

            <div class="field-row-item" *ngIf="tableService.filter.del">
              <span class="label">Del</span>
              <span *ngIf="tableService.filter.del == 1"> Ultima hora </span>
              <span *ngIf="tableService.filter.del == 2"> Ultimas 6 horas </span>
              <span *ngIf="tableService.filter.del == 3"> Ultimas 24 horas </span>
              <span *ngIf="tableService.filter.del == 4"> Ultimos 7 dias </span>
              <span *ngIf="tableService.filter.del == 5"> Sin limite </span>
              <span *ngIf="tableService.filter.del == 6"> Personalizado </span>
            </div>

						<div class="field-row-item" *ngIf="tableService.filter.parameter">
							<span class="label">Texto</span>
							<span>{{tableService.filter.parameter}}</span>
            </div>

            <div class="field-row-item" *ngIf="tableService.filter.label">
							<span class="label">Etiquetas</span>
							<span>{{tableService.filter.label}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

          <div class="field-row">

                <mat-form-field class="field-row-item">
                <label class="label" for="company" >Texto</label>
                <mat-chip-list [(value)]="filter.parameter" #chipList aria-label="parameter selection">
                  <mat-chip  *ngFor="let parameter of parameters" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(parameter)">
                    {{parameter}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>

                  <input  style="width:90%;background:ghostwhite"
                        [matChipInputFor]="chipList"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
              </mat-form-field>
           

            

            <div class="field-row-item">
              <label class="label" for="company" >Periodo</label>
              <mat-select [formControl]="periodS" [(value)]="filter.del"  disableRipple>
                <mat-option *ngFor="let opt of types" [value]="opt.value">{{opt.desc}}</mat-option>
              </mat-select>
            </div>

          </div>


          <div class="field-row">

              <mat-form-field class="field-row-item">
              <label class="label" for="label" >Etiquetas</label>
              <mat-chip-list [(value)]="filter.label" #chipList2 aria-label="label selection">
                <mat-chip  *ngFor="let label of labels" [selectable]="selectable"
                        [removable]="removable" (removed)="removeLabel(label)">
                  {{label}}
                  <mat-icon matChipRemove *ngIf="removable">add</mat-icon>
                </mat-chip>

                <input readonly  style="width:90%;background-color:white;border:none;"
                      [matChipInputFor]="chipList2"
                      [matChipInputAddOnBlur]="addOnBlur">
              </mat-chip-list>

              </mat-form-field>

          </div>

          

          <div class="field-row" *ngFor="let label of searchLabels; index as i">

            <mat-chip [selectable]="selectable"
                    [removable]="removable" (removed)="removeSearchLabel(label)">
              {{label}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>

            <input  (blur)="test_event($event,i)"  style="width:90%;background:ghostwhite">
            
      
          </div>
          
          <div class="field-row" *ngIf="filter.del == 6">

						<div class="field-row-item">  
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>
            </div>
            
            <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
					</div>

			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>

  <div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>
  
  <div class="mat-elevation-z8e" *ngIf="dataSource">
    
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="user">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Usuario</th>
				<td mat-cell *matCellDef="let element">{{element.user}}</td>
			</ng-container>

			<ng-container matColumnDef="ip">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >IP</th>
				<td mat-cell *matCellDef="let element">{{element.ip}}</td>
      </ng-container>
      
      <ng-container matColumnDef="permission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Funcion</th>
        <td mat-cell *matCellDef="let element">{{element.permission}}</td>
      </ng-container>

      <ng-container matColumnDef="response_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Respuesta</th>
        <td mat-cell *matCellDef="let element">{{element.response_code}}</td>
      </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
