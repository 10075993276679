<h3 class="title">Facturas para sincronizar estatus</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_invoice">
							<span class="label">Invoice</span>
							<span>{{tableService.filter.id_invoice}}</span>
						</div>

					
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
					-->

					<div class="field-row">
          <div class="field-row-item">
            <label class="label" for="company" >Compañia</label>
            <mat-select placeholder="None" [(value)]="filter.id_partner" disableRipple>
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>
					</div>
					<div class="field-row-item">
								<label class="label" for="id_invoice">Invoice</label>
								<input type="number" name="id_invoice" placeholder="None"  [(ngModel)]="filter.id_invoice">
							</div>
					</div>
          
          <div class="field-row">

						<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>
            		</div>
            
            		<div class="field-row-item">
						<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker touchUi #picker2></mat-datepicker>
            		</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>


	<div class="tool-bar-wrap both-side">
		<div class="right row">

			<button  class="btn-icon"  title="Actualizar" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i  class="material-icons">check_box</i>
			</button>


		</div>
	</div>


	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">


		<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)">
						</mat-checkbox>
					</td>
    </ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
      </ng-container>
      
      <ng-container matColumnDef="desc_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.desc_status}}</td>
      </ng-container>

      <ng-container matColumnDef="total_invoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Monto</th>
        <td mat-cell *matCellDef="let element">{{element.total_invoice}}</td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.created_at}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" >Confirmacion</div>
					<div class="dialog-message" >Esta seguro que desea cambiar el estatus de los registros seleccionados?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span >No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span>SI</span>
						</button>
					</div>
				</div>
			</ng-template>statusServiceOrder
		</modal-ns>
