import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReportService} from '../report.service';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';

@Component({
  template: `
	<h3 class="title">Datos del cliente</h3>
    
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ customer?.name_customer + " " + customer?.lastName_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">DNI</span>
					<span>{{ customer?.identifier_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Telefono</span>
					<span>{{ customer?.phoneNumber_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ customer?.adress_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Codigo</span>
					<span>{{ customer?.code_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Email</span>
					<span>{{ customer?.email_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ customer?.status_customer  || '-'}}</span>
				</div>
      </div>

      <div *ngIf="customer?.sucursal_id != null" class="field-row">
        <div class="field-row-item">
          <span class="label">Sucursal</span>
          <span>{{ customer?.sucursal_id  || '-'}}</span>
        </div>
      </div>

  <br>
  <h2 class="title">Planes del cliente</h2>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			<ng-container matColumnDef="plan.name_plan">
				<th mat-header-cell *matHeaderCellDef  >Plan</th>
				<td mat-cell *matCellDef="let element">{{element?.plan.name_plan || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="startdate_customerPlan">
				<th mat-header-cell *matHeaderCellDef  >Fecha inicio</th>
				<td mat-cell *matCellDef="let element">{{element?.startdate_customerPlan || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="endDate_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Fecha fin</th>
        <td mat-cell *matCellDef="let element">{{element?.endDate_customerPlan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="next_bill_date">
        <th mat-header-cell *matHeaderCellDef  >Proxima fecha de cobro</th>
        <td mat-cell *matCellDef="let element">{{element?.next_bill_date || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="contractNumber_Plan">
        <th mat-header-cell *matHeaderCellDef  >Contrato</th>
        <td mat-cell *matCellDef="let element">{{element?.contractNumber_Plan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="status_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element?.status_customerPlan || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="balance_customerPlan">
        <th mat-header-cell *matHeaderCellDef  >Balance</th>
        <td mat-cell *matCellDef="let element">{{element.balance_customerPlan }}</td>
      </ng-container>

      <ng-container matColumnDef="plan.feeCost_plan">
        <th mat-header-cell *matHeaderCellDef  >Costo</th>
        <td mat-cell *matCellDef="let element">{{element.plan.feeCost_plan }}</td>
      </ng-container>


      <ng-container matColumnDef="plan.enrollmentCost_plan">
        <th mat-header-cell *matHeaderCellDef  >inscripción</th>
        <td mat-cell *matCellDef="let element">{{element.plan.enrollmentCost_plan }}</td>
      </ng-container>

      <ng-container matColumnDef="plan.freezeCostPlan">
        <th mat-header-cell *matHeaderCellDef  >Congelamiento</th>
        <td mat-cell *matCellDef="let element">{{element.plan.freezeCostPlan }}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
`
})
export class CustomerPlansComponent implements OnInit {

  customer;

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['plan.name_plan', 'startdate_customerPlan', 'endDate_customerPlan', 'next_bill_date', 'contractNumber_Plan','status_customerPlan', 'balance_customerPlan', 'plan.feeCost_plan', 'plan.enrollmentCost_plan', 'plan.freezeCostPlan' ];
 

  items: any[] = [];

  constructor(
    private reportService: ReportService,
    public tableService: TableService<any>,
    private activatedRoute: ActivatedRoute,
  ) { this.tableService = new TableService; }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param =>{
      this.reportService.getCustomerHasPlan(param['idCustomer']).subscribe(response =>{

          this.items = response['result'];
          this.customer = this.items[0].customer;
          if(this.customer.status_customer == 1){
            this.customer.status_customer = 'Activo';
          }else{
            this.customer.status_customer = 'Inactivo';
          }


          for(var plan of this.items){
            if(plan.status_customerPlan == 1){
              plan.status_customerPlan = 'Activo'
            }else{
              plan.status_customerPlan = 'Inactivo'
            }
          }

          this.dataSource = new MatTableDataSource<any>(this.items);
          this.tableService.pager = response['pager'];
          this.tableService.selection.clear();

      });
    });
    
    
  }

}
