import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import { Observable } from 'rxjs';
import { Customer } from './customer';
import { PartnerService } from '../partner/partner.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

export interface Filter {
    name_customer?: string;
}


@Injectable({
  providedIn : 'root'
})
export class CustomerService extends BaseService {

  	BASE_URL: string = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';

    httpOptions : any;

    constructor(
      private http: HttpClient,
        private partnerService: PartnerService,
    ){
        super();
         // Http Headers
         this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
    }

   create(customer: Customer): Observable<Customer> {
        this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
        return this.http.post(this.BASE_URL, customer, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

   getByPartner(partnerID:number,params: HttpParams = new HttpParams()): Observable<any> {
        this.BASE_URL = this.HOST +'/partner/' + partnerID + '/customer';
        this.httpOptions.params = params;
        return this.http.get(this.BASE_URL, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getByService(id: number): Observable<any> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
      return this.http.get(this.BASE_URL +'/service/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

    }

    getByPlan(id: number): Observable<any> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
      return this.http.get(this.BASE_URL + '/plan/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    }

    getByStatus(id: number): Observable<any> {
    this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
    return this.http.get(this.BASE_URL + '/status/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    }

    getByDNI(dni: string): Observable<any> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
      return this.http.get(this.BASE_URL + '/ident/' + dni, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getByPhone(phone: string): Observable<any> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
      return this.http.get(this.BASE_URL + '/phone/' + phone, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getByEmail(email: string): Observable<any> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
      return this.http.get(this.BASE_URL + '/email/' + email, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    }

    getById(id: number): Observable<Customer> {
      this.BASE_URL = this.HOST +'/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
        return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }

    delete(id: number): Observable<any> {
        this.BASE_URL = this.HOST + '/partner/' + JSON.parse(localStorage.getItem("data")).user + '/customer';
        return this.http.delete(this.BASE_URL + '/' + id, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    deletes(array:any[]): Observable<any> {
      return this.http.post(this.BASE_URL + '/delete', array, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    }

    update(customer: Customer): Observable<any> {
      this.BASE_URL = this.HOST + '/partner/' + JSON.parse(localStorage.getItem('data')).user + '/customer';
        return this.http.put(this.BASE_URL + '/' + customer.id,  customer, this.httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    }
}
