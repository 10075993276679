import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {PartnerService} from '../../partner/partner.service';
import {CityService} from '../../city/city.service';
import {CountryService} from '../../country/country.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Partner} from '../../partner/partner';
import {BaseService} from '../../shared/services/base.service';
import {NotificationService} from '../../shared/notification/notification.service';
import { CustomValidators } from '../../shared/form/custom.validators';

@Component({
  template: `
  <h3 class="title">Crear Sucursal</h3>

  <form *ngIf="createPartnerForm" [formGroup]="createPartnerForm" (ngSubmit)="create()">
    <fieldset class="fieldset">
      <legend class="legend" i18n="@@data-account">Datos de la sucursal</legend>


      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="name_partner">Nombre</label>
          <input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
          <validator [control]="createPartnerForm.controls['name_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label for="partner">Compañía</label>
        
          <mat-select [formControl]="parentPartnerId" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
          <validator [control]="parentPartnerId"></validator>
        
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="adress_partner" >Direccion</label>
          <textarea formControlName="adress_partner"></textarea>
          <validator [control]="createPartnerForm.controls['adress_partner']"></validator>
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="country" >Pais</label>

          <mat-select [formControl]="createPartnerForm.controls['selectedCountry']" [compareWith]="compareFn" (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
					</mat-select>
					<validator [control]="createPartnerForm.controls['selectedCountry']"></validator>


        </div>
        <div class="field-row-item">
          <label class="label" for="city">Ciudad</label>
          <mat-select [formControl]="createPartnerForm.controls['selectedCity']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
					</mat-select>
					<validator [control]="createPartnerForm.controls['selectedCity']"></validator>
        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="phone_partner" >Telefono</label>
          <input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
          <validator [control]="createPartnerForm.controls['phone_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="feeRate_partner">Porcentaje de cobro</label>
          <input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
          <validator [control]="createPartnerForm.controls['feeRate_partner']"></validator>
        </div>
        <div class="field-row-item">
						<label class="label" for="returnRoute">Ruta de notificación</label>
            <input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" >
            <button style="display:inline;box-shadow: none;padding:0;min-width: 30px;" mat-raised-button matTooltip="Agregar nombre del cliente Ej: poliwin"
                  			aria-label="Button that displays a tooltip when focused or hovered over" >
                  			<i class="material-icons">info</i>
          	</button>
						<validator [control]="createPartnerForm.controls['returnRoute']"></validator>
					</div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="email_partner">Email</label>
          <input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
          <validator [control]="createPartnerForm.controls['email_partner']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="identifier_bank">Identificador del banco</label>
          <input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
          <validator [control]="createPartnerForm.controls['identifier_bank']"></validator>
        </div>
        <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createPartnerForm.controls['status_partner']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['status_partner']"></validator>
				</div>
      </div>
      

    </fieldset>

    <div class="options">
      <button class="btn-text" type="submit" [disabled]="!createPartnerForm.valid || !parentPartnerId.valid">
        <span>Guardar</span>
      </button>
    </div>
  </form>
`
})
export class CreateBranchComponent implements OnInit {

  private countries = [];
  private cities = [];
  private partner: any;
  private partners : Partner[];
  index = -1;
  idPartner = -1;

  public parentPartnerId: FormControl = new FormControl('', Validators.required);
  private estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];

  	// validaciones para campos de formulario
	createPartnerForm = this.fb.group({
    name_partner: ['', [Validators.required]],
    identifier_bank: ['', [Validators.required]],
		adress_partner: ['', [Validators.required]],
		email_partner: ['', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]],
		phone_partner: ['', [Validators.required]],
		feeRate_partner: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
    selectedCountry:['', [Validators.required]],
		selectedCity:['', [Validators.required]],
		status_partner:['', [Validators.required]],
		returnRoute:['', [Validators.required]]
	});
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private location: Location,
    private partnerService: PartnerService,
    private countryService: CountryService,
    private cityService: CityService,
    private notificationService: NotificationService,
    private base: BaseService
  ){ }

  ngOnInit() {
    this.partnerService.getConfigUrl("customer_url").subscribe(config =>{

      this.createPartnerForm.controls["returnRoute"].setValue(config.value_config +"/responses");
      // esto es por si venimos del listado de las Compañia
      if (this.router.url.split('/')[2] === 'partners') {
        this.idPartner = +this.router.url.split('/')[3];
      }
      this.cargarCombos(); // cargamos los combos de la vista

		}, (err) => {
				console.log(err)
				this.notificationService.error("Error buscando url de Customer");
		});
   
  }

  cargarCombos() {
    /// cargamos los paises registrados
    this.countryService.getAll().subscribe(params => {
      this.countries = params['result'];
    }, (err) => {
      this.notificationService.error(err);
    });

    let httpParams = this.base.buildRequestParams()
    
    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result'];
      // seleccionamos en el drop el partner al que le queremos crear una sucursal
      // esto solo si venimos del listado de partner a crear una sucursal a un partner en especifico
      if (this.idPartner !== -1) {
        this.parentPartnerId.setValue(this.partners[this.partners.findIndex( p => this.idPartner === p.id)].id);
      }
     
    }, (err) => {
      
      this.notificationService.error(err);
    });
  }
  onItemChange(country:any){
		 // cargamos las ciudades del pais que seleccione el usuario
		this.cities = [];
		if(country!=undefined){
			this.cityService.getByCountry(country).subscribe(params => {
					this.cities = params['result'];
					}, (err) => {
						this.notificationService.error(err);
			});
		}
  }
  
  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  create() {
    if(!this.createPartnerForm.valid){
      this.notificationService.error("Campos incompletos o invalidos, por favor verifique");
      return;
    }

    let obj = <any> BaseService.builderObject(this.createPartnerForm.value);
    this.partner = obj;
    this.partner.city = {id: obj.selectedCity};
    this.partner.parentPartner = {id: this.parentPartnerId.value};
    this.partnerService.create(this.partner).subscribe(partner =>  {
      this.notificationService.sucessInsert(partner.name_partner);
      this.location.back();
    }, (err) => {
      console.log(err);
      this.notificationService.error(err?.error?.message);
    });
  }
}
