import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class HomeService extends BaseService {

    private readonly BASE_URL2: string = this.HOST + '/dashboard';
    private readonly BASE_URL: string = this.HOST + '/invoice';
    httpOptions : any;

    constructor(
      private http: HttpClient
      ) { 
        super();
        // Http Headers
        this.httpOptions = {
          headers: this.createAuthorizationHeader()
        }
       }

    getReportInvoices(params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
        return this.http.get(this.BASE_URL + '/report/home', this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    getByAuthId(id: number): Observable<any> {
      return this.http.get(this.BASE_URL2 + '/auth/' + id, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
    } 
}