import { HttpParams } from '@angular/common/http';

export class PlanFilter {

	name_plan: string;
    partnerp: number;
    service: number;
	id: number;
	status_plan: number;
    
	constructor(filter?: PlanFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_plan = filter.name_plan;
			this.partnerp = filter.partnerp;
            this.status_plan = filter.status_plan;
            this.service = filter.service;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_plan) {
			httpParams = httpParams.set('name_plan', this.name_plan);
        }
        if (this.partnerp) {
			httpParams = httpParams.set('partner', this.partnerp.toString());
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_plan) {
			httpParams = httpParams.set('status_plan', this.status_plan.toString());
        }
        if (this.service) {
			httpParams = httpParams.set('service', this.service.toString());
        }
		return httpParams;
	}
}