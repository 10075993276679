
	<h3 class="title">Detalle del servicio</h3>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['create.service']" class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ servicio?.name_service || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">{{label}}</span>
					<span>{{ partner?.name_partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ servicio?.status_service || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Tipo</span>
					<span>{{ servicio?.type || '-'}}</span>
				</div>
			</div>

			<div *ngIf="show">
				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Titulo</span>
						<span>{{ servicio?.title || '-'}}</span>
					</div>
				</div>
				
				<div class="field-row">
					<div class="field-row-item">
						<span class="label">URL Ecommerce</span>
						<span>{{ servicio?.url_ecommerce || '-'}}</span>
					</div>
				</div>

				<!-- <div class="field-row">
					<div class="field-row-item">
						<span class="label">Color de tema</span>
						<color-circle [colors]="colors" ></color-circle>
					</div>
				</div> -->

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Email</span>
						<span>{{ servicio?.email_service || '-'}}</span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item" style="width: 100%;">
						<span class="label">Descripción</span>

						<span>{{ servicio?.description || '-'}}</span>
						<!-- <editor [inline]="true" [disabled]="true" apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh"
						
						[(ngModel)]="servicio.description" >
						</editor> -->
						
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item" style="width: 100%;">
						<span class="label">Descripción de último artículo enviado</span>

						<span>{{ servicio?.desc_of_last_sended || '-'}}</span>
						
						
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Términos</span>
						<span>{{ servicio?.terms || '-'}}</span>
						<!-- <editor [inline]="true" [disabled]="true" apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh"
						
						[(ngModel)]="servicio.terms" >
						</editor> -->

					</div>
				</div>

				<div class="field-row">
					<div style="width: 100%;" class="field-row-item">
						<span class="label">Banner de servicio</span>
						<gallery-image [items]="items"></gallery-image>

					</div>
				</div>

				<div class="field-row">
					<div style="width: 100%;" class="field-row-item">
						<span class="label">Imagen del último artículo enviado </span>

						<br/>

						<img [src]="servicio?.image_of_last_sended">

					</div>
				</div>

			</div>

		</div>
	</div>


	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
