
	<h3 class="title">Detalle de factura</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Cliente</span>
				<span>{{ invoice?.customer?.name_customer  + ' '+ invoice?.customer?.lastName_customer || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">DNI</span>
				<span>{{ invoice?.customer?.identifier_customer || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Codigo de cliente</span>
				<span>{{ invoice?.customer?.code_customer || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Plan</span>
				<span>{{ invoice?.customer_has_plan?.plan?.name_plan || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Contrato de cliente</span>
				<span>{{ invoice?.customer_has_plan?.contractNumber_Plan || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Total factura</span>
				<span>{{ invoice?.total_invoice || '-'}}</span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Balance de factura</span>
				<span>{{ invoice?.balance_invoice }}</span>
			</div>
		</div>


		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Estatus de factura</span>
				<span>{{ invoice?.status_invoice || '-'}} </span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Creada</span>
				<span>{{ invoice?.createdAt || '-'}} </span>
			</div>
		</div>

		<div class="field-row">
			<div class="field-row-item">
				<span class="label">Actualizada</span>
				<span>{{ invoice?.updatedAt || '-'}} </span>
			</div>
		</div>

		</div>
  </div>

  <br>
  <h2 class="title">Pagos</h2>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" >
		
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef  >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="description_payment">
				<th mat-header-cell *matHeaderCellDef  >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element.description_payment}}</td>
			</ng-container>
			
			<ng-container matColumnDef="resultStatus_payment">
				<th mat-header-cell *matHeaderCellDef  >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.resultStatus_payment}}</td>
      </ng-container>
      
      <ng-container matColumnDef="type_payment">
        <th mat-header-cell *matHeaderCellDef  >Tipo</th>
        <td mat-cell *matCellDef="let element">{{element.type_payment}}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated_payment">
        <th mat-header-cell *matHeaderCellDef  >Creada</th>
        <td mat-cell *matCellDef="let element">{{element.dateCreated_payment}}</td>
	  </ng-container>
	  
	  <ng-container matColumnDef="cardMask">
        <th mat-header-cell *matHeaderCellDef  >TDC</th>
        <td mat-cell *matCellDef="let element">{{element.cardMask}}</td>
      </ng-container>

      <ng-container matColumnDef="amount_payment">
        <th mat-header-cell *matHeaderCellDef  >Total</th>
        <td mat-cell *matCellDef="let element">{{element.amount_payment}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
