
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Crear Renovacion</h3>

	<form *ngIf="createBillingForm" [formGroup]="createBillingForm" (ngSubmit)="create()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" for="partner" >Compañia</label>
          
          <mat-select  [compareWith]="compareFn" (selectionChange)="onPartnerChange($event.value)" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>
			
				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Sucursal</label>
			
          <mat-select [formControl]="sucursal" [compareWith]="compareFn" (selectionChange)="onSucursalChange($event.value)" disableRipple>
								<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>

				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="servicio" >Servicio</label>
          <mat-select [formControl]="service"  [compareWith]="compareFn" (selectionChange)="onServiceChange($event.value)" disableRipple>
                <mat-option *ngFor="let serv of services" [value]="serv.id">{{serv.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
        

          <mat-select  [formControl]="createBillingForm.controls['plans']" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank.id">
              {{bank.name_plan}}
            </mat-option>
          </mat-select>




          <validator [control]="createBillingForm.controls['plans']"></validator>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_billingsetting" >Nombre</label>
					<input formControlName="name_billingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createBillingForm.controls['name_billingsetting']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
         
          <mat-select [formControl]="createBillingForm.controls['status_billingsetting']" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
					<validator [control]="createBillingForm.controls['status_billingsetting']"></validator>

				</div>
			</div>
			

      
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="cycle" >Inicio ciclo</label>
          
          <mat-select [formControl]="createBillingForm.controls['cycle_start_billingsetting']" disableRipple>
              <mat-option *ngFor="let cycle of cycles" [value]="cycle">{{cycle.desc}}</mat-option>
            </mat-select>
          <validator [control]="createBillingForm.controls['cycle_start_billingsetting']"></validator>

        </div>
        <div class="field-row-item">
          <label class="label" for="plan" >Cantidad de deuda acumulada</label>
         
          <mat-select [formControl]="createBillingForm.controls['qtyaccumulated_debt_billingsetting']" disableRipple>
          
              <mat-option *ngFor="let debt of debts" [value]="debt">{{debt}}</mat-option>
            </mat-select>
          <validator [control]="createBillingForm.controls['qtyaccumulated_debt_billingsetting']"></validator>

        </div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="frecuency">Elija una Frecuencia</label>
          <cron-jobs [formControl]="createBillingForm.controls['time_billingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
          {{frecuency}}
         </div>
      </div>

		</fieldset>

		<div class="options">
      <button class="btn-text" type="submit" [disabled]="!createBillingForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
