import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {FrecuencyService} from '../../frecuency.service';
import {NotificationService} from '../../../shared/notification/notification.service';
import {BaseService} from '../../../shared/services/base.service';

@Component({
  template: `
	<h3 class="title">Crear Frecuencia</h3>

	<form *ngIf="createFrecuencyForm" [formGroup]="createFrecuencyForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos de la frecuencia</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_frecuency">Nombre</label>
					<input formControlName="name_frecuency" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createFrecuencyForm.controls['name_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="day_frecuency">Dia de frecuencia</label>
          <input formControlName="day_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de frecuencia" >
          <validator [control]="createFrecuencyForm.controls['day_frecuency']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="month_frecuency">Mes de frecuencia</label>
          <input formControlName="month_frecuency" type="number" autocomplete="off" placeholder="Ingrese mes de frecuencia" >
          <validator [control]="createFrecuencyForm.controls['month_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="dayOfWeek_frecuency">Dia de la semana</label>
          <input formControlName="dayOfWeek_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de la semana" >
          <validator [control]="createFrecuencyForm.controls['dayOfWeek_frecuency']"></validator>
        </div>
      </div>
      
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createFrecuencyForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CreateFrecuencyComponent implements OnInit {
  public loading = false;
  private frecuency: any;
  // validaciones para campos de formulario
  createFrecuencyForm = this.fb.group({
    name_frecuency: ['', [Validators.required]],
    day_frecuency: ['', [Validators.required]],
    month_frecuency: ['', [Validators.required]],
    dayOfWeek_frecuency: ['', [Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private location: Location,
    private freceuncyService: FrecuencyService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createFrecuencyForm.value);
    obj.day_frecuency = this.createFrecuencyForm.get('day_frecuency').value;
    obj.month_frecuency = this.createFrecuencyForm.get('month_frecuency').value;
    obj.dayOfWeek_frecuency = this.createFrecuencyForm.get('dayOfWeek_frecuency').value;
    this.freceuncyService.create(obj).subscribe(result => {
      this.notificationService.sucessInsert(result['name_frecuency']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    }) ; 
  }

}
