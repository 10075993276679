import { Component} from '@angular/core';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { PlanService } from './plan.service';
import { NotificationService} from '../shared/notification/notification.service';
import { BaseService } from '../shared/services/base.service';
import { Partner } from '../partner/partner';
import { Servicio } from '../servicio/servicio';
import { Frecuency } from '../frecuency/frecuency';
import { PartnerService } from '../partner/partner.service';
import { ServicioService } from '../servicio/servicio.service';
import { FrecuencyService } from '../frecuency/frecuency.service';
import { CustomValidators } from '../shared/form/custom.validators';


@Component({
	template: `
	<h3 class="title">Crear Plan</h3>

	<form *ngIf="createPlanForm" [formGroup]="createPlanForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del plan</legend>

			<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="partner" >{{label}}</label>
						<mat-select [formControl]="partner"  (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
						</mat-select>
						<validator [control]="partner"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="service" >Servicio</label>
						<mat-select [formControl]="service" disableRipple>
								<mat-option *ngFor="let servicio of servicios" [value]="servicio.id">{{servicio.name_service}}</mat-option>
						</mat-select>
						<validator [control]="service"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="name_plan">Nombre</label>
						<input formControlName="name_plan" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
						<validator [control]="createPlanForm.controls['name_plan']"></validator>
					</div>

			</div>



			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="enrollmentCost_plan">Costo de inscripción</label>
					<input formControlName="enrollmentCost_plan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese Costo" >
					<validator [control]="createPlanForm.controls['enrollmentCost_plan']"></validator>
				</div>

				<div *ngIf="show" class="field-row-item">
						<label class="label" for="charge" >Cobrar inscripción</label>
						<mat-select [formControl]="charge"  disableRipple>
							<mat-option *ngFor="let option of options" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="charge"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="feeCost_plan">Costo </label>
					<input formControlName="feeCost_plan" type="text" (keypress)="onlyNumberKey($event)" autocomplete="off" placeholder="Ingrese costo"  value="0" >
					<validator [control]="createPlanForm.controls['feeCost_plan']"></validator>
				</div>
				
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="freezeCostPlan">Costo por congelamiento</label>
					<input formControlName="freezeCostPlan" type="text" (keypress)="onlyNumberKey($event)" autocomplete="off" placeholder="Ingrese costo" >
					<validator [control]="createPlanForm.controls['freezeCostPlan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="taxRate_plan">Impuesto(%)</label>
					<input formControlName="taxRate_plan" type="text" maxlength="3" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese impuesto" >
					<validator [control]="createPlanForm.controls['taxRate_plan']"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="idPlan_partner">ID plan partner</label>
					<input formControlName="idPlan_partner" type="text" autocomplete="off" placeholder="Ingrese ID" >
					<validator [control]="createPlanForm.controls['idPlan_partner']"></validator>
				</div>
				<div class="field-row-item">
						<label class="label" for="frecuency" >Frecuencia</label>
						<mat-select [formControl]="createPlanForm.controls['frecuency']"  disableRipple>
								<mat-option *ngFor="let frecuency of frecuencies" [value]="frecuency.id">{{frecuency.name_frecuency}}</mat-option>
						</mat-select>
						<validator [control]="createPlanForm.controls['frecuency']"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createPlanForm.controls['status_plan']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createPlanForm.controls['status_plan']"></validator>
				</div>
			</div>

			<div *ngIf="show">
				<div class="field-row">

					<div class="field-row-item">
						<label class="label" for="title">Título</label>
						<input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Título" >
						<validator [control]="createPlanForm.controls['title']"></validator>
					</div>

				
					<div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input [formControl]="createPlanForm.controls['position']" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="createPlanForm.controls['position']"></validator>
					</div>
					
				</div>


				<div class="field-row">

				

					<div class="field-row-item">
						<label class="label" for="url_image">Imagen de plan</label>
						<input id="file-upload" type="file" style="display:block; "accept="image/png, image/* , image/jpeg" (change)="onFileChange($event)" required>
					</div>

					<!-- <div class="field-row-item">
						<label class="label" for="url_image_detail">Imagen para detalle de plan</label>
						<input id="file-detail-upload" type="file" style="display:block; "accept="image/png, "accept="image/png,image/*,image/jpeg" (change)="onFileChangeDetail($event)" required>
					</div> -->
					
					
				</div>


			<!--	<div class="field-row">

					<div class="field-row-item">
						<label class="label" for="label_frecuency">Etiqueta de frecuencia</label>
						<input formControlName="label_frecuency" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="createPlanForm.controls['label_frecuency']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_inscription">Etiqueta de inscripción</label>
						<input formControlName="label_inscription" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="createPlanForm.controls['label_inscription']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_payment">Etiqueta de pago</label>
						<input formControlName="label_payment" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="createPlanForm.controls['label_payment']"></validator>
					</div>

					
					
				</div> -->

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="title">Titulo del email</label>
							<textarea formControlName="title_email"></textarea>
							<validator [control]="createPlanForm.controls['title_email']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="url_ecommerce">Mensaje del email</label>
							<textarea formControlName="message_email"></textarea>
							<validator [control]="createPlanForm.controls['message_email']"></validator>
						</div>

				</div>	


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="validity_period" >Periodo</label>
						<mat-select [formControl]="createPlanForm.controls['validity_period']"  disableRipple>
							<mat-option *ngFor="let period of periods" [value]="period.value">{{period.description}}</mat-option>
						</mat-select>
						<validator [control]="createPlanForm.controls['validity_period']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="validity_value">Valor</label>
						<input formControlName="validity_value" type="text" maxlength="5" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Valor" >
						<validator [control]="createPlanForm.controls['validity_value']"></validator>
					</div>
					
				</div>


				


				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
						<textarea formControlName="description"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="detail_plan" >Detalle del plan</label>
						<textarea formControlName="detail_plan"></textarea>
						
					</div>
				</div>

				 <!-- <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="extended_description" >Descripción extendida</label>
						<editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="extended_description" ></editor>
					</div> 
				</div> -->

			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createPlanForm.valid || !partner.valid || !service.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})

export class PlanCreateComponent {
	
	plan: any;
	partners : Partner[];
	servicios : Servicio[];
	frecuencies : Frecuency[];
	parents = false;
	label = 'Compañía';
	show = false;

	imageName: any;
	image64: any;
	imageType: any;

	imageDetailName: any;
	imageDetail64: any;
	imageDetailType: any;

	estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
	periods : any[] = [{value:1,description:"Dias"},{value:2,description:'Meses'}];
	options : any[] = [{value: false, description: "NO"}, {value: true, description: "SI"}];

	partner: FormControl = new FormControl('', Validators.required);
	service: FormControl = new FormControl('', Validators.required);
	charge: FormControl = new FormControl('', Validators.required);

	// validaciones para campos de formulario
	createPlanForm = this.fb.group({
		name_plan: ['', [Validators.required]],
		feeCost_plan: ['', [Validators.required]],
		idPlan_partner : ['',[Validators.required]],
		enrollmentCost_plan: ['', [Validators.required]],
		freezeCostPlan:['', [Validators.required]],
		taxRate_plan: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
		status_plan:['', [Validators.required]],
		frecuency:['', [Validators.required]],
		url_image:[''],
		url_image_detail:[''],
		description:[''],
		detail_plan:[''],
		position:[''],
		validity_period:[''],
		validity_value:[''],
		title:[''],
		charge_inmediately:[''],
		label_inscription: [''],
		label_payment:[''],
		label_frecuency:[''],
		title_email:[''],
		message_email:[''],
		extended_description:['']
	});

	constructor(
		public fb: FormBuilder,
		private router: Router,
		private location: Location,
		private planService: PlanService,
		private partnerService: PartnerService,
		private frecuencyService : FrecuencyService,
		private servicioService: ServicioService,
		private notificationService: NotificationService
	){}

	ngOnInit(){
		if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
			this.parents = true;
			this.label = 'Sucursal';
		}
		this.cargarCombos(); // cargamos los combos de la vista
	}

	cargarCombos(){
		/// cargamos los partner registrados
		let httpParams = this.partnerService.buildRequestParams();

		httpParams = httpParams.set('parents',this.parents.toString());
		httpParams = httpParams.set('parent',(-1).toString());
		
		this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
			this.partners = params['result'];
			this.partner.setValue(this.partners[this.partners.findIndex(e => JSON.parse(localStorage.getItem("planes")).partner === e.id)].id);
			this.servicioService.getByPartner(this.partner.value).subscribe(params => {
				this.servicios = params['result'];
				this.service.setValue(this.servicios[this.servicios.findIndex(e => JSON.parse(localStorage.getItem("planes")).servicio === e.id)].id);
				if(this.servicios[this.servicios.findIndex(e => JSON.parse(localStorage.getItem("planes")).servicio === e.id)]['type'] == 2){
					this.show = true;
					this.charge.setValue(this.options[0].value);
				}
			}, (err) => {
				this.notificationService.error(err);
			});
        }, (err) => {
        	this.notificationService.error(err);
		});

		// cargamos las frecuencias que esten registradas
		this.frecuencyService.getAll().subscribe(params => {
			this.frecuencies = params["result"];
		}, (err) => {
		
        	this.notificationService.error(err);
		});
	}


	// funcion que se ejecuta cuando se selecciona un partner
	onItemChange(partner:any){
		 // cargamos las ciudades del pais que seleccione el usuario
		this.servicios = [];
		// cargamos los servicios que esten registrados
		if(partner!= undefined){
			this.servicioService.getByPartner(partner).subscribe(params => {
				this.servicios = params['result'];
			}, (err) => {
				this.notificationService.error(err);
			});
		}
	}

	create() {

        let obj = <any> BaseService.builderObject(this.createPlanForm.value)
        this.plan = obj;      	
		this.plan.service = {id:this.service.value};
		this.plan.frecuency = {id:this.plan.frecuency};
		this.plan.feeCost_plan = this.createPlanForm.value.feeCost_plan;
        this.plan.enrollmentCost_plan = this.createPlanForm.value.enrollmentCost_plan;
        this.plan.freezeCostPlan = this.createPlanForm.value.freezeCostPlan;
		this.plan.taxRate_plan = this.createPlanForm.value.taxRate_plan;

		if(this.show == true){
			if(this.plan.title == null){
				return this.notificationService.error("Debe ingresar Título");
			} 

			
			/*f(this.plan.extended_description == null){
				return this.notificationService.error("Debe ingresar Descripción extendida del plan");
			}*/

			if(this.plan.description == null){
				return this.notificationService.error("Debe ingresar Descripción del plan");
			}
			if(this.plan.position == null){
				return this.notificationService.error("Debe ingresar Posición");
			}
			if(this.plan.validity_period == null){
				return this.notificationService.error("Debe seleccionar un Periodo");
			}
			if(this.plan.validity_value == null){
				return this.notificationService.error("Debe ingresar un Valor para el periodo");
			}

			if(this.plan.title_email == null){
				return this.notificationService.error("Debe ingresar un titulo para el email");
			}

			if(this.plan.message_email == null){
				return this.notificationService.error("Debe ingresar un mensaje para el email");
			}


			/*if(this.plan.label_inscription == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de inscripción");
			}

			if(this.plan.label_payment == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de pago");
			}

			if(this.plan.label_frecuency == null){
				return this.notificationService.error("Debe ingresar un valor para etiqueta de frecuencia");
			}*/




			this.plan.imageName = this.imageName;
			this.plan.imageType = this.imageType;
			this.plan.image64 = this.image64;

			this.plan.imageDetailName = this.imageDetailName;
			this.plan.imageDetailType = this.imageDetailType;
			this.plan.imageDetail64 = this.imageDetail64;

			this.plan.charge_inmediately = this.charge.value;

		}
		this.planService.create(this.plan).subscribe(plan =>  {
				this.notificationService.sucessInsert(plan.name_plan);
				this.location.back();
		}, err => this.notificationService.error(err)); 

	}

	//Metodo que permite que solo se introduzcan valores numericos
	onlyNumberKey(event){
		return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
	  }

	  onFileChange(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageName = file.name;
			this.imageType = file.type;
			this.image64 = reader.result.toString();
		  };
		}
	  }

	  onFileChangeDetail(event) {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
		  const file = event.target.files[0];
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imageDetailName = file.name;
			this.imageDetailType = file.type;
			this.imageDetail64 = reader.result.toString();
		  };
		}
	  }
	
}
