import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from './auth.service';

// reference: https://juristr.com/blog/2018/02/angular-permission-directive/
@Directive({
	selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
	private currentUser;
	private permissions = [];
	private op;
	// private val;
	private elseBlock: TemplateRef<any>;

	constructor(
		// private element: ElementRef,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authService: AuthService
	) {}
	
	ngOnInit() {
		/*this.authService.currentUser.subscribe(user => {
			this.currentUser = user;
			this.updateView();
		});*/
		this.currentUser = JSON.parse(localStorage.getItem('currentData'))['user'];
		this.updateView();
	}

	@Input()
	set hasPermission(val) {
		// this.val = val;
		this.permissions = val;
		this.updateView();
	}

	@Input()
	set hasPermissionOperation(op) {
		this.op = op;
	}

	@Input()
	set hasPermissionElse(templateRef: TemplateRef<any>) {
		this.elseBlock = templateRef;
	}

	private updateView() {
		if (this.checkPermission()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
			if (this.elseBlock) {
				this.viewContainer.createEmbeddedView(this.elseBlock);
			}
		}
	}

	private checkPermission() {
		if (this.currentUser && this.currentUser.authUser.roles) {
			for (const checkPermission of this.permissions) {
				if (this.currentUser.authUser.roles[0].permissions.find(x => x.identifier.toUpperCase() === checkPermission.toUpperCase())) {
					return true;
				}
			}
		}
		return false;
	}
}
