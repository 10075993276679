
  <h3 class="title">Listado de Integraciones</h3>

  <div class="tool-bar-wrap both-side">
    <div class="right row">
      <button *hasPermission="['group.create']" class="btn-icon" type="button" (click)="create()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
    
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
            [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
            [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="tableService.selection.toggle(row)"
            [checked]="tableService.selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Usuario</th>
        <td mat-cell *matCellDef="let element">{{element.user}}</td>
      </ng-container>

      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Password</th>
        <td mat-cell *matCellDef="let element">{{element.password}}</td>
      </ng-container>
      
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >URL</th>
        <td mat-cell *matCellDef="let element">{{element.url}}</td>
      </ng-container>

      <ng-container matColumnDef="token">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Token</th>
        <td mat-cell *matCellDef="let element">{{element.token}}</td>
      </ng-container>

      <ng-container matColumnDef="partner?.name_partner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >compañía</th>
        <td mat-cell *matCellDef="let element">{{element?.partner?.name_partner}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
      </tr>
    </table>

    <mat-paginator showFirstLastButtons
      [length]="tableService.pager.totalEntities"
      [pageIndex]="tableService.pager.pageIndex"
      [pageSize]="tableService.pager.pageSize"
      [pageSizeOptions]="[20, 50, 100]"
      (page)="list($event)">
    </mat-paginator>
  </div>

  <context-menu #basicMenu>

      <ng-template contextMenuItem (execute)="update($event.item)">
        <div class="item">
          <i class="material-icons">edit</i>
          <span>Actualizar</span>
        </div>
      </ng-template>
    
  </context-menu>
