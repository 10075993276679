import { Component, ViewChild,Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService} from '../shared/notification/notification.service';
import { BaseService } from '../shared/services/base.service';
import {Creditcard} from "./creditcard";
import {FormBuilder, Validators} from "@angular/forms";
import {CreditcardService} from "./creditcard.service";
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../utils/models/pager';

@Component({
  template: `

  <h3  class="title">Listado de Tarjetas de credito</h3>

  <form *ngIf="estado" [formGroup]="addNewCreditcardForm" (ngSubmit)="add()" novalidate>

  <fieldset class="fieldset">

  <div class="field-row">
      
				<div class="field-row-item">
          <label for="numberCreditCard">Número de tarjeta</label>
          <input type="text" formControlName="numberCreditCard" (keypress)="onlyNumberKey($event)"   class="form-control" placeholder="Número de tarjeta" required>
          <validator [control]="addNewCreditcardForm.controls['numberCreditCard']"></validator>
        </div>
        
				<div class="field-row-item">
          <label for="nameHolderCreditCard">Nombre en tarjeta</label>
          <input type="text" formControlName="nameHolderCreditCard"   class="form-control" placeholder="Nombre" required>
          <validator [control]="addNewCreditcardForm.controls['nameHolderCreditCard']"></validator>
        </div>

        <div class="field-row-item">
          <label style="display: block; " for="expiration">Vencimiento</label>
          <label for="expMonthCreditCard"></label><input type="text" (keypress)="onlyNumberKey($event)" formControlName="expMonthCreditCard" style="width: 15%; " min="1" max="12" maxlength="2" class="form-control" placeholder="Mes" required>
          <label for="expYearCreditCard"></label><input type="text" (keypress)="onlyNumberKey($event)" min="1" max="99" formControlName="expYearCreditCard" style="width: 15%; " maxlength="2" class="form-control" placeholder="Año" required>
          <validator [control]="addNewCreditcardForm.controls['expYearCreditCard']"></validator>
        </div>

      </div>

    <div class="options">
      <button class="btn-text" type="button" (click)="cancel()">
        <span> Cancelar </span>
      </button>
			<button class="btn-text" type="submit" [disabled]="!addNewCreditcardForm.valid">
				<span> Añadir </span>
			</button>
		</div>
    
  </fieldset>
  </form>

  <form *ngIf="editar" [formGroup]="editCreditcardForm" (ngSubmit)="edit(idedit)" novalidate>

    <fieldset class="fieldset">

      <div class="field-row">

          <input type="text" value="{{ idedit }}" hidden/>
        
          <div class="field-row-item">
            <label for="nameHolderCreditCard">Nombre en tarjeta</label>
            <input type="text" formControlName="nameHolderCreditCard" class="form-control" placeholder="Nombre" required>
            <validator [control]="editCreditcardForm.controls['nameHolderCreditCard']"></validator>
          </div>
        
      </div>

      <div class="options">
        <button class="btn-text" type="button" (click)="cancel()">
          <span> Cancelar </span>
        </button>
        <button class="btn-text" type="submit" [disabled]="!editCreditcardForm.valid">
          <span> Editar </span>
        </button>
		  </div>
    
    </fieldset>
  </form>


	<div class="tool-bar-wrap both-side">
    <div class="right row">
    
			<button *hasPermission="['tdc.create']" class="btn-icon" title="Crear" type="button" (click)="create()">
				<i class="material-icons">add</i>
      </button>
      
      <!--
      <button class="btn-icon"  title="Cambiar estado de la tarjeta" type="button" (click)="change($event.item)">
          <i class="material-icons">loop</i>
      </button>
			
			<button class="btn-icon"  title="Editar nombre de la tarjeta" type="button" (click)="update($event.item)">
					<i class="material-icons">edit</i>
      </button> 
      -->

		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="maskCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Número de tarjeta</th>
				<td mat-cell *matCellDef="let element">{{element.maskCreditCard}}</td>
			</ng-container>

			<ng-container matColumnDef="expMonthCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Proveedor</th>
				<td mat-cell *matCellDef="let element">{{element.expMonthCreditCard}}</td>
      </ng-container>
      
      <ng-container matColumnDef="expMonth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Mes de vencimiento</th>
        <td mat-cell *matCellDef="let element">{{element.expMonth}}</td>
      </ng-container>

			<ng-container matColumnDef="expYearCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Año de vencimiento</th>
				<td mat-cell *matCellDef="let element">{{element.expYearCreditCard}}</td>
			</ng-container>
			
			<ng-container matColumnDef="nameHolderCreditCard">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.nameHolderCreditCard}}</td>
      </ng-container>
      
      <ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha de creación</th>
				<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
      </ng-container>
      
      <ng-container matColumnDef="estatus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.estatus}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
			</tr>
		</table>

  </div>
  
  <context-menu #basicMenu>

			<ng-template *hasPermission="['tdc.create']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Editar Nombre</span>
				</div>
      </ng-template>
      
      
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['tdc.delete']" contextMenuItem (execute)="change($event.item)">
				<div class="item">
					<i class="material-icons">loop</i>
					<span>Cambiar estado</span>
				</div>
      </ng-template>
      
	</context-menu>


	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmación de cambio de estado</div>
				<div class="dialog-message" >¿Está seguro que desea cambiar el estado de las tarjetas seleccionadas?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})

export class CreditcardListComponent {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'maskCreditCard', 'expMonthCreditCard', 'expMonth' ,'expYearCreditCard', 'nameHolderCreditCard', 'createdAt', 'estatus'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;

  public loading = false;
	@Input('id_customer') id_customer : number;
	items: Creditcard[] = [];
  tarjetas = [];
  editar = false;
  estado = false;
  IDcustomer;

  //Formulario para anadir una nueva TDC
  addNewCreditcardForm = this.fb.group({
    numberCreditCard: ['', [Validators.required]],
    nameHolderCreditCard: ['', [Validators.required]],
    expMonthCreditCard: ['', [Validators.required]],
    expYearCreditCard: ['', [Validators.required]]
  });
  //Formulario para editar el NameHolder dada una TDC
  editCreditcardForm = this.fb.group({
    nameHolderCreditCard: ['', [Validators.required]]
  });

  confirm: boolean = false;
	constructor(
    public fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		public tableService: TableService<any>,
    private creditCardService : CreditcardService,
		private notificationService: NotificationService
	){
    this.tableService = new TableService;
  }

	ngOnInit(){
    this.activatedRoute.params.subscribe(params =>{
      this.IDcustomer = params["IDcustomer"];
      //Loading activado
      this.loading = true;
      this.confirm = false;
      //Se enlistan las TDC de un customer
      this.list(this.tableService.pager.pageIndex);
    });
	}

  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

	list(page?: number){
    //La lista de tarjetas se inicia vacia
      this.items = [];
    //Se buscan las tarjetas de credito de un customer
      this.creditCardService.findByCustomer(this.IDcustomer).subscribe(params => {
          if(params['result'] != null){
            this.tarjetas = params['result'];
            //Por cada tarjeta
            for(let i in this.tarjetas){
              let tdc = this.tarjetas[i].creditCard;
              //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
              this.creditCardService.findByToken(this.IDcustomer,this.tarjetas[i].tokenCreditCard).subscribe(params => {
                //Si la tarjeta esta correctamente referenciada
                if(params['result'] != null) {
                  tdc.expMonth = tdc.expMonthCreditCard;
                  //Se carga el nombre del proveedor TDC en la columna indicada
                  tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
                  //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
                  tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
                  //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
                  tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
                  //Se busca el estatus de la TDC de manera de mostrarlo al cliente
                  this.creditCardService.findTdcById(this.IDcustomer,tdc.id).subscribe(parms => {
                    //Se guarda la descripcion del estado en la columna correspondiente
                    tdc.estatus = parms['result']['statusCreditCard'] == 1 ? "Activo" : "Inactivo";
                    //Se anade la TDC a la tabla correspondiente
                    this.items.push(tdc);
                    
                    this.dataSource = new MatTableDataSource<any>(this.items);
                    //this.tableService.pager = response['pager'];
                    this.tableService.selection.clear();
                  }, (err) => this.notificationService.error(err) );
                }

              }, (err) => {
                console.log("ERRORRR");
                console.log(err)
                this.notificationService.error(err)} );
            }
            
          }else{
            console.log("NO TDC")
          }
        //Las tarjetas estan ahora cargadas
          this.loading = false;
      }, (err) => {
        console.log("ERRORRR");
        console.log(err)
        this.notificationService.error(err);   this.loading = false; });
	}

  add(valorconf?: boolean){
      let obj = <Creditcard> BaseService.builderObject(this.addNewCreditcardForm.value);
      //Si no se ha dejado un campo vacio en el formulario para agregar TDCs
      if (obj.expMonthCreditCard != null && obj.expYearCreditCard != null &&
        obj.nameHolderCreditCard != null && obj.numberCreditCard != null) {
        //Se verifica el rango del mes y ano de vencimiento de la TDC
          if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(),obj.expYearCreditCard.toString())) {
            //Se verifica el numero de digitos de la TDC
            if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
              //Se verifica que el numero de la TDC cumpla con el algoritmo de Luhm
              if (this.valid_credit_card(obj.numberCreditCard.toString())) {
                //En caso de que el numero de tarjeta (ultimos digitos) coincidan con una TDC previamente agregada, pedir confirmacion
                if (!this.confirm) {
                  //Se busca en las tarjetas
                  for (let i in this.items) {
                    //Si una TDC coincide en sus ultimos digitos
                    if (this.items[i].maskCreditCard.substr(this.items[i].maskCreditCard.length-4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length-4)) {
                      //Solicitar confirmacion
                      this.confirm = true;
                      /*this.confirmation = {
                        title: "Confirmación para agregar tarjeta",
                        message: "La tarjeta que está intentando agregar puede que ya se encuentre creada," +
                        " ¿Está seguro que desea agregarla?",
                        yesClicked: this.add.bind(this, true),
                        noClicked: this.add.bind(this, false),
                        hiddenClose: false
                      };*/
                      return;
                    }
                  }
                  valorconf = true;
                }
                //Una vez confirmada (de ser necesaria una confirmacion, en caso contrario entra directamente)
                if (valorconf) {
                  let creditcard = new Creditcard();
                  creditcard.estatus = "1";
                  creditcard.numberCreditCard = obj.numberCreditCard.toString();
                  creditcard.nameHolderCreditCard = obj.nameHolderCreditCard;
                  creditcard.expMonthCreditCard = obj.expMonthCreditCard;
                  creditcard.expYearCreditCard = obj.expYearCreditCard;

                  //Se crea la TDC con todos los valores introducidos
                  this.creditCardService.create(this.IDcustomer,creditcard).subscribe(params => {
                    if (params['message'] = "Successful created") {
                      this.notificationService.sucessInsert("Tarjeta de crédito");
                      //Se reinician los componentes visuales de la pantalla
                      this.addNewCreditcardForm.reset();
                      this.ngOnInit();
                    }
                    else {
                      this.notificationService.error("No se pudo crear tarjeta");
                    }
                  }, err => {
                    //En caso de error al no reconocer el proveedor de la TDC
                    if (err == "Credit card provider doesn't exist") {
                      this.notificationService.error("Proveedor de la tarjeta de crédito desconocido");
                    }else{
                      this.notificationService.error("No se pudo crear tarjeta");
                    }
                  });
                }
                this.confirm = false;
              }
              else {
                this.notificationService.error("El número no corresponde a una tarjeta de crédito");
              }
            }
            else {
              this.notificationService.error("Verifique el número de la tarjeta. Cantidad de digitos inválida");
            }
          }
          else {
            this.notificationService.error("Verifique el año/mes de vencimiento de la tarjeta introducida");
          }
      } else {
        this.notificationService.error("Todos los campos de la tarjeta son requeridos");
      }
  }

  //Metodo que permite verificar el mes y ano de la TDC introducida
  verifyCCYearAndMonth(month : string, year: string) : boolean{
    //Verifica que el mes se encuentre entre 01 y 12
    if(1 < Number.parseInt(month) && Number.parseInt(month) > 12){
      return false;
    }

    //Verifica el limite inferior del ano de vencimiento
    if((Number.parseInt(month)-1) < new Date().getMonth()){
      if(Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }
    else{
      if(Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }

    //Verifica el limite superior del ano de vencimiento
    if(Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2))+15)){
        return false;
    }
    return true;
  }

  //Metodo que permite verificar si la TDC introducida cumple con el algoritmo de Luhm (es una tarjeta valida)
  valid_credit_card(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) == 0;
  }

  //Permite editar el nameHolder de una TDC previamente agregada
  edit(idedit : number){
    //Busca dentro de las TDC del customer
    this.creditCardService.findByCustomer(this.IDcustomer).subscribe(params => {
      if(params['result'] != null){
        let lastarjetas = params['result'];
        //Por cada tarjeta
        for(let i in lastarjetas){
          let latdc : Creditcard = lastarjetas[i].creditCard;
          //La tarjeta encontrada es la que se va a editar
          if(latdc.id == idedit) {
            latdc.nameHolderCreditCard = this.editCreditcardForm.value.nameHolderCreditCard;
            //Se verifica que el nombre en la TDC no se encuentre vacio
            if (this.editCreditcardForm.value.nameHolderCreditCard.toString().trim().length > 1) {
              //Se actualiza la informacion de la tarjeta
              this.creditCardService.update(this.IDcustomer,latdc).subscribe(params => {
                if (params['message'] == 'Successful updated') {
                  this.notificationService.sucessUpdate("Tarjeta de crédito");
                  //Se actualizan los componentes de la pantalla
                  this.editar = false;
                  this.editCreditcardForm.reset();
                  this.ngOnInit();
                }
                else {
                  this.notificationService.error("No se pudo actualizar la tarjeta");
                }
              }, err => {
                this.notificationService.error(err)
              });
            }else{
              this.notificationService.error("El nombre es requerido");
            }
          }
        }
      }
    });
  }

  cancel(){
    this.estado = false;
    this.editar = false;
  }

  deletes(){
    let ids : Array<number> = [];
    for(const r of this.tableService.selection.selected ){
      ids.push(r.id);
    }
    this.cambioestado(ids);
  }

  //Metodo que permite desactivar/reactivar una tarjeta para poder ser utilizada
  cambioestado(ids : number[]){
    for(let id of ids) {
      let mask="";
      for(let i in this.tarjetas){
        if(this.tarjetas[i].creditCard.id == id){
          mask = this.tarjetas[i].creditCard.maskCreditCard;
        }
      }
      this.creditCardService.findTdcById(this.IDcustomer,id).subscribe(tdc => {
        //Se guarda la descripcion del estado en la columna correspondiente
        this.creditCardService.delete(this.IDcustomer,tdc['result'].id).subscribe(params => {
          this.ngOnInit();
      }, (err) => {
          this.notificationService.error("No se pudo cambiar el estado de la tarjeta " + mask + ". Verifique que no se encuentre asociada a un plan activo");
      });
      }, (err) => this.notificationService.error(err) );
    } 
  }

  wait(ms : number){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
    }
  }

  create(this){
    if(!this.editar) {
      if(!this.estado) {
        this.estado = true;
      }else{
        this.estado = false;
      }
      this.ngOnInit();
    }
  }

  //Metodo que permite cargar el nameHolder de la tarjeta seleccionada en la barra de edicion correspondiente
  update(this){
    let ids : Array<number> = [];
    for(const r of this.tableService.selection.selected ){
      ids.push(r.id);
    }
    if (ids.length == 1 && !this.estado){
      if(this.editar) {
        this.editar = false;
      }
      else{
        this.editar = true;
        let nombreactual = "";
        for(let i in this.items){
          if(this.items[i].id == ids[0]){
            nombreactual = this.items[i].nameHolderCreditCard;
          }
        }
        this.editCreditcardForm.setValue({'nameHolderCreditCard' : nombreactual});
      }
      this.idedit = ids[0];
    }
  }

  change(){
    this.confirmDelete = false;
  }

  read(){
  this.ngOnInit();
  }
}
