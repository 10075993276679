import { HttpParams } from '@angular/common/http';

export class OptionFilter {

	name: string;
    plan: number;
	id: number;
	partner: number;
    service: number;
    
	constructor(filter?: OptionFilter) {
		if (filter) {
            this.id = filter.id;
            this.name = filter.name;
			this.plan = filter.plan;
			this.partner = filter.partner;
            this.service = filter.service;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		
		if (this.partner) {
			httpParams = httpParams.set('partner', this.partner.toString());
        }
    
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		
        if (this.plan) {
			httpParams = httpParams.set('plan', this.plan.toString());
		}
		
		if (this.service) {
			httpParams = httpParams.set('service', this.service.toString());
        }
		return httpParams;
	}
}