import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class ReportService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/invoice';
  private readonly BASE_URL2: string = this.HOST + '/payment';
  private readonly URL_BILLINGTRACK: string = this.HOST + '/billingsettingtrack/report';
  private readonly URL_REBILLINGTRACK: string = this.HOST + '/rebillingsettingtrack/report';

  httpOptions : any;

  constructor(
    private http: HttpClient
  ) { 
    super();
     // Http Headers
     this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
   }

  getReportInvoices(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getReportInvoicesByPaymentDate(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/bypayment', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getBillingSettins(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    console.log(this.HOST + '/billing');
    
    return this.http.get(this.HOST + '/billing', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getRebillingSettins(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/rebilling', this.httpOptions)
    .pipe(catchError(this.handleError));
  }


  getCustomerHasPlan(id:number,params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/hasplan/customer/'+ id, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getCustomerByPlan(id:number,params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/hasplan/plan/'+ id, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getCustomersByPlan(id:number,params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/partner/'+ id + '/customers/plan/' + id, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getCustomerByPartner(id:number,params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/partner/'+ id + '/customer', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getInvoicesForUpdate(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/update', this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  
  getInvoicesForFix(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/fix', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getPaymentsByInvoice(params: HttpParams = new HttpParams(), id_invoice): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL2 + '/list/' + id_invoice.toString(), this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getReportInvoicesCharged(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/charged', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getReportCustomerInvoices(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/customer', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  cancelInvoice(id: number): Observable<any> {
    return this.http.put(this.BASE_URL+ '/cancel/' + id, {} ,this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  sendInvoicesToUpdate(array: any[]): Observable<any> {
    return this.http.post(this.BASE_URL + '/process', {invoices: array} ,this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  sendInvoicesToFix(array: any[]): Observable<any> {
    return this.http.post(this.BASE_URL + '/fix/error/notification', {invoices: array} ,this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getReportBillingTracks(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.URL_BILLINGTRACK, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getReportRebillingTracks(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.URL_REBILLINGTRACK, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getInvoicesToNotificate(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/report/notification-failed', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getHasPlanById(id: number): Observable<any> {
    return this.http.get(this.HOST + '/hasplan/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getInvoiceById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getStatusInvoice(value: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/status/' + value , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getErrocCodeBacDescription(value: string): Observable<any> {
    return this.http.get(this.HOST+ '/errorcodebac/description/' + value , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getAuditionApiReport(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/audition/api/report', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getAuditionInfoReport(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/audition/info/report', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  getInvoicesTOcharge(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.HOST + '/invoice/report/tocharge', this.httpOptions)
    .pipe(catchError(this.handleError));
  }

}
