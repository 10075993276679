
import { Routes } from '@angular/router';
import { OptionComponent } from './option/option.component';
import { OptionListComponent } from './option-list/option-list.component';
import { OptionCreateComponent } from './option-create/option-create.component';
import { OptionReadComponent } from './option-read/option-read.component';
import { OptionUpdateComponent } from './option-update/option-update.component';

export const optionsRoutes: Routes = [
	{
				path: ':IDplan/options',
				component: OptionComponent,
				data: {
					breadcrumb: "Opciones"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: OptionListComponent,
					
					},
					{
					    path:'create',
					    component: OptionCreateComponent,
					    data: {
					    	breadcrumb : "Crear"
					    }
					},
				  	{
					    path: ':optionID',
					    component: OptionComponent,
					    data: {
					    	breadcrumb: "Detalle"
					    },
					    children:[
						    {
							    path: '',
							    pathMatch: 'full',
							    component: OptionReadComponent,
							
							},
							{
							    path: 'update',
							    component: OptionUpdateComponent,
							  	data: {
							    	breadcrumb: "Actualizar"
							    }
						  	}

					  	]
				  	}
				]
			}
];

export const routingOptionComponnents = [OptionComponent, OptionListComponent, OptionCreateComponent, OptionReadComponent, OptionUpdateComponent];
