import { HttpParams } from '@angular/common/http';

export class FrecuencyFilter {

	name_frecuency: string;
    id: number;
    
	constructor(filter?: FrecuencyFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_frecuency = filter.name_frecuency;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_frecuency) {
			httpParams = httpParams.set('name_frecuency', this.name_frecuency);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		return httpParams;
	}
}