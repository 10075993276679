import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecoverPassComponent } from './recoverPass/recoverPass.component';
import { RecoverPassForgotComponent } from './recoverPass/recoverPass-forgot.component';
import { RecoverPassResetComponent } from './recoverPass/recoverPass-reset.component';
import { RecoverPassDoneComponent } from './recoverPass/recoverPass-done';

export const authRoutes: Routes = [
	{
		path: '',pathMatch: 'full',component: LoginComponent
	},
	{
		path: 'login',redirectTo: ''
	},
	{
		path: 'recoverPass',
		component: RecoverPassComponent,
		children: [
			{
				path: '',pathMatch: 'full',component: RecoverPassForgotComponent
			},
			{
				path: 'reset',component: RecoverPassResetComponent
			},
			{
				path: 'done',component: RecoverPassDoneComponent
			}
		]
	}
];

export const routingComponnents = [LoginComponent, RecoverPassComponent, RecoverPassForgotComponent, RecoverPassResetComponent, RecoverPassDoneComponent];


