
	<h3 class="title">Reporte de facturas por cliente</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.company">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.company}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.parameter">
							<span class="label">Cliente</span>
							<span>{{tableService.filter.parameter}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
            <div class="field-row-item">
              <label class="label" for="company" >Compañia</label>
              <mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.company" disableRipple>
                <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
              </mat-select>
            </div>

            <div class="field-row-item">
                  <label class="label" for="parameter">DNI o Código</label>
                  <input type="text" name="parameter" placeholder="None"  [(ngModel)]="filter.parameter">
            </div>
					</div>

			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>
  
  <div class="tool-bar-wrap both-side">
    <div class="right row">
    <button class="btn-icon" type="button" (click)="export()">
      <i class="material-icons">cloud_download</i>
    </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
			<ng-container matColumnDef="invoice">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id Factura</th>
				<td mat-cell *matCellDef="let element">{{element.invoice}}</td>
			</ng-container>

			<ng-container matColumnDef="customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.customer}}</td>
      </ng-container>
      
      <ng-container matColumnDef="centro">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
				<td mat-cell *matCellDef="let element">{{element.sucursal}}</td>
			</ng-container>
			
      
      <ng-container matColumnDef="estatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.estatus}}</td>
      </ng-container>


      <ng-container matColumnDef="creada">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.creada}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row" (click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  <context-menu #basicMenu>

			<ng-template *hasPermission="['processfile.process']" contextMenuItem (execute)="cancelPayment($event.item)">
				<div class="item">
					<i class="material-icons">cancel</i>
					<span>Cancelar Cobro</span>
				</div>
			</ng-template>
	</context-menu>
