
	<h3 class="title">Editar Frecuencia</h3>

	<form *ngIf="updateFrecuencyForm" [formGroup]="updateFrecuencyForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
      <legend class="legend" >Datos de la frecuencia</legend>
      
      <input type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_frecuency">Nombre</label>
					<input formControlName="name_frecuency" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateFrecuencyForm.controls['name_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="day_frecuency">Dia de frecuencia</label>
          <input formControlName="day_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de frecuencia" >
          <validator [control]="updateFrecuencyForm.controls['day_frecuency']"></validator>
        </div>
      </div>
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="month_frecuency">Mes de frecuencia</label>
          <input formControlName="month_frecuency" type="number" autocomplete="off" placeholder="Ingrese mes de frecuencia" >
          <validator [control]="updateFrecuencyForm.controls['month_frecuency']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="dayOfWeek_frecuency">Dia de la semana</label>
          <input formControlName="dayOfWeek_frecuency" type="number" autocomplete="off" placeholder="Ingrese dia de la semana" >
          <validator [control]="updateFrecuencyForm.controls['dayOfWeek_frecuency']"></validator>
        </div>
      </div>
      
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateFrecuencyForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
