import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { OptionService } from '../option.service';
import { NotificationService} from '../../shared/notification/notification.service';
import { BaseService } from '../../shared/services/base.service';
import { PlanService } from '../../plan/plan.service';

@Component({
  template: `
	<h3 class="title">Actualizar opcion</h3>

	<form *ngIf="updateOptionsForm" [formGroup]="updateOptionsForm" (ngSubmit)="update()">
    <fieldset class="fieldset">
    
      <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" >
      </div>
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateOptionsForm.controls['name']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label> 
					<mat-select [formControl]="planS"  disableRipple>
							<mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
					</mat-select>
					<validator [control]="planS"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

          <div class="field-row-item">
            <label class="label" for="status" >Estatus</label>
            <mat-select [formControl]="statuS" disableRipple>
              <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
            </mat-select>
            <validator [control]="statuS"></validator>
          </div>
        
        <div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input [formControl]="updateOptionsForm.controls['position']" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="updateOptionsForm.controls['position']"></validator>
					</div>

      </div>
      
      <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
            <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" ></editor>
					</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateOptionsForm || !planS.valid || !statuS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class OptionUpdateComponent implements OnInit {

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  public planS: FormControl = new FormControl('', Validators.required);
  public statuS: FormControl = new FormControl('', Validators.required);
  plan: any;
  plans = [];
  id_service: number;
  id_plan: number;


  updateOptionsForm = this.fb.group({
		name: ['', [Validators.required]],
		status:['', [Validators.required]],
    description:['', [Validators.required]],
    id: ['', [Validators.required]],
		position:['', [Validators.required]]
	});

  constructor(
    public fb: FormBuilder,	
    private location: Location,
    private activatedRoute: ActivatedRoute,
	  private planService: PlanService,
    private notificationService: NotificationService,
    private optionService: OptionService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(param =>{
      this.optionService.getById(param['optionID']).subscribe(option=>{
        let httpParams = this.planService.buildRequestParams();
        this.planService.getByService(option.plan.service.id,httpParams).subscribe(plans =>{
          this.plans = plans['result'];

          this.planS.setValue(this.plans[this.plans.findIndex(e => option.plan.id === e.id )].id);

          this.statuS.setValue(this.estatus[this.estatus.findIndex(e => option.status === e.value)].value);

          this.updateOptionsForm.controls["name"].setValue(option.name);
          this.updateOptionsForm.controls["id"].setValue(option.id);
          this.updateOptionsForm.controls["description"].setValue(option.description);
          this.updateOptionsForm.controls["position"].setValue(option.position);
        });
      });
    });
  }


  update() {
    let obj = <any> BaseService.builderObject(this.updateOptionsForm.value);
    obj.status = this.statuS.value;
    obj.plan = {id:this.planS.value};
    this.optionService.update(obj).subscribe(option =>  {
                        this.notificationService.sucessUpdate(option.name_plan);
                        this.location.back();
    }, err => this.notificationService.error(err));

  }
  onlyNumberKey(event){
		return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
	  }
}
