import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupService} from '../group.service';

@Component({
  template: `
	<h3 class="title">Detalle del grupo</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ name }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ description }}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Roles</span>
          <span *ngFor="let r of roles" >
            - {{r.description}}
          </span>
          <span *ngIf="roles.length == 0">
            - No posee roles asociados
          </span>
				</div>
      </div>
      
    </div>
   </div>
`
})
export class GroupReadComponent implements OnInit {
  public loading = false;
  roles: any[] = [];


  name;
  description;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private groupService: GroupService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute
      .params
      .subscribe(param => {
        this.groupService.getById(param['groupId']).subscribe(grupo => {
          this.name = grupo['name'];
          this.description = grupo['description'];
          this.roles = grupo['roles'];
          this.loading = false;
        }, err => {
          console.log(err);
          this.notificationService.error(err);
        });
      }, err => this.notificationService.error(err));

  }

  update() {
    this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
  }

}
