import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location,DatePipe } from '@angular/common';
import { BaseService } from '../shared/services/base.service';
import {CustomerService} from './customer.service';
import { NotificationService} from '../shared/notification/notification.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../shared/form/custom.validators';

@Component({
  template: `
	<h3 class="title">Editar Cliente</h3>

	<form *ngIf="updateCustomerForm" [formGroup]="updateCustomerForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
			<legend class="legend" >Datos del cliente</legend>

      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="name_customer" >Nombre</label>
          <input formControlName="name_customer" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updateCustomerForm.controls['name_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="lastName_customer" >Apellido</label>
          <input formControlName="lastName_customer" type="text" autocomplete="off" placeholder="Ingrese Appellido" >
					<validator [control]="updateCustomerForm.controls['lastName_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="code_customer" >Código</label>
          <input formControlName="code_customer" type="text" autocomplete="off" placeholder="Ingrese Código" >
					<validator [control]="updateCustomerForm.controls['code_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="identifier_customer" >DNI</label>
          <input formControlName="identifier_customer" autocomplete="off" placeholder="Ingrese DNI" >
					<validator [control]="updateCustomerForm.controls['identifier_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="email_customer" >Email</label>
          <input formControlName="email_customer" type="text" autocomplete="off" placeholder="Ingrese Email" >
					<validator [control]="updateCustomerForm.controls['email_customer']"></validator>
        </div>
        
				<div class="field-row-item" style="padding-top:15px;">
          <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Fecha de nacimiento" formControlName="birthDate_customer"  readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
					<validator [control]="updateCustomerForm.controls['birthDate_customer']"></validator>
        </div>

      </div>
      
      <div class="field-row">
      
				<div class="field-row-item">
					<label class="label" for="phoneNumber_customer" >Teléfono</label>
          <input formControlName="phoneNumber_customer" type="text" (keypress)="onlyNumberKey($event)" autocomplete="off" placeholder="Ingrese Teléfono" >
					<validator [control]="updateCustomerForm.controls['phoneNumber_customer']"></validator>
        </div>
        
				<div class="field-row-item">
					<label class="label" for="adress_customer" >Dirección</label>
          <input formControlName="adress_customer" type="text" autocomplete="off" placeholder="Ingrese Dirección" >
					<validator [control]="updateCustomerForm.controls['adress_customer']"></validator>
        </div>


      </div>
      
      <div class="field-row">
      
      <div class="field-row-item">
        <label class="label" for="sucursal_id" >Sucursal</label>
        <input formControlName="sucursal_id" type="text" autocomplete="off" placeholder="Ingrese ID de sucursal" >
      </div>

      <div class="field-row-item">
        <label class="label" for="status" >Estatus</label>
        <mat-select [formControl]="status_customer" disableRipple>
          <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
        </mat-select>
        <validator [control]="status_customer"></validator>
      </div>


      <div class="field-row-item">
      <label class="label" for="status" >Contribuyente</label>
      <mat-select [formControl]="taxexempt" disableRipple>
          <mat-option *ngFor="let tax of taxes" [value]="tax.value">{{tax.description}}</mat-option>
        </mat-select>
        <validator [control]="taxexempt"></validator>
    </div>

      

    </div>
					

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCustomerForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CustomerUpdateComponent{


  customer: any;
  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  status_customer: FormControl = new FormControl('', Validators.required);

  taxes : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];
  taxexempt: FormControl = new FormControl('', Validators.required);


  //Formulario de actualizacion del cliente
  updateCustomerForm = this.fb.group({
    name_customer: ['', [Validators.required]],
    lastName_customer: ['', [Validators.required]],
    identifier_customer: ['', [Validators.required]],
    code_customer: ['',[Validators.required]],
    email_customer: ['', [CustomValidators.emailRegex, Validators.maxLength(100)]],
    phoneNumber_customer: [''],
    adress_customer: [''],
    birthDate_customer: [''],
    sucursal_id:['']
  });

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private customerService: CustomerService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
	){}

	ngOnInit(){
    this.activatedRoute.parent.params.subscribe(params=>{
      //Se carga la informacion del cliente seleccionado para la actualizacion
      this.customerService.getById(params["customerID"]).subscribe(customer => {
        this.customer = customer;
        //Se llenan los componentes del formulario con la informacion
        this.updateCustomerForm.controls["name_customer"].setValue(this.customer.name_customer);
        this.updateCustomerForm.controls["lastName_customer"].setValue(this.customer.lastName_customer);
        if(this.customer.code_customer != null){
          this.updateCustomerForm.controls["code_customer"].setValue(this.customer.code_customer);
        }
        this.updateCustomerForm.controls["adress_customer"].setValue(this.customer.adress_customer);
        this.updateCustomerForm.controls["phoneNumber_customer"].setValue(this.customer.phoneNumber_customer);
        this.updateCustomerForm.controls["birthDate_customer"].setValue(this.customer.birthDate_customer);
        this.updateCustomerForm.controls["email_customer"].setValue(this.customer.email_customer);
        this.updateCustomerForm.controls["identifier_customer"].setValue(this.customer.identifier_customer);
        this.updateCustomerForm.controls["sucursal_id"].setValue(this.customer.sucursal_id);

        this.status_customer.setValue(this.estatus[this.estatus.findIndex(e => customer.status_customer === e.value)].value);
        var tax = null;
        if(customer['taxexempt']){
          tax = false;
        }else{
          tax = true;
        }
        this.taxexempt.setValue(this.taxes[this.taxes.findIndex(e => tax === e.value)].value);

      });
		});
	}

  //Metodo que permite que solo se introduzcan valores numericos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  formatearFecha(fecha : string) : string{
    let splitted = fecha.split("-");
    return splitted[2] + "-" + splitted[1] + "-" + splitted[0];
  }

  //Metodo de actualizacion del cliente
	update() {
    let obj = <any> BaseService.builderObject(this.updateCustomerForm.value);
    obj.partner = {id:this.customer.partner.id};
    this.customer = obj;
    this.customer.birthDate_customer = this.datePipe.transform(this.customer.birthDate_customer, 'yyyy-MM-dd');
  
      this.activatedRoute.parent.params.subscribe(param => {
          this.customer.id = param['customerID'];
          this.customer.status_customer = this.status_customer.value;

          if(this.taxexempt.value){
            this.customer.taxexempt = false;
          }else{
            this.customer.taxexempt = true;
          }
          
          //Se actualiza la informacion del cliente
          this.customerService.update(this.customer).subscribe(customer => {
                this.notificationService.sucessUpdate(customer.name_customer);
                this.location.back();
          }, (err) => {
            console.log(err);
            this.notificationService.error(err?.error?.message);
          });
      }, (err) => {
          this.notificationService.error(err);
      });

   
  }
}
