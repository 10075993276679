import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  template: `
              <h3 class="title" >Detalle del usuario</h3>

              <div class="tool-bar-wrap both-side">
                <div class="right row">
                  <button class="btn-icon" title="Cambiar contraseña" type="button" (click)="updatePassword()">
                    <i class="material-icons">vpn_key</i>
                  </button>
                </div>
              </div>

              <div class="fieldset-wrap">
                <div class="fieldset">
                  <div class="legend" >Informacion</div>

                  <div class="field-row">
                    <div class="field-row-item">
                      <span class="label" >Correo</span>
                      <span>{{user.authUser?.email || '-'}}</span>
                    </div>
                  </div>
                  <div class="field-row">
                    <div class="field-row-item">
                      <span class="label" >Nombre</span>
                      <span>{{user.name_user || '-'}}</span>
                    </div>
                    <div class="field-row-item">
                      <span class="label" >Apellido</span>
                      <span>{{user.lastName_user || '-'}}</span>
                    </div>
                  </div>
                
                </div>
`
})
export class UserReadComponent implements OnInit {

  user;

  constructor(
    private router: Router,
		private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentData')).user;
  }

  updatePassword() {
		this.router.navigate([`./${this.user.id}/password`], {relativeTo: this.activatedRoute});
	}

}
