import {Routes} from '@angular/router';
import {GroupComponent} from './group.component';
import {GroupListComponent} from './group-list/group-list.component';
import {GroupCreateComponent} from './group-create/group-create.component';
import {GroupReadComponent} from './group-read/group-read.component';
import {GroupUpdateComponent} from './group-update/group-update.component';

export const groupRoutes: Routes = [
  {
    path: 'group',
    component: GroupComponent,
    data: {
      breadcrumb: 'Grupos',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: GroupListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: GroupCreateComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':groupId',
        component: GroupComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: GroupReadComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: GroupUpdateComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }

        ]
      }
    ]
  }
];

export const routingGroupComponents = [GroupComponent,GroupListComponent,GroupCreateComponent,GroupReadComponent,GroupUpdateComponent];
