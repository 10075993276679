import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RebillingComponent } from './rebilling/rebilling.component';
import { ListRebillingComponent } from './list-rebilling/list-rebilling.component';
import { ReadRebillingComponent } from './read-rebilling/read-rebilling.component';
import { CreateRebillingComponent } from './create-rebilling/create-rebilling.component';
import { UpdateRebillingComponent } from './update-rebilling/update-rebilling.component';
import {homeRouting} from '../home/home.routes';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RebillingService} from './rebilling.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import { CronJobsModule } from 'ngx-cron-jobs';
import {MatButtonModule} from '@angular/material/button'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
    MatPaginatorModule,
    MatButtonModule,
		MatSortModule,
		MatExpansionModule,
    MatSelectModule,
    MatInputModule,
    CronJobsModule,
    NgxMatSelectSearchModule
  ],
  declarations: [RebillingComponent, ListRebillingComponent, ReadRebillingComponent, CreateRebillingComponent, UpdateRebillingComponent],
  providers: [RebillingService]
})
export class RebillingModule { }
