import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { homeRouting } from '../home/home.routes';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseService } from '../shared/services/base.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button'
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { routingCustomerComponnents } from './customer.routes';

@NgModule({
  imports: [
    	CommonModule,
    	SharedModule,
		homeRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatButtonModule,
		MatExpansionModule,
		MatSelectModule, MatInputModule,MatDatepickerModule
  ],
  declarations: [
	routingCustomerComponnents
	],
	providers: [
    	BaseService
	]
})
export class CustomerModule { }
