import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlanService } from './plan.service';
import { NotificationService} from '../shared/notification/notification.service';

@Component({
	template: `
	<h3 class="title">Detalle del plan</h3>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['plan.create']" class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ plan?.name_plan }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo de inscripción del plan</span>
					<span>{{ plan?.enrollmentCost_plan }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo </span>
					<span>{{ plan?.feeCost_plan }}</span>
				</div>
			</div>

			

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo por congelamiento del plan</span>
					<span>{{ plan?.freezeCostPlan }}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Impuesto</span>
					<span>{{ plan?.taxRate_plan }} %</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ plan?.status_plan || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">ID Plan Partner</span>
					<span>{{ plan?.idPlan_partner || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Frecuencia</span>
					<span>{{ frecuency?.name_frecuency || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">{{label}}</span>
					<span>{{ partner?.name_partner || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Servicio</span>
					<span>{{ service?.name_service || '-'}} </span>
				</div>
			</div>

			<div *ngIf="service?.type == 2">

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Título</span>
						<span>{{ plan?.title || '-'}} </span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Posición</span>
						<span>{{ plan?.position || '-'}} </span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Periodo</span>
						<span>{{ plan?.validity_period || '-'}} </span>
					</div>
				</div>

				
				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Valor</span>
						<span>{{ plan?.validity_value || '-'}} </span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Cobrar inscripción</span>
						<span>{{ plan?.charge_inmediately || '-'}} </span>
					</div>
				</div>
<!--
				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Etiqueta de inscripción</span>
						<span>{{ plan?.label_inscription || '-'}} </span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Etiqueta de frecuencia</span>
						<span>{{ plan?.label_frecuency || '-'}} </span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Etiqueta de pago</span>
						<span>{{ plan?.label_payment || '-'}} </span>
					</div>
				</div>
-->
				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Titulo email</span>
						<span>{{ plan?.title_email || '-'}}</span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Mensaje email</span>
						<span>{{ plan?.message_email || '-'}}</span>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Descripción</span>

						<span>{{ plan?.description || '-'}}</span>

						<!-- <editor [inline]="true" [disabled]="true" apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh"
						[init]="{
							height: 500,
							menubar: false,
							plugins: [
							],
							toolbar:
							  ''
						  }"
						[(ngModel)]="plan.description" >
						</editor> -->
					</div>
				</div>


				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Detalle del plan</span>

						<span>{{ plan?.detail_plan || '-'}}</span>

					</div>
				</div>

			<!-- 	<div class="field-row">
					<div class="field-row-item">
						<span class="label">Descripción extendida</span>
						<editor [inline]="true" [disabled]="true" apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh"
						[init]="{
							height: 500,
							menubar: false,
							plugins: [
							],
							toolbar:
							  ''
						  }"
						[(ngModel)]="plan.extended_description" >
						</editor>
					</div>
				</div> -->

				<div class="field-row">
					<div class="field-row-item">
						<span class="label">Imagen del plan</span>
						<gallery-image [items]="items"></gallery-image>
					</div>
				</div>

				<!-- <div class="field-row">
					<div class="field-row-item">
						<span class="label">Imagen del detalle plan</span>
						<gallery-image [items]="items_detail"></gallery-image>
					</div>
				</div> -->

			</div>

		</div>
	</div>

	

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
`
})
export class PlanReadComponent {

	confirmDelete = true;
	partner: any;
	service: any;
	frecuency: any;
	public plan: any;
	label = 'Compañía';
	items: any[] = new Array();
	items_detail: any[]  = new Array();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private planService: PlanService,
		private notificationService: NotificationService
	){}

	ngOnInit() {

		if (this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
		this.label = 'sucursal';
		}
		
		this.activatedRoute.params.subscribe(param => {
	
			// buscamos el plan por su ID
			this.planService.getById(param['planID'])
			.subscribe(plan => {
				this.plan = plan;
				
				this.items.push(this.plan.url_image)
				this.items_detail.push(this.plan.url_image_detail)

				if(this.plan.charge_inmediately == true){
					this.plan.charge_inmediately = "SI";
				}else{
					this.plan.charge_inmediately = "NO";
				}

				switch(this.plan.status_plan) { 
					case 1: { 
						this.plan.status_plan = "Activo"; 
					   	break; 
					} 
					case 2: { 
						this.plan.status_plan = "Aplicado"; 
					   	break; 
					} 
		
					default: {  
					   break; 
					} 
		  
				}

				switch(this.plan.validity_period) { 
					case 1: { 
						this.plan.validity_period = "Dias"; 
					   	break; 
					} 
					case 2: { 
						this.plan.validity_period = "Meses"; 
					   	break; 
					} 
		
					default: {  
					   break; 
					} 
		  
				}
	
				this.service = this.plan.service;
				this.partner = this.plan.service.partner;
				this.frecuency = this.plan.frecuency;	

			});
		}, err => this.notificationService.error(err));	
				
	}

	update(){
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	delete(this){
		this.planService.delete(this.plan.id)
			.subscribe(any =>  {
				this.notificationService.sucessDelete();
				this.location.back();
			}, err => this.notificationService.error(err));

	}
}
