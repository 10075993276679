
	<h3 class="title">Crear opcion</h3>

	<form *ngIf="createOptionsForm" [formGroup]="createOptionsForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createOptionsForm.controls['name']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label> 
					<mat-select [formControl]="planS"  disableRipple>
							<mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
					</mat-select>
					<validator [control]="planS"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createOptionsForm.controls['status']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createOptionsForm.controls['status']"></validator>
        </div>
        
        <div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input [formControl]="createOptionsForm.controls['position']" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="createOptionsForm.controls['position']"></validator>
					</div>

      </div>
      
      <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
						<textarea formControlName="description"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createOptionsForm || !planS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
