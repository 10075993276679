
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Editar renovacion Plan</h3>

	<form *ngIf="editBillingForm" [formGroup]="editBillingForm" (ngSubmit)="update()">
		<fieldset class="fieldset">      
      <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="partner" >Compañia</label>
				
          <mat-select [formControl]="partner"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
                <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
          </mat-select>

				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Sucursal</label>
          <mat-select [formControl]="sucursal" (selectionChange)="onSucursalChange($event.value)" disableRipple>
								<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="servicio" >Servicio</label>
          <mat-select [formControl]="service" (selectionChange)="onServiceChange($event.value)" disableRipple>
                <mat-option *ngFor="let serv of services" [value]="serv.id">{{serv.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
          
        
          <mat-select  [formControl]="plan" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name_plan}}
            </mat-option>
          </mat-select>


          <validator [control]="plan"></validator>

				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_billingsetting" >Nombre</label>
					<input formControlName="name_billingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="editBillingForm.controls['name_billingsetting']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
         
          <mat-select [formControl]="status_billing" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
          <validator [control]="status_billing"></validator>
          
				</div>
			</div>
			
	
      
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="cycle" >Inicio ciclo</label>
          
          <mat-select [formControl]="cycle" disableRipple>
            <mat-option *ngFor="let cycle of cycles" [value]="cycle">{{cycle}}</mat-option>
          </mat-select>
          <validator [control]="cycle"></validator>


        </div>
        <div class="field-row-item">
          <label class="label" for="plan" >Cantidad de deuda acumulada</label>
         
          <mat-select [formControl]="accumulated" disableRipple>
            <mat-option *ngFor="let debt of debts" [value]="debt">{{debt}}</mat-option>
          </mat-select>
          <validator [control]="accumulated"></validator>

        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
					<label class="label" >Frecuencia actual</label>
					<input formControlName="frequency_billingsetting" type="text" autocomplete="off" (click) ="onClick()" readonly>
				</div>
        <div class="field-row-item">
            <div *ngIf = "show">
              <label class="label" for="frecuency">Elija una Frecuencia nueva</label>
              <cron-jobs [formControl]="editBillingForm.controls['time_billingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
            </div>  
				</div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!editBillingForm.valid || !status_billing.valid || !accumulated.valid || !cycle.valid || !plan.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
