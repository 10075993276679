import { Component } from '@angular/core';

@Component({
	template: `
		<div i18n="@@recoverPass-done">
			<div class="dialog-title">Su contraseña ha sido reiniciada!</div>
			<div class="dialog-message">Ahora puede <u class="clickable-item" routerLink="/login">Loguearse</u> con su nueva contraseña</div>
		</div>
	`,
	styleUrls: ['../account.css']
})
export class RecoverPassDoneComponent {
}
