import { Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Plan } from './plan';
import { PlanService } from './plan.service';
import { NotificationService} from '../shared/notification/notification.service';
import { PartnerService } from '../partner/partner.service';
import { ServicioService } from '../servicio/servicio.service';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../utils/models/pager';
import { PlanFilter } from './plan.filter';

@Component({
	template: `
	<h3 *ngIf="!show" class="title">Listado de planes del servicio : {{service?.name_service}}</h3>

	<h3 *ngIf="show" class="title">Listado de planes</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.name_plan">
							<span class="label">Nombre</span>
							<span>{{tableService.filter.name_plan}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.partnerp">
							<span class="label">{{label}}</span>
							<span>{{partner.name_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.service">
							<span class="label">Servicio</span>
							<span>{{service.name_service}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="name_plan">Nombre</label>
							<input type="text" name="name_plan" placeholder="None"  [(ngModel)]="filter.name_plan">
						</div>

						<div class="field-row-item">
								<label class="label" for="company" >Compañia</label>
								<mat-select [formControl]="partnerS" [(value)]="filter.partnerp"  (selectionChange)="changeCompany($event.value)" [disabled]="true">
									<mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
								</mat-select>
						</div>

						<div class="field-row-item">
								<label class="label" for="service" >Servicio</label>
								<mat-select [formControl]="serviceS"  [(value)]="filter.service"  disableRipple>
									<mat-option *ngFor="let opt2 of services" [value]="opt2.id">{{opt2.name_service}}</mat-option>
								</mat-select>
						</div>
					</div>

					
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div *hasPermission="['plan.create']" class="right row">
			<button  class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>

			<button  class="btn-icon"  title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i class="material-icons">delete</i>
			</button>


		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
			</ng-container>
			
			<ng-container matColumnDef="status_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{element.status_plan}}</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['plan.update']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>

			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template *hasPermission="['plan.delete']" contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span>Eliminar</span>
				</div>
			</ng-template>

			<ng-template *ngIf="options" contextMenuItem divider="true"></ng-template>
			<ng-template *ngIf="options" contextMenuItem (execute)="toOptions($event.item)">
				<div class="item">
					<i class="material-icons">view_carousel</i>
					<span>Opciones</span>
				</div>
			</ng-template>
		
	</context-menu>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea eliminar los registros seleccionados?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
`
})

export class PlanListComponent {

	show = false;
	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['select', 'id', 'name_plan', 'status_plan'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new PlanFilter(this.tableService.filter);
	partners = [];
	partner: any;
	id_partner: number;
	id_service: number;
	services : any[];
	service: any;
  	parents = false;
  	label = 'Compañía';
	pos : number;
	posServ : number;
	options = false;

	partnerS: FormControl = new FormControl('');
	serviceS: FormControl = new FormControl('');
	
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private planService: PlanService,
		public tableService: TableService <any>,
		private servicioService : ServicioService,
		private notificationService: NotificationService,
		private partnerService: PartnerService
	){this.tableService = new TableService;}

	ngOnInit(){
    	if(this.router.url.split('/')[2] === 'sucursals' || this.router.url.split('/')[4] === 'sucursals') {
      	this.parents = true;
      	this.label = 'Sucursal'
		}
		this.activatedRoute.params.subscribe(param => {
			this.id_service = param['IdService']
			this.activatedRoute.parent.parent.params.subscribe(param =>{
					this.id_partner = param['IdPartner'];
					this.searchDrops();
					this.list();
				})
		}, err => this.notificationService.error(err));		
	}

	selectPartner(id:number){
		this.partner = this.partners.find(c => c.id == id);
		this.partnerS.setValue(this.partner.id);
	}

	selectService(id:number){
		
		if(this.services.length != 0){
			this.service = this.services.find(s => s.id == id);
			if(this.service.type == 2){
				this.options = true;
			}
			this.serviceS.setValue(this.service.id);
		}else{
			this.notificationService.error("NO Existen servicios activos")
		}
		
	}

	searchDrops() {
		let httpParams = this.partnerService.buildRequestParams();

		httpParams = httpParams.set('parents',this.parents.toString());
		httpParams = httpParams.set('parent',(-1).toString());
	
			// se cargan los partners con estatus activo
			this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
				// se cargan los servicios que posee el partner y que esten activos
				this.servicioService.getByPartner(this.id_partner).subscribe(services => {
					// se llenan los dropdown
					this.services = services['result'];
					this.partners = params['result'];
					this.selectService(this.id_service);
					this.selectPartner(this.id_partner);
					this.filter = new PlanFilter();
				});
			});
	}

	list(event?: PageEvent){
		// si se esta haciendo alguna busqueda por Compañia
		if(this.filter.partnerp != undefined){
			this.selectPartner(this.filter.partnerp)
		}

		if(this.filter.service != undefined){
			this.selectService(this.filter.service)
		}

		if(this.filter.name_plan != undefined){
			this.show = true;
		}else{
			this.show = false;
		}

		
		if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}


		let httpParams = this.planService.buildRequestParams(this.tableService.sort,"m",
		{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

  
		httpParams = this.filter.getHttpParams(httpParams);
		
		this.planService.getByService(this.id_service,httpParams).subscribe(response => {

			for(const r of response['result']){
				if(r.status_plan == 1){
					r.status_plan = 'Activo';
				}
				if(r.status_plan == 2){
					r.status_plan = 'Inactivo';
				}
			}

			this.dataSource = new MatTableDataSource<any>(response['result']);
			this.tableService.pager = response['pager'];
			this.tableService.selection.clear();
				
			}, err => {
        this.notificationService.error(err);
        console.log(err);
      });

	}

	create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
	}

	read(item: Plan){
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
	}
	update(item: Plan){ // actualiza un plan
		localStorage.setItem("planread", JSON.stringify({id: item.id}));
		this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
	}

	//-- 

	deletes() {
		const length = this.tableService.selection.selected.length;
		this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
			--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

			if (length === 1) {
				this.planService.delete(this.tableService.selection.selected[0].id).subscribe(response => {
					this.notificationService.sucessDelete(response.name_service);
					this.list();
				}, err => this.notificationService.error(err));
			} else if (length > 1) {
				this.planService.deletes(this.tableService.selection.selected).subscribe(response =>  {
					this.notificationService.sucessDelete();
					this.list();
				}, err => console.log(err));
		} 
	}

	reset() {
		this.filter = new PlanFilter();
		this.list();
		this.searchDrops();
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
		this.tableService.filter = new PlanFilter(this.filter);
		this.list();
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new PlanFilter(this.tableService.filter);
	}


	changeCompany(company: any) {
		if(company != undefined){
			delete this.filter['service'];
			this.servicioService.getByStatus(1, company).subscribe(services => {
				this.services = services['result'];
			});
		}else{
			this.services = [];
			delete this.filter.service;
		}
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	toOptions(item:any){ /// carga los planes asociados a un servicio
		//localStorage.setItem("planes", JSON.stringify({servicio: item.id, partner : partner}));
		this.router.navigate(['../' + item.id + '/options'], {relativeTo: this.activatedRoute});
	}
//--
}
