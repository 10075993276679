
	<h3 class="title">Detalle del cliente</h3>

	<div *hasPermission="['customer.create']" class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="TArjetas de credito" type="button" (click)="creditcard()">
				<i class="material-icons">credit_card</i>
			</button>
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
			
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ customer?.name_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Apellido</span>
					<span>{{ customer?.lastName_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Código</span>
					<span>{{customer?.code_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">DNI</span>
					<span>{{ customer?.identifier_customer || '-'}}</span>
				</div>
			</div>

			<div *ngIf="this.customer?.sucursal_id != null" class="field-row">
				<div class="field-row-item">
					<span class="label">Sucursal</span>
					<span>{{ customer?.sucursal_id || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Email</span>
					<span>{{ customer?.email_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha de Nacimiento</span>
					<span>{{ customer?.birthDate_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Teléfono</span>
					<span>{{ customer?.phoneNumber_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Dirección</span>
					<span>{{ customer?.adress_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ customer?.status_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Contribuyente</span>
					<span>{{ customer?.taxexempt || '-'}}</span>
				</div>
			</div>

		</div>
	</div>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
