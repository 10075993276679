import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'notification',
	template: `
	`
})
export class NotificationComponent implements OnInit {
	@Input() content: JSON;

	constructor(private service: ToastrService) { }

	ngOnInit() {
		if (this.content) {
			this.createNotification();
		};
	}

	createNotification() {
		switch (this.content['type']) {
			case 'success':
				this.service.success('Notification', this.content['message']);
				break;
			case 'alert':
				this.service.warning('Notification', this.content['message']);
				break;
			case 'error':
				this.service.error('Error', this.content['message']);
				break;
		}
	}

	onCreate(event) { /*console.log(event);*/ }

	onDestroy(event) { /*console.log(event);*/ }
}
