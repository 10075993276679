
		<h3 class="title" >Actualizar contraseña</h3>

		<form *ngIf="updatePasswordForm" [formGroup]="updatePasswordForm" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend">User data</legend>

        <div class="field-row">
        
					<div class="field-row-item">
              <label class="label" for="currentPassword" >Contraseña actual</label>
              <input formControlName="currentPassword" type="password" autocomplete="off">
              <validator [control]="updatePasswordForm.controls['currentPassword']"></validator>
          </div>
          
          <div class="field-row-item">
            <label class="label" for="newPassword" >Nueva contraseña</label>
            <input formControlName="newPassword" type="password" autocomplete="off">
            <validator [control]="updatePasswordForm.controls['newPassword']"></validator>
          </div>

          <div class="field-row-item">
						<label class="label" for="newPasswordConfirm" >Confirma la nueva contraseña</label>
						<input formControlName="newPasswordConfirm" type="password" autocomplete="off">
						<validator [control]="updatePasswordForm.controls['newPasswordConfirm']"></validator>
          </div>

        </div>
       
			
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!updatePasswordForm.valid">
					<span>Guardar</span>
				</button>
			</div>
		</form>
	