
  <ng-progress></ng-progress>
  <div class="grid-h">
    <div class="nav">
      <side-nav></side-nav>
    </div>
    <div class="content grid-v">
      <section class="header">
        <header>
          <usertool></usertool>
          <breadcrumbs id="breadcrumbs"></breadcrumbs>
        </header>
      </section>

      <section class="content">
        <router-outlet>
       <!-- <div> <canvas #lineChart>{{ chart }}</canvas> </div> -->
        </router-outlet>
      </section>

      <!--<footer class="footer"></footer>-->
    </div>
  </div>
	