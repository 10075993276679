import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CuponService } from '../cupon.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { CuponFilter } from '../cupon.filter';
import { DatePipe} from '@angular/common';


@Component({
  template: `
	<h3  class="title">Listado de Cupones</h3>

	<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
						
						<!--
              <div class="field-row-item" *ngIf="tableService.filter.id">
								<span class="label" i>id</span>
								<span>{{tableService.filter.id}}</span>
              </div>
							-->
							
							<div class="field-row-item" *ngIf="tableService.filter.name_cupon">
								<span class="label">Nombre del cupon</span>
								<span>{{tableService.filter.name_cupon}}</span>
							</div>
							
              <!--
							<div class="field-row-item" *ngIf="tableService.filter.status_partner">
								<span class="label">Estatus</span>
								<span>{{tableService.filter.status_partner}}</span>
							</div>
							-->
              
						</div>
					</div>
					<button class="btn-icon"  title="Buscar" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
					
						<!--
            <div class="field-row">
							<div class="field-row-item">
								<label class="label" for="id">id</label>
								<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
							</div>
						</div>
					-->
					
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="name_cupon">Nombre</label>
								<input type="text" name="name_cupon" placeholder="None"  [(ngModel)]="filter.name_cupon">
							</div>

						</div>
						
            <!--
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="status_partner" >Estatus</label>
								<mat-select placeholder="None" [(value)]="filter.status_partner" [compareWith]="compareFn" disableRipple>
									<mat-option >None</mat-option>
									<mat-option [value]="'1'">Activo</mat-option>
									<mat-option [value]="'2'">Inactivo</mat-option>
								</mat-select>
							</div>
						</div>
						-->
            
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span >Limpiar</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span>Buscar</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

	<div  *hasPermission="['cupons.create']" class="tool-bar-wrap both-side">
		<div class="right row">
			<button  class="btn-icon" type="button" (click)="create()">
				<i class="material-icons">add</i>
			</button>
		</div>
	</div>

	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		<!--
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="tableService.selection.toggle(row)"
						[checked]="tableService.selection.isSelected(row)">
					</mat-checkbox>
				</td>
			</ng-container>
			-->
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="name_cupon">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_cupon}}</td>
      </ng-container>
      
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Tipo</th>
        <td mat-cell *matCellDef="let element">{{element.type}}</td>
      </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Valor</th>
      <td mat-cell *matCellDef="let element">{{element.value}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Valor</th>
      <td mat-cell *matCellDef="let element">{{element.status}}</td>
    </ng-container>

    <ng-container matColumnDef="dateFrom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Desde</th>
      <td mat-cell *matCellDef="let element">{{element.dateFrom}}</td>
		</ng-container>
		
		<ng-container matColumnDef="dateUntil">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Hasta</th>
      <td mat-cell *matCellDef="let element">{{element.dateUntil}}</td>
	</ng-container>

	<ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Creado</th>
      <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
    </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
				>
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>

	<context-menu #basicMenu>

			<ng-template *hasPermission="['cupons.update']" contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span>Actualizar</span>
				</div>
			</ng-template>
		
	</context-menu>

`
})
export class ListCuponComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'name_cupon','type','value','status','dateFrom', 'dateUntil', 'createdAt'];
  
  isOpenSearchPanel = false;
	filter = new CuponFilter(this.tableService.filter);

  constructor(
    	private router: Router,
		private activatedRoute: ActivatedRoute,
		private cuponService: CuponService,
		public tableService: TableService<any>,
		private datePipe: DatePipe
  ) { this.tableService = new TableService; }

  ngOnInit() {
    this.list();
  }

  list(event?: PageEvent){

	if(this.tableService.pager.pageSize != 20){
		this.tableService.pager.pageSize = 20;
	}
	
	let httpParams = this.cuponService.buildRequestParams(this.tableService.sort,"m",
	{pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})


  	httpParams = this.filter.getHttpParams(httpParams);

      this.cuponService.getbyplan(httpParams,JSON.parse(localStorage.getItem("customer_has_plan")).chp).subscribe(response => {
		  console.log(response);
        for(const r of response['result']){
          if(r.status == 1){
            r.status = 'Activo';
          }
          if(r.status == 2){
            r.status = 'Inactivo';
          }
          if(r.type == 1) {
            r.type = '%'
          }
          if(r.type == 2) {
            r.type = '$'
			}
			r.createdAt = this.datePipe.transform(r.createdAt, 'yyyy-MM-dd')
		}

        this.dataSource = new MatTableDataSource<any>(response['result']);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
      });
    
   
  }

  create(){
		this.router.navigate(["./create"], {relativeTo: this.activatedRoute});
  }
  
  update(item: any){
		localStorage.setItem("cupon", JSON.stringify({cupon: item.id}));
		this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }
  
  reset() {
    this.filter = new CuponFilter();
    this.list();
  }
  
  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new CuponFilter(this.filter);
    this.list();
  }
  
  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new CuponFilter(this.tableService.filter);
  }
  
  compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  applyFilter(filterValue: string) {
	this.dataSource.filter = filterValue.trim().toLowerCase();
}


}
