import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';
import { Validators, FormControl } from '@angular/forms';

@Component({
  template: `
	<h3 class="title">Clientes por compañía</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.status">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.dni">
							<span class="label">DNI</span>
							<span>{{tableService.filter.dni}}</span>
						</div>
 
						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

            <div class="field-row">
              <div class="field-row-item">
                <label class="label" for="company" >Compañia</label>
                <mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.id_partner" >
                  <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
                </mat-select>
              </div>
              <div class="field-row-item">
                <label class="label" for="status" >Estatus</label>
                  <mat-select placeholder="None" [formControl]="statusS" [(value)]="filter.status" >
                  <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
                </mat-select>
              </div>

							<div class="field-row-item">
								<label class="label" for="dni">DNI</label>
								<input type="text" name="dni" placeholder="None"  [(ngModel)]="filter.dni">
							</div>
						
            

					</div>

				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>



	<div class="mat-elevation-z8e" *ngIf="dataSource">
    
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>

		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
      <ng-container matColumnDef="identifier_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
        <td mat-cell *matCellDef="let element">{{element.identifier_customer}}</td>
      </ng-container>

			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer}}</td>
			</ng-container>
			
			<ng-container matColumnDef="lastName_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Apellido</th>
				<td mat-cell *matCellDef="let element">{{element.lastName_customer}}</td>
      </ng-container>

      <ng-container matColumnDef="code_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Codigo</th>
        <td mat-cell *matCellDef="let element">{{element.code_customer}}</td>
      </ng-container>

      <ng-container matColumnDef="sucursal_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
        <td mat-cell *matCellDef="let element">{{element.sucursal_id}}</td>
      </ng-container>

      <ng-container matColumnDef="email_customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Email</th>
        <td mat-cell *matCellDef="let element">{{element.email_customer}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class CustomerPartnerComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'identifier_customer' ,'name_customer', 'lastName_customer','code_customer','sucursal_id','email_customer'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
  filter = new ReportFilter(this.tableService.filter);

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  

  partnerS: FormControl = new FormControl('', Validators.required);
  statusS: FormControl = new FormControl('', Validators.required);

  partners: any[]= [];
	selectedPartner: any;
	partner: any;

  items: any[] = [];

	toExport : any[] = [];

	static_data: any[] = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe) { this.tableService = new TableService; }

  ngOnInit() {
    let httpParams = this.partnerService.buildRequestParams();

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    // cargamos companias
    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
      this.partners = params['result'];
      // cargamos sucursales
      let httpParams2 = this.partnerService.buildRequestParams();

      httpParams2 = httpParams2.set('parents',true.toString());
      httpParams2 = httpParams2.set('parent',(-1).toString());

      this.partnerService.getPartnersByUserId(httpParams2).subscribe(params =>{
        for( var p of params['result']){
          this.partners.push(p)
        }
        if(localStorage.getItem('searchPlans')){
          this.partnerS.setValue(this.partners[this.partners.findIndex(p => JSON.parse(localStorage.getItem('searchPlans')).partner == p.id)].id);
          this.filter.id_partner = this.partnerS.value;
          if(JSON.parse(localStorage.getItem('searchPlans')).status){
            this.statusS.setValue(this.estatus[this.estatus.findIndex(p => JSON.parse(localStorage.getItem('searchPlans')).status == p.value)].value);
            this.filter.status = this.statusS.value;
          }
          this.filter.dni = JSON.parse(localStorage.getItem('searchPlans')).dni;
          localStorage.removeItem('searchPlans');
          this.search();
        }

      });

      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });
  }

  list(event?: PageEvent) {

      if(this.filter.id_partner == null || this.filter.id_partner == undefined){
        this.notificationService.error("Debe seleccionar una compania");
        return;
      }
    
      if(this.tableService.pager.pageSize != 20){
        this.tableService.pager.pageSize = 20;
      }
      this.items = [];
   
      this.tableService.filter = new ReportFilter(this.filter);
      
      let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
      httpParams = this.filter.getHttpParams(httpParams);
    
      this.reportService.getCustomerByPartner(this.filter.id_partner,httpParams).subscribe(params => {
        this.items = params['result']; // items que mostrara la tabla
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = params['pager'];
        this.tableService.selection.clear();
        if (this.items.length === 0) {
          this.notificationService.alert('No se encontraron resultados para la busqueda');
        }
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      }); 
    
  }

  export() {
    if(this.items.length > 0){
        
        this.tableService.filter = new ReportFilter(this.filter);
        
        let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	
        httpParams = this.filter.getHttpParams(httpParams);

        this.reportService.getCustomerByPartner(this.filter.id_partner,httpParams).subscribe(params => {
          let data = [];
          
           for(var item of params['result']){
            if(item.sucursal_id ==  null){
              item.sucursal_id = "N/A";
              //item.adress_customer.replace(","," ");
            }
            data.push({'ID':item.id,'DNI': item.identifier_customer ,'NOMBRE':item.name_customer,'APELLIDO':item.lastName_customer,'TELEFONO':item.phoneNumber_customer,'DIRECCION':item.adress_customer,'CODIGO':item.code_customer,'CENTRO':item.sucursal_id,'EMAIL':item.email_customer})
           }
        
          const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
          const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            useTextFile: false,
            filename: "Report-Clients-By-Partner-"+date,
            useBom:false,
            useKeysAsHeaders: true,
            showTitle: true,
            title: 'Clientes por compañía - Fecha:' + date,
          };
          const csvExporter = new ExportToCsv(options);
  
          this.toExport = data;
          csvExporter.generateCsv(this.toExport);
        
        }, err => {
          this.notificationService.error(err);
          console.log(err);
        }); 
  
      }else{
        this.notificationService.error("No existen registros para exportar");
      }
      
    }
  
  
      //-- 
  
  reset() {
    this.filter = new ReportFilter();
    this.dataSource = new MatTableDataSource<any>([]);
    this.tableService.filter = new ReportFilter(this.filter);
    this.partnerS = new FormControl('', Validators.required);
    this.statusS  = new FormControl('', Validators.required);
  }

  read(item: any) {
    localStorage.setItem("searchPlans", JSON.stringify({customer: item, partner:this.filter.id_partner, status:this.filter.status, dni: this.filter.dni}));
    this.router.navigate(['./plans/' + item.id], {relativeTo: this.activatedRoute});
  }

  
  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new ReportFilter(this.filter);
    this.list();
  }
  
  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new ReportFilter(this.tableService.filter);
  }
  
  applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
