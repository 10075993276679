
	<div class="fieldset-wrap">
	<div class="fieldset">
	
	<h3 class="title">Datos del cliente</h3>
    
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ hasplan?.customer?.name_customer + " " + hasplan?.customer?.lastName_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">DNI</span>
					<span>{{ hasplan?.customer?.identifier_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Telefono</span>
					<span>{{ hasplan?.customer?.phoneNumber_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Direccion</span>
					<span>{{ hasplan?.customer?.adress_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Codigo</span>
					<span>{{ hasplan?.customer?.code_customer  || '-'}}</span>
				</div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Email</span>
					<span>{{ hasplan?.customer?.email_customer  || '-'}}</span>
				</div>
	  </div>

	  <div *ngIf="hasplan?.customer?.sucursal_id != null" class="field-row">
				<div class="field-row-item">
					<span class="label">Sucursal</span>
					<span>{{ hasplan?.customer?.sucursal_id  || '-'}}</span>
				</div>
      </div>
	  
	  <h3 class="title">Datos del plan</h3>
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ hasplan?.plan?.name_plan || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus en el plan</span>
					<span>{{ hasplan?.status_customerPlan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha  inicio</span>
					<span>{{ hasplan?.startdate_customerPlan || '-'}}</span>
				</div>
      </div>
      
      

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha fin</span>
					<span>{{ hasplan?.endDate_customerPlan || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Proxima fecha de cobro</span>
					<span>{{ hasplan?.next_bill_date || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Numero contrato</span>
					<span>{{ hasplan?.contractNumber_Plan || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Balance</span>
					<span>{{ hasplan?.balance_customerPlan || '-'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo del plan</span>
					<span>{{ hasplan?.plan?.feeCost_plan || '-'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo de inscripción</span>
					<span>{{ hasplan?.plan?.enrollmentCost_plan || '-'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Costo de congelamiento</span>
					<span>{{ hasplan?.plan?.freezeCostPlan || '-'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Impuesto</span>
					<span>{{ hasplan?.plan?.taxRate_plan || '-'}} % </span>
				</div>
			</div>


		</div>
  </div>

