import { Injectable } from '@angular/core';
import {BaseService} from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class PermissionService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/permission';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super();
     // Http Headers
     this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
   }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAllSearch(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL + '/search', this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

}
