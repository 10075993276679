
		<ng-progress></ng-progress>
		<header>
			<img id="logo" src="./assets/images/logo-blanco.png" routerLink="/login"/>
		</header>
		<div class="wrap">
		<div i18n="@@signin">
			<div class="dialog-title">Inicia sesión</div>
		</div>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="login()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input type="text" formControlName="email" class="form-control">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Contraseña</label>
						<input type="password" formControlName="password" class="form-control">
						<validator [control]="form.controls['password']"></validator>
						<div class="clickable-item" style="text-align: right" routerLink="/recoverPass" >Olvidaste tu contraseña?</div>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@login">Acceder</span>
				</button>
			</div>
		</form>
		</div>
	