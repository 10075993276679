import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators} from '@angular/forms';
import { Partner } from './partner';
import { Country } from '../country/country';
import { City } from '../city/city';
import { PartnerService } from './partner.service'; 
import { CountryService } from '../country/country.service';
import { CityService } from '../city/city.service';
import { NotificationService} from '../shared/notification/notification.service';
import { BaseService } from '../shared/services/base.service';
import { CustomValidators } from '../shared/form/custom.validators';


@Component({
  template: `
		<h3 class="title">Crear Compañía</h3>

		<form *ngIf="createPartnerForm" [formGroup]="createPartnerForm" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-account">Datos de la compañía</legend>

				<div class="field-row"> 
					<div class="field-row-item">
						<label class="label" for="name_partner">Nombre</label>
						<input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
						<validator [control]="createPartnerForm.controls['name_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="identifier_bank">Identificador del banco</label>
						<input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
						<validator [control]="createPartnerForm.controls['identifier_bank']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="adress_partner" >Direccion</label>
						<textarea formControlName="adress_partner"></textarea>
						<validator [control]="createPartnerForm.controls['adress_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="country" >Pais</label>
						<mat-select [formControl]="createPartnerForm.controls['selectedCountry']" [compareWith]="compareFn" (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['selectedCountry']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="city">Ciudad</label>
						<mat-select [formControl]="createPartnerForm.controls['selectedCity']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['selectedCity']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone_partner" >Telefono</label>
						<input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
						<validator [control]="createPartnerForm.controls['phone_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="feeRate_partner">Porcentaje de cobro</label>
						<input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
						<validator [control]="createPartnerForm.controls['feeRate_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email_partner">Email</label>
						<input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
						<validator [control]="createPartnerForm.controls['email_partner']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createPartnerForm.controls['status_partner']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['status_partner']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="notify" >Notificar cobros</label>
						<mat-select [formControl]="createPartnerForm.controls['notify']" disableRipple>
							<mat-option *ngFor="let option of notify" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['notify']"></validator>
					</div>
					
					
				</div>

				<div class="form-group">
				<input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" hidden="true" >
                </div>

			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!createPartnerForm.valid">
					<span>Guardar</span>
				</button>
			</div>
		</form>
	`
})

export class PartnerCreateComponent {

	countries : Country[];
	cities : City[];
	partner: any;
	partners : Partner[];
	estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
	notify : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];

	// validaciones para campos de formulario
	createPartnerForm = this.fb.group({
		name_partner: ['', [Validators.required]],
		identifier_bank: ['', [Validators.required]],
		adress_partner: ['', [Validators.required]],
		email_partner: ['', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]],
		phone_partner: ['', [Validators.required]],
		feeRate_partner: ['', [Validators.required, CustomValidators.min(0),CustomValidators.max(100)]],
		selectedCountry:['', [Validators.required]],
		selectedCity:['', [Validators.required]],
		status_partner:['', [Validators.required]],
		returnRoute:['', [Validators.required]],
		notify:['', [Validators.required]]
	});
	constructor(
		public fb: FormBuilder,
		private location: Location,
		private partnerService: PartnerService,
		private countryService: CountryService,
		private cityService: CityService,
		private notificationService: NotificationService,
	){ }

	ngOnInit(){
		this.partnerService.getConfigUrl("customer_url").subscribe(config =>{

			this.createPartnerForm.controls["returnRoute"].setValue(config.value_config +"/responses");
			this.cargarCombos(); // cargamos los combos de la vista
		}, (err) => {
				console.log(err)
				this.notificationService.error("Error buscando url de Customer");
		});
	}

	cargarCombos() {
		/// cargamos los paises registrados
		this.countryService.getAll().subscribe(params => {
			this.countries = params['result'];
			let httpParams = this.partnerService.buildRequestParams();

			httpParams = httpParams.set('parents',false.toString());
			httpParams = httpParams.set('parent',(-1).toString());
		
			this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
				this.partners = params['result'];
			}, (err) => {
				this.notificationService.error(err);
			});
        }, (err) => {
        	this.notificationService.error(err);
		});
	}
	onItemChange(country:any){
		 // cargamos las ciudades del pais que seleccione el usuario
		this.cities = [];
		if(country!=undefined){
			this.cityService.getByCountry(country).subscribe(params => {
					this.cities = params['result'];
					}, (err) => {
						this.notificationService.error(err);
			});
		}
	}

	create() {
			let obj = <any> BaseService.builderObject(this.createPartnerForm.value);
			this.partner = obj;
			this.partner.city = {id: obj.selectedCity};
			if(this.partner.notify == null){
				this.partner.notify = false;
			}
			this.partnerService.create(this.partner).subscribe(partner =>  {
				this.notificationService.sucessInsert(partner.name_partner);
				this.location.back();
			}, (err) => {
				console.log(err);
				this.notificationService.error(err?.error?.message);
			});
	}

	compareFn(c1: any, c2: any): boolean { 
    	return c1 && c2 ? c1.id === c2.id : c1 === c2;
 	}

}
