
	<h3 class="title">Cargar Archivo</h3>

	<form *ngIf="uploadFileForm" [formGroup]="uploadFileForm" (ngSubmit)="upload()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label class="label" >Compañia</label>

          <mat-select [formControl]="uploadFileForm.controls['selectedPartner']" [compareWith]="compareFn" (selectionChange)="onPartnerChange($event.value)" disableRipple>
							<mat-option >None</mat-option>
							<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
					</mat-select>

				</div>
				<div class="field-row-item">
          <label class="label" >Sucursal</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedSucursal']" [compareWith]="compareFn" (selectionChange)="onSucursalChange($event.value)" disableRipple>
							<mat-option >None</mat-option>
							<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
          <label class="label" >Servicio</label>
          <mat-select [formControl]="uploadFileForm.controls['selectedService']" [compareWith]="compareFn" (selectionChange)="onServiceChange($event.value)" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let service of services" [value]="service.id">{{service.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
          <label class="label" >Plan</label>

          <mat-select [formControl]="uploadFileForm.controls['plan']" [compareWith]="compareFn" (selectionChange)="onPlanChange($event.value)" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
          </mat-select>

				</div>
			</div>
			
			<div class="field-row">
				<div class="field-row-item">
          <label class="label">Tipo de cobro</label>

          <mat-select [formControl]="uploadFileForm.controls['billing']" [compareWith]="compareFn" disableRipple>
              <mat-option >None</mat-option>
              <mat-option *ngFor="let billing of billings" [value]="billing.id">{{billing.name_billingsetting}}</mat-option>
          </mat-select>

        </div>
        <div class="field-row-item">
					<label class="label" for="name_processFile">Nombre del cobro</label>
					<input formControlName="name_processFile" type="text" autocomplete="off" placeholder="Ingrese nombre" >
					<validator [control]="uploadFileForm.controls['name_processFile']"></validator>
        </div>
        
        <div class="field-row-item">
          <label class="label">Archivo</label>
          <input style="display:block;" type="file" (change)="onFileChange($event)" required>
				</div>
      </div>

	

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!uploadFileForm.valid">
				<span>Continuar</span>
			</button>
		</div>
  </form>
  
  <modal-ns [(closed)]="confirmation">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >Esta seguro que desea procesar los cobros de este archivo?</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmation = true">
						<span >NO</span>
					</button>
					<button class="btn-text green" type="button" (click)="onCharge(); confirmation = true">
						<span>SI</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
  </modal-ns>
  
  <modal-ns [(closed)]="back">
		<ng-template modalContentDirective>
      <div class="dialog-content">
      <div class="dialog-title" >Notificacion</div>
				<div class="dialog-message" >El proceso de cobro se esta ejecutando, usted recibira un email con el resultado, adicionalmente puede consultar el estado aqui</div>
				<div class="options">
					<button class="btn-text green" type="button" (click)="backToList()">
						<span >Consultar</span>
					</button>
				</div>
			</div>
		</ng-template>statusServiceOrder
	</modal-ns>
