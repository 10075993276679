import { Component } from '@angular/core';
import { MenuService } from './menu.service';

import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';

interface menuItem {
	title: string;
	routerLink?: string;
	selected?: boolean;
	expanded?: boolean;
	children?: menuItem[];
}

@Component({
	selector: 'side-nav',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css'],
	providers: [MenuService],
})
export class MenuComponent {
	public readonly NAV_CONFIG = 'configurations';
	public readonly NAV_SECURITY = 'security';
	public readonly NAV_COMPANIES_SUCURSALS = 'companies_sucursals';
	public readonly NAV_REPORT = 'reports';

	dataMenuItem: string;
	selection = new SelectionModel<string>(true, []);

	constructor(
		private activatedRoute: ActivatedRoute
	) {
    // esto en caso de que se quiera que se mantenga un nav abierto al abrir la pagina
		//this.selection.select(this.NAV_REPORT);
	}

	ngOnInit() {
		if (this.activatedRoute.firstChild && this.activatedRoute.firstChild.data) {
			this.activatedRoute.firstChild.data.subscribe(data => {
				if (data['menu']) {
					this.dataMenuItem = data['menu'];
				}
			});
		}
	}

	select(event: any) {
		this.dataMenuItem = event.target.getAttribute('data-menu-item');
	}
}
