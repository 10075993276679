import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-invoices-by-payment',
  templateUrl: './invoices-by-payment.component.html',
  styleUrls: ['./invoices-by-payment.component.css']
})
export class InvoicesByPaymentComponent implements OnInit {

  	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['name_customer','identifier_customer', 'code_customer' , 'name_plan','centro', 'desc_status', 'mask' ,'total_invoice','autoritation','Fecha_Factura','Fecha_Procesado', 'next_bill'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

	partners: any[]= [];
	selectedPartner: any;
	partner: any;

	services: any[]= [];
	selectedService: any;
	service: any;

	plans: any[]= [];
	selectedPlan: any;
	plan: any;

	items: any[] = [];

	toExport : any[] = [];

	filterDateDesde = null;
	filterDateHasta = null;

	static_data: any[] = [];


	  partnerS: FormControl = new FormControl('');
  	serviceS: FormControl = new FormControl('');
  	planS: FormControl = new FormControl('');


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public 	tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}


  ngOnInit() {
	let httpParams = this.partnerService.buildRequestParams();

	httpParams = httpParams.set('parents',false.toString());
	httpParams = httpParams.set('parent',(-1).toString());
	  // cargamos companias
    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
	  this.partners = params['result'];
	  // cargamos sucursales
	  let httpParams2 = this.partnerService.buildRequestParams();

      httpParams2 = httpParams2.set('parents',true.toString());
      httpParams2 = httpParams2.set('parent',(-1).toString());
	  this.partnerService.getPartnersByUserId(httpParams2).subscribe(params =>{
		for( var p of params['result']){
			this.partners.push(p)
		}
		
		if(localStorage.getItem('reportInvoice')){
			this.partnerS.setValue(this.partners[this.partners.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).company == p.id)].id)
			this.filter.id_partner = this.partnerS.value;
			this.filter.since_date = JSON.parse(localStorage.getItem('reportInvoice')).since;
			this.filter.until_date = JSON.parse(localStorage.getItem('reportInvoice')).until;
			this.onPartnerChange(this.partnerS.value);
		  }


	  });
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  list(event?: PageEvent) {
	 
			if(this.filter.id_service == undefined){
				this.notificationService.error("Debe seleccionar un servicio");
				return;
			}

			if(this.tableService.pager.pageSize != 20){
				this.tableService.pager.pageSize = 20;
			}
			
			this.items = [];
		
			if(this.filter.id_service == null){
				this.notificationService.error("Debe seleccionar al menos un servicio");
				return;
			}
			if(this.filter.since_date != null && this.filter.until_date == null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
				return;
			}
			if(this.filter.since_date == null && this.filter.until_date != null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
				return;
			}
			this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
			this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
			var since = new  Date (this.filter.since_date);
			var until = new  Date (this.filter.until_date);
			if(until.getTime() < since.getTime()){
				this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
				return;
			}
			this.tableService.filter = new ReportFilter(this.filter);
			
			let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
			httpParams = this.filter.getHttpParams(httpParams);
			
			this.reportService.getReportInvoicesByPaymentDate(httpParams).subscribe(params => {
				this.items = params['result']; // items que mostrara la tabla
        
				// cortamos la TDC
				for( var invoice of this.items){
					var mask = invoice.mask;
					if(mask != null){
						invoice.mask = mask.substring(mask.length - 4, mask.length)
					}
					if(invoice.autoritation == ""){
						invoice.autoritation = "N/A"
					}
				}
				this.dataSource = new MatTableDataSource<any>(this.items);
				this.tableService.pager = params['pager'];
				this.tableService.selection.clear();
				if (this.items.length === 0) {
					this.notificationService.alert('No se encontraron resultados para la busqueda');
				}
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 	
  }


  onPartnerChange(partner: any) {
		this.services = [];
		this.plans = [];
		delete this.filter.id_service;
		delete this.filter.id_plan;
		if(partner != undefined){
			this.servicesService.getByPartner(partner).subscribe( services => {
				this.services = services['result'];
				if(localStorage.getItem('reportInvoice')){
					this.serviceS.setValue(this.services[this.services.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).service == p.id)].id)
					this.filter.id_service = this.serviceS.value;
					this.onServiceChange(this.serviceS.value);
				  }
			}, err => {
				this.notificationService.error('Error al cargar los servicios de la sucursal');
				console.log(err);
			});
		}
  }

  onServiceChange(value:any) {
		this.plans = [];
		delete this.filter.id_plan;
		if( value != undefined ){
			this.planServices.getByService(value).subscribe( plans => {
				this.plans = plans['result'];
				// ESTO PARA NO HACERLO LA 1ERA VEZ QUE SE BUSCA
				if(localStorage.getItem('reportInvoice')){
					if(JSON.parse(localStorage.getItem('reportInvoice')).plan){
						this.planS.setValue(this.plans[this.plans.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).plan == p.id)].id);
						this.filter.id_plan = this.planS.value;
						localStorage.removeItem("reportInvoice");
						this.search();
					}else{
						localStorage.removeItem("reportInvoice");
						this.search();
					}
				}
				
			}, error1 => {
				this.notificationService.error('Error cargando los planes del servicio');
				console.log(error1);
			});
		}
  }

  read(item: any) {
    // hacemos esto para no tener que consultar de nuevo la informacion del invoice en la siguiente pantalla
	localStorage.setItem('invoice', JSON.stringify(item));
	localStorage.setItem("reportInvoice", JSON.stringify({company: this.filter.id_partner, service: this.filter.id_service, plan : this.filter.id_plan, since: this.filter.since_date, until: this.filter.until_date}));
	this.router.navigate(['./detail/' + item.id], {relativeTo: this.activatedRoute});
  }

  export() {
	if(this.items.length > 0){
			
			this.tableService.filter = new ReportFilter(this.filter);
			let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			{pageIndex: -1 , pageSize: -1})
  
			httpParams = this.filter.getHttpParams(httpParams);
			this.reportService.getReportInvoicesByPaymentDate(httpParams).subscribe(params => {
				let data = [];
				 for(var item of params['result']){
					var mask = item.mask;
					if(mask != null){
						item.mask = mask.substring(mask.length - 4, mask.length)
					}
					if(item.autoritation == ""){
						item.autoritation = "N/A"
					}
					data.push({'ID':item.id,'NOMBRE':item.name_customer ,'APELLIDO': item.last_name, 'DNI': item.identifier_customer , 'CODIGO': item.code_customer ,'PLAN':item.name_plan,'CENTRO':item.sucursal,'ESTATUS':item.desc_status,'TDC': item.mask,'MONTO':item.total_invoice,'Autorizacion':item.autoritation,'CREADA':item.Fecha_Factura,'PROCESADA':item.Fecha_Procesado, 'PROXIMO COBRO': item.next_bill})
				 }
			
				const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
				const options = { 
					fieldSeparator: ',',
					quoteStrings: '"',
					decimalSeparator: '.',
					useTextFile: false,
					filename: "Report-Invoices-By-Payment-"+date,
					useBom:false,
					useKeysAsHeaders: true,
					showTitle: true,
					title: 'Facturas por fecha de cobro - Fecha:' + date,
				};
				
				const csvExporter = new ExportToCsv(options);

				this.toExport = data;
				csvExporter.generateCsv(this.toExport);
			
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 

		}else{
			this.notificationService.error("No existen registros para exportar");
		}
    
  	}


  	//-- 

	reset() {
		this.filter = new ReportFilter();
		this.plans = [];
		this.services = [];
		this.dataSource = new MatTableDataSource<any>([]);
		this.tableService.filter = new ReportFilter(this.filter);
		this.partnerS = new FormControl('');
		this.serviceS = new FormControl('');
		this.planS = new FormControl('');
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
		this.tableService.filter = new ReportFilter(this.filter);
		this.list();
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new ReportFilter(this.tableService.filter);
	}

	compareFn(c1: any, c2: any): boolean { 
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
