import { Routes } from '@angular/router';
import { UserUpdatePasswordComponent } from './user-update-password/user-update-password.component';
import { UserComponent } from './user/user.component';
import { UserReadComponent } from './user-read/user-read.component';


export const usersRoutes: Routes = [
	{
		path: 'user',
		component: UserComponent,
		data: {
			breadcrumb: 'user'
		},
		children: [
			{
				path: '',
				component: UserReadComponent
			},
			{
				path: ':userId/password',
				component: UserUpdatePasswordComponent,
				data: {
					breadcrumb: 'password'
				},
			}
		]
	}
];
