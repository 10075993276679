import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { OptionService } from '../option.service';
import { NotificationService} from '../../shared/notification/notification.service';
import { BaseService } from '../../shared/services/base.service';
import { PlanService } from '../../plan/plan.service';


@Component({
  template: `
	<h3 class="title">Crear opcion</h3>

	<form *ngIf="createOptionsForm" [formGroup]="createOptionsForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
			<div class="field-row">

				<div class="field-row-item">
					<label class="label" for="name">Nombre</label>
					<input formControlName="name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createOptionsForm.controls['name']"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label> 
					<mat-select [formControl]="planS"  disableRipple>
							<mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name_plan}}</mat-option>
					</mat-select>
					<validator [control]="planS"></validator> 
				</div>

				

			</div>	


			<div class="field-row">

				<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createOptionsForm.controls['status']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createOptionsForm.controls['status']"></validator>
        </div>
        
        <div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input [formControl]="createOptionsForm.controls['position']" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="createOptionsForm.controls['position']"></validator>
					</div>

      </div>
      
      <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
						<textarea formControlName="description"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createOptionsForm || !planS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class OptionCreateComponent implements OnInit {

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  public planS: FormControl = new FormControl('', Validators.required);
  plan: any;
  plans = [];
  id_service: number;
  id_plan: number;

  createOptionsForm = this.fb.group({
		name: ['', [Validators.required]],
		status:['', [Validators.required]],
		description:['', [Validators.required]],
		position:['', [Validators.required]]
	});

  constructor(
    public fb: FormBuilder,	
    private location: Location,
    private activatedRoute: ActivatedRoute,
	private planService: PlanService,
    private notificationService: NotificationService,
    private optionService: OptionService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(param =>{
      this.id_plan = param['IDplan'];

      this.activatedRoute.parent.parent.params.subscribe(param =>{
        this.id_service = param['IdService'];
        let httpParams = this.planService.buildRequestParams();
        
        this.planService.getByService(this.id_service,httpParams).subscribe(plans =>{
          this.plans = plans['result'];
          this.plan = this.plans.find(s => s.id == this.id_plan);
          this.planS.setValue(this.plan.id);
        });
      });
    });
  }


  create() {

    let obj = <any> BaseService.builderObject(this.createOptionsForm.value)
    obj.plan = {id:this.planS.value};
    this.optionService.create(obj).subscribe(obj =>  {
        this.notificationService.sucessInsert(obj.name);
        this.location.back();
    }, err => this.notificationService.error(err));

}

//Metodo que permite que solo se introduzcan valores numericos
onlyNumberKey(event){
return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
}

}
