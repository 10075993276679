import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class JobService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/job';

  httpOptions : any;

  constructor(
    private http: HttpClient
  ) {
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }

  update(id: any, status: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + id, {status: status} , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  edit(job:any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + job['id'] + '/edit', job , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

}
