
	<h3 class="title">Editar demonio</h3>

	  <form *ngIf="updateJobForm" [formGroup]="updateJobForm" (ngSubmit)="update()">
		    <fieldset class="fieldset">
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" > 
            </div>  

			      <div class="field-row">
				
                 <div class="field-row-item">
                    <label class="label" for="full_name">Nombre</label>
                    <input formControlName="full_name" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
                    <validator [control]="updateJobForm.controls['full_name']"></validator>
                  </div>

                  <div class="field-row-item">
                    <label class="label" for="description">Descripción</label>
                    <input formControlName="description" type="text" autocomplete="off" placeholder="Ingrese Descripción" >
                    <validator [control]="updateJobForm.controls['description']"></validator>
                  </div>

            </div>	
            
            <div class="field-row">

              <div class="field-row-item">
                <label class="label" for="status" >Estatus</label>
                <mat-select [formControl]="status_job" disableRipple>
                  <mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
                </mat-select>
                <validator [control]="status_job"></validator>
              </div>

              <div class="field-row-item">
                  <label class="label" for="millisSeparation">Milisegundos</label>
                  <input formControlName="millisSeparation" type="text" autocomplete="off" (keypress)="onlyNumberKey($event)" placeholder="Ingrese Milisegundos" >
                  <validator [control]="updateJobForm.controls['millisSeparation']"></validator>
              </div>

		    	  </div>

		    </fieldset>

      <div class="options">
        <button class="btn-text" type="submit" [disabled]="!updateJobForm.valid">
          <span>Guardar</span>
        </button>
      </div>
	  </form>
