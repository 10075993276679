
	<h3 class="title">Archivos de clientes</h3>

      <mat-accordion>
        <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
          <mat-expansion-panel-header>
            <div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
              <div class="field-row">
              <!--
                <div class="field-row-item" *ngIf="tableService.filter.id">
                  <span class="label" i>id</span>
                  <span>{{tableService.filter.id}}</span>
                </div>
                -->

                <div class="field-row-item" *ngIf="tableService.filter.id_companys">
                  <span class="label">Compañia</span>
                  <span>{{ tableService.filter.id_companys}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_sucursals">
                  <span class="label">sucursal</span>
                  <span>{{tableService.filter.id_sucursals}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_services">
                  <span class="label">Servicio</span>
                  <span>{{tableService.filter.id_services}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_plans">
                  <span class="label">Plan</span>
                  <span>{{tableService.filter.id_plans}}</span>
                </div>
                
                <div class="field-row-item" *ngIf="tableService.filter.since_dates">
                  <span class="label">Desde</span>
                  <span>{{tableService.filter.since_dates}}</span>
                </div>
                
                <div class="field-row-item" *ngIf="tableService.filter.until_dates">
                  <span class="label">Hasta</span>
                  <span>{{tableService.filter.until_dates}}</span>
                </div>

              </div>
            </div>
            <button class="btn-icon"  title="Buscar" type="button">
              <i class="material-icons">search</i>
            </button>
          </mat-expansion-panel-header>

          <form>
            <fieldset class="fieldset">
              <!--
              <div class="field-row">
                <div class="field-row-item">
                  <label class="label" for="id">id</label>
                  <input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
                </div>
              </div>
            -->

              <div class="field-row">
                <div class="field-row-item">
                  <label class="label" for="company" >Compañia</label>
                  <mat-select placeholder="None" [(value)]="filter.id_companys"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
                    <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
                  </mat-select>
                </div>

                <div class="field-row-item" *ngIf="hasSucursals">
                  <label class="label" for="company" >Sucursal</label>
                  <mat-select placeholder="None" [(value)]="filter.id_sucursals" (selectionChange)="onSucursalChange($event.value)" disableRipple>
      
                    <mat-option *ngFor="let opt of sucursales" [value]="opt.id">{{opt.name_partner}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="field-row">

                  <div class="field-row-item">
                    <label class="label" for="company" >Servicio</label>
                    <mat-select placeholder="None" [(value)]="filter.id_services"  (selectionChange)="onServiceChange($event.value)" disableRipple>
          
                      <mat-option *ngFor="let opt of services" [value]="opt.id">{{opt.name_service}}</mat-option>
                    </mat-select>
                  </div>

                  <div class="field-row-item">
                    <label class="label" for="service" >Plan</label>
                    <mat-select placeholder="None" [(value)]="filter.id_plans"  disableRipple>
        
                      <mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
                    </mat-select>
                  </div>

              </div>
              
              <div class="field-row">

                <div class="field-row-item">
                  <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_dates" [ngModelOptions]="{standalone: true}" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </div>

                <div class="field-row-item">
                  <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_dates" [ngModelOptions]="{standalone: true}" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker2></mat-datepicker>
                </div>
                
              </div>
          
            </fieldset>
            <div class="options">
              <button class="btn-text gray" type="button" (click)="reset()">
                <span >Limpiar</span>
              </button>
              <button class="btn-text blue" type="button" (click)="search()">
                <span>Buscar</span>
              </button>
            </div>
          </form>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="tool-bar-wrap both-side">
      <div class="right row">
        <button *hasPermission="['processfile.create']" class="btn-icon" type="button" (click)="create()">
          <i class="material-icons">add</i>
        </button>
      </div>
    </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

			<ng-container matColumnDef="plan.service.partner.name_partner">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Sucursal</th>
				<td mat-cell *matCellDef="let element">{{element?.plan?.service?.partner?.name_partner || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="plan.service.name_service">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Servicio</th>
				<td mat-cell *matCellDef="let element">{{element?.plan?.service?.name_service || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="plan.name_plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
        <td mat-cell *matCellDef="let element">{{ element?.plan?.name_plan || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="name_source">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Archivo</th>
				<td mat-cell *matCellDef="let element">{{ element?.name_source || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
				<td mat-cell *matCellDef="let element">{{ element?.createdAt || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{ element?.status || '-'}}</td>
      </ng-container>

     

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
      (click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
  <context-menu #basicMenu>
<!--
			<ng-template *hasPermission="['processfile.process']" contextMenuItem (execute)="payments($event.item)">
				<div class="item">
					<i class="material-icons">attach_money</i>
					<span>Generar Cobros</span>
				</div>
      </ng-template>
      -->
<!--
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template contextMenuItem (execute)="answers($event.item)">
				<div class="item">
					<i class="material-icons">swap_vert</i>
					<span>Respuestas</span>
				</div>
			</ng-template>
-->
	</context-menu>
