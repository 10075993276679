
	<h3 class="title">Actualizar</h3>

	<form *ngIf="updateIntegrationForm" [formGroup]="updateIntegrationForm" (ngSubmit)="update()">
    <fieldset class="fieldset">
    
    <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

      <div class="field-row">

        <div class="field-row-item">
          <label class="label" for="country" >Integracion de compañía</label> 
          <mat-select [formControl]="partner"  disableRipple>
              <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
          </mat-select>
          <validator [control]="partner"></validator> 
        </div>
      
				<div class="field-row-item">
					<label class="label" for="user">Usuario</label>
					<input formControlName="user" type="text" autocomplete="off" placeholder="Ingrese Usuario" >
					<validator [control]="updateIntegrationForm.controls['user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="password">Password</label>
          <input formControlName="password" type="text" autocomplete="off" placeholder="Ingrese Password" >
          <validator [control]="updateIntegrationForm.controls['password']"></validator>
        </div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="url">Url del cliente</label>
					<input formControlName="url" type="text" autocomplete="off" placeholder="Ingrese url" >
					<validator [control]="updateIntegrationForm.controls['url']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="token">Token de seguridad</label>
          <input formControlName="token" type="text" autocomplete="off" placeholder="Ingrese token" >
          <validator [control]="updateIntegrationForm.controls['token']"></validator>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateIntegrationForm.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
