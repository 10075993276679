import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {ReportService} from '../../report/report.service';
import {PartnerService} from '../../partner/partner.service';
import {FileService} from '../file.service';
import { HttpParams } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { FileFilter } from '../file.filter';

@Component({
  template: `
	<h3 class="title">Listado de Archivos de cobro</h3>

      <mat-accordion>
        <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
          <mat-expansion-panel-header>
            <div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
              <div class="field-row">
              <!--
                <div class="field-row-item" *ngIf="tableService.filter.id">
                  <span class="label" i>id</span>
                  <span>{{tableService.filter.id}}</span>
                </div>
                -->

                <div class="field-row-item" *ngIf="tableService.filter.id_companyf">
                  <span class="label">Compañia</span>
                  <span>{{ tableService.filter.id_companyf}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_sucursalf">
                  <span class="label">sucursal</span>
                  <span>{{tableService.filter.id_sucursalf}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_servicef">
                  <span class="label">Servicio</span>
                  <span>{{tableService.filter.id_servicef}}</span>
                </div>

                <div class="field-row-item" *ngIf="tableService.filter.id_planf">
                  <span class="label">Plan</span>
                  <span>{{tableService.filter.id_planf}}</span>
                </div>
                
                <div class="field-row-item" *ngIf="tableService.filter.since_datef">
                  <span class="label">Desde</span>
                  <span>{{tableService.filter.since_datef}}</span>
                </div>
                
                <div class="field-row-item" *ngIf="tableService.filter.until_datef">
                  <span class="label">Hasta</span>
                  <span>{{tableService.filter.until_datef}}</span>
                </div>

              </div>
            </div>
            <button class="btn-icon"  title="Buscar" type="button">
              <i class="material-icons">search</i>
            </button>
          </mat-expansion-panel-header>

          <form>
            <fieldset class="fieldset">
              <!--
              <div class="field-row">
                <div class="field-row-item">
                  <label class="label" for="id">id</label>
                  <input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
                </div>
              </div>
            -->

              <div class="field-row">
                <div class="field-row-item">
                  <label class="label" for="company" >Compañia</label>
                  <mat-select placeholder="None" [(value)]="filter.id_companyf"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
          
                    <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
                  </mat-select>
                </div>

                <div class="field-row-item" *ngIf="hasSucursals">
                  <label class="label" for="company" >Sucursal</label>
                  <mat-select placeholder="None" [(value)]="filter.id_sucursalf"  (selectionChange)="onSucursalChange($event.value)" disableRipple>
                
                    <mat-option *ngFor="let opt of sucursales" [value]="opt.id">{{opt.name_partner}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="field-row">

                  <div class="field-row-item">
                    <label class="label" for="company" >Servicio</label>
                    <mat-select placeholder="None" [(value)]="filter.id_servicef"  (selectionChange)="onServiceChange($event.value)" disableRipple>
                 
                      <mat-option *ngFor="let opt of services" [value]="opt.id">{{opt.name_service}}</mat-option>
                    </mat-select>
                  </div>

                  <div class="field-row-item">
                    <label class="label" for="service" >Plan</label>
                    <mat-select placeholder="None" [(value)]="filter.id_planf"  disableRipple>
                
                      <mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
                    </mat-select>
                  </div>

              </div>
              
              <div class="field-row">

                <div class="field-row-item">
                  <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_datef" [ngModelOptions]="{standalone: true}" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
            		</div>

                <div class="field-row-item">
                  <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_datef" [ngModelOptions]="{standalone: true}" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker2></mat-datepicker>
            		</div>
                
              </div>
          
            </fieldset>
            <div class="options">
              <button class="btn-text gray" type="button" (click)="reset()">
                <span >Limpiar</span>
              </button>
              <button class="btn-text blue" type="button" (click)="search()">
                <span>Buscar</span>
              </button>
            </div>
          </form>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="tool-bar-wrap both-side">
      <div class="right row">
        <button *hasPermission="['processfile.create']" class="btn-icon" type="button" (click)="create()">
          <i class="material-icons">add</i>
        </button>
      </div>
    </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Id</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

			<ng-container matColumnDef="plan.service.partner.name_partner">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Sucursal</th>
				<td mat-cell *matCellDef="let element">{{element?.plan?.service?.partner?.name_partner || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="plan.service.name_service">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Servicio</th>
				<td mat-cell *matCellDef="let element">{{element?.plan?.service?.name_service || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="plan.name_plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
        <td mat-cell *matCellDef="let element">{{ element?.plan?.name_plan || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="name_processFile">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Archivo</th>
				<td mat-cell *matCellDef="let element">{{ element?.name_processFile || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
				<td mat-cell *matCellDef="let element">{{ element?.createdAt || '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="status_processFile">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
				<td mat-cell *matCellDef="let element">{{ element?.status_processFile || '-'}}</td>
      </ng-container>

     

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
      (click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
  </div>
  
  <context-menu #basicMenu>

			<ng-template *hasPermission="['processfile.process']" contextMenuItem (execute)="payments($event.item)">
				<div class="item">
					<i class="material-icons">attach_money</i>
					<span>Generar Cobros</span>
				</div>
			</ng-template>
<!--
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template contextMenuItem (execute)="answers($event.item)">
				<div class="item">
					<i class="material-icons">swap_vert</i>
					<span>Respuestas</span>
				</div>
			</ng-template>
-->
	</context-menu>
`
})
export class FileListComponent implements OnInit {


  dataSource: MatTableDataSource<any>;
  columns = ['id', 'plan.service.partner.name_partner', 'plan.service.name_service', 'plan.name_plan', 'name_processFile','createdAt', 'status_processFile'];
	displayedColumns: string[] = [];
	confirmDelete = true;
	isOpenSearchPanel = false;
	filter = new FileFilter(this.tableService.filter);

  partners: any[]= [];
  services: any[]= [];
  plans: any[]= [];
  sucursales: any[]= [];
  hasSucursals = false;
 
  items: any[] = [];
  cobros: any[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private servicesService: ServicioService,
    private fileService: FileService,
    private planServices: PlanService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {
    this.tableService = new TableService;
  }

  ngOnInit() {
    this.displayedColumns = this.columns;
    let httpParams = this.partnerService.buildRequestParams();

    httpParams = httpParams.set('parents',false.toString());
    httpParams = httpParams.set('parent',(-1).toString());

    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
        this.partners = params['result']; // items que mostrara la tabla
        if(this.filter.getHttpParams()['updates'] != null ){
          this.list();
        }
        // tomamos la variable que tengamos en memoria si es que venimos de la creacion de un archivo
        if(sessionStorage.getItem('searchFilesPlan')){
          this.filter.id_planf = +sessionStorage.getItem('searchFilesPlan');
         // removemos la variable para que ya no se realice esta busqueda
          sessionStorage.removeItem('searchFilesPlan');
          this.list();
        }
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });
  }

  list(event?: PageEvent) {

    let httpParams = new HttpParams();

    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
    }
    
    httpParams = this.filter.getHttpParams(httpParams); 
    this.items = [];

    if(httpParams['updates'] != null){
  
      if(this.filter.since_datef != null && this.filter.until_datef == null){
        this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
        return;
      }
      if(this.filter.since_datef == null && this.filter.until_datef != null){
        this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
        return;
      }

      this.filter.since_datef = this.datePipe.transform(this.filter.since_datef, 'yyyy-MM-dd');
      this.filter.until_datef = this.datePipe.transform(this.filter.until_datef, 'yyyy-MM-dd');
      
      var since = new  Date (this.filter.since_datef);
      var until = new  Date (this.filter.until_datef);
      if(until.getTime() < since.getTime()){
        this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
        return;
      }

      this.tableService.filter = new FileFilter(this.filter);
      

      httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		
			httpParams = this.filter.getHttpParams(httpParams);
    
      this.fileService.getAll(httpParams).subscribe(params => {
          this.items = params['result']; // items que mostrara la tabla
          this.estatus_change();
          this.dataSource = new MatTableDataSource<any>(this.items);
          this.tableService.pager = params['pager'];
          this.tableService.selection.clear();
          if (this.items.length === 0) {
            this.notificationService.alert('No se encontraron resultados para la busqueda');
          }
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      }); 
    }
  }

  estatus_change() {
    for (const file of this.items) {
      if (file.status_processFile === 0) {
        file.status_processFile = 'Procesando';
      }
      if (file.status_processFile === 1) {
        file.status_processFile = 'Por procesar';
      }
      if (file.status_processFile === 2) {
        file.status_processFile = 'Procesado Exitosamente';
      }
      if (file.status_processFile === 3) {
        file.status_processFile = 'Erroneo, no cargado el archivo';
      }
      if (file.status_processFile === 4) {
        file.status_processFile = 'Erroneo, cobro no procesado';
      }
    }
  }

  onPartnerChange(partner: any) {
    if(partner != undefined){
      // cargamos las ciudades del pais que seleccione el usuario
      this.partnerService.getPartnersByParent(partner).subscribe(sucursales => {
        this.sucursales = sucursales['result']; // tomamos las sucursales
        if (this.sucursales.length === 0) {
          this.notificationService.info('Info', 'Esta Compañia no posee sucursales');
          this.hasSucursals = false;
        }else{
          this.hasSucursals = true;
        }
        // buscamos los servicios del partner
        this.servicesService.getByPartner(partner).subscribe( services => {
            this.services = services['result'];
            if (this.services.length === 0) {
              this.notificationService.info('Info', 'Esta Compañia no posee servicios');
            }
          }, err => {
            this.notificationService.error('Error al cargar los servicios de la sucursal');
            console.log(err);
          });

      }, err => {
        this.notificationService.error('Error consultando las sucursales del partner seleccionado');
        console.log(err);
      });
    }else{
      this.services = [];
      delete this.filter.id_servicef;
    }
  }

  onSucursalChange(sucursal: any) {
    if(sucursal != undefined){
      // buscamos los servicios de la sucursal
      this.servicesService.getByPartner(sucursal).subscribe( services => {
        this.services = services['result'];
      }, err => {
        this.notificationService.error('Error al cargar los servicios de la sucursal');
        console.log(err);
      });
    }else{
      this.services = [];
      delete this.filter.id_servicef;
    }
  }

  onServiceChange(value : any) {
    if(value != undefined){
      this.planServices.getByService(value).subscribe( plans => {
        this.plans = plans['result'];
        if (this.plans.length === 0) {
          this.notificationService.info('Info', 'Este servicio no posee planes registrados');
        }
      }, error1 => {
        this.notificationService.error('Error cargando los planes del servicio');
        console.log(error1);
      });
    }else{
      this.plans = [];
      delete this.filter.id_planf;
    }
  }

  read(item: any) {
    // hacemos esto para no tener que consultar de nuevo la informacion del invoice en la siguiente pantalla
    localStorage.setItem('invoice', JSON.stringify(item));
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  show(item){
    console.log(item);
  }

  create() { /// crear un partner
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }


  payments(item) {
    const index = this.items.findIndex( cobro => cobro.id === item.id);
    if (this.items[index].status_processFile === 'Procesado Exitosamente') {
      this.notificationService.error('Este archivo ya ha sido procesado');
      return;
    }
    if (this.items[index].status_processFile === 'Erroneo, no cargado el archivo') {
      this.notificationService.error('No ha sido cargado el archivo de correctamente');
      return;
    }
    this.notificationService.sucessInsert('Se inicia proceso de cobro, recibira un correo con el resultado');
    this.list();
    this.fileService.processPayments(item.id).subscribe(r => {
    });
  }

  reset() {
    this.filter = new FileFilter();
    this.displayedColumns = this.columns;
    this.dataSource = new MatTableDataSource<any>([]);
    this.list();
  }

  cleanFilters(){
    delete this.filter.id_companyf;
  }

  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new FileFilter(this.filter);
    this.list();
  }

  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new FileFilter(this.tableService.filter);
  }

  answers(item) {
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
