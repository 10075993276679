import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {BaseService} from '../../shared/services/base.service';
import { FormControl } from '@angular/forms';
import { IntegrationService } from '../integration.service';
import { PartnerService } from '../../partner/partner.service';
import { CustomValidators } from '../../shared/form/custom.validators';

@Component({
  template: `
	<h3 class="title">Crear Integracion</h3>

	<form *ngIf="createIntegrationForm" [formGroup]="createIntegrationForm" (ngSubmit)="create()">
		<fieldset class="fieldset">

      <div class="field-row">
      
        <div class="field-row-item">
          <label class="label" for="country" >Integracion para compañía</label> 
          <mat-select [formControl]="partner"  disableRipple>
              <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
          </mat-select>
          <validator [control]="partner"></validator> 
        </div>

				<div class="field-row-item">
					<label class="label" for="user">Usuario</label>
					<input formControlName="user" type="text" autocomplete="off" placeholder="Ingrese Usuario" >
					<validator [control]="createIntegrationForm.controls['user']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="password">Password</label>
          <input formControlName="password" type="text" autocomplete="off" placeholder="Ingrese Password" >
          <validator [control]="createIntegrationForm.controls['password']"></validator>
        </div>
      </div>

      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="url">Url del cliente</label>
					<input formControlName="url" type="text" autocomplete="off" placeholder="Ingrese url" >
					<validator [control]="createIntegrationForm.controls['url']"></validator>
        </div>
        <div class="field-row-item">
          <label class="label" for="token">Token de seguridad</label>
          <input formControlName="token" type="text" autocomplete="off" placeholder="Ingrese token" >
          <validator [control]="createIntegrationForm.controls['token']"></validator>
        </div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createIntegrationForm.valid || !partner.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CreateIntegrationComponent implements OnInit {

  private partners : any[];
  public partner: FormControl = new FormControl('', Validators.required);
  integration: any;

  createIntegrationForm = this.fb.group({
    user: ['', [Validators.required]],
    password: ['',[Validators.required]],
    url: ['', [Validators.required]],
    token: ['',[Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private integrationService: IntegrationService,
    private partnerService : PartnerService,
    private location: Location,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.partnerService.getAll2().subscribe(response =>{
      this.partners = response.result;
    });
  }

  create() {
    this.integration = <any> BaseService.builderObject(this.createIntegrationForm.value)
    this.integration.partner = {id: this.partner.value};
    this.integrationService.create(this.integration).subscribe(response => {
      this.notificationService.sucessInsert(response.url);
			this.location.back();
    }, err => this.notificationService.error(err));  
  }

}
