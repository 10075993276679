
	<h3 class="title">Editar Plan</h3>

	<form *ngIf="updatePlanForm" [formGroup]="updatePlanForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
            <legend class="legend" >Datos del plan</legend>
            
            <div class="form-group">
                <input type="text" formControlName="id" class="form-control" hidden="true" >
            </div>

			<div class="field-row">
                
                <div class="field-row-item">
						<label class="label" for="partner" >{{label}}</label>
						<mat-select [formControl]="partnerS"  (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
						</mat-select>
						<validator [control]="partnerS"></validator>
				</div>

				<div class="field-row-item">
						<label class="label" for="service" >Servicio</label>
						<mat-select [formControl]="service"  disableRipple>
								<mat-option *ngFor="let servicio of servicios" [value]="servicio.id">{{servicio.name_service}}</mat-option>
						</mat-select>
						<validator [control]="service"></validator>
                </div>
                
                <div class="field-row-item">
					<label class="label" for="name_plan">Nombre</label>
					<input formControlName="name_plan" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="updatePlanForm.controls['name_plan']"></validator>
				</div>
			</div>
			
			

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="enrollmentCost_plan">Costo de inscripción</label>
					<input formControlName="enrollmentCost_plan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese Costo" >
					<validator [control]="updatePlanForm.controls['enrollmentCost_plan']"></validator>
				</div>

				<div *ngIf="show" class="field-row-item">
						<label class="label" for="charge" >Cobrar inscripción</label>
						<mat-select [formControl]="charge"  disableRipple>
							<mat-option *ngFor="let option of options" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="charge"></validator>
				</div>

				<div class="field-row-item">
					<label class="label" for="feeCost_plan">Costo </label>
					<input formControlName="feeCost_plan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese costo" >
					<validator [control]="updatePlanForm.controls['feeCost_plan']"></validator>
				</div>
				
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="freezeCostPlan">Costo por congelamiento</label>
					<input formControlName="freezeCostPlan" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese costo" >
					<validator [control]="updatePlanForm.controls['freezeCostPlan']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="taxRate_plan">Impuesto(%)</label>
					<input formControlName="taxRate_plan" maxlength="3" type="text" (keypress)="onlyNumberKey($event)"  autocomplete="off" placeholder="Ingrese impuesto" >
					<validator [control]="updatePlanForm.controls['taxRate_plan']"></validator>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="idPlan_partner">ID plan partner</label>
					<input formControlName="idPlan_partner" type="text" autocomplete="off" placeholder="Ingrese ID" >
					<validator [control]="updatePlanForm.controls['idPlan_partner']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="frecuency" >Frecuencia</label>
                    <mat-select [formControl]="frecuencyS" disableRipple>
							<mat-option *ngFor="let frecuency of frecuencies" [value]="frecuency.id">{{frecuency.name_frecuency}}</mat-option>
					</mat-select>
					<validator [control]="frecuencyS"></validator>
                </div>
                <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_plan" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="status_plan"></validator>
				</div>
            </div>
            



			<div *ngIf="show">
			

				<div class="field-row">

					<div class="field-row-item">
						<label class="label" for="title">Título</label>
						<input formControlName="title" type="text" autocomplete="off" placeholder="Ingrese Título" >
						<validator [control]="updatePlanForm.controls['title']"></validator>
					</div>

					
					<div class="field-row-item">
						<label class="label" for="position">Posición</label>
						<input formControlName="position" type="text" maxlength="2" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Posición" >
						<validator [control]="updatePlanForm.controls['position']"></validator>
					</div>
				</div>

				<div class="field-row">

					

					<div class="field-row-item">
						<label class="label" for="url_image">Imagen del plan</label>
						<input id="file-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChange($event)" >
					</div>

					<!-- <div class="field-row-item">
						<label class="label" for="url_image_detail">Imagen para detalle de plan</label>
						<input id="file-detail-upload" type="file" style="display:block; "accept="image/png, image/jpeg" (change)="onFileChangeDetail($event)" required>
					</div> -->
					
				</div>


				<!-- <div class="field-row">

					<div class="field-row-item">
						<label class="label" for="label_frecuency">Etiqueta de frecuencia</label>
						<input formControlName="label_frecuency" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_frecuency']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_inscription">Etiqueta de inscripción</label>
						<input formControlName="label_inscription" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_inscription']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="label_payment">Etiqueta de pago</label>
						<input formControlName="label_payment" type="text" autocomplete="off" placeholder="Ingrese etiqueta" >
						<validator [control]="updatePlanForm.controls['label_payment']"></validator>
					</div>

					
					
				</div> -->

				<div class="field-row">

						<div class="field-row-item">
							<label class="label" for="title">Titulo del email</label>
							<textarea formControlName="title_email"></textarea>
							<validator [control]="updatePlanForm.controls['title_email']"></validator>
						</div>

						<div class="field-row-item">
							<label class="label" for="url_ecommerce">Mensaje del email</label>
							<textarea formControlName="message_email"></textarea>
							<validator [control]="updatePlanForm.controls['message_email']"></validator>
						</div>

				</div>	



				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="validity_period" >Periodo</label>
						<mat-select [formControl]="period"  disableRipple>
							<mat-option *ngFor="let period of periods" [value]="period.value">{{period.description}}</mat-option>
						</mat-select>
						<validator [control]="period"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="validity_value">Valor</label>
						<input formControlName="validity_value" type="text" maxlength="5" (keypress)="onlyNumberKey($event)" autocomplete="off"  placeholder="Ingrese Valor" >
						<validator [control]="updatePlanForm.controls['validity_value']"></validator>
					</div>
					
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" >Descripción</label>
						<textarea formControlName="description"></textarea>
						<!-- <editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="description" >
						  </editor> -->
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="detail_plan" >Descripción</label>
						<textarea formControlName="detail_plan"></textarea>
						
					</div>
				</div>

				<!-- <div class="field-row">
					<div class="field-row-item">
						<label class="label" for="extended_description" >Descripción extendida</label>
						<editor [init]="{
							height: 600
						  }"  apiKey="rv7qtvpvfahwshq6y14lp4gflvg20fwiiov7gjchqtjjjmhh" plugins="table,autolink,autoresize,code,media,fullpage,fullscreen,image,image imagetools,importcss,lists,media,noneditable,preview,print" formControlName="extended_description" ></editor>
					</div>
				</div> -->

			</div>

			

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updatePlanForm.valid || !status_plan.valid || !partnerS.valid || !service.valid || !frecuencyS.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
