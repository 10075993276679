import { Routes } from '@angular/router';
import {CreditcardComponent} from "./creditcard.component";
import {CreditcardListComponent} from "./creditcard-list.component";

export const creditcardRoutes: Routes = [
	{
				path: ':IDcustomer/creditcards',
				component: CreditcardComponent,
				data: {
					breadcrumb: "Creditcards"
				},
				children:[
					{
						path: '',
						pathMatch: 'full',
						component: CreditcardListComponent,
						data: {
						   	breadcrumb: undefined
						    }
					}
				]
			}
];

export const routingCreditComponents = [CreditcardComponent, CreditcardListComponent];
