import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {PartnerService} from '../../partner/partner.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {ReportService} from '../report.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';
import { FormControl } from '@angular/forms';

@Component({
  template: `
	<h3 class="title">Reporte de facturas por fecha de creación</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					<!--
						<div class="field-row-item" *ngIf="tableService.filter.id">
							<span class="label" i>id</span>
							<span>{{tableService.filter.id}}</span>
						</div>
						-->

						<div class="field-row-item" *ngIf="tableService.filter.id_partner">
							<span class="label">Compañia</span>
							<span>{{tableService.filter.id_partner}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_service">
							<span class="label">Servicio</span>
							<span>{{tableService.filter.id_service}}</span>
						</div>

						<div class="field-row-item" *ngIf="tableService.filter.id_plan">
							<span class="label">Plan</span>
							<span>{{tableService.filter.id_plan}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
						</div>

						<!--
						<div class="field-row-item" *ngIf="tableService.filter.status_partner">
							<span class="label">Estatus</span>
							<span>{{tableService.filter.status_partner}}</span>
						</div>
						-->
						
					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
				<fieldset class="fieldset">
					<!--
					<div class="field-row">
						<div class="field-row-item">
							<label class="label" for="id">id</label>
							<input type="number" name="id" placeholder="None"  [(ngModel)]="filter.id">
						</div>
					</div>
				-->

					<div class="field-row">
          <div class="field-row-item">
            <label class="label" for="company" >Compañia</label>
            <mat-select placeholder="None" [formControl]="partnerS" [(value)]="filter.id_partner"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>
          </div>
					</div>

					<div class="field-row">

							<div class="field-row-item">
								<label class="label" for="service" >Servicio</label>
								<mat-select placeholder="None" [formControl]="serviceS" [(value)]="filter.id_service"  (selectionChange)="onServiceChange($event.value)" disableRipple>
									<mat-option *ngFor="let opt of services" [value]="opt.id">{{opt.name_service}}</mat-option>
								</mat-select>
							</div>

							<div class="field-row-item">
								<label class="label" for="plan" >Plan</label>
								<mat-select placeholder="None" [formControl]="planS" [(value)]="filter.id_plan"  disableRipple>
									<mat-option *ngFor="let opt2 of plans" [value]="opt2.id">{{opt2.name_plan}}</mat-option>
								</mat-select>
							</div>

          			</div>
          
          			<div class="field-row">

						<div class="field-row-item">

							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker></mat-datepicker>

            			</div>
            
            			<div class="field-row-item">
							<input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker touchUi #picker2></mat-datepicker>
            			</div>
            
					</div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()" >
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
		<button class="btn-icon" type="button" (click)="export()">
			<i class="material-icons">cloud_download</i>
		</button>
		</div>
	</div>



	<div class="mat-elevation-z8e" *ngIf="dataSource">
		<mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
		
			<ng-container matColumnDef="name_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.name_customer + ' ' + element.last_name }}</td>
			</ng-container>

			

			<ng-container matColumnDef="identifier_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >DNI</th>
				<td mat-cell *matCellDef="let element">{{ element.identifier_customer }}</td>
			</ng-container>

			<ng-container matColumnDef="code_customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Código</th>
				<td mat-cell *matCellDef="let element">{{ element.code_customer }}</td>
			</ng-container>
			
			<ng-container matColumnDef="name_plan">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Plan</th>
				<td mat-cell *matCellDef="let element">{{element.name_plan}}</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="centro">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Centro</th>
				<td mat-cell *matCellDef="let element">{{element.sucursal}}</td>
      		</ng-container>
      
      <ng-container matColumnDef="desc_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Estatus</th>
        <td mat-cell *matCellDef="let element">{{element.desc_status}}</td>
	  </ng-container>
	  
	  <ng-container matColumnDef="mask">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >TDC</th>
        <td mat-cell *matCellDef="let element">{{element.mask}}</td>
      </ng-container>

      <ng-container matColumnDef="total_invoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Monto</th>
        <td mat-cell *matCellDef="let element">{{element.total_invoice}}</td>
	  </ng-container>
	  
	  

	  <ng-container matColumnDef="autoritation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Autorización</th>
        <td mat-cell *matCellDef="let element">{{element.autoritation}}</td>
	  </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.created_at}}</td>
	  </ng-container>
	  
	  <ng-container matColumnDef="next_bill">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Próximo cobro</th>
        <td mat-cell *matCellDef="let element">{{element.next_bill}}</td>
      </ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				(click)="read(row)">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class ReportInvoicesComponent implements OnInit {

  	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['name_customer','identifier_customer', 'code_customer' , 'name_plan','centro', 'desc_status', 'mask' ,'total_invoice','autoritation','created_at', 'next_bill'];
	confirmDelete = true;
	/*
	statusServiceOrders = [
		{id: 'so_draft', name: 'so_draft'}, {id: 'so_requested', name: 'so_requested'}, {id: 'so_rejected', name: 'so_rejected'}
	]; */
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

	partners: any[]= [];
	selectedPartner: any;
	partner: any;

	services: any[]= [];
	selectedService: any;
	service: any;

	plans: any[]= [];
	selectedPlan: any;
	plan: any;

	items: any[] = [];

	toExport : any[] = [];

	filterDateDesde = null;
	filterDateHasta = null;

	static_data: any[] = [];


	partnerS: FormControl = new FormControl('');
  	serviceS: FormControl = new FormControl('');
	planS: FormControl = new FormControl('');
	  
	staticLine_data: any[] = [];


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public 	tableService: TableService<any>,
    private partnerService: PartnerService,
    private reportService: ReportService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {this.tableService = new TableService;}

  ngOnInit() {
	let httpParams = this.partnerService.buildRequestParams();

	httpParams = httpParams.set('parents',false.toString());
	httpParams = httpParams.set('parent',(-1).toString());
	  // cargamos companias
    this.partnerService.getPartnersByUserId(httpParams).subscribe(params => {
	  this.partners = params['result'];
	  // cargamos sucursales
	  let httpParams2 = this.partnerService.buildRequestParams();

      httpParams2 = httpParams2.set('parents',true.toString());
      httpParams2 = httpParams2.set('parent',(-1).toString());
	  this.partnerService.getPartnersByUserId(httpParams2).subscribe(params =>{
		for( var p of params['result']){
			this.partners.push(p)
		}
		
		if(localStorage.getItem('reportInvoice')){
			this.partnerS.setValue(this.partners[this.partners.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).company == p.id)].id)
			this.filter.id_partner = this.partnerS.value;
			this.filter.since_date = JSON.parse(localStorage.getItem('reportInvoice')).since;
			this.filter.until_date = JSON.parse(localStorage.getItem('reportInvoice')).until;
			this.onPartnerChange(this.partnerS.value);
		  }


	  });
    }, err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  list(event?: PageEvent) {
	 
			if(this.filter.id_service == undefined){
				this.notificationService.error("Debe seleccionar un servicio");
				return;
			}

			if(this.tableService.pager.pageSize != 20){
				this.tableService.pager.pageSize = 20;
			}
			
			this.items = [];
		
			if(this.filter.id_service == null){
				this.notificationService.error("Debe seleccionar al menos un servicio");
				return;
			}
			if(this.filter.since_date != null && this.filter.until_date == null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
				return;
			}
			if(this.filter.since_date == null && this.filter.until_date != null){
				this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
				return;
			}
			this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
			this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
			var since = new  Date (this.filter.since_date);
			var until = new  Date (this.filter.until_date);
			if(until.getTime() < since.getTime()){
				this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
				return;
			}
			this.tableService.filter = new ReportFilter(this.filter);
			
			let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
			httpParams = this.filter.getHttpParams(httpParams);
			
			this.reportService.getReportInvoices(httpParams).subscribe(params => {
				this.items = params['result']; // items que mostrara la tabla
				// cortamos la TDC
				for( var invoice of this.items){
					var mask = invoice.mask;
					if(mask != null){
						invoice.mask = mask.substring(mask.length - 4, mask.length)
					}
					if(invoice.autoritation == ""){
						invoice.autoritation = "N/A"
					}
				}

				this.dataSource = new MatTableDataSource<any>(this.items);
				this.tableService.pager = params['pager'];
				this.tableService.selection.clear();
				if (this.items.length === 0) {
					this.notificationService.alert('No se encontraron resultados para la busqueda');
				}
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 	
  }

  onPartnerChange(partner: any) {
		this.services = [];
		this.plans = [];
		delete this.filter.id_service;
		delete this.filter.id_plan;
		if(partner != undefined){
			this.servicesService.getByPartner(partner).subscribe( services => {
				this.services = services['result'];
				if(localStorage.getItem('reportInvoice')){
					this.serviceS.setValue(this.services[this.services.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).service == p.id)].id)
					this.filter.id_service = this.serviceS.value;
					this.onServiceChange(this.serviceS.value);
				  }
			}, err => {
				this.notificationService.error('Error al cargar los servicios de la sucursal');
				console.log(err);
			});
		}
  }

  onServiceChange(value:any) {
		this.plans = [];
		delete this.filter.id_plan;
		if( value != undefined ){
			this.planServices.getByService(value).subscribe( plans => {
				this.plans = plans['result'];
				// ESTO PARA NO HACERLO LA 1ERA VEZ QUE SE BUSCA
				if(localStorage.getItem('reportInvoice')){
					if(JSON.parse(localStorage.getItem('reportInvoice')).plan){
						this.planS.setValue(this.plans[this.plans.findIndex(p => JSON.parse(localStorage.getItem('reportInvoice')).plan == p.id)].id);
						this.filter.id_plan = this.planS.value;
						localStorage.removeItem("reportInvoice");
						this.search();
					}else{
						localStorage.removeItem("reportInvoice");
						this.search();
					}
				}
				
			}, error1 => {
				this.notificationService.error('Error cargando los planes del servicio');
				console.log(error1);
			});
		}
  }

  read(item: any) {
    // hacemos esto para no tener que consultar de nuevo la informacion del invoice en la siguiente pantalla
	localStorage.setItem('invoice', JSON.stringify(item));
	localStorage.setItem("reportInvoice", JSON.stringify({company: this.filter.id_partner, service: this.filter.id_service, plan : this.filter.id_plan, since: this.filter.since_date, until: this.filter.until_date}));
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  export() {
	if(this.items.length > 0){
			
			this.tableService.filter = new ReportFilter(this.filter);
			let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			{pageIndex: -1 , pageSize: -1})
  
			httpParams = this.filter.getHttpParams(httpParams);
			this.reportService.getReportInvoices(httpParams).subscribe(params => {
				let data = [];
				const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
				 for(var item of params['result']){
					var mask = item.mask;
					if(mask != null){
						item.mask = mask.substring(mask.length - 4, mask.length)
					}
					if(item.autoritation == ""){
						item.autoritation = "N/A"
					}
					data.push({'ID':item.id,'NOMBRE':item.name_customer , 'APELLIDO': item.last_name,'DNI': item.identifier_customer , 'CODIGO': item.code_customer ,'PLAN':item.name_plan,'CENTRO':item.sucursal,'ESTATUS':item.desc_status,'TDC': item.mask,'MONTO':item.total_invoice,'Autorizacion':item.autoritation,'FECHA':item.created_at,'PROXIMO COBRO': item.next_bill})
				 }
			
				
				const options = { 
					fieldSeparator: ',',
					quoteStrings: '"',
					decimalSeparator: '.',
					useTextFile: false,
					filename: "Report-Invoices-"+date,
					showTitle: true,
   				    title: 'Facturas por fecha de creación - Fecha:' + date,
					useBom:false,
					useKeysAsHeaders: true,
				};
				const csvExporter = new ExportToCsv(options);

				this.toExport = data;
				csvExporter.generateCsv(this.toExport);
			
			}, err => {
				this.notificationService.error(err);
				console.log(err);
			}); 

		}else{
			this.notificationService.error("No existen registros para exportar");
		}
    
  	}


  	//-- 

	reset() {
		this.filter = new ReportFilter();
		this.plans = [];
		this.services = [];
		this.dataSource = new MatTableDataSource<any>([]);
		this.tableService.filter = new ReportFilter(this.filter);
		this.partnerS = new FormControl('');
		this.serviceS = new FormControl('');
		this.planS = new FormControl('');
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
		this.tableService.filter = new ReportFilter(this.filter);
		this.list();
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new ReportFilter(this.tableService.filter);
	}

	compareFn(c1: any, c2: any): boolean { 
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


}
