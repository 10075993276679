import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Plan} from "../plan/plan";
import {Customer_has_plansService} from "../customer_has_plans/customer_has_plans.service";
import {Partner} from "../partner/partner";
import {Servicio} from "../servicio/servicio";
import {Customer} from "../customer/customer";
import {Creditcard} from "../creditcard/creditcard";
import {CreditcardService} from "../creditcard/creditcard.service";


@Component({
  template: `
	<h3 class="title">Detalle del cliente</h3>

	<div *hasPermission="['hasplan.create']" class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre del cliente</span>
					<span>{{ customer?.name_customer || '-'}} {{ customer?.lastName_customer || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Compañia</span>
					<span>{{ partner?.name_partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Servicio</span>
					<span>{{ servicio?.name_service || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{ plan?.name_plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Numero de contrato</span>
					<span>{{ chp?.contractNumber_Plan || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Código de renovación de matricula del partner</span>
					<span>{{ chp?.idPlanEnrollmentrenewalPartner || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha de inscripción</span>
					<span>{{ chp?.startdate_customerPlan || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Próxima fecha de cobro</span>
					<span>{{ chp?.next_bill_date || 'No Disponible'}} </span>
				</div>
      		</div>

			

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha de culminación</span>
					<span>{{ chp?.endDate_customerPlan || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Tarjeta de crédito asociada</span>
					<span>{{ tarjeta?.maskCreditCard || '-'}} {{ tarjeta?.nameHolderCreditCard || '-' }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status || '-'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Monto Pendiente</span>
					<span>{{ chp?.balance_customerPlan || 'No Disponible'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">ID Factura actual</span>
					<span>{{ chp?.idCurrent_invoice || 'No disponible'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha de Último Cobro Exitoso</span>
					<span>{{ chp?.lastOkBillingDate || 'No Disponible'}} </span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha de Último Cobro No Exitoso</span>
					<span>{{ chp?.lastNokBillingDate || 'No Disponible'}} </span>
				</div>
			</div>

		</div>
	</div>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
`
})
export class CustomerHasPlansReadComponent {

  confirmDelete = true;

  public customer : Customer = new Customer();
  public chp;
  public plan : Plan = new Plan();
  public partner : Partner = new Partner();
  public servicio : Servicio = new Servicio();
  public tarjeta : Creditcard = new Creditcard();
  public status;


	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
    	private creditCardService : CreditcardService,
    	private hasplanService : Customer_has_plansService
	){}

	ngOnInit(){
		//Se carga el cliente asociado
		this.activatedRoute.params.subscribe(params =>{
			//Se carga la informacion del plan asociado al cliente
			this.hasplanService.getById(params["customerhasplansID"]).subscribe(customerhp => {
						
				this.chp = customerhp;
				this.customer = this.chp.customer;
				this.plan = this.chp.plan;
				this.servicio = this.chp.plan.service; 
				this.partner = this.chp.plan.service.partner;
				//Se carga el estado del plan asociado al cliente
				if(this.chp.status_customerPlan == 1){
					this.status = "Activo"
				}
				if(this.chp.status_customerPlan == 2){
					this.status = "Inactivo"
				}
				if(this.chp.status_customerPlan == 3){
					this.status = "Congelado"
				}
									  
				//Se carga la tarjeta de credito asociada
				this.creditCardService.findById(this.customer.id,this.chp['creditCardToken'].id).subscribe(params => {

					let token = params['result']['token_creditCardToken'];
							
					this.creditCardService.findByCustomer(this.customer.id).subscribe(params => {
						if(params['result'] != null) {
							let lastarjetas = params['result'];
							for (let i in lastarjetas) {
								let tdc = lastarjetas[i].creditCard;
								if(lastarjetas[i].tokenCreditCard == token){
									this.tarjeta = tdc;
								}
							}
						}
					});
				});
			});
		});
	}

	update(){
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	delete(this){
		this.chp.status_customerPlan = 2;
		this.hasplanService.update(this.chp).subscribe(param => {
			this.ngOnInit();
		}, (err) => { this.notificationService.error(err); });
	}
}
