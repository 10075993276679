import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../shared/notification/notification.service';
import {DatePipe} from '@angular/common';
import {ReportService} from '../report.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../utils/models/pager';
import { ReportFilter } from '../report.filter';
import { ExportToCsv } from 'export-to-csv';

@Component({
  template: `
	<h3 class="title">Bitacora de Renovaciones</h3>

	<mat-accordion>
		<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
			<mat-expansion-panel-header>
				<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
					<div class="field-row">
					
						<div class="field-row-item" *ngIf="tableService.filter.del">
							<span class="label">Del</span>
              <span *ngIf="tableService.filter.del == 1"> Dia </span>
              <span *ngIf="tableService.filter.del == 2"> Dia Anterior </span>
              <span *ngIf="tableService.filter.del == 3"> Mes </span>
              <span *ngIf="tableService.filter.del == 4"> Mes Anterior </span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.since_date">
							<span class="label">Desde</span>
							<span>{{tableService.filter.since_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.until_date">
							<span class="label">Hasta</span>
							<span>{{tableService.filter.until_date}}</span>
            </div>
            
            <div class="field-row-item" *ngIf="tableService.filter.id_billing">
							<span class="label">Renovacion</span>
							<span>{{tableService.filter.id_billing}}</span>
						</div>

					</div>
				</div>
				<button class="btn-icon"  title="Buscar" type="button">
					<i class="material-icons">search</i>
				</button>
			</mat-expansion-panel-header>

			<form>
        <fieldset class="fieldset">
          <div class="field-row">
            <div class="field-row-item">
              <label class="label" for="name_plan">Del</label>
              <input type="radio" value="1" (change)="dropBusqueda()" name="type">
            </div>
            <div class="field-row-item">
              <label class="label" for="name_plan">Rango de fecha</label>
              <input type="radio" value="2" (change)="rangoBusqueda()" name="type">
            </div>
					</div>
			
					<div class="field-row" *ngIf="del">
            <div class="field-row-item">
              <mat-select placeholder="Selecciona" [(value)]="filter.del" disableRipple>
                <mat-option *ngFor="let opt of types" [value]="opt.value">{{opt.desc}}</mat-option>
              </mat-select>
            </div>
					</div>
                   
          <div class="field-row" *ngIf="rango">

						<div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker" placeholder="Desde" [(ngModel)]="filter.since_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
            
            <div class="field-row-item">
              <input matInput  style="width:90%;background:ghostwhite" [matDatepicker]="picker2" placeholder="Hasta" [(ngModel)]="filter.until_date" [ngModelOptions]="{standalone: true}" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
          </div>
          
          <div class="field-row">
							<div class="field-row-item">
								<label class="label" for="service" >Renovacion</label>
								<mat-select placeholder="None" [(value)]="filter.id_billing" disableRipple>
									<mat-option *ngFor="let billing of billings" [value]="billing.id">{{billing.name_billingsetting}}</mat-option>
								</mat-select>
							</div>
          </div>
			
				</fieldset>
				<div class="options">
					<button class="btn-text gray" type="button" (click)="reset()">
						<span >Limpiar</span>
					</button>
					<button class="btn-text blue" type="button" (click)="search()">
						<span>Buscar</span>
					</button>
				</div>
			</form>
		</mat-expansion-panel>
  </mat-accordion>
  

  <div class="tool-bar-wrap both-side">
    <div class="right row">
    <button class="btn-icon" type="button" (click)="export()">
      <i class="material-icons">cloud_download</i>
    </button>
    </div>
  </div>


  <div class="mat-elevation-z8e" *ngIf="dataSource">
    <mat-form-field style="display:inline;" appearance = "standard">
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
		
			
	
			<ng-container matColumnDef="idprocess">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >ID Proceso</th>
				<td mat-cell *matCellDef="let element">{{element?.idprocess || '-'}}</td>
			</ng-container>
			
			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef mat-sort-header >Descripción</th>
				<td mat-cell *matCellDef="let element">{{element?.description || '-'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha creacion</th>
        <td mat-cell *matCellDef="let element">{{element.date_created}}</td>
      </ng-container>

      <ng-container matColumnDef="qtyprocessed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Cantidad</th>
        <td mat-cell *matCellDef="let element">{{element.qtyprocessed}}</td>
      </ng-container>

     

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;">
			</tr>
		</table>

		<mat-paginator showFirstLastButtons
			[length]="tableService.pager.totalEntities"
			[pageIndex]="tableService.pager.pageIndex"
			[pageSize]="tableService.pager.pageSize"
			[pageSizeOptions]="[20, 50, 100]"
			(page)="list($event)">
		</mat-paginator>
	</div>
`
})
export class BillingsettingtrackComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'idprocess', 'description', 'date_created', 'qtyprocessed'];
	confirmDelete = true;
	isOpenSearchPanel = false;
	filter = new ReportFilter(this.tableService.filter);

  items: any[] = [];
  rango = false;
  del = false;
  types: any[]= [];
  billings: any[]= [];

  constructor(
    public tableService: TableService<any>,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {
    this.filter = new ReportFilter();
    this.tableService = new TableService;
   }

  ngOnInit() {
    
    this.types = [{desc: 'Dia', value: 1}, {desc: 'Dia anterior', value: 2}, {desc: 'Mes', value: 3}, {desc: 'Mes Anterior', value: 4}];
    this.reportService.getBillingSettins().subscribe(response =>{
      this.billings = response['result'];
    });
  }

  list(event?: PageEvent) {

    if(this.filter.del == undefined && this.filter.since_date == undefined && this.filter.until_date == undefined){
      this.notificationService.error("Debe seleccionar un criterio de busqueda");
      return;
    }
   
    if(this.tableService.pager.pageSize != 20){
			this.tableService.pager.pageSize = 20;
		}

    if(this.filter.id_billing == null){
        this.notificationService.error("Debe seleccionar una renovacion");
        return;
    }
    if(this.filter.since_date != null && this.filter.until_date == null){
        this.notificationService.error("Debe ingresar el parametro de fecha : Hasta");
        return;
    }
    if(this.filter.since_date == null && this.filter.until_date != null){
        this.notificationService.error("Debe ingresar el parametro de fecha : Desde");
        return;
    }
    this.filter.since_date = this.datePipe.transform(this.filter.since_date, 'yyyy-MM-dd');
		this.filter.until_date = this.datePipe.transform(this.filter.until_date, 'yyyy-MM-dd');
    var since = new  Date (this.filter.since_date);
    var until = new  Date (this.filter.until_date);
    if(until.getTime() < since.getTime()){
      this.notificationService.error("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
      return;
    }

    this.tableService.filter = new ReportFilter(this.filter);
     
      
    let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
	
		httpParams = this.filter.getHttpParams(httpParams);


      this.reportService.getReportBillingTracks(httpParams).subscribe(params => {
        this.items = params['result']; // items que mostrara la tabla
        this.dataSource = new MatTableDataSource<any>(this.items);
        this.tableService.pager = params['pager'];
        this.tableService.selection.clear();
        if (this.items.length === 0) {
          this.notificationService.alert('No se encontraron resultados para la busqueda');
        } 
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      });

  }

  rangoBusqueda() {
    delete this.filter.del;
    this.rango = true;
    this.del = false;
  }

  dropBusqueda() {
    delete this.filter.since_date;
    delete this.filter.until_date;
    this.del = true;
    this.rango = false;
  }

  reset() {
    this.filter = new ReportFilter();
    this.dataSource = new MatTableDataSource<any>([]);
    this.tableService.filter = new ReportFilter(this.filter);
  }

  search() {
    this.isOpenSearchPanel = false;
    this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new ReportFilter(this.filter);
    this.list();
  }

  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new ReportFilter(this.tableService.filter);
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

export() {
  if(this.items.length > 0){
      
      this.tableService.filter = new ReportFilter(this.filter);


      let httpParams = this.reportService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	
      httpParams = this.filter.getHttpParams(httpParams);
      
      this.reportService.getReportBillingTracks(httpParams).subscribe(params => {
        let data = [];
         for(var item of params['result']){
          data.push({'ID PROCESO':item.idprocess,'DESCRIPCION':item.description,'FECHA CREACION':item.date_created,'CANTIDAD':item.qtyprocessed})
         }
      
        const date = this.datePipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          useTextFile: false,
          filename: "Report-Billings-"+date,
          useBom:false,
          useKeysAsHeaders: true,
          showTitle: true,
          title: 'Bitacora de renovaciones - Fecha:' + date,
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
      
      }, err => {
        this.notificationService.error(err);
        console.log(err);
      }); 

    }else{
      this.notificationService.error("No existen registros para exportar");
    }
    
  }


}
