import {Routes} from '@angular/router';
import {RebillingComponent} from './rebilling/rebilling.component';
import {ListRebillingComponent} from './list-rebilling/list-rebilling.component';
import {CreateRebillingComponent} from './create-rebilling/create-rebilling.component';
import {ReadRebillingComponent} from './read-rebilling/read-rebilling.component';
import {UpdateRebillingComponent} from './update-rebilling/update-rebilling.component';

export const rebillingRoutes: Routes = [
  {
    path: ':billingId/rebillings',
    component: RebillingComponent,
    data: {
      breadcrumb: 'Recobros',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ListRebillingComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: CreateRebillingComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':rebillingId',
        component: RebillingComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ReadRebillingComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: UpdateRebillingComponent,
            data: {
              breadcrumb: 'Actualizar',
              icon: 'update'
            }
          }

        ]
      }
    ]
  }
];
