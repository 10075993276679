
	<h3 class="title">Tablero</h3>

	<form [formGroup]="dashboardForm" (ngSubmit)="create()">
		<fieldset class="fieldset">
		
    <div class="field-row">

        <div class="field-row-item">
          <label class="label" for="updateEvery" >Actualizar Cada</label>
          <mat-select [formControl]="interval" disableRipple>
              <mat-option *ngFor="let opt of updateEvery" [value]="opt.value">{{opt.desc}}</mat-option>
          </mat-select>
          
        </div>

        <div class="field-row-item">
            <label class="label" for="company" >Compañias</label>
            <mat-select [formControl]="partner" multiple>
              <mat-option *ngFor="let opt of partners" [value]="opt.id">{{opt.name_partner}}</mat-option>
            </mat-select>    
        </div>

        <div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="status_dashboard" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
				</div>

    </div>


    <div class="field-row">

          <div class="field-row-item">
                  <label class="label" for="name_plan">Periodo</label>
                  <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" type="radio" value="1" (change)="dropBusqueda()" name="type">
          </div>

          <div class="field-row-item">
                  <label class="label" for="name_plan">Rango de fecha</label>
                  <input [(ngModel)]="radio" [ngModelOptions]="{standalone: true}" type="radio" value="2" (change)="rangoBusqueda()" name="type">
          </div>

    </div>
                        
    <div class="field-row" *ngIf="del">

          <div class="field-row-item">
                <mat-select [formControl]="periodS"  disableRipple>
                    <mat-option *ngFor="let opt of period" [value]="opt.value">{{opt.desc}}</mat-option>
                </mat-select>
                
          </div>

          <div class="field-row-item">
                                 
          </div>

    </div>
                            
      <div class="field-row" *ngIf="rango">
            <div class="field-row-item">
                  <input matInput formControlName="since_date" style="width:90%;" [matDatepicker]="picker" placeholder="Desde" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
                  
            <div class="field-row-item">
                <input matInput formControlName="until_date" style="width:90%;" [matDatepicker]="picker2" placeholder="Hasta" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </div>
            
		  </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!partner.valid || !status_dashboard.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>