
		<h3 class="title">Crear Compañía</h3>

		<form *ngIf="createPartnerForm" [formGroup]="createPartnerForm" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-account">Datos de la compañía</legend>

				<div class="field-row"> 
					<div class="field-row-item">
						<label class="label" for="name_partner">Nombre</label>
						<input formControlName="name_partner" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
						<validator [control]="createPartnerForm.controls['name_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="identifier_bank">Identificador del banco</label>
						<input formControlName="identifier_bank" type="text" autocomplete="off" placeholder="Ingrese Identificador" >
						<validator [control]="createPartnerForm.controls['identifier_bank']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="adress_partner" >Direccion</label>
						<textarea formControlName="adress_partner"></textarea>
						<validator [control]="createPartnerForm.controls['adress_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="country" >Pais</label>
						<mat-select [formControl]="createPartnerForm.controls['selectedCountry']" [compareWith]="compareFn" (selectionChange)="onItemChange($event.value)" disableRipple>
								<mat-option *ngFor="let country of countries" [value]="country.id">{{country.name_country}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['selectedCountry']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="city">Ciudad</label>
						<mat-select [formControl]="createPartnerForm.controls['selectedCity']" [compareWith]="compareFn" disableRipple>
							<mat-option *ngFor="let city of cities" [value]="city.id">{{city.name_city}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['selectedCity']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone_partner" >Telefono</label>
						<input type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone_partner"  placeholder="Ingrese Telefono">
						<validator [control]="createPartnerForm.controls['phone_partner']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="feeRate_partner">Porcentaje de cobro</label>
						<input type="text" maxlength="3" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="feeRate_partner"  placeholder="Ingrese porcentaje">
						<validator [control]="createPartnerForm.controls['feeRate_partner']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email_partner">Email</label>
						<input type="text" formControlName="email_partner" placeholder="Ingrese Email" >
						<validator [control]="createPartnerForm.controls['email_partner']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="status" >Estatus</label>
						<mat-select [formControl]="createPartnerForm.controls['status_partner']"  disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['status_partner']"></validator>
					</div>

					<div class="field-row-item">
						<label class="label" for="notify" >Notificar cobros</label>
						<mat-select [formControl]="createPartnerForm.controls['notify']" disableRipple>
							<mat-option *ngFor="let option of notify" [value]="option.value">{{option.description}}</mat-option>
						</mat-select>
						<validator [control]="createPartnerForm.controls['notify']"></validator>
					</div>
					
					
				</div>

				<div class="form-group">
				<input style="width:90%;" type="text" formControlName="returnRoute" placeholder="Ingrese ruta" hidden="true" >
                </div>

			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!createPartnerForm.valid">
					<span>Guardar</span>
				</button>
			</div>
		</form>
	