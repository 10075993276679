import { Routes } from '@angular/router';
import {FrecuencyComponent} from './component/frecuency/frecuency.component';
import {ListFrecuencyComponent} from './component/list-frecuency/list-frecuency.component';
import {ReadFrecuencyComponent} from './component/read-frecuency/read-frecuency.component';
import {UpdateFrecuencyComponent} from './component/update-frecuency/update-frecuency.component';
import {CreateFrecuencyComponent} from './component/create-frecuency/create-frecuency.component';
export const frecuencyRoutes: Routes = [
  {
    path: 'frecuencies',
    component: FrecuencyComponent,
    data: {
      breadcrumb: 'Frecuencias',
      icon: 'list'
    },
    children: [{
        path: '',
        pathMatch: 'full',
        component: ListFrecuencyComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: CreateFrecuencyComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':frecuencyId',
        component: FrecuencyComponent,
        data: {
          breadcrumb: 'Detalle',
          icon: 'detail'
        },
        children: [{
            path: '',
            pathMatch: 'full',
            component: ReadFrecuencyComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: UpdateFrecuencyComponent,
            data: {
              breadcrumb: 'Actualizar',
              icon: 'update'
            }
          }

        ]
      }
    ]
  }
];
