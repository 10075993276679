import {Routes} from '@angular/router';
import { IntegrationComponent } from './integration/integration.component';
import { IntegrationListComponent } from './integration-list/integration-list.component';
import { CreateIntegrationComponent } from './create-integration/create-integration.component';
import { ReadIntegrationComponent } from './read-integration/read-integration.component';
import { UpdateIntegrationComponent } from './update-integration/update-integration.component';

export const integrationRoutes: Routes = [
  {
    path: 'integrations',
    component: IntegrationComponent,
    data: {
      breadcrumb: 'Integraciones',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: IntegrationListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: CreateIntegrationComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':integrationId',
        component: IntegrationComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ReadIntegrationComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: UpdateIntegrationComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }

        ]
      }
    ]
  }
];

export const routingIntegrationComponents = [IntegrationComponent,IntegrationListComponent,CreateIntegrationComponent,ReadIntegrationComponent,UpdateIntegrationComponent];
