import { BaseModel } from '../shared/models/base.model';

export class Partner extends BaseModel{
	name_partner: string;
	identifier_bank: string;
	adress_partner: string;
	phone_partner: string;
	status_partner: number;
	parentPartnerId: number;
	city_id: number;
	feeRate_partner: number;
	idNumPriority_partner: number;
	email_partner: string;
	token: string;
	nameOf_status: string;

}
