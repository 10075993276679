import { Injectable } from '@angular/core';
import {BaseService} from '../shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OptionService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/option';

  httpOptions : any;

  constructor(private http: HttpClient) { 
    super(); 

    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }

  }

  getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  
  getById(id: number): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getByPlan(id: number,params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
      return this.http.get(this.BASE_URL + '/plan/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  create(group: any): Observable<any> {
    return this.http.post(this.BASE_URL, group, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  update(option: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + option['id'], option , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
}
