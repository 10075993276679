import {Routes} from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BuildDashboardComponent } from './build-dashboard/build-dashboard.component';


export const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      breadcrumb: 'Tablero',
      icon: 'create'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: BuildDashboardComponent,
        data: {
          breadcrumb: undefined
        }
      }
    ]
  }
];
