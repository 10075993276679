import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn : 'root'
})
export class FrecuencyService extends BaseService {

  private  readonly BASE_URL: string = this.HOST + '/frecuency';
  
  httpOptions : any;

	constructor(private http: HttpClient) { 
    super();
    // Http Headers
    this.httpOptions = {
      headers: this.createAuthorizationHeader()
    }
   }

	getAll(params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(this.BASE_URL, this.httpOptions)
      .pipe(catchError(this.handleError));
    }
    

  	getFrecuency(id: number): Observable <any> {
	  return this.http.get(this.BASE_URL + '/' + id, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
   	}

  update(frecuency: any): Observable<any> {
    return this.http.put(this.BASE_URL + '/' + frecuency['id'], frecuency , this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  create(frecuency: any): Observable<any> {
    return this.http.post(this.BASE_URL,  frecuency, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

}
