
  <h3 class="title">Editar Cupon</h3>

	<form  [formGroup]="updateCuponForm" (ngSubmit)="update()">
		<fieldset class="fieldset">
    <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_cupon">Nombre</label>
					<input formControlName="name_cupon" type="text" autocomplete="off" placeholder="Ingrese Nombre" readonly >
					<validator [control]="updateCuponForm.controls['name_cupon']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="type" >Tipo</label>
			
          <mat-select [formControl]="typeS" disabled="true">
            <mat-option *ngFor="let type of types" [value]="type.value">{{type.description}}</mat-option>
          </mat-select>
          <validator [control]="typeS"></validator>

				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="value">Costo</label>
					<input formControlName="value" type="number" autocomplete="off" placeholder="Ingrese valor" >
					<validator [control]="updateCuponForm.controls['value']"></validator>
				</div>
				<div class="field-row-item">
          <label class="label" for="status" >Estatus</label>

          <mat-select [formControl]="estatusS" disableRipple>
						<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
					</mat-select>
          <validator [control]="estatusS"></validator>
          
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="dateFrom">Fecha desde</label>
					<input formControlName="dateFrom" type="date"  placeholder="Ingrese costo" readonly >
					<validator [control]="updateCuponForm.controls['dateFrom']"></validator>
        </div>
        <div class="field-row-item">
					<label class="label" for="dateUntil">Fecha Hasta</label>
					<input formControlName="dateUntil" type="date"  placeholder="Ingrese fecha" >
					<validator [control]="updateCuponForm.controls['dateUntil']"></validator>
				</div>
			</div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!updateCuponForm.valid || !estatusS.valid">
				<span>Guardar</span>
			</button>
		</div>
