
	<h3 class="title">Detalle de auditoria</h3>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Usuario</span>
					<span>{{ audition?.user || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">IP</span>
					<span>{{ audition?.ip || '-'}}</span>
				</div>
      </div>
      

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">URL</span>
					<span>{{ audition?.url || '-'}}</span>
				</div>
      </div>
      

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Token</span>
					<span>{{ audition?.token || '-'}}</span>
				</div>
      </div>
      

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Json request</span>
					<span>{{ audition?.json || '-'}}</span>
				</div>
      </div>
      

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Funcion</span>
					<span>{{ audition?.function || '-'}}</span>
				</div>
      </div>
      

      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Codigo de respuesta</span>
					<span>{{ audition?.response_code || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Json de respuesta</span>
					<span>{{ audition?.json_response || '-'}}</span>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha</span>
					<span>{{ audition?.createdAt || '-'}}</span>
				</div>
			</div>

		

		</div>
  </div>
