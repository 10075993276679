
	<h3 class="title">Detalle de la renovacion</h3>

	<div class="tool-bar-wrap both-side">
		<div class="right row">
			<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
				<i class="material-icons">edit</i>
			</button>
			<button class="btn-icon" title="Eliminar" type="button" (click)="confirmDelete = false">
				<i class="material-icons">delete</i>
			</button>
		</div>
	</div>

	<div class="fieldset-wrap">
		<div class="fieldset">
		
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Compañia</span>
					<span>{{ partner || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Sucursal</span>
					<span>{{ sucursal || '-'}}</span>
				</div>
			</div>
<!--
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Servicio</span>
					<span>{{ billing?.plan?.service?.name_service || '-'}}</span>
				</div>
			</div>
			

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Plan</span>
					<span>{{  billing?.plan.name_plan || '-'}}</span>
				</div>
			</div>
-->
			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Nombre</span>
					<span>{{ billing?.name_billingsetting || '-'}}</span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Estatus</span>
					<span>{{ status || '-' }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fase</span>
					<span>{{ fase || '-' }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Frecuencia</span>
					<span>{{ time || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Próxima fecha de ejecución</span>
					<span>{{ billing?.next_date_execution_billingsetting || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha ultima ejecución</span>
					<span>{{ billing?.date_execution_billingsetting|| '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha ejecución de proceso almacenado</span>
					<span>{{ billing?.date_execution_storeprocedure || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Fecha ejecución exitosa de proceso almacenado</span>
					<span>{{ billing?.date_successfullexecution_storeprocedure|| '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Inicio del ciclo</span>
					<span>{{ billing?.cycle_start_billingsetting }} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Cantidad de deuda acumulada</span>
					<span>{{ billing?.qtyaccumulated_debt_billingsetting || '-'}} </span>
				</div>
			</div>

			<div class="field-row">
				<div class="field-row-item">
					<span class="label">Planes</span> <br>
					
						<span *ngFor="let b of billing?.billingPlans" >{{ b?.plan?.name_plan || '-'}}  <br></span>
					
				</div>
			</div>

		</div>
	</div>
	
	<div class="mat-elevation-z8e" *ngIf="dataSource">
	<table mat-table [dataSource]="dataSource" >
	
		

		<ng-container matColumnDef="date_created_billingsetting">
			<th mat-header-cell *matHeaderCellDef  >Fecha de creacion</th>
			<td mat-cell *matCellDef="let element">{{element.date_created_billingsetting}}</td>
		</ng-container>
		
		<ng-container matColumnDef="description_billingsetting">
			<th mat-header-cell *matHeaderCellDef  >Descripción</th>
			<td mat-cell *matCellDef="let element">{{element.description_billingsetting}}</td>
		</ng-container>
		
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;">
				</tr>
		</table>


	</div>

	<modal-ns [(closed)]="confirmDelete">
		<ng-template modalContentDirective>
			<div class="dialog-content">
				<div class="dialog-title" >Confirmacion</div>
				<div class="dialog-message" >
					Esta seguro de borrar este registro?
				</div>
				<div class="options">
					<button class="btn-text red" type="button" (click)="confirmDelete = true">
						<span >No</span>
					</button>
					<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
						<span >Si</span>
					</button>
				</div>
			</div>
		</ng-template>
	</modal-ns>
