import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionComponent } from './option/option.component';
import { OptionCreateComponent } from './option-create/option-create.component';
import { OptionListComponent } from './option-list/option-list.component';
import { OptionUpdateComponent } from './option-update/option-update.component';
import { OptionReadComponent } from './option-read/option-read.component';


import { SharedModule } from '../shared/shared.module';
import { homeRouting } from '../home/home.routes';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseService } from '../shared/services/base.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import { routingOptionComponnents } from './option.routes';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';



@NgModule({
    declarations: [
      routingOptionComponnents
  ],
  imports: [
    CommonModule,
	SharedModule,
	EditorModule,
		homeRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule, MatInputModule,
		MatButtonModule
  ],
	providers: [
		BaseService,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
	]
})
export class OptionModule { }
