
		<div class="error-message-wrap">
			<div *ngIf="control.hasError('noMatch')" class="error-message" i18n="@@validator-noMatches">No hay coincidencias</div>

			<div *ngIf="control.hasError('required')" class="error-message" i18n="@@validator-required">Requerido</div>

			<!--<div *ngIf="control.hasError('pattern')" class="error-message" i18n="@@validator-invalid">Invalido</div>-->

			<div *ngIf="control.hasError('emailRegex')" class="error-message" i18n="@@validator-email">Correo Invalido. El formato debe ser example@dot.com</div>

			<div *ngIf="(control.dirty || control.touched) && control.hasError('integerRegex')" class="error-message" i18n="@@validator-integer">Numero Invalido, solo numeros enteros</div>

			<div *ngIf="(control.dirty || control.touched) && control.hasError('numberRegex')" class="error-message" i18n="@@validator-number">Numero Invalido (usa '.' para numeros float)</div>

			<div *ngIf="control.hasError('minlength')" class="error-message" i18n="@@validator-minlength">Debe tener al menos {{control.getError('maxlength').requiredLength}} characters</div>

			<div *ngIf="control.hasError('maxlength')" class="error-message" i18n="@@validator-maxlength">DEbe tener maximo {{control.getError('maxlength').requiredLength}} characters</div>

			<div *ngIf="control.hasError('min')" class="error-message" i18n="@@validator-min">No debe ser menor que {{control.getError('min').minValue}}</div>

			<div *ngIf="control.hasError('max')" class="error-message" i18n="@@validator-max">No debe ser mayor que {{control.getError('max').maxValue}}</div>

			<div *ngIf="control.hasError('urlRegex')" class="error-message" i18n="@@validator-url">Url Invalida. El formato debe ser http://example.com o https://example.com</div>
		</div>
	