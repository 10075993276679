import {Routes} from '@angular/router';
import {BillingComponent} from './billing.component';
import {ListBillingComponent} from './list-billing/list-billing.component';
import {CreateBillingComponent} from './create-billing/create-billing.component';
import {ReadBillingComponent} from './read-billing/read-billing.component';
import {EditBillingComponent} from './edit-billing/edit-billing.component';
import {rebillingRoutes} from '../rebilling/rebilling-routing';

export const billingRoutes: Routes = [
  {
    path: 'billings',
    component: BillingComponent,
    data: {
      breadcrumb: 'Renovaciones',
      icon: 'list'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ListBillingComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: 'create',
        component: CreateBillingComponent,
        data: {
          breadcrumb: 'Crear',
          icon: 'create'
        }
      },
      {
        path: ':billingId',
        component: BillingComponent,
        data: {
          icon: 'detail'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ReadBillingComponent,
            data: {
              breadcrumb: 'Detalle',
            }
          },
          {
            path: 'update',
            component: EditBillingComponent,
            data: {
              breadcrumb: 'Actualizar',
              icon: 'update'
            }
          }

        ]
      },
      ...rebillingRoutes
    ]
  }
];
