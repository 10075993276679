import {Component,OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {Location} from '@angular/common';
import {NotificationService} from '../../shared/notification/notification.service';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from '../../shared/services/base.service';
import {ServicioService} from '../../servicio/servicio.service';
import {PlanService} from '../../plan/plan.service';
import {BillingService} from '../billing.service';
import {PartnerService} from '../../partner/partner.service';
import cronstrue from 'cronstrue';

@Component({
  template: `
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Editar renovacion Plan</h3>

	<form *ngIf="editBillingForm" [formGroup]="editBillingForm" (ngSubmit)="update()">
		<fieldset class="fieldset">      
      <input style="display:none;" type="text" formControlName="id" class="form-control" hidden="true" >

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="partner" >Compañia</label>
				
          <mat-select [formControl]="partner"  (selectionChange)="onPartnerChange($event.value)" disableRipple>
                <mat-option *ngFor="let partner of partners" [value]="partner.id">{{partner.name_partner}}</mat-option>
          </mat-select>

				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Sucursal</label>
          <mat-select [formControl]="sucursal" (selectionChange)="onSucursalChange($event.value)" disableRipple>
								<mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">{{sucursal.name_partner}}</mat-option>
					</mat-select>
				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="servicio" >Servicio</label>
          <mat-select [formControl]="service" (selectionChange)="onServiceChange($event.value)" disableRipple>
                <mat-option *ngFor="let serv of services" [value]="serv.id">{{serv.name_service}}</mat-option>
          </mat-select>
				</div>
				<div class="field-row-item">
					<label class="label" for="plan" >Plan</label>
          
        
          <mat-select  [formControl]="plan" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let bank of filteredRolesMulti | async" [value]="bank">
              {{bank.name_plan}}
            </mat-option>
          </mat-select>


          <validator [control]="plan"></validator>

				</div>
      </div>
      
      <div class="field-row">
				<div class="field-row-item">
					<label class="label" for="name_billingsetting" >Nombre</label>
					<input formControlName="name_billingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="editBillingForm.controls['name_billingsetting']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="status" >Estatus</label>
         
          <mat-select [formControl]="status_billing" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
          <validator [control]="status_billing"></validator>
          
				</div>
			</div>
			
	
      
      
      <div class="field-row">
        <div class="field-row-item">
          <label class="label" for="cycle" >Inicio ciclo</label>
          
          <mat-select [formControl]="cycle" disableRipple>
            <mat-option *ngFor="let cycle of cycles" [value]="cycle">{{cycle}}</mat-option>
          </mat-select>
          <validator [control]="cycle"></validator>


        </div>
        <div class="field-row-item">
          <label class="label" for="plan" >Cantidad de deuda acumulada</label>
         
          <mat-select [formControl]="accumulated" disableRipple>
            <mat-option *ngFor="let debt of debts" [value]="debt">{{debt}}</mat-option>
          </mat-select>
          <validator [control]="accumulated"></validator>

        </div>
      </div>

      <div class="field-row">
        <div class="field-row-item">
					<label class="label" >Frecuencia actual</label>
					<input formControlName="frequency_billingsetting" type="text" autocomplete="off" (click) ="onClick()" readonly>
				</div>
        <div class="field-row-item">
            <div *ngIf = "show">
              <label class="label" for="frecuency">Elija una Frecuencia nueva</label>
              <cron-jobs [formControl]="editBillingForm.controls['time_billingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
            </div>  
				</div>
      </div>

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!editBillingForm.valid || !status_billing.valid || !accumulated.valid || !cycle.valid || !plan.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class EditBillingComponent implements OnInit {

    public roleMultiFilterCtrl: FormControl = new FormControl();
    public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    @ViewChild('multiSelect1',{static: false}) multiSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  public cronConfig = {
    multiple:true,
    bootstrap:true,
    quartz:true
  };

  public cronValidate = {
    validate:true
  };
  
  billing : any;
  partners: any[]= [];

  sucursales: any[]= [];
  services: any[]= [];
  plans: any[]= [];

  cycles: any[]= [] ;

  debts: any[]= [] ;

  show = false;
  time_billingsetting;

  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  status_billing: FormControl = new FormControl('', Validators.required);
  accumulated: FormControl = new FormControl('', Validators.required);
  cycle: FormControl = new FormControl('', Validators.required);
  plan: FormControl = new FormControl('', Validators.required);

  partner: FormControl = new FormControl('', Validators.required);
  service: FormControl = new FormControl('', Validators.required);
  sucursal: FormControl = new FormControl('', Validators.required);

  // validaciones para campos de formulario
  editBillingForm = this.fb.group({
    name_billingsetting: ['', [Validators.required]],
    time_billingsetting:[''],
    frequency_billingsetting: [''],
    id: [''],
  });

  constructor(
    public fb: FormBuilder,
    private billingService: BillingService,
    private partnerService: PartnerService,
    private servicesService: ServicioService,
    private planServices: PlanService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(param => {
        this.billingService.getById(param['billingId']).subscribe( billing => {
        this.billing = billing;
        this.editBillingForm.controls['name_billingsetting'].setValue(billing.name_billingsetting);
        this.editBillingForm.controls['frequency_billingsetting'].setValue(cronstrue.toString(billing.time_billingsetting));
        this.time_billingsetting = billing.time_billingsetting;
        // this.editBillingForm.controls['time_billingsetting'].setValue(billing.time_billingsetting);
        this.editBillingForm.controls['id'].setValue(billing.id);
       
        // comienza la carga de informacion para Compañia - sucursal - servicio y plan

        // obtenemos todos los partners
        this.partnerService.getAllPartners().subscribe(partners => {
          this.partners = partners['result'];

          let planesAsociados = []
        

          if(this.billing.billingPlans.length > 0){ // En caso de que hayan planes asociados al billing
            
            // tomamos los planes asociados al billing
            for (let b of this.billing.billingPlans){
              planesAsociados.push(b.plan);
            }
          
            if(this.billing.billingPlans[0].plan.service.partner.parentPartner == null) { // no tiene parent, por lo tanto es el padre
            
              this.partner.setValue(this.partners[this.partners.findIndex(partner => partner.id === this.billing.billingPlans[0].plan.service.partner.id )].id) ;

                  this.servicesService.getByPartner(this.partner.value).subscribe( services => { // cargamos los servicios de ese partner
                    
                    this.services = services['result'];
                    if (this.services.length === 1) {
                      this.service.setValue(this.services[this.services.findIndex( service => service.id === billing.billingPlans[0].plan.service.id)].id);
                      this.planServices.getByService(this.services[0].id).subscribe( plans => { // buscamos los planes de ese servicio
                        this.plans = plans['result'];
                       
                         // set initial selection
                        this.plan.setValue(planesAsociados);
                        // load the initial role list
                        this.filteredRolesMulti.next(this.plans.slice());
                        // listen for search field value changes
                        this.roleMultiFilterCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                              this.filterRoleMulti();
                        });
                      }, error1 => {
                        this.notificationService.error('Error cargando los planes del servicio');
                        console.log(error1);
                      });

                    }else { // hay varios servicios
                      this.service.setValue(this.services[this.services.findIndex( service => service.id === billing.billingPlans[0].plan.service.id)].id);
                      
                      this.planServices.getByService(this.service.value).subscribe( plans => { // buscmaos los planes
                        this.plans = plans['result'];
                        // set initial selection
                        this.plan.setValue(planesAsociados);
                        // load the initial role list
                        this.filteredRolesMulti.next(this.plans.slice());
                        // listen for search field value changes
                        this.roleMultiFilterCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                              this.filterRoleMulti();
                        });
                      }, error1 => {
                        this.notificationService.error('Error cargando los planes del servicio');
                        console.log(error1);
                      });
                    }
                  }, err => {
                    this.notificationService.error('Error al cargar los servicios de la Compañia');
                    console.log(err);
                  });
            } else { // el partner del plan del billing, es una sucursal, osea tiene un padre - por lo tanto ese padre es la Compañia y debemos buscar sus sucursales asi como el index del partner del plan del billing dentro de esas sucursales
              
              this.partner.setValue(this.partners[this.partners.findIndex(partner => partner.id === this.billing.billingPlans[0].plan.service.partner.parentPartner.id )].id);
              
              this.partnerService.getPartnersByParent(this.partner.value).subscribe( sucursales => { // buscamos las sucursales de ese parent

                this.sucursales = sucursales['result'];

                this.sucursal.setValue(this.sucursales[this.sucursales.findIndex( sucur => sucur.id === this.billing.billingPlans[0].plan.service.partner.id )].id)
              
                this.servicesService.getByPartner(this.sucursal.value).subscribe( services => { // cargamos los servicios de ese partner

                  this.services = services['result'];

                  if (this.services.length === 1) {

                    this.planServices.getByService(this.services[0].id).subscribe( plans => { // buscamos los planes de ese servicio
                      this.plans = plans['result'];
                        
                        this.service.setValue(this.services[0].id);
                        // set initial selection
                        this.plan.setValue(planesAsociados);
                        // load the initial role list
                        this.filteredRolesMulti.next(this.plans.slice());
                        // listen for search field value changes
                        this.roleMultiFilterCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                              this.filterRoleMulti();
                        });
                    }, error1 => {
                      this.notificationService.error('Error cargando los planes del servicio');
                      console.log(error1);
                    });
                  }else { // hay varios servicios
                    this.service.setValue(this.services[this.services.findIndex( service => service.id === billing.plan.service.id)].id);
                    this.planServices.getByService(this.service.value).subscribe( plans => { // buscmaos los planes
                      this.plans = plans['result'];
                       // set initial selection
                       this.plan.setValue(planesAsociados);
                       // load the initial role list
                       this.filteredRolesMulti.next(this.plans.slice());
                       // listen for search field value changes
                       this.roleMultiFilterCtrl.valueChanges
                           .pipe(takeUntil(this._onDestroy))
                           .subscribe(() => {
                             this.filterRoleMulti();
                       });
                    }, error1 => {
                      this.notificationService.error('Error cargando los planes del servicio');
                      console.log(error1);
                    });
                  }
                }, err => {
                  this.notificationService.error('Error al cargar los servicios de la Sucursal');
                  console.log(err);
                });
              }, error1 => {
                this.notificationService.error('Error cargando las sucursales del parent');
                console.log(error1);
              });
            }
            // termina la carga de datos con respecto a la informacion de Compañia - sucursal - servicio y plan
          }else{
            this.notificationService.alert("Renovación sin plan asociado")
          }
          
       
          for ( let i = 0; i <= 30; i ++ ) {
            this.cycles.push(i);
          }
          for ( let i = 1; i <= 30; i ++ ) {
            this.debts.push(i);
          }
         
          this.status_billing.setValue(this.estatus[this.estatus.findIndex(e => billing.status_billingsetting === e.value)].value);
          this.cycle.setValue(this.cycles[this.cycles.findIndex(cycle => cycle === billing.cycle_start_billingsetting)]);
          this.accumulated.setValue(this.debts[this.debts.findIndex(debt => debt === billing.qtyaccumulated_debt_billingsetting)]);
         
        }, err => {
          this.notificationService.error(err);
          console.log(err);
        });
      });
    });
  }

  update() {
    const obj = <any> BaseService.builderObject(this.editBillingForm.value);
    obj.plans = [];
    // tomamos los ids de los planes para enviarlos
    for (let b of this.plan.value){
      obj.plans.push(b.id);
    }
    obj.status_billingsetting = this.status_billing.value;
    obj.cycle_start_billingsetting = this.cycle.value;
    obj.qtyaccumulated_debt_billingsetting = this.accumulated.value;
    // Removemos el campo que colocamos en el formulario para mostrar el cron entendible para el usuario
    delete obj.frequency_billingsetting;
    // Esto es en caso de que NO se elija una frecuencia nueva. 
    if(obj.time_billingsetting == null){
      obj.time_billingsetting = this.time_billingsetting;
    }
    this.billingService.update(obj).subscribe(billing => {
      this.notificationService.sucessInsert(billing['name_billingsetting']);
      this.location.back();
    },  err => {
      this.notificationService.error(err);
      console.log(err);
    });
  }

  onPartnerChange(partner:any) {
    this.services = [];
    this.service.reset()
    this.sucursales = [];
    this.sucursal.reset()
    this.plans = [];
    this.plan.reset();
    if(partner != undefined){
      this.partnerService.getPartnersByParent(partner).subscribe(sucursales => {
        this.sucursales = sucursales['result'];
        if (this.sucursales.length === 0) {
          this.notificationService.info('Info', 'Esta Compañia no posee sucursales');
          this.servicesService.getByPartner(partner).subscribe( services => {
            this.services = services['result'];
            if (this.services.length === 0) {
              this.notificationService.info('Info', 'Esta Compañia no posee servicios');
            }
          }, err => {
            this.notificationService.error('Error al cargar los servicios de la sucursal');
            console.log(err);
          });
        }
      }, err => {
        this.notificationService.error('Error consultando las sucursales del partner seleccionado');
        console.log(err);
      });
    }
  }

  onSucursalChange(sucursal:any) {
    this.services = [];
    this.service.reset()
    this.plans = [];
    this.plan.reset();
    if(sucursal!=undefined){
      this.servicesService.getByPartner(sucursal).subscribe( services => {
        this.services = services['result'];
        if (this.services.length === 0) {
          this.notificationService.info('Info', 'Esta sucursal no posee servicios');
        }
      }, err => {
        this.notificationService.error('Error al cargar los servicios de la sucursal');
        console.log(err);
      });
    }
  }

  onServiceChange(service:any) {
    this.plans = [];
    this.plan.reset();
    if(service!=undefined){
      this.planServices.getByService(service).subscribe( plans => {
        this.plans = plans['result'];
        if (this.plans.length === 0) {
          this.notificationService.info('Info', 'Este servicio no posee planes registrados');
          return;
        }
        
        // set initial selection
        this.plan.setValue([]);
        // load the initial role list
        this.filteredRolesMulti.next(this.plans.slice());
        // listen for search field value changes
        this.roleMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterRoleMulti();
        });
      }, error1 => {
        this.notificationService.error('Error cargando los planes del servicio');
        console.log(error1);
      });
    }
  }

   compareFn(c1: any, c2: any): boolean { 
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  onClick(){
    this.show = true;
  }

  ngAfterViewInit() {
    this.setInitialValueRole();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValueRole() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterRoleMulti() {

    if (!this.plans) {
      console.log("pasa por aqui");
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      console.log("pasa por aqui2");
      this.filteredRolesMulti.next(this.plans.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.plans.filter(bank => bank.name_plan.toLowerCase().indexOf(search) > -1)
    );

  }

}
