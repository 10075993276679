import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, Validators, FormControl} from '@angular/forms';
import {NotificationService} from '../../shared/notification/notification.service';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from '../../shared/services/base.service';
import {BillingService} from '../../billing/billing.service';
import {RebillingService} from '../rebilling.service';
import cronstrue from 'cronstrue';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { CustomValidators } from '../../shared/form/custom.validators';

@Component({
  template: `
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
  <style>
    label{
      color: #adadad;
    },
    a{
      color: #3f3f3f;
    }
  </style>

	<h3 class="title">Crear Recobro</h3>

	<form *ngIf="createRebillingForm" [formGroup]="createRebillingForm" (ngSubmit)="create()">
		<fieldset class="fieldset">

			<div class="field-row">
				<div class="field-row-item">
          <label  for="name_billingsetting">Renovacion:</label>
          <input type="text" formControlName="name_billingsetting"  class="form-control" readonly>
				</div>
				<div class="field-row-item">
					<label class="label" for="sucursal" >Nombre del recobro</label>
          <input formControlName="name_rebillingsetting" type="text" autocomplete="off" placeholder="Ingrese Nombre" >
					<validator [control]="createRebillingForm.controls['name_rebillingsetting']"></validator>
        </div>
      </div>




      <div class="field-row">
          <div class="field-row-item">
            <label  for="retry_frecuency_rebillingsetting">Dias para intentar cobro</label>
            <input type="text" (keypress)="onlyNumberKey($event)" formControlName="retry_frecuency_rebillingsetting"  class="form-control" >
            <validator [control]="createRebillingForm.controls['retry_frecuency_rebillingsetting']"></validator>
          </div>
				<div class="field-row-item">
					<label class="label" for="daysForRebilling" >Dias de corte</label>
          <mat-select  [formControl]="createRebillingForm.controls['daysForRebilling']" [multiple]="true" #multiSelect1>
            <ngx-mat-select-search [formControl]="roleMultiFilterCtrl"></ngx-mat-select-search>
            <mat-option *ngFor="let day of filteredRolesMulti | async" [value]="day">
              {{day}}
            </mat-option>
          </mat-select>
          <validator [control]="createRebillingForm.controls['daysForRebilling']"></validator>
				</div>
      </div>

      

      <div class="field-row">
				<div class="field-row-item">
          <label>Elija una frecuencia:</label>
          <cron-jobs [formControl]="createRebillingForm.controls['time_rebillingsetting']"  [config]="cronConfig" [validate]="cronValidate" ></cron-jobs>
          {{frecuency}}
        </div>
        <div class="field-row-item">
          <label class="label" for="estatus" >Estatus</label>
         
          <mat-select [formControl]="createRebillingForm.controls['status_rebillingsetting']" disableRipple>
							<mat-option *ngFor="let status of estatus" [value]="status.value">{{status.description}}</mat-option>
						</mat-select>
					<validator [control]="createRebillingForm.controls['status_rebillingsetting']"></validator>

        </div>
      </div>
      
      
      <div class="field-row">
        <div class="field-row-item">
          <label>Intentos de recobros:</label>
          <div style="display: flex">
            <div style="width: 50%">
              <div style="display: flex">
                <input formControlName="radio" style="width: 10%; height: 20px" type="radio" value="1">  Por cantidad de Intentos
              </div>
              <div style="display: flex">
                <input formControlName="radio" style="width: 10%; height: 20px"type="radio"  value="2"> Por cantidad de dias
              </div>
            </div>
            <div style="width: 50%">
            
              <mat-select [formControl]="recobro" disableRipple>
                <mat-option *ngFor="let recobro of recobros" [value]="recobro">{{recobro}}</mat-option>
              </mat-select>
              <validator [control]="recobro"></validator>

            </div>
          </div>
        </div>
      </div>
      
      <div class="field-row" style="background: ghostwhite">
				<div class="field-row-item">
          <label>Aplicar Rebilling en: </label>
          <div >
            <div style="width: 100%; margin: 0 0 0 10px">
              <div *ngFor="let code of rebillingCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{code.id}}" name="SomeName" (change)="changeEvent($event,code)" /> {{code.code_errorcodebac + " - " + code.description_errorcodebac}}</div>
            </div>
          </div>
        </div>

        <div class="field-row-item">
        <div style="display: block">
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onAdd()" > > </button>
            <button type="button" style="position: relative; top: 40%; left: 10%;" (click)="onRemov()" > < </button>
        </div>
        </div>
        
				<div class="field-row-item">
          <div class="form-subgroup" >
            <label>Codigo Errores del Api: </label>
            <div>
              <div style="width: 100%">
                <div *ngFor="let error of errorCodes"  > <input style="width: 10%; height: 20px; margin: 0px; padding: 0px;" type="checkbox" value="{{error.id}}" name="SomeName" (change)="checkErrorCodes($event,error)" /> {{error.code_errorcodebac + " - " + error.description_errorcodebac}}</div>
              </div>
            </div>
          </div>
				</div>
      </div>
		

		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!createRebillingForm.valid || !recobro.valid">
				<span>Guardar</span>
			</button>
		</div>
	</form>
`
})
export class CreateRebillingComponent implements OnInit {

  days = ['01','02','03','04','05','06','07','08','09','10'
  ,'11','12','13','14','15','16','17','18','19','20'
  ,'21','22','23','24','25','26','27','28','29','30','31'];
  // Para dias
  public roleMultiCtrl: FormControl = new FormControl();
  public roleMultiFilterCtrl: FormControl = new FormControl();
  public filteredRolesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect1',{static: false}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  frecuency;

  public cronConfig = {
    multiple:true,
    bootstrap:true,
    quartz:true
  };

  public cronValidate = {
    validate:true
  };
  estatus : any[] = [{value:1,description:"Activo"},{value:2,description:'Inactivo'}];
  recobro: FormControl = new FormControl('', Validators.required);
  recobros: any[]= [] ;
  rebillingCodes: any[]= [] ;
  seleccionados: any[]= [] ;
  errorCodeSeleccionados: any[]= [] ;
  errorCodes: any[]= [];
  billing;


  // validaciones para campos de formulario
  createRebillingForm = this.fb.group({
    name_rebillingsetting: ['', [Validators.required]],
    status_rebillingsetting: ['', [Validators.required]],
    progress_rebillingsetting: ['', ],
    name_billingsetting : [''],
    radio : '',
    daysForRebilling: [''],
    retry_frecuency_rebillingsetting:['', [Validators.required,CustomValidators.min(1)]],
    time_rebillingsetting:['', [Validators.required]]
  });

  constructor(
    public fb: FormBuilder,
    private rebillingService: RebillingService,
    private billingService: BillingService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    // load the initial role list
    this.filteredRolesMulti.next(this.days.slice());
    // listen for search field value changes
    this.roleMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRoleMulti();
      });

    this.onItemChange();
    this.activatedRoute.parent.params.subscribe(param => {
      this.billingService.getById(param['billingId']).subscribe(billing => {
        this.billing = billing;
        this.createRebillingForm.controls['name_billingsetting'].setValue(billing.name_billingsetting);
        for ( let i = 1; i <= 120; i ++ ) {
          this.recobros.push(i);
        }
       
        this.rebillingService.getAllErrorsCodeBac().subscribe( errors => { // listamos los codigo de errores de bac registrados
          this.errorCodes = errors['result'];
        }, error1 => {
          this.notificationService.error('Error cargando los ErrorCodeBac');
          console.log(error1);
        });

      }, error1 => {
        this.notificationService.error('Error cargando la informacion del billing');
        console.log(error1);
      });
    }, error1 => {
      this.notificationService.error('Error tomando el parametro del billign desde la url');
      console.log(error1);
    });
  }

  create() {
    const obj = <any> BaseService.builderObject(this.createRebillingForm.value);
  
    if (obj.radio === null) {
      this.notificationService.error('Debe seleccionar un intento de recobro para el rebilling');
      return;
    }
    if(obj.radio == "1"){
      obj.typeoftry_rebillingsetting = 1;
    }else{
      obj.typeoftry_rebillingsetting = 2;
    }
    obj.qtytry_rebillingsetting = this.recobro.value;
    delete obj.radio; // eliminamos esta propiedad del radiobutton
    delete obj.name_billingsetting; // borramos este parametro que es extra;
    obj.billingSetting = {id: this.billing.id}; // seteamos el billing al que pertenece el rebilling
    //obj.status_rebillingsetting = this.selectedStatus.value;
    obj.progress_rebillingsetting = 1;
    const errorCodeBacs = [];
    for (let i = 0 ; i < this.rebillingCodes.length; i++) {
      errorCodeBacs.push({id: this.rebillingCodes[i].id});
    }
    obj.errorCodeBacs = errorCodeBacs;
    this.rebillingService.create(obj).subscribe(rebilling => {
      this.notificationService.sucessInsert(rebilling['name_rebillingsetting']);
      this.location.back();
    }, error1 => {
      this.notificationService.error('Error creando el Rebilling');
      console.log(error1);
    });
  }

  changeEvent(event, prueba) {
    if (event.target.checked) {
      this.seleccionados.push(prueba);
    } else {
      this.seleccionados.splice(this.seleccionados.indexOf(prueba), 1);
    }
  }
  checkErrorCodes(event, prueba) {
    if (event.target.checked) {
      this.errorCodeSeleccionados.push(prueba);
    } else {
      this.errorCodeSeleccionados.splice(this.seleccionados.indexOf(prueba), 1);
    }
  }
  onAdd() {
    let index;
    for (let i = 0 ; i < this.seleccionados.length; i++) {
      index = this.rebillingCodes.findIndex(e => e.id === this.seleccionados[i].id);
      this.rebillingCodes.splice(index, 1); // removemos de aplicar rebilling en
      this.errorCodes.push(this.seleccionados[i]); // agregamos en codigo de errores del ap
    }
    this.seleccionados = [];
  }
  onRemov() {
    let index;
    for (let i = 0 ; i < this.errorCodeSeleccionados.length; i++) {
      index = this.errorCodes.findIndex(e => e.id === this.errorCodeSeleccionados[i].id);
      this.errorCodes.splice(index, 1); // removemos de aplicar rebilling en
      this.rebillingCodes.push(this.errorCodeSeleccionados[i]); // agregamos en codigo de errores del ap
    }
    this.errorCodeSeleccionados = [];
  }

  onItemChange(){
    this.createRebillingForm.get('time_rebillingsetting').valueChanges.subscribe(val =>{
      if(val != ''){
        this.frecuency = "La frecuencia se ejecutara: " + cronstrue.toString(val);
      }
    });
  }



  ngAfterViewInit() {
    this.setInitialValueRole();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValueRole() {
    this.filteredRolesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterRoleMulti() {

    if (!this.days) {
      return;
    }
    // get the search keyword
    let search = this.roleMultiFilterCtrl.value;
    if (!search) {
      this.filteredRolesMulti.next(this.days.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRolesMulti.next(
      this.days.filter(bank => bank.toLowerCase().indexOf(search) > -1)
    );

  }

  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
