import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {homeRouting} from '../home/home.routes';
import {PermissionService} from './permission.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule
  ],
  declarations: [],
  providers: [
    PermissionService
  ]
})
export class PermissionModule { }
