import { Component, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { HomeFilter } from './home.filter';
import { TableService } from '../utils/models/pager';
import { HomeService } from './home.service';
import {NotificationService} from '../shared/notification/notification.service';



@Component({
    template: `
    <div *ngIf="show">
    
        <h3 class="title">Procesamiento de Facturas</h3>

        <mat-accordion>
            <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                <mat-expansion-panel-header>
                    <button class="btn-icon"  title="Buscar" type="button">
                        <i class="material-icons">timer</i>
                    </button>
                </mat-expansion-panel-header>

                <form>
                    <fieldset class="fieldset">
                        <mat-tab-group>
                            <mat-tab label="Actualizar cada">  
                                <div class="field-row">
                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(1)">1 Minuto</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(2)">5 Minutos</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(3)">10 Minutos</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(4)">30 Minutos</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                    <div class="field-row-item">
                                        <mat-list>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(5)">1 Hora</button> </mat-list-item>
                                            <mat-list-item> <button mat-raised-button (click)="intervalChange(6)">2 Horas</button> </mat-list-item>
                                        </mat-list>
                                    </div>

                                </div>
                            </mat-tab>
                            <mat-tab label="Periodo"> 
                                <div class="field-row">
                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(1)">Hoy</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(2)">Ayer</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(3)">Esta semana</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(4)">Semana pasada</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(5)">Este mes</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(6)">Mes pasado</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                <div class="field-row-item">
                                    <mat-list>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(7)">Este año</button> </mat-list-item>
                                        <mat-list-item> <button mat-raised-button (click)="periodChange(8)">Año pasado</button> </mat-list-item>
                                    </mat-list>
                                </div>

                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </fieldset>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
        
        <div ><canvas #lineChart>{{ chart }}</canvas></div>
    
        </div>

        

      `,
  })

  export class ResumenComponent{

    dashboardSetting : any;

    @ViewChild('lineChart',{static: false}) private chartRef;
    chart: any; 
    filter = new HomeFilter(this.tableService.filter);
    labels = [];
    data = [];
    interval = 60000;
    intervalUpdate;
    partners = [];
    colors = [];
    isOpenSearchPanel = false;

    show = false;



    constructor(public tableService: TableService<any>, 
        private homeService: HomeService,
        private notificationService: NotificationService) {}

      ngOnInit() {
            this.homeService.getByAuthId(JSON.parse(localStorage.getItem('currentData')).user.authUser.id).subscribe(response =>{
                if(response['id']){
                this.dashboardSetting = response;
                    if(this.dashboardSetting.status_dashboard == 1){
                        this.show = true;
                        this.intervalUpdate = setInterval(function(){
                            this.generateData();
                        }.bind(this), this.dashboardSetting.interval_time);
                        this.generateData() 
                    }else{
                        this.show = false;
                    }
                }
            });    
        }
        

        ngOnDestroy(){
            clearInterval(this.intervalUpdate);
        }

        generateData(){
           
            
            if(this.dashboardSetting.period != -1){
                this.filter.period = this.dashboardSetting.period;
            }else{
                this.filter.day_since_date = this.dashboardSetting.since_date;
                this.filter.day_until_date = this.dashboardSetting.until_date;
            }

            this.filter.partners = this.dashboardSetting.partner.toString();
        
           

            let httpParams = this.homeService.buildRequestParams(this.tableService.sort,"m",
			  {pageIndex: -1 , pageSize: -1})
	
			httpParams = this.filter.getHttpParams(httpParams);

            this.homeService.getReportInvoices(httpParams).subscribe(response => {
               
                this.labels = [];
                this.data = [];
                this.colors = [];
            
                if(response['result'].length){
                    for(var inv of response['result']){
                        this.labels.push(inv['description']);
                        this.data.push(inv['total']);
                        this.colors.push(inv['color']);
                    }
                    this.generateChart();
                }else{
                    this.generateVoidChart();
                }
                            
            }, err => {
                this.notificationService.error(err);
                console.log(err);
              })
        }

    
        generateChart(){
            this.chart = new Chart(this.chartRef.nativeElement, {
                //polarArea
                type: 'pie',
                data: {
                    labels: this.labels, 
                    datasets: [{
                        data: this.data,
                        backgroundColor: this.colors
                    }],
                    borderColor:'#fff',
                    borderWidth: 1
                },
                options: {
                    events: ['click'],
                    animation:{
                    animateRotate:false
                  }
                }
              });
        }

        generateVoidChart(){
            this.chart = new Chart(this.chartRef.nativeElement, {
                type: 'pie',
                data: {
                    labels: ['No hay resultados'], 
                    datasets: [{
                        data: [100],
                        backgroundColor: []
                    }],
                    borderColor:'#fff',
                    borderWidth: 1
                },
                options: {
                    events: ['click'],
                    animation:{
                    animateRotate:false
                  }
                }
              });
        }

        openSearchPanel(value: boolean) {
            this.isOpenSearchPanel = value;
        }

        intervalChange(value){
            var newInterval
            switch(value){
                case 1:{
                    newInterval = 60000;
                    break;
                }
                case 2:{
                    newInterval = 300000;
                    break;
                }
                case 3:{
                    newInterval = 600000;
                    break;
                }
                case 4:{
                    newInterval = 1800000;
                    break;
                }
                case 5:{
                    newInterval = 3600000;
                    break;
                }
                case 6:{
                    newInterval = 7200000;
                    break;
                }
            }

            this.intervalUpdate = setInterval(function(){
                this.generateData();
            }.bind(this), newInterval);
            this.generateData()

            this.isOpenSearchPanel = false; 
        }
        
        periodChange(value){
            // esto es por si anteriormente se buscaba por rango de fecha
            if(this.dashboardSetting.period == -1){
                this.dashboardSetting.since_date = null;
                this.dashboardSetting.until_date = null;
            }
            this.dashboardSetting.period = value;
            this.generateData();
            this.isOpenSearchPanel = false;
        }

    }

    

  