
              <h3 class="title" >Detalle del usuario</h3>

              <div class="tool-bar-wrap both-side">
                <div class="right row">
                  <button class="btn-icon" title="Cambiar contraseña" type="button" (click)="updatePassword()">
                    <i class="material-icons">vpn_key</i>
                  </button>
                </div>
              </div>

              <div class="fieldset-wrap">
                <div class="fieldset">
                  <div class="legend" >Informacion</div>

                  <div class="field-row">
                    <div class="field-row-item">
                      <span class="label" >Correo</span>
                      <span>{{user.authUser?.email || '-'}}</span>
                    </div>
                  </div>
                  <div class="field-row">
                    <div class="field-row-item">
                      <span class="label" >Nombre</span>
                      <span>{{user.name_user || '-'}}</span>
                    </div>
                    <div class="field-row-item">
                      <span class="label" >Apellido</span>
                      <span>{{user.lastName_user || '-'}}</span>
                    </div>
                  </div>
                
                </div>
