import { Component, OnInit } from '@angular/core';

@Component({
  template: `
	<h3 class="title">Leer Cupon</h3>
`
})
export class ReadCuponComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
